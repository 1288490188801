import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Navbar from "react-bootstrap/Navbar";
import logo from "../images/logo.png";
import Loader from "./Loader";
import axios from "axios";
import moment from "moment";

function NavBar() {
  const navigate = useNavigate();
  const [loading, setIsLoading] = useState(false);
  const [greeting, setGreeting] = useState("");

  const updateGreeting = () => {
    const currentTime = moment();
    const currentHour = currentTime.hour();
    let newGreeting = "";

    if (currentHour >= 5 && currentHour < 12) {
      newGreeting = "Good Morning";
    } else if (currentHour >= 12 && currentHour < 17) {
      newGreeting = "Good Afternoon";
    } else {
      newGreeting = "Good Evening";
    }

    newGreeting +=
      " " + JSON.parse(localStorage.getItem("mtm_user_data"))?.user_full_name;

    setGreeting(newGreeting);
  };
  async function logoutButton() {
    try {
      const email = JSON.parse(localStorage.getItem("mtm_user_data"))?.email;
      setIsLoading(true);
      const data = {
        email: email,
        app_type: "pricing",
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/mtmuser/logout`,
        data
      );

      if (response.status == 200) {
        setIsLoading(false);
        if (response.data.success) {
          localStorage.removeItem("mtm_user_token");
          localStorage.removeItem("mtm_user_data");
          navigate("/");
        }
      } else {
        setIsLoading(false);
        alert("Error", response.data.msg);
        console.log("Error", response.data.msg);
      }
    } catch (error) {
      setIsLoading(false);

      alert("Error: " + error?.response?.data?.msg);
      console.log("Error", error);
    }
  }
  useEffect(() => {
    updateGreeting(); // Initial update when the component mounts
  }, []);
  return (
    <>
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand href="#">
            <img src={logo} style={{ width: "140px", height: "52px" }}></img>
          </Navbar.Brand>
          <div className="fw-600 mt-2 fs-18" style={{ fontSize: "18px" }}>
            {greeting}
          </div>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Form className="d-flex ms-auto">
              <button
                type="button"
                disabled={loading}
                className="logout-btn"
                onClick={logoutButton}
              >
                {loading ? <Loader /> : "Logout"}
              </button>
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavBar;
