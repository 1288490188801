import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { conditionalExp, getCountriesFn } from "../utils/commonFn";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "./Loader";
import Select from "react-select";
import ErrorModal from "./ErrorModal";
import { SuccessModal } from "./SuccessModal";
import axios from "axios";
import validator from "validator";

function OfficeDetails() {
  const [isLoading, setIsLoading] = useState(false);
  const [showadd, setShowadd] = useState(false);
  const [optionsForCountries, setoptionsForCountries] = useState([]);
  const [officeList, setOfficeList] = useState([]);
  const [showErrModal, setShowErrModal] = useState(false);
  const [error, setError] = useState({ title: "", err: "" });
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [modalData, setModalData] = useState({
    office_name: null,
    office_address1: null,
    office_address2: null,
    country: "",
    contact_person_name: null,
    contact_person_email: null,
    contact_person_phone: null,
  });
  const [modalDataErrors, setModalDataErrors] = useState({
    office_name: false,
    office_address1: false,
    office_address2: false,
    country: false,
    contact_person_name: false,
    contact_person_email: false,
    contact_person_phone: false,
  });
  const navigate = useNavigate();
  const { career_id } = useParams();

  const handleChange = (e) => {
    setModalData({
      ...modalData,
      [e.target.id]: e.target.value,
    });
    setModalDataErrors({
      ...modalDataErrors,
      [e.target.id]: false,
    });
  };

  const validateEmail = (email) => {
    return validator.isEmail(email);
  };
  const handleChange2 = (e) => {
    const { id, value } = e.target;
    if (id === "contact_person_email") {
      if (!validateEmail(value)) {
        setModalDataErrors((prevErrors) => ({
          ...prevErrors,
          contact_person_email: true,
        }));
      } else {
        setModalDataErrors((prevErrors) => ({
          ...prevErrors,
          contact_person_email: false,
        }));
      }
    }
    
  };

  function smallCheckFnForm() {
    let office_name = !modalData?.office_name?.trim() ? true : false;
    let office_address1 = !modalData?.office_address1?.trim() ? true : false;
    let office_address2 = !modalData?.office_address2?.trim() ? true : false;
    let country = !modalData?.country?.value ? true : false;
    let contact_person_name = !modalData?.contact_person_name?.trim()
      ? true
      : false;
    let contact_person_email = !modalData?.contact_person_email?.trim()
      ? true
      : false;
    let contact_person_phone = !modalData?.contact_person_phone?.trim()
      ? true
      : false;

    if (
      office_name ||
      office_address1 ||
      office_address2 ||
      country ||
      contact_person_name ||
      contact_person_email ||
      contact_person_phone
    ) {
      setModalDataErrors({
        office_name,
        office_address1,
        office_address2,
        country,
        contact_person_name,
        contact_person_email,
        contact_person_phone,
      });
      return true;
    } else {
      return false;
    }
  }

  async function handleAddOffice(event) {
    event.preventDefault();

    if (smallCheckFnForm()) {
      return;
    }

    setIsLoading(true);
    try {
      const token = localStorage.getItem("mtm_user_token");
      let data = {
        ...modalData,
        career_id: career_id,
        country: modalData?.country?.value,
      };

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtm/carrier/add_edit_office`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        
        data: data,
      });

      if (!response.data.error) {
        setSuccessMsg(response.data.message);
        setShowSuccessModal(true);
        setOfficeList([]);
        setShowadd(false);
        setModalData({
          office_name: null,
          office_address1: null,
          office_address2: null,
          country: "",
          contact_person_name: null,
          contact_person_email: null,
          contact_person_phone: null,
        });
        setTimeout(() => {
          setShowSuccessModal(false);
          setSuccessMsg();
        }, 5000);
      } else {
        setOfficeList([]);
        setError({ title: "Error", err: response?.data?.message });
        setShowErrModal(true);
      }
    } catch (error) {
      setOfficeList([]);
      setShowErrModal(true);
      setError({ title: "Error", err: error.message });
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }

      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      await getOfficeList();
      setIsLoading(false);
    }
  }

  const getOfficeList = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("mtm_user_token");
      let data = {
        career_id: career_id,
      };

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtm/carrier/list_office`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        
        data: data,
      });

      if (!response.data.error) {
        setOfficeList(response?.data?.Office);
      } else {
        setOfficeList([]);
       
      }
    } catch (error) {
      setOfficeList([]);
      setShowErrModal(true);
      setError({ title: "Error", err: error.message });
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }

      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getOfficeList();
    getCountriesFn(setoptionsForCountries, null, navigate);
  }, []);
  return (
    <>
      <div>
        <Row>
          <Col>
            <button
              type="button"
              className="pricing-btn rounded-pill"
              onClick={() => {
                setShowadd(true);
              }}
            >
              Add Offices
            </button>
          </Col>
        </Row>
        <hr />
        <div>
          {conditionalExp(
            !officeList.length && !isLoading,
            <div className="w-100 text-center ">
              <h1>No Offices Found.</h1>
            </div>,
            conditionalExp(
              isLoading,
              <div className="w-100 text-center ">
                <Loader />
              </div>,
              <div className="w-100 selfpricing-tbl">
                <table className="table text-center my-4 border">
                  <thead>
                    <tr className="border" style={{ background: "#EBEEFF" }}>
                      <th>Office Name</th>
                      <th>Office Address</th>
                      <th>Country</th>
                      <th>Contact Person Name</th>
                      <th>Contact Person Email</th>
                      <th>Contact Person Phone</th>
                      <th>&nbsp; </th>
                    </tr>
                  </thead>
                  <tbody>
                    {officeList?.map((office) => (
                      <tr key={office?.office_name + office?.office_id}>
                        <td>{office?.office_name}</td>
                        <td className="text-truncate" style={{ maxWidth: "200px",height:'max-content' }}>
                          {office?.office_address1},{office?.office_address2}
                        </td>
                        <td>{office?.country}</td>
                        <td>{office?.contact_person_name}</td>
                        <td>{office?.contact_person_email}</td>
                        <td>{office?.contact_person_phone}</td>
                        <td>
                          <div className="d-flex align-items-center justify-content-center">
                            <button
                              type="button"
                              className="p-0 m-0 border-0 bg-body me-4 edit-btn"
                              onClick={() => {
                                setModalData({
                                  office_id: office?.office_id,
                                  office_name: office?.office_name,
                                  office_address1: office?.office_address1,
                                  office_address2: office?.office_address2,
                                  country: {
                                    label: office?.country,
                                    value: office?.country,
                                  },
                                  contact_person_name:
                                    office?.contact_person_name,
                                  contact_person_email:
                                    office?.contact_person_email,
                                  contact_person_phone:
                                    office?.contact_person_phone,
                                });
                                setShowadd(true);
                              }}
                            >
                              <svg className="icon ms-2 fs-22">
                                <use href="#icon_edit"></use>
                              </svg>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )
          )}
        </div>
      </div>
      <Modal
        show={showadd}
        centered
        onHide={() => {
          setIsLoading(false);
          setShowadd(false);
          setModalData({
            office_name: null,
            office_address1: null,
            office_address2: null,
            country: "",
            contact_person_name: null,
            contact_person_email: null,
            contact_person_phone: null,
          });
        }}
        className="tracking-modal"
        scrollable={true}
      >
        <Modal.Header className="border-0 text-center fs-26 fw-bold pb-2" closeButton>{conditionalExp(
                modalData?.office_id,
                "Edit Office",
                "Add Office"
              )}</Modal.Header>
        <Modal.Body className="px-4 overflow-auto">
          <form onSubmit={handleAddOffice} className="p-4">
            <div className="input-box mb-3">
              {modalData?.office_name && modalData?.office_name != "" && (
                <label htmlFor="" className="fw-bold">
                  Office Name*
                </label>
              )}
              <input
                value={modalData?.office_name}
                onChange={handleChange}
                required
                type="text"
                className="w-100"
                id="office_name"
                placeholder="Enter Office Name*"
              />
              {modalDataErrors?.office_name && (
                <p className="text-danger">Please Enter Office Name</p>
              )}
            </div>
            <div className="input-box mb-3">
              {modalData?.office_address1 &&
                modalData?.office_address1 != "" && (
                  <label htmlFor="" className="fw-bold">
                    Office Address 1*
                  </label>
                )}
              <input
                value={modalData?.office_address1}
                onChange={handleChange}
                required
                type="text"
                className="w-100"
                id="office_address1"
                placeholder="Enter Office Address 1*"
              />
              {modalDataErrors?.office_address1 && (
                <p className="text-danger">Please Enter Office Address 1</p>
              )}
            </div>
            <div className="input-box mb-3">
              {modalData?.office_address2 &&
                modalData?.office_address2 != "" && (
                  <label htmlFor="" className="fw-bold">
                    Office Address 2*
                  </label>
                )}
              <input
                value={modalData?.office_address2}
                onChange={handleChange}
                required
                type="text"
                className="w-100"
                id="office_address2"
                placeholder="Enter Office Address 2*"
              />
              {modalDataErrors?.office_address2 && (
                <p className="text-danger">Please Enter Office Address 2</p>
              )}
            </div>

            <div className="input-box mb-3">
              {modalData.country && (
                <label htmlFor="" className="fw-bold mb-1">
                  Country*
                </label>
              )}
              <Select
                required
                value={modalData.country}
                onChange={(option) => {
                  setModalData({ ...modalData, country: option });
                  if (option) {
                    setModalDataErrors({ ...modalDataErrors, country: false });
                  } else {
                    setModalDataErrors({ ...modalDataErrors, country: true });
                  }
                }}
                options={optionsForCountries}
                isSearchable={true}
                placeholder="Select Country*"
                isClearable
                isLoading={!optionsForCountries?.length}
              />
              {modalDataErrors?.country && (
                <p className="text-danger">Please Select Country</p>
              )}
            </div>

            <div className="input-box mb-3">
              {modalData?.contact_person_name &&
                modalData?.contact_person_name != "" && (
                  <label htmlFor="" className="fw-bold">
                    Contact Person Name*
                  </label>
                )}
              <input
                value={modalData?.contact_person_name}
                onChange={handleChange}
                required
                type="text"
                className="w-100"
                id="contact_person_name"
                placeholder="Enter Contact Person Name*"
              />
              {modalDataErrors?.contact_person_name && (
                <p className="text-danger">Please Enter Contact Person Name</p>
              )}
            </div>

            <div className="input-box mb-3">
              {modalData?.contact_person_email &&
                modalData?.contact_person_email != "" && (
                  <label htmlFor="" className="fw-bold">
                    Contact Person Email*
                  </label>
                )}
              <input
                value={modalData?.contact_person_email}
                onChange={handleChange}
                onBlur={handleChange2}
                required
                type="email"
                className="w-100"
                id="contact_person_email"
                placeholder="Enter Contact Person Email*"
              />
              {modalDataErrors?.contact_person_email && (
                <p className="text-danger">
                  Please Enter Valid Contact Person Email
                </p>
              )}
            </div>
            <div className="input-box mb-3">
              {modalData?.contact_person_phone &&
                modalData?.contact_person_phone != "" && (
                  <label htmlFor="" className="fw-bold">
                    Contact Person Phone*
                  </label>
                )}
              <input
                value={modalData?.contact_person_phone}
                onChange={handleChange}
                required
                type="text"
                className="w-100"
                id="contact_person_phone"
                placeholder="Enter Contact Person Phone*"
              />
              {modalDataErrors?.contact_person_phone && (
                <p className="text-danger">Please Enter Contact Person Phone</p>
              )}
            </div>

            <div className="d-flex">
              <div className="ms-auto">
                <button
                  type="submit"
                  className="grd-btn rounded-pill"
                  onClick={(e) => {
                    handleAddOffice(e);
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? <Loader /> : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <ErrorModal
        error={error}
        showErrModal={showErrModal}
        setShowErrModal={setShowErrModal}
      />
      <SuccessModal
        showSuccess={showSuccessModal}
        handleCloseSuccess={() => {
          setShowSuccessModal(false);
          setSuccessMsg();
        }}
        successMessagePopup={successMsg}
      />
    </>
  );
}

export default OfficeDetails;
