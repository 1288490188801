import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Routing from "./components/Routing";
import Icons from "./components/Icons";
import UserState from "./context/UserState";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import ErrorBoundary from "./components/ErrorBoundary";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <UserState>
        <Routing></Routing>
        <Icons></Icons>
      </UserState>
    </ErrorBoundary>
  </React.StrictMode>
);
reportWebVitals();


