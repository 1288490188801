import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { conditionalExp } from "../utils/commonFn";
import NavBar from "../components/NavBar";
import OfficeDetails from "../components/OfficeDetails";
import CreditStandardPrice from "../components/CreditStandardPrice";
import BankDetails from "../components/BankDetails";
import Documents from "../components/Documents";
import AgentsBankDetails from "../components/AgentsBankDetails";
import AgentsCreditStandardPrice from "../components/AgentsCreditStandardPrice";
import AgentsDocuments from "../components/AgentsDocuments";
import AgentsOfficeDetails from "../components/AgentsOfficeDetails";

function Agents_Profile() {
  const { company_name } = useParams();
  const navigate = useNavigate();
  return (
    <>
      <NavBar />
      <Container  className="wrapper">
        <Col className="col-12 ">
          <div className="py-3 ">
            <button
              type="button"
              className="back-btn d-flex alig-items-center"
              onClick={() => {
                navigate(-1);
              }}
            >
              <svg className="icon me-2 fs-22">
                <use href="#icon_leftarrow"></use>
              </svg>
              <strong className="fs-14">Back</strong>
            </button>
            <h2 className="fw-700 fs-30 text-center ">{company_name}</h2>
          </div>
          <div className="w-100 tabs-row">
            <ul
              className="nav nav-pills mb-3 gap-3"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link active`}
                  id="pills-office-details-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-office-details"
                  type="button"
                  role="tab"
                  aria-controls="pills-office-details"
                  aria-selected="false"
                  // onClick={() => {
                  //   navigate(`/${customerId}`, {
                  //     state: {
                  //       previous: {
                  //         tab: "office-details",
                  //       },
                  //     },
                  //   });
                  // }}
                >
                  Office Details
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link `}
                  id="pills-credit-standard-prices-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-credit-standard-prices"
                  type="button"
                  role="tab"
                  aria-controls="pills-credit-standard-prices"
                  aria-selected="false"
                  // onClick={() => {
                  //   navigate(`/${customerId}`, {
                  //     state: {
                  //       previous: {
                  //         tab: "credit-standard-prices",
                  //       },
                  //     },
                  //   });
                  // }}
                >
                  Credit & Standard Prices
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link `}
                  id="pills-bank-details-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-bank-details"
                  type="button"
                  role="tab"
                  aria-controls="pills-bank-details"
                  aria-selected="false"
                  // onClick={() => {
                  //   navigate(`/${customerId}`, {
                  //     state: {
                  //       previous: {
                  //         tab: "bank-details",
                  //       },
                  //     },
                  //   });
                  // }}
                >
                  Bank Details
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link `}
                  id="pills-documents-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-documents"
                  type="button"
                  role="tab"
                  aria-controls="pills-documents"
                  aria-selected="false"
                  // onClick={() => {
                  //   navigate(`/${customerId}`, {
                  //     state: {
                  //       previous: {
                  //         tab: "documents",
                  //       },
                  //     },
                  //   });
                  // }}
                >
                  Documents
                </button>
              </li>
            </ul>
          </div>

          <div className="tab-content" id="pills-tabContent">
            <div
              className={`tab-pane fade show active`}
              id="pills-office-details"
              role="tabpanel"
              aria-labelledby="pills-office-details-tab"
              tabIndex="1"
            >
              <div className="py-3">
                <AgentsOfficeDetails/>
              </div>
            </div>
            <div
              className={`tab-pane fade `}
              id="pills-credit-standard-prices"
              role="tabpanel"
              aria-labelledby="pills-credit-standard-prices-tab"
              tabIndex="2"
            >
              <div className="py-3">
                <AgentsCreditStandardPrice/>
              </div>
            </div>
            <div
              className={`tab-pane fade `}
              id="pills-bank-details"
              role="tabpanel"
              aria-labelledby="pills-bank-details-tab"
              tabIndex="3"
            >
              <div className="py-3">
              <AgentsBankDetails/>
              </div>
            </div>
            <div
              className={`tab-pane fade `}
              id="pills-documents"
              role="tabpanel"
              aria-labelledby="pills-documents-tab"
              tabIndex="4"
            >
              <div className="py-3">
             
               <AgentsDocuments/>
              </div>
            </div>
          </div>
        </Col>
      </Container>
    </>
  );
}

export default Agents_Profile;
