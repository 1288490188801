import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { conditionalExp, getCountriesFn } from "../utils/commonFn";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "./Loader";
import moment from "moment";
import axios from "axios";
import ErrorModal from "./ErrorModal";
import { SuccessModal } from "./SuccessModal";

function AgentsDocuments() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setisLoadingDelete] = useState(false);
  const [isFileUploading, setisFileUploading] = useState(false);
  const [show, setShow] = useState(false);
  const [imgURL, setimgURL] = useState();
  const [previewUrl, setpreviewUrl] = useState();
  const [rawFile, setrawFile] = useState();
  const [popupTitle, setpopupTitle] = useState();
  const [showimgpop, setShowimgpop] = useState();
  const [documentList, setdocumentList] = useState([]);
  const [showDelete, setShowDelete] = useState();
  const [selectedDoc, setSelectedDoc] = useState();
  const [uploadOption, setUploadOption] = useState("");
  const [documentName, setDocumentName] = useState();
  const [showErrModal, setShowErrModal] = useState(false);
  const [error, setError] = useState({ title: "", err: "" });
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const { broker_id } = useParams();

  const navigate = useNavigate();
  const MAX_FILE_SIZE = 6 * 1024 * 1024; // 6MB in bytes
  const [fileUploadLimitRxError, setfileUploadLimitRxError] = useState(false);
  function getFileType(type) {
    if (type == "pdf") {
      return (
        <svg className="icon me-2 fs-22">
          <use href="#icon_pdf"></use>
        </svg>
      );
    } else if (type == "xls" || type == "xlsx") {
      return (
        <svg className="icon me-2 fs-22">
          <use href="#icon_exel"></use>
        </svg>
      );
    } else if (type == "doc" || type == "docx") {
      return (
        <svg className="icon me-2 fs-22">
          <use href="#icon_word"></use>
        </svg>
      );
    } else {
      return (
        <svg className="icon me-2 fs-22">
          <use href="#icon_doc"></use>
        </svg>
      );
    }
  }

  const downloadFile = async (filePath, fileType) => {
    setIsLoading(true);
    
    try {
      // Make a request to the proxy route with the 'url' header
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/proxy`, {
        headers: {
          url: filePath,
        },
      });

      // Check if the request was successful
      if (!response.ok) {
        throw new Error("Error while fetching the file.");
      }

      // Create a blob from the response data
      const blob = await response.blob();

      // Create a temporary link to initiate the download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileType + "." + filePath.split(".").slice(-1); // Replace with your desired file name
      link.click();

      // Clean up the temporary link
      URL.revokeObjectURL(link.href);
      setIsLoading(false);
      
    } catch (error) {
      // Handle any errors
      setIsLoading(false);
      
      console.error("Error downloading the file:", error);
      alert("Error downloading the file: " + error);
    }
  };

  async function handleFileView(doc) {
    setimgURL(doc.document_file);
    setpopupTitle(doc.document_name);
    setShowimgpop(true);
  }

  const handleShowDelete = (doc) => {
    setShowDelete(true);
    setSelectedDoc(doc);
  };

  const handleCloseDelete = () => {
    setShowDelete(false);
    setSelectedDoc();
  };

  const handleShow = (doc) => {
    if (doc) {
      setUploadOption(doc.document_name);
      setSelectedDoc(doc);
    }
    setpreviewUrl(null);
    setrawFile(null);
    setShow(true);
  };

  const handleClose = () => {
    setUploadOption("");
    setSelectedDoc();
    setShow(false);
    setDocumentName("");
    setpreviewUrl(null);
    setrawFile(null);
  };

  async function handleFileUpload(event) {
    event.preventDefault();
    if(!rawFile?.length){
      setError({ title: "Error", err: 'Please select a file to upload' });
        setShowErrModal(true);
        return
    }
    setisFileUploading(true);
    try {
      const token = localStorage.getItem("mtm_user_token");
      const formData = new FormData();
      formData.append('broker_id',broker_id);
      console.log(JSON.parse(localStorage.getItem('mtm_user_data'))?.id);
      formData.append('user_id',JSON.parse(localStorage.getItem('mtm_user_data'))?.id);
      formData.append('document_name',conditionalExp(
        uploadOption == "Add new documents",
        documentName,
        uploadOption
      ));

      rawFile?.forEach((file) => {
        formData.append("document", file);
      });

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtm/agents/add_document`,
        method: "POST",
        headers: {
          jwt_token: token,
        },
        
        data: formData,
      });

      if (!response?.data?.error) {
        setSuccessMsg(response?.data?.message);
        setShowSuccessModal(true);
        setdocumentList([]);
        handleClose()
        setTimeout(() => {
          setShowSuccessModal(false);
          setSuccessMsg();
        }, 5000);
      } else {
        setdocumentList([]);
        setError({ title: "Error", err: response?.data?.message });
        setShowErrModal(true);
      }
    } catch (error) {
      setdocumentList([]);
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      await getDocumentsList();
      setisFileUploading(false);
    }
  }


  const getDocumentsList = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("mtm_user_token");
      let data = {
        broker_id: broker_id,
      };

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtm/agents/list_documents`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        
        data: data,
      });

      if (!response.data.error) {
        setdocumentList(response?.data?.documents);
      } else {
        setdocumentList([]);
       
      }
    } catch (error) {
      setdocumentList([]);
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDocDelete = async (document_id) => {
    setisLoadingDelete(true);
    try {
      const token = localStorage.getItem("mtm_user_token");
      let data = {
        broker_id: broker_id,
        document_id: document_id,
      };

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtm/agents/delete_document`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        
        data: data,
      });

      if (!response?.data?.error) {
        setSuccessMsg(response?.data?.message);
        setShowSuccessModal(true);
        setdocumentList([]);
        handleCloseDelete()
        setTimeout(() => {
          setShowSuccessModal(false);
          setSuccessMsg();
        }, 5000);
      } else {
        setdocumentList([]);
        setError({ title: "Error", err: response?.data?.message });
        setShowErrModal(true);
      }
    } catch (error) {
      setdocumentList([]);
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setisLoadingDelete(false);
      getDocumentsList()
    }
  };
  
  const checkRxType = (file) => {
    let rawFilee = file ? file : rawFile;
    let objectURL = URL.createObjectURL(rawFilee);
    if (!rawFilee) {
      return (
        <img
          src={objectURL}
          style={{
            width: "40px",
            height: "30px",
            margin: "5px",
            marginRight: "10px",
          }}
          alt="doc"
        />
      );
    }
    let type = rawFilee?.name?.split(".");
    return getTypeDoc(objectURL, type);
  };

  const getTypeDoc = (path, typ) => {
    if (!path) {
      return "";
    }
    let type = typ ? typ : path?.split(".");
    if (type[type.length - 1] == "pdf") {
      return returnImgComp(window.location.origin + "/images/pdf.png");
    } else if (
      type[type.length - 1] == "xls" ||
      type[type.length - 1] == "xlsx"
    ) {
      return returnImgComp(window.location.origin + "/images/exel.png");
    } else if (
      type[type.length - 1] == "doc" ||
      type[type.length - 1] == "docx"
    ) {
      return returnImgComp(window.location.origin + "/images/doc.png");
    }

    return returnImgComp(path);

    function returnImgComp(src) {
      return (
        <img
          src={src}
          style={{
            width: "30px",
            height: "30px",
            margin: "5px",
            marginRight: "10px",
          }}
          alt="doc"
        />
      );
    }
  };

  const handleChangeFileFun = (e) => {
    for (let file of e.target.files) {
      if (file && file.size > MAX_FILE_SIZE) {
        setfileUploadLimitRxError(true);
        return;
      }
    }
    setfileUploadLimitRxError(false);
    setrawFile(Object.values(e.target.files));
  };


  useEffect(() => {
    if (!rawFile || rawFile.length == 0) {
      setpreviewUrl(null);
      return;
    }
    const objectURL = URL.createObjectURL(
      rawFile.length ? rawFile[0] : rawFile
    );
    setpreviewUrl(objectURL);

  }, [rawFile]);

  useEffect(() => {
    getDocumentsList()
  }, [])
  
  return (
    <>
       {conditionalExp(
                  !isLoading,
                  <div>
                    <div className="d-flex py-3">
                      <div style={{ width: "580px" }}>
                        <select
                          className="form-select py-2 rounded-pill"
                          value={uploadOption}
                          onChange={(e) => setUploadOption(e.target.value)}
                        >
                          <option value="">Please select Document</option>
                          
                          <option value="NDA">NDA</option>
                          <option value="Signed Agreement">
                            Signed Agreement
                          </option>
                          <option value="Add new documents">
                            Add new documents
                          </option>
                        </select>
                      </div>
                      <div className="ms-4">
                        <button
                          disabled={uploadOption === ""}
                          onClick={() => handleShow(null)}
                          type="button"
                          className="upload-btn fs-20 fw-600 d-flex text-center"
                        >
                          Upload
                        </button>
                      </div>
                    </div>

                    <div className="col-7 py-4">
                      {conditionalExp(
                        documentList.length > 0,
                        documentList.map((doc, index) => (
                          <div
                            key={doc.document_id + "DOC"}
                            className="rounded-pill my-2 py-2 px-3 d-flex align-items-center"
                            style={{
                              border: " 1px solid #F1F1F1",
                              background: "#FDFDFD",
                            }}
                          >
                            <div className="d-flex align-items-center">
                              {doc &&
                                getFileType(
                                  doc?.document_file?.split(".")?.slice(-1)
                                )}
                              <div>
                                <b>
                                  <p>{doc.document_name}</p>
                                </b>
                                <i>
                                  <strong>Uploaded By:</strong>{" "}
                                  {doc.user_full_name}
                                </i>
                              </div>
                              <div className="mx-1">
                                <p>&nbsp;</p>
                                <i>
                                  <strong>Uploaded Date:</strong>{" "}
                                  {doc.createdAt &&
                                    moment(doc.createdAt).format("MM-DD-YYYY")}
                                </i>
                              </div>
                            </div>
                            <div className="icon-btn ms-auto">
                              <button
                                disabled={isLoading}
                                onClick={() =>
                                  downloadFile(
                                    doc.document_file,
                                    doc.document_name
                                  )
                                }
                                type="button"
                                className=""
                              >
                                {isLoading ? (
                                  <Loader />
                                ) : (
                                  <svg className="icon fs-22">
                                    <use href="#icon_download"></use>
                                  </svg>
                                )}
                              </button>
                              <button
                                onClick={() => handleFileView(doc)}
                                type="button"
                                className=""
                              >
                                <svg className="icon fs-22">
                                  <use href="#icon_eye"></use>
                                </svg>
                              </button>
                              
                              <button
                                onClick={() => handleShowDelete(doc)}
                                type="button"
                                className="delete-btn"
                              >
                                <svg className="icon fs-22">
                                  <use href="#icon_delete"></use>
                                </svg>
                              </button>
                            </div>
                          </div>
                        )),
                        null
                      )}
                    </div>
                  </div>,
                  <div
                    style={{
                      marginTop: "100px",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <Loader />
                  </div>
                )}
      <Modal
         show={showimgpop}
         centered
         onHide={() => {
           setShowimgpop(false);
           setimgURL();
         }}
         dialogClassName="custom-modal"
      >
        <Modal.Header className="border-0" closeButton>{popupTitle}</Modal.Header>
        <Modal.Body style={{
            height: "calc(90vh - 56px)",
            padding: 0,
            overflowY: "scroll",
          }} >
        {!imgURL ? (
          <h4
            style={{
              height: "100%",
              width: "100%",
              textAlign: "center",
            }}
          >
            No file found!
          </h4>
        ) : (
          <iframe
            style={{
              height: "100%",
              width: "100%",
              borderRadius: "5px",
              overflow: "scroll",
            }}
            // src={"https://docs.google.com/viewer?url=" + imgURL + "&embedded=true"}
            src={
              imgURL?.split(".")?.slice(-1) == "xls" ||
              imgURL?.split(".")?.slice(-1) == "xlsx" ||
              imgURL?.split(".")?.slice(-1) == "doc" ||
              imgURL?.split(".")?.slice(-1) == "docx" ||
              imgURL?.split(".")?.slice(-1) == "pptx" ||
              imgURL?.split(".")?.slice(-1) == "ppt"
                ? "https://view.officeapps.live.com/op/embed.aspx?src=" + imgURL
                : imgURL
            }
          ></iframe>
        )}
        </Modal.Body>
      </Modal>
      {/*doc delete confirmation modal */}
      <Modal show={showDelete} onHide={handleCloseDelete} centered>
        <Modal.Body className="p-4">
          <p className="fs-4 text-center">Are you sure you want delete ?</p>
          <div className="d-flex justify-content-center mt-2">
            <Button
              variant="secondary"
              className="me-3"
              onClick={handleCloseDelete}
            >
              Close
            </Button>
            <Button
              variant="danger"
              disabled={isLoadingDelete}
              onClick={() => {
               
                  handleDocDelete(selectedDoc.document_id);
               
              }}
            >
              {conditionalExp(!isLoadingDelete, "Delete", <Loader />)}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={show}
        centered
        onHide={handleClose}
        className="tracking-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <h4 className="text-center pb-3 fs-22">{uploadOption}</h4>
          {uploadOption == "Add new documents" && (
            <div className="input-box pb-3">
              <label className="fw-600">Document Name</label>
              <input
                type="tel"
                className="w-100"
                value={documentName}
                onChange={(e) => setDocumentName(e.target.value)}
                placeholder="Enter document name"
              />
            </div>
          )}

<div className="upload-box p-3">
          <label className="drag-box input-file">
            <img
              className="img-fluid rounded-start"
              width={80}
              height={55}
              src={window.location.origin + "/images/uploader.png"}
              alt="..."
            />
            <input
            multiple
              type="file"
              id="fileUpload2"
              onChange={
                handleChangeFileFun
              }
            />
            <span>
              Drag and drop your image or <a href="">browse file</a> on your
              computer
            </span>
          </label>
        </div>
        <div className="d-block rounded-2 p-1 my-3">
          { previewUrl ? (
            
              rawFile?.map((file, index) => (
                <div
                  key={file?.name + "rawFiles"}
                  className="file-import d-flex ai-center my-2"
                  id="remove_${inputid}"
                >
                  {checkRxType(file)}
                  <span style={{ marginRight: "auto" }}>{file?.name}</span>

                  <button
                    onClick={() => {
                      setrawFile((pre) =>
                        pre.filter((item, iIndex) => index != iIndex)
                      );
                    }}
                  >
                    <svg
                      className="icon"
                      style={{ width: "20px", height: "20px" }}
                    >
                      <use href="#icon_fileclose"></use>
                    </svg>
                  </button>
                </div>
              ))
            
          ) : (
            <div></div>
          )}
        </div>
        {fileUploadLimitRxError && (
          <div className="mb-2 w-100 text-danger text-center">
            Please upload file less than 6MB
          </div>
        )}
          <div className="mt-1">
            <button
              disabled={isFileUploading}
              onClick={ handleFileUpload}
              className="button button-blue upload-btn w-100 py-2 fs-20"
            >
              {conditionalExp(isFileUploading, <Loader />, "Upload")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <ErrorModal
        error={error}
        showErrModal={showErrModal}
        setShowErrModal={setShowErrModal}
      />
      <SuccessModal
        showSuccess={showSuccessModal}
        handleCloseSuccess={() => {
          setShowSuccessModal(false);
          setSuccessMsg();
        }}
        successMessagePopup={successMsg}
      />
    </>
  );
}

export default AgentsDocuments;
