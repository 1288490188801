import {
  React,
  useCallback,
  useContext,
  useRef,
  useState,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Modal } from "react-bootstrap";
import NavBar from "../components/NavBar";
import moment from "moment";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import axios from "axios";
import jwt_decode from "jwt-decode";
import Loader from "../components/Loader";
import UserContext from "../context/UserContext";
import { DatePicker } from "antd";
import Details from "./Details";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import { CustomIcon } from "../components/Common";

function Filter() {
  const context = useContext(UserContext);
  const {
    setquotationDetails,
    setselectedQuote,
    selectedDate,
    setselectedDate,
    clkData,
    setclkData,
    selectedProductType,
    setselectedProductType,
    selectedStatus,
    setselectedStatus,
    selectedCustomerId,
    setselectedCustomerId,
    setapprovedQuoteDetails,
    setselectedTempQuoteId,
    editSave,
    setEditSave,
  } = context;
  const [clickupData, setClickupData] = useState([]);

  const dateFormat = "YYYY-MM-DD";

  const { RangePicker } = DatePicker;
  const [show, setShow] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showErrorModalRejection, setShowErrorModalRejection] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessageRateStatus, seterrorMessageRateStatus] = useState("");
  const [errorMessageRateStatusRejection, seterrorMessageRateStatusRejection] =
    useState([]);
  const [selectedQuoteData, setselectedQuoteData] = useState({});
  const [quoteDetailsCameModified, setquoteDetailsCameModified] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [hasMore, sethasMore] = useState(false);
  const observer = useRef();
  const tempRef = useRef();

  const [allCountries, setallCountries] = useState([]);

  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) {
        return;
      }
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        console.log(entries, "entries");
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, hasMore]
  );
  const [showThankyou, setshowThankyou] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseThankyou = () => setshowThankyou(false);
  const handleShowThankyou = () => setshowThankyou(true);
  const handleCloseErrorModal = () => setShowErrorModal(false);
  const handleShowErrorModal = () => setShowErrorModal(true);
  const handleCloseErrorModalRejection = () =>
    setShowErrorModalRejection(false);
  const handleShowErrorModalRejection = () => setShowErrorModalRejection(true);
  const handleCloseSuccess = () => setShowSuccess(false);

  const navigate = useNavigate();

  const [dataFetched, setdataFetched] = useState(true);
  const [questionData, setQuestionData] = useState([]);
  async function getQuestions(modal_id, selectedQuoteData) {
    if (!modal_id || modal_id == "") return;
    try {
      const token = localStorage.getItem("mtm_user_token");
      let clickup_data = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/quote/modal/questions`,
        { modal_id: modal_id },
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );
      // let data =await clickup_data?.data?.questions;
      // data = data.map((item) => {
      //   return {
      //     ...item,
      //     value: selectedQuoteData[item?.content_key],
      //   };
      // });
      setQuestionData(clickup_data?.data?.questions);
    } catch (err) {
      if (err?.response?.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      console.log(err);
    }
  }
  const getCountriesFn = async () => {
    try {
      let token = localStorage.getItem("mtm_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/country/list`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
      });

      console.log(response, "result");
      if (response.data.status) {
        setallCountries(response?.data?.country);
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  };
  console.log(questionData, "question data");
  const discardFunction = () => {
    setquoteDetailsCameModified(selectedQuoteData);
    getQuestions(selectedQuoteData?.modal_id, selectedQuoteData);
    setEditSave(false);
  };
  function conditionalExp(condition, a, b) {
    if (condition) {
      return a;
    } else {
      return b;
    }
  }

  const ifEditSave = () => {
    return (
      <div className="d-flex">
        <button
          type="button"
          className="px-4 py-1 grd-btn  fw-600 d-flex text-center align-items-center me-3"
          onClick={() => {
            discardFunction();
          }}
        >
          Discard
        </button>
        <button
          type="button"
          className="px-4 py-1 grd-btn  fw-600 d-flex text-center align-items-center"
          // disabled={getDisabledCheck()}
          disabled={
            !questionData
              ?.filter(
                (item) =>
                  item?.content_key != "palletized_cargo" &&
                  item?.content_key != "number_of_packages" &&
                  item?.content_key != "stackable_cargo"
              )
              ?.every((q) => {
                if (q?.dependent_question_id) {
                  return conditionalExp(
                    questionData?.find(
                      (que) => que?.id == q?.dependent_question_id
                    )?.value == "yes",
                    q?.value,
                    true
                  );
                } else {
                  return conditionalExp(
                    q?.content_key == "cbm" || q?.content_key == "total_weight",
                    conditionalExp(q?.value > 0, true, false),
                    conditionalExp(q?.value, true, false)
                  );
                }
              })
          }
          onClick={postQuoteDetails}
        >
          {isLoading ? <Loader /> : "Save"}
        </button>
      </div>
    );
  };
  const checkDisabled = () => {
    if (
      quoteDetailsCameModified?.clickup_status == "Opportunity Identified" ||
      quoteDetailsCameModified?.clickup_status == "Quote Ready" ||
      quoteDetailsCameModified?.clickup_status == "Negotiating with customer"
    ) {
      return false;
    } else {
      return true;
    }
  };
  const ifNotEditSave = () => {
    return (
      <button
        type="button"
        disabled={checkDisabled()}
        className="px-4 py-1 grd-btn  fw-600 d-flex text-center align-items-center"
        onClick={() => {
          setEditSave(true);
        }}
      >
        Edit
      </button>
    );
  };
  const getButtons = () => {
    return editSave ? ifEditSave() : ifNotEditSave();
  };
  const getFilterDatesFn = async (from) => {
    setIsLoadingMore(true);
    try {
      let token = localStorage.getItem("mtm_user_token");
      let from_date =
        selectedDate?.length > 0 ? getFormattedDate(selectedDate[0]) : "";
      let to_date =
        selectedDate?.length > 0 ? getFormattedDate(selectedDate[1]) : "";
      let data = {
        from_date: from_date,
        to_date: to_date,
        quote_status: selectedStatus,
        customer_id: selectedCustomerId?.value,
        product_type: selectedProductType,
        page: currentPage?.toString(),
      };

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmclick/quote`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },

        data: data,
      });

      console.log(response, "result");

      if (!response.data.error) {
        setIsLoadingMore(false);

        const newQuotes = response.data.clkdata.filter((newQuote) => {
          return !clkData.some(
            (existingQuote) =>
              existingQuote.quote.quote_id === newQuote.quote.quote_id
          );
        });

        setdataFetched(true);
        if (from == "reload") {
          setclkData(response.data.clkdata);
        } else {
          setclkData([...clkData, ...newQuotes]);
        }
        sethasMore(newQuotes.length > 0);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      setdataFetched(true);
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  };

  const getFormattedDate = (date_createdAt) => {
    const createdAt = date_createdAt;

    const date = new Date(createdAt);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  // const nextButton = (clk) => {
  //   setquotationDetails({});
  //   setselectedQuote({});
  //   setapprovedQuoteDetails({});
  //   if (clk?.quote?.quote_id && clk?.quote?.quote_count > 0) {
  //     navigate(
  //       `/filter/${clk?.quote?.quote_id}/${clk?.quote?.task_id}/${clk?.quote?.trade}`
  //     );
  //     window.scrollTo(0, 0);
  //   }
  // };
  const nextButton = (clk) => {
    setquotationDetails({});
    setselectedQuote({});
    setapprovedQuoteDetails({});
    if (clk?.quote?.quote_id && clk?.quote?.quote_count > 0) {
      const url = `/filter/${clk?.quote?.quote_id}/${clk?.quote?.task_id}/${clk?.quote?.trade}`;

      // Open the URL in a new window
      // window.open(url, '_blank');
      // Open the URL in the same page
      window.location.href = url;
    }
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };
  const getCreatedDate = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    const currentDate = new Date();
    const timeDifference = Math.abs(currentDate - createdAtDate);
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));

    let color = "";
    if (hoursDifference <= 24) {
      color = "green-bottom";
    } else if (hoursDifference <= 48) {
      color = "yellow-bottom";
    } else {
      color = "maroon-bottom";
    }
    let sinceText = "";
    if (daysDifference > 1) {
      sinceText = `(Since ${daysDifference} days)`;
    } else {
      sinceText = `(Since ${daysDifference} day)`;
    }

    const formattedDate = `${createdAtDate
      .getDate()
      .toString()
      .padStart(2, "0")}-${(createdAtDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${createdAtDate.getFullYear()}`;
    return {
      formattedDate,
      daysDifference,
      sinceText,
      color,
    };
  };
  async function getDropdown() {
    try {
      const token = localStorage.getItem("mtm_user_token");
      let clickup_data = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/quote/dropdowns`,
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );
      setClickupData(clickup_data?.data?.dropdown_details);
      setTimeout(() => {
        getFilterDatesFn();
      }, 200);
    } catch (err) {
      if (err.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      console.log(err);
    }
  }
  const postQuoteDetailsFn = async (data, navigate_to, quote_states) => {
    try {
      let token = localStorage.getItem("mtm_user_token");
      let dataAdd = {
        ...data,
        quote_action: data?.task_id ? "update" : "add",
        quote_states: quote_states,
      };

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/opportunity/quote/create`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: dataAdd,
      });

      if (!response.data.error) {
        delete data.quote_action;
        data["quote_id"] = response?.data?.quote_id;
        return response;
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  };
  const postQuoteDetails = async () => {
    setIsLoading(true);
    let joinLength = `${quoteDetailsCameModified?.measurements_length}X${quoteDetailsCameModified?.measurements_width}X${quoteDetailsCameModified?.measurements_height}`;

    try {
      const data = {
        ...quoteDetailsCameModified,
        cargo_measurements: joinLength,
        // quote_states: 1,
        // contact_person: JSON.parse(localStorage.getItem("mtm_user_data"))
        //   ?.user_full_name,
        // contact_person_email: JSON.parse(localStorage.getItem("mtm_user_data"))
        //   ?.email,
        // user_id: JSON.parse(localStorage.getItem("mtm_user_data"))?.id,
        // quote_action: quoteDetailsCameModified?.task_id ? "update" : "add",
      };
      delete data?.measurements_length;
      delete data?.measurements_width;
      delete data?.measurements_height;

      let response = await postQuoteDetailsFn(data, "home", 1);
      if (!response?.data?.error) {
        reloadFn();

        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDropdown();
    getCountriesFn();
  }, []);
  useEffect(() => {
    if(questionData?.length>0){
      setQuestionData((prev) =>
      prev?.map((item) => {
        return {
          ...item,
          value: quoteDetailsCameModified[item?.content_key],
        };
      })
    );
    }
  }, [quoteDetailsCameModified,selectedQuoteData?.length,questionData?.length]);

  useEffect(() => {
    const token = localStorage.getItem("mtm_user_token");
    if (!token) {
      navigate("/");
    }
    if (token) {
      const decodedToken = jwt_decode(token);
      if (decodedToken.exp < Date.now() / 1000) {
        // Token has expired
        navigate("/");
      }
    }
    getFilterDatesFn();
  }, [
    selectedDate?.length,
    selectedProductType,
    selectedStatus,
    currentPage,
    selectedCustomerId,
  ]);
  const reloadFn = () => {
    setEditSave(false);
    setShow(false);
    setclkData([]);
    setCurrentPage(0);
    handleShowThankyou();
    setTimeout(() => {
      handleCloseThankyou();
    }, 1000);
    getFilterDatesFn("reload");
  };
  const getIcon = (product_type_name) => {
    let icon = "";
    let lowerCase = product_type_name?.toLowerCase();
    if (lowerCase?.includes("air")) {
      icon = "#icon_air";
    } else if (lowerCase?.includes("truck")) {
      icon = "#icon_truck";
    } else if (lowerCase?.includes("ocean")) {
      icon = "#icon_ocean";
    } else {
      icon = "";
    }
    return icon;
  };

  function debounce(func, delay) {
    let timeoutId;

    return function (...args) {
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  const loadOptions = async (inputValue, callback) => {
    try {
      if (inputValue?.length < 3) {
        return callback([]);
      }
      let token = localStorage.getItem("mtm_user_token");
      // Make API call to fetch options based on user input
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/mtmclick/quote/search_customers`,
        {
          query: inputValue,
        },
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );

      // Assuming the API response contains an array of options
      const options = response.data?.customers?.map((item) => ({
        label: item?.organization_name,
        value: item?.customer_id,
      }));

      // Callback with the fetched options
      callback(options);
    } catch (error) {
      console.error("Error fetching options:", error);
      callback([]);
    }
  };

  const CustomDropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CustomIcon name="icon_search" className="fs-5" />
      </components.DropdownIndicator>
    );
  };

  return (
    <>
      <NavBar />
      <Container>
        <Row>
          <Col md={12}>
            <div className="wrapper-box">
              <div className="pt-5 pb-4">
                <form
                  action="/action_page.php"
                  className="d-flex me-auto align-items-center "
                  style={{ width: "150px" }}
                >
                  <div className="d-flex me-auto align-items-center">
                    <div className="me-4">
                      <RangePicker
                        defaultValue={[selectedDate[0], selectedDate[1]]}
                        onChange={(value) => {
                          if (value) {
                            setselectedDate(value.map((item) => item));
                          } else {
                            setselectedDate([]);
                          }
                          setclkData([]);
                          setCurrentPage(0);
                        }}
                        style={{ height: "38px", width: "max-content" }}
                        format={dateFormat}
                        disabledDate={disabledDate}
                      />
                    </div>
                    <div className="me-4">
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        value={selectedStatus}
                        onChange={(e) => {
                          setselectedStatus(e.target.value);
                          setclkData([]);
                          setCurrentPage(0);
                        }}
                        style={{ width: "200px" }}
                      >
                        <option value="">All Statuses</option>
                        <option value="Opportunity Identified">
                          Opportunity Identified
                        </option>
                        <option value="Quote Ready">Quote Ready</option>
                        <option value="Quote Sent">Quote Sent</option>
                        <option value="Won">Won</option>
                        <option value="Negotiating with customer">
                          Negotiating with customer
                        </option>
                        <option value="Negotiating with vendor">
                          Negotiating with vendor
                        </option>
                        <option value="Waiting on customer">
                          Waiting on customer
                        </option>
                        <option value="Lost">Lost</option>
                      </select>
                    </div>
                    <div>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        value={selectedProductType}
                        onChange={(e) => {
                          setselectedProductType(e.target.value);
                          setclkData([]);
                          setCurrentPage(0);
                        }}
                      >
                        <option value="">All Products</option>
                        {clickupData
                          ?.filter((item) => item?.dropdown_type == "product")
                          ?.map((item) => (
                            <option value={item?.clickup_product_id}>
                              {item?.clickup_product_name}
                            </option>
                          ))}
                        {/* <option value="OCEAN / FCL">OCEAN (FCL)</option>
                        <option value="TRUCK">TRUCK</option>
                        <option value="CONTROL TOWER">CONTROL TOWER</option>
                        <option value="INTERMODAL">INTERMODAL</option>
                        <option value="LCL">LCL</option>
                        <option value="LTL">LTL</option>
                        <option value="Air">AIR</option> */}
                      </select>
                    </div>

                    <div className="d-flex">
                      <div>
                        <div className="">
                          <button
                            type="button"
                            className="py-2  rounded-pill grd-btn fs-20 fw-600 d-inline-flex text-center align-items-center"
                            style={{ marginLeft: "105px", marginTop: "-8px" }}
                            onClick={() => {
                              navigate("/manage-self-pricing");
                            }}
                          >
                            Manage Self Pricing
                          </button>
                        </div>
                      </div>
                      <div>
                        {JSON.parse(localStorage.getItem("mtm_user_data"))
                          ?.user_type == 1 && (
                          <div className="">
                            <button
                              type="button"
                              className="py-2  rounded-pill grd-btn fs-20 fw-600 d-inline-flex text-center align-items-center"
                              style={{ marginLeft: "10px", marginTop: "-8px" }}
                              onClick={() => {
                                navigate("/manage_trade_margin");
                              }}
                            >
                              Manage Trade Margin
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </form>

                <div style={{ maxWidth: 400, marginTop: 20 }}>
                  <AsyncSelect
                    value={selectedCustomerId}
                    onChange={(option) => {
                      setselectedCustomerId(option);
                      setclkData([]);
                      setCurrentPage(0);
                    }}
                    components={{
                      DropdownIndicator: CustomDropdownIndicator,
                    }}
                    placeholder={"Search Organization Name"}
                    isClearable
                    cacheOptions
                    isSearchable
                    loadOptions={debounce(loadOptions, 500)}
                  />
                </div>
              </div>

              <div className="row rows-cols-3 row-cols-lg-3 g-4">
                {dataFetched &&
                  clkData?.map((clk, clk_index) => {
                    return (
                      <div
                        className="col"
                        ref={
                          clkData?.length == clk_index + 1
                            ? lastBookElementRef
                            : tempRef
                        }
                        key={clk?.quote?.opportunity_name}
                      >
                        <div
                          className={`cardz w-100 rounded-lg  ${
                            getCreatedDate(clk?.quote?.quote_date)?.color
                          } `}
                        >
                          <div className="card-box p-0">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="bg-lightblue p-2 rounded-2 d-flex align-items-center text-nowrap icon-box">
                                <span className="fw-600 text-blue">
                                  {clk?.quote?.clickup_status}
                                </span>
                              </div>
                              <div className="p-3  me-3">
                                <svg className="icon fs-22 ">
                                  <use
                                    href={getIcon(
                                      clk?.quote?.product_type_name
                                    )}
                                  ></use>
                                </svg>
                              </div>
                            </div>
                            <ul>
                              <li className="text-truncate">
                                <strong>Opportunity Name: </strong>
                                <span>{clk?.quote?.opportunity_name}</span>
                              </li>
                              <li className="text-truncate">
                                <strong>Organization Name: </strong>
                                <span>{clk?.customer?.organization_name}</span>
                              </li>
                              <li>
                                <strong>Product Type: </strong>
                                <span>{clk?.quote?.product_type_name}</span>
                              </li>
                              <li>
                                <strong>Created: </strong>
                                <span>
                                  {" "}
                                  {
                                    getCreatedDate(clk?.quote?.quote_date)
                                      ?.formattedDate
                                  }{" "}
                                  {
                                    getCreatedDate(clk?.quote?.quote_date)
                                      ?.sinceText
                                  }
                                </span>
                              </li>
                              <li>
                                <strong>Created By: </strong>
                                <span>{clk?.quote?.contact_person}</span>
                              </li>

                              <li>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <strong>Total Quotes: </strong>
                                    <span>{clk?.quote?.quote_count}</span>
                                  </div>
                                  <div>
                                    {clk?.quote?.quote_count == 0 &&
                                      clk?.quote?.rate_status && (
                                        <div
                                          style={{
                                            cursor: "pointer",
                                            textDecoration: "underline",
                                            color: "red",
                                          }}
                                          onClick={() => {
                                            seterrorMessageRateStatus(
                                              clk?.quote?.rate_status
                                            );
                                            handleShowErrorModal();
                                          }}
                                        >
                                          Error from Freightify
                                        </div>
                                      )}
                                  </div>

                                  <div>
                                    {clk?.quote?.rejectReasons &&
                                      clk?.quote?.rejectReasons?.length > 0 && (
                                        <span
                                          style={{
                                            cursor: "pointer",
                                            textDecoration: "underline",
                                            color: "red",
                                          }}
                                          onClick={() => {
                                            seterrorMessageRateStatusRejection(
                                              clk?.quote?.rejectReasons
                                            );
                                            handleShowErrorModalRejection();
                                          }}
                                        >
                                          Customer Reject Reason
                                        </span>
                                      )}
                                  </div>
                                </div>
                              </li>

                              <li className="h-100">
                                <div>
                                  <div className="btn-box d-flex align-items-center ">
                                    <button
                                      type="button"
                                      onClick={() => {
                                        getQuestions(clk?.quote?.modal_id);
                                        setselectedQuoteData(clk?.quote);
                                        setquoteDetailsCameModified(clk?.quote);
                                        handleShow();
                                      }}
                                      className="tracking-btn d-flex justify-content-center px-3 w-100"
                                    >
                                      <span>view quote data</span>
                                    </button>
                                    <button
                                      type="button"
                                      className="next-btn ms-2"
                                      onClick={() => {
                                        setselectedTempQuoteId({});
                                        nextButton(clk);
                                      }}
                                    >
                                      <svg className="icon fs-30">
                                        <use href="#icon_arrow"></use>
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>

              {(isLoadingMore || !dataFetched) && (
                <div className="w-100 text-center pt-3">
                  <Loader />
                </div>
              )}
              {!isLoadingMore && dataFetched && clkData?.length == 0 && (
                <div className="w-100 text-center ">
                  <h1>No opportunities available</h1>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <Modal
        show={showSuccess}
        centered
        onHide={handleCloseSuccess}
        className="tracking-modal"
      >
        <Modal.Body className="p-4 text-center">
          <div class="d-flex justify-content-center  text-success pb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="55"
              height="55"
              fill="currentColor"
              className="bi bi-check-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
            </svg>
          </div>
          <h3>Success</h3>
          <p>File uploaded successfully</p>
        </Modal.Body>
      </Modal>
      <Modal
        show={show}
        scrollable={true}
        centered
        onHide={() => {
          discardFunction();
          setEditSave(false);
          handleClose();
        }}
        className="modal-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex  w-100 align-items-center">
            <div>Quote Data</div>
            <div className="ms-auto pe-5">{getButtons()}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <Details
            quoteDetailsCameFromLocalStorage={selectedQuoteData}
            quoteDetailsCameModified={quoteDetailsCameModified}
            setquoteDetailsCameModified={setquoteDetailsCameModified}
            tempClickupData={clickupData}
            questionData={questionData}
            setQuestionData={setQuestionData}
            editSave={editSave}
            handleClose={handleClose}
            reloadFn={reloadFn}
            getQuestions={getQuestions}
            allCountries={allCountries}
            discardFunction={discardFunction}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={showErrorModal}
        centered
        onHide={handleCloseErrorModal}
        className="tracking-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title> Error from Freightify</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <div
            className="modal-content-container"
            style={{ maxHeight: "400px", overflowY: "auto" }}
          >
            {errorMessageRateStatus ? (
              <pre>{JSON.stringify(errorMessageRateStatus)}</pre>
            ) : (
              ""
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showErrorModalRejection}
        centered
        onHide={handleCloseErrorModalRejection}
        className="tracking-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title> Customer Reject Reason</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <div className="modal-content-container">
            {errorMessageRateStatusRejection?.length > 0 ? (
              <div>
                <div className="row pb-1">
                  <div className="col-6 fw-bold">Reject Type</div>
                  <div className="col-6 fw-bold">Reject Reason</div>
                </div>
                <div className="row">
                  {errorMessageRateStatusRejection?.map((reason, index) => (
                    <>
                      <div className="col-6">{reason?.reject_type}</div>
                      <div className="col-6">{reason?.reject_reason}</div>
                    </>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showThankyou} onHide={handleCloseThankyou} centered>
        <Modal.Body className="text-center py-5">
          <div className="d-flex justify-content-center">
            <div className="check-box">
              <svg className="icon fs-30">
                <use href="#icon_check"></use>
              </svg>
            </div>
          </div>
          {/* <p className="fw-600 fs-26 py-4">Thank you</p> */}
          <p className="fw-400 fs-20 py-4">Data Updated Successfully</p>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Filter;
