import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Modal, Button, Form } from "react-bootstrap";
import logo from "../images/logo.png";
import { useNavigate, useParams } from "react-router-dom";
import UserContext from "../context/UserContext";
import axios from "axios";
import Loader from "../components/Loader";
import jwt_decode from "jwt-decode";
import {
  conditionalExp,
  currencyOptions,
  convertPrice,
} from "../utils/commonFn";
import { convert } from "currency-exchanger-js";
import Ckeditor from "../components/Ckeditor";

function Home2() {
  const params = useParams();
  const { quote_id, task_id, trade } = params;
  const context = useContext(UserContext);
  const {
    quotationDetails,
    setquotationDetails,
    setdataFetched,
    selectedQuote,
    setselectedQuote,
    editSave,
    setEditSave,
    setprofitMargin,
    approvedQuoteDetails,
    selectedQuotePriceDetails,
    setselectedQuotePriceDetails,
    firstTimeProfitMargin,
    selectedTempQuoteId,
    setselectedTempQuoteId,
    componentRef,
    downloadButton,
    grossTotalCurrencies,
    setgrossTotalCurrencies,
    grossTotalCurrenciesIndidual,
    setgrossTotalCurrenciesIndividual,
    asideBarQuotePrices,
    setasideBarQuotePrices,
  } = context;
  const [updateCurrency, setupdateCurrency] = useState({
    rate_type_code: "",
    currency_to: "",
    currency_from: "",
  });
  const [changeCurrency, setchangeCurrency] = useState(false);
  const [showCurrency, setshowCurrency] = useState(false);

  const [differentCurrencies, setdifferentCurrencies] = useState(0);
  const [commentData, setCommentData] = useState("");

  const [postEditedDataFetched, setpostEditedDataFetched] = useState(true);

  console.log(postEditedDataFetched);
  const [selectedDate, setselectedDate] = useState("");
  // Modal
  const [rateTypeCodes, setrateTypeCodes] = useState([]);
  const [showCustomer, setshowCustomer] = useState(false);
  const handleCloseCustomer = () => setshowCustomer(false);
  const handleShowCustomer = () => {
    setsendingPlusDetailsError(null);
    setshowCustomer(true);
  };
  const [showCustomer2, setshowCustomer2] = useState(false);
  const handleCloseCustomer2 = () => setshowCustomer2(false);
  const handleShowCustomer2 = () => {
    setsendingPlusDetailsError(null);
    setshowCustomer2(true);
  };
  const [showCustomerComments, setshowCustomerComments] = useState(false);
  const handleCloseCustomerComments = () => setshowCustomerComments(false);
  const handleShowCustomerComments = () => setshowCustomerComments(true);
  const navigate = useNavigate();
  const [selectedLabel, setselectedLabel] = useState({});
  const [sendingPlusDetails, setsendingPlusDetails] = useState(false);
  const [sendingPlusDetailsComments, setsendingPlusDetailsComments] =
    useState(false);
  const [sendingPlusDetailsError, setsendingPlusDetailsError] = useState(null);
  const [sendingPlusDetailsErrorComments, setsendingPlusDetailsErrorComments] =
    useState(null);
  const [showVendorNew, setShowVendorNew] = useState(false);
  const [showVendorNewComment, setShowVendorNewComment] = useState(false);
  const [selectedDocId, setselectedDocId] = useState("");
  const [conatinerTypes, setconatinerTypes] = useState([]);
  const [deleting, setdeleting] = useState(false);
  const [deletingComment, setdeletingComment] = useState(false);
  const handleCloseVendorNew = () => setShowVendorNew(false);
  const handleShowVendorNew = () => setShowVendorNew(true);
  const handleCloseVendorNewComment = () => setShowVendorNewComment(false);
  const handleShowVendorNewComment = () => setShowVendorNewComment(true);
  const [airObject, setairObject] = useState({
    weight_per_pallet: "",
    gross_weight_tons: "",
    volume_weight: "",
    route_via: "",
    transit_time_in_days: "",
    air_service: "",
    no_of_pallet: "",
    cargo_description: "",
    pallet_description: "",
    transporter: "",
    destination_country: "",
    border: "",
  });
  const [no_of_qty, setno_of_qty] = useState("");
  const handleAirObject = (e) => {
    if (!editSave) return;
    setairObject({ ...airObject, [e.target.id]: e.target.value });
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let rightSideArray = [
    "Quote Number",
    "Date",
    "Validity",
    "Pricing Analyst",
    "Contact",
    "Sales Person",
    "Contact",
  ];
  const [calculatingTotal, setcalculatingTotal] = useState(false);

  const getFormattedDate = (quote_date_validFromAndTo) => {
    const dateStr = quote_date_validFromAndTo;
    const date = new Date(dateStr);

    const day = date.getDate();
    const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
      date
    );
    const year = date.getFullYear().toString().slice(-2);

    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  };
  const getFormattedDateToday = () => {
    const date = new Date();

    const day = date.getDate();
    const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
      date
    );
    const year = date.getFullYear().toString().slice(-2);

    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  };

  const manageResponseFn = async (response, from_post, id) => {
    if (from_post == "from_new_edit") {
      let data = await response?.data?.results?.quote_details;

      for (const element of data) {
        if (element?.id == id) {
          setselectedQuote(element);
          break;
        }
      }
    }
    // allAiseBarPrices(response?.data?.results?.quote_details,setasideBarQuotePrices);

    setquotationDetails(response?.data);
  };
  const manageErrorFn = (error) => {
    if (error.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/");
    } else if (error.code === "ECONNABORTED") {
      console.log("Request timed out");
    } else {
      console.log("An error occurred:", error);
    }
  };
  console.log(selectedQuote, "selected quote");

  const getQuotationDetails = async (from_post, id) => {
    if (from_post != "from_post") {
      setdataFetched(false);
    }

    try {
      let token = localStorage.getItem("mtm_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmclick/quote/details`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: {
          quote_id: quote_id,
          task_id: task_id,
        },
      });

      if (!response.data.error) {
        setdataFetched(true);
        manageResponseFn(response, from_post, id);
      }
    } catch (error) {
      manageErrorFn(error);
    }
  };
  console.log(selectedTempQuoteId, "quote temp selected");
  console.log(selectedLabel, "selected label");
  const postPlusIcon = async (from) => {
    setsendingPlusDetails(true);
    setsendingPlusDetailsError(null);
    try {
      let token = localStorage.getItem("mtm_user_token");
      // let diff =
      //   parseFloat(grossTotal("other")) -
      //   parseFloat(grossTotalOriginal("other"));
      // let percentage = diff / parseFloat(grossTotalOriginal("other"));
      // let amount =
      //   parseFloat(selectedLabel?.rate_usd) +
      //   percentage * parseFloat(selectedLabel?.rate_usd);
      // amount = amount.toFixed(2);
      let data = {
        freightify_id: selectedQuote?.freightify_id,
        quote_id: quote_id,
        description: selectedLabel?.description,
        rate_type_code: selectedLabel?.rate_type_code,
        rate_usd: selectedLabel?.rate_usd,
        rate_currency: selectedLabel?.rate_currency,
        container_type:
          getValue("container_type") == "20GP + 40GP"
            ? selectedLabel?.container_type
            : getValue("container_type"),
        // amount_usd:
        //   from == "edit" ||
        //   quotationDetails?.quote_form?.product_type_name == "Air"
        //     ? selectedLabel?.amount_usd
        //     : amount,
        amount_usd: selectedLabel?.amount_usd,
        actual_amount_usd: selectedLabel?.actual_amount_usd,
        actual_rate_usd: selectedLabel?.actual_rate_usd,
        rate_description: selectedLabel?.rate_description,
        pallet: selectedLabel?.pallet,
        qty: selectedLabel?.qty,
      };
      if (quotationDetails?.quote_form?.product_type_name?.includes("TRUCK")) {
        data = {
          freightify_id: selectedQuote?.freightify_id,
          quote_id: quote_id,
          description: selectedLabel?.description,
          rate_type_code: selectedLabel?.rate_type_code,
          rate_currency: selectedLabel?.rate_currency,
          container_type:
            getValue("container_type") == "20GP + 40GP"
              ? selectedLabel?.container_type
              : getValue("container_type"),
          amount_usd: selectedLabel?.amount_usd,
          actual_amount_usd: selectedLabel?.actual_amount_usd,
          rate_description: selectedLabel?.rate_description,
          pallet: selectedLabel?.pallet,
          qty: selectedLabel?.qty,
        };
      }
      if (from == "edit") {
        data.id = selectedLabel?.id;
        delete data.quote_id;
        delete data.rate_type_code;
      }

      const response = await axios({
        url:
          from == "add"
            ? `${process.env.REACT_APP_BASE_URL}/mtmclick/quote/price/add`
            : `${process.env.REACT_APP_BASE_URL}/mtmclick/quote/price/edit`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: data,
      });
      setsendingPlusDetails(false);
      if (!response.data.error) {
        let id = { ...selectedQuote };
        if (
          quotationDetails?.quote_form?.product_type_name == "Air" ||
          quotationDetails?.quote_form?.product_type_name?.includes("TRUCK")
        ) {
          await onlyAirCaseTableUpdate();
        }
        // if(quotationDetails?.quote_form?.no_of_qty&&quotationDetails?.quote_form?.no_of_qty!=""){
        //  await postQuotationDetails("update_block");
        // }

        setselectedTempQuoteId({ id: id.id });
        getQuotationDetails("from_new_edit", id.id);
        setquotationDetails([]);
        setselectedQuote({});
        handleCloseCustomer();
        handleCloseCustomer2();
      } else {
        setsendingPlusDetailsError(response.data.message);
      }
    } catch (error) {
      setsendingPlusDetails(false);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  };
  const postCommentApi = async () => {
    setsendingPlusDetailsComments(true);
    setsendingPlusDetailsErrorComments(null);
    try {
      let token = localStorage.getItem("mtm_user_token");

      const response = await axios({
        url: selectedQuote?.comment
          ? `${process.env.REACT_APP_BASE_URL}/mtmclick/quote/comment/edit`
          : `${process.env.REACT_APP_BASE_URL}/mtmclick/quote/comment/add`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: {
          freightify_id: selectedQuote?.freightify_id,
          comment: commentData,
        },
      });
      setsendingPlusDetailsComments(false);
      if (!response.data.error) {
        let id = { ...selectedQuote };
        setselectedTempQuoteId({ id: id.id });
        getQuotationDetails("from_new_edit", id.id);
        setselectedQuote({});
        setquotationDetails([]);
        handleCloseCustomerComments();
      } else {
        setsendingPlusDetailsErrorComments(response.data.message);
      }
    } catch (error) {
      setsendingPlusDetailsComments(false);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  };
  const getCargoMesurements = (type) => {
    let value = quotationDetails?.quote_form?.cargo_measurements;
    if (!value) return "";
    let splitMeasurements = value?.split("X");
    let measurement = {};
    measurement["length"] = splitMeasurements[0];
    measurement["width"] = splitMeasurements[1];
    measurement["height"] = splitMeasurements[2];
    return measurement[type];
  };

  const postQuotationDetails = async (from = "normal") => {
    if (from == "save_button") {
      setpostEditedDataFetched(false);
    }
    if (
      quotationDetails?.quote_form?.product_type_name == "Air" ||
      quotationDetails?.quote_form?.product_type_name?.includes("TRUCK")
    ) {
      await onlyAirCaseTableUpdate();
    }
    try {
      let token = localStorage.getItem("mtm_user_token");
      const quote_prices = [];

      for (const element of selectedQuotePriceDetails) {
        let obj = {};
        obj["id"] = element?.id;
        obj["amount_usd"] = element?.amount_usd;

        quote_prices.push(obj);
      }

      const data = {
        quote_id: quotationDetails?.quote_form?.quote_id,
        task_id: quotationDetails?.results?.task_id,
        freightify_id: selectedQuote?.freightify_id,
        total_amount_usd: grossTotal("other"),
        quote_prices: quote_prices,
        trade: trade,
        valid_to_date: selectedDate,
        no_of_qty:
          quotationDetails?.quote_form?.no_of_qty &&
          quotationDetails?.quote_form?.no_of_qty != ""
            ? parseInt(quotationDetails?.quote_form?.no_of_qty)
            : null,
      };

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmclick/quote/update`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: data,
      });
      setpostEditedDataFetched(true);
      if (!response.data.error) {
        if (from != "update_block") {
          setEditSave(false);
        }
        handleClose();
        getQuotationDetails("from_post");
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      setpostEditedDataFetched(true);
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  };
  console.log(no_of_qty, "no of qty");
  const finalRates = (rate_type_code) => {
    let name = rate_type_code?.toUpperCase();
    if (name?.includes("FREIGHT")) {
      return getAirRates(rate_type_code, "actual_amount_usd")?.toFixed(2);
    } else if (name?.includes("ORIGIN")) {
      return getAirRates(rate_type_code, "actual_amount_usd")?.toFixed(2);
    } else if (name?.includes("DESTINATION")) {
      return getAirRates(rate_type_code, "actual_amount_usd")?.toFixed(2);
    } else if (name?.includes("ADDON")) {
      return getAirRates(rate_type_code, "actual_amount_usd")?.toFixed(2);
    }
  };
  const finalRatesMarkUp = (rate_type_code) => {
    let name = rate_type_code?.toUpperCase();
    if (name?.includes("FREIGHT")) {
      return getAirRates(rate_type_code, "amount_usd")?.toFixed(2);
    } else if (name?.includes("ORIGIN")) {
      return getAirRates(rate_type_code, "amount_usd")?.toFixed(2);
    } else if (name?.includes("DESTINATION")) {
      return getAirRates(rate_type_code, "amount_usd")?.toFixed(2);
    } else if (name?.includes("ADDON")) {
      return getAirRates(rate_type_code, "amount_usd")?.toFixed(2);
    }
  };
  const getFinalRatesAir = () => {
    return (
      getAirRates("FREIGHT", "actual_amount_usd") +
      getAirRates("ORIGIN", "actual_amount_usd") +
      getAirRates("DESTINATION", "actual_amount_usd") +
      getAirRates("ADDON", "actual_amount_usd")
    );
  };
  const getFinalRatesAirMarkUp = () => {
    return (
      getAirRates("FREIGHT", "amount_usd") +
      getAirRates("ORIGIN", "amount_usd") +
      getAirRates("DESTINATION", "amount_usd") +
      getAirRates("ADDON", "amount_usd")
    );
  };
  const getTruckRates = (rate_type_code, cont, key) => {
    let allRrieghtDetails = selectedQuotePriceDetails?.filter((quote) => {
      if (cont == "other") {
        return quote?.rate_type_code?.includes(rate_type_code);
      } else {
        return (
          quote?.rate_type_code?.includes(rate_type_code) &&
          quote?.container_type == cont
        );
      }
    });
    let sum = 0;

    for (const element of allRrieghtDetails) {
      if (element[key]) {
        sum += parseFloat(element[key]);
      }
    }
    return sum;
  };
  const getFrieghtRates = (rate_type_code, cont) => {
    let allRrieghtDetails = selectedQuotePriceDetails?.filter((quote) => {
      if (cont == "other") {
        return quote?.rate_type_code?.includes(rate_type_code);
      } else {
        return (
          quote?.rate_type_code?.includes(rate_type_code) &&
          quote?.container_type == cont
        );
      }
    });
    let sum = 0;
    for (const element of allRrieghtDetails) {
      if (element?.amount_usd) {
        sum += parseFloat(element?.amount_usd);
      }
    }
    return sum;
  };
  const getAirRates = (rate_type_code, type) => {
    let allRrieghtDetails = selectedQuotePriceDetails?.filter((quote) => {
      return quote?.rate_type_code?.includes(rate_type_code);
    });
    let sum = 0;

    for (const element of allRrieghtDetails) {
      if (element[type]) {
        sum += parseFloat(element[type]);
      }
    }
    return sum;
  };

  const getFrieghtRatesOriginal = (rate_type_code, cont) => {
    let allRrieghtDetails = selectedQuotePriceDetails?.filter((quote) => {
      if (cont == "other") {
        return quote?.rate_type_code?.includes(rate_type_code);
      } else {
        return (
          quote?.rate_type_code?.includes(rate_type_code) &&
          quote?.container_type == cont
        );
      }
    });
    let sum = 0;

    for (const element of allRrieghtDetails) {
      if (element?.actual_amount_usd) {
        sum += parseFloat(element?.actual_amount_usd);
      }
    }
    return sum;
  };
  // gross total
  const grossTotal = (cont, currency = "USD") => {
    let sum = 0;
    let array = [];
    if (cont != "other") {
      array = selectedQuotePriceDetails?.filter((item) => {
        return item?.container_type == cont;
      });
    } else {
      array = selectedQuotePriceDetails;
    }

    for (const element of array) {
      sum += element?.amount_usd ? parseFloat(element?.amount_usd) : 0;
    }

    return parseFloat(sum).toFixed(2);
  };
  const convertOtherThanUsdFn = async (array) => {
    const conversionPromises = array.map(async (element) => {
      const convertedAmount = await convertPrice(
        element?.amount_usd,
        element?.rate_currency,
        "USD"
      );
      return parseFloat(convertedAmount || 0);
    });

    const convertedAmounts = await Promise.all(conversionPromises);
    const sum = convertedAmounts.reduce((acc, val) => acc + val, 0);

    return sum;
  };

  const grossTotalTruck = () => {
    let sum = 0;
    let array = [];

    array = selectedQuotePriceDetails;

    for (const element of array) {
      sum += element?.actual_amount_usd
        ? parseFloat(element?.actual_amount_usd)
        : 0;
    }
    return parseFloat(sum).toFixed(2);
  };
  const grossTotalOriginalTruck = () => {
    let sum = 0;
    let array = [];

    array = selectedQuotePriceDetails;

    for (const element of array) {
      sum += element?.amount_usd ? parseFloat(element?.amount_usd) : 0;
    }
    return parseFloat(sum).toFixed(2);
  };
  const grossTotalAir = (type) => {
    let sum = 0;
    let array = [];

    array = selectedQuotePriceDetails;

    for (const element of array) {
      sum += element[type] ? parseFloat(element[type]) : 0;
    }
    return parseFloat(sum).toFixed(2);
  };
  const grossTotalOriginal = (cont, currency = "USD") => {
    let sum = 0;
    let array = [];
    if (cont != "other") {
      array = selectedQuotePriceDetails?.filter((item) => {
        return item?.container_type == cont;
      });
    } else {
      array = selectedQuotePriceDetails;
    }
    // for (const element of array) {
    //   sum += element?.actual_amount_usd
    //     ? parseFloat(element?.actual_amount_usd)
    //     : 0;
    // }
    for (let i = 0; i < array.length; i++) {
      const element = array[i];
      if (element?.rate_currency == currency) {
        sum += element?.actual_amount_usd
          ? parseFloat(element?.actual_amount_usd)
          : 0;
      }
    }
    return parseFloat(sum).toFixed(2);
  };
  // ist part table values
  const getValue = (req_key) => {
    if (Object.keys(quotationDetails || {}).length == 0) {
      return;
    }
    return quotationDetails?.quote_form[req_key];
  };
  // get profit margin
  const getProfitMargin = () => {
    let diff;
    let percentage;
    diff =
      parseFloat(grossTotalCurrencies["USD"]["other"]) -
      parseFloat(grossTotalCurrencies["USD"]["other_original"]);
    percentage =
      (diff / parseFloat(grossTotalCurrencies["USD"]["other_original"])) * 100;
    if (
      percentage.toFixed(2) <
      parseFloat(
        quotationDetails?.results?.trade_percent_obj?.min_trade_percent
      )
    ) {
      setprofitMargin(true);
    } else {
      setprofitMargin(false);
    }
    if (percentage) {
      percentage = percentage.toFixed(2);
      return `${percentage} %`;
    } else {
      return "0 %";
    }
  };
  const getProfitMargin2040 = (cont) => {
    let diff;
    let percentage;
    diff =
      parseFloat(grossTotalCurrencies["USD"]["other"]) -
      parseFloat(grossTotalCurrencies["USD"]["other_original"]);
    percentage =
      (diff / parseFloat(grossTotalCurrencies["USD"]["other_original"])) * 100;
    if (
      percentage.toFixed(2) <
      parseFloat(
        quotationDetails?.results?.trade_percent_obj?.min_trade_percent
      )
    ) {
      setprofitMargin(true);
    } else {
      setprofitMargin(false);
    }

    let diff2040;
    let percentage2040;
    if (cont == "20GP") {
      diff2040 =
        parseFloat(grossTotalCurrencies["USD"]["20GP"]) -
        parseFloat(grossTotalCurrencies["USD"]["20GP_original"]);
      percentage2040 =
        (diff2040 / parseFloat(grossTotalCurrencies["USD"]["20GP_original"])) *
        100;
    }
    if (cont == "40GP") {
      diff2040 =
        parseFloat(grossTotalCurrencies["USD"]["40GP"]) -
        parseFloat(grossTotalCurrencies["USD"]["40GP_original"]);
      percentage2040 =
        (diff2040 / parseFloat(grossTotalCurrencies["USD"]["40GP_original"])) *
        100;
    }

    // console.log(diff2040,percentage2040,"percentage2040...")
    if (percentage2040) {
      percentage2040 = percentage2040.toFixed(2);
      return `${percentage2040} %`;
    } else {
      return "0 %";
    }
  };
  const getPickUpData = () => {
    let data = "";
    data += getValue("pickup_address1") && getValue("pickup_address1") + ", ";
    data += getValue("pickup_address2") && getValue("pickup_address2") + ", ";
    data += getValue("pickup_city") && getValue("pickup_city") + ", ";
    data += getValue("pickup_state") && getValue("pickup_state") + ", ";
    data += getValue("pickup_zip_code") ? getValue("pickup_zip_code") : "";
    return data;
  };
  const getDestinationData = () => {
    let data = "";
    data +=
      getValue("delivery_address1") && getValue("delivery_address1") + ", ";
    data +=
      getValue("delivery_address2") && getValue("delivery_address2") + ", ";
    data += getValue("delivery_city") && getValue("delivery_city") + ", ";
    data += getValue("delivery_state") && getValue("delivery_state") + ", ";
    data += getValue("delivery_zip_code") ? getValue("delivery_zip_code") : "";
    return data;
  };
  // modify rates
  const modifyRates = (e, quote) => {
    if (!editSave) {
      return;
    }
    setselectedQuotePriceDetails((prev) => {
      return prev?.map((item) => {
        if (item?.id == quote.id) {
          return { ...item, amount_usd: e.target.value };
        } else {
          return item;
        }
      });
    });
  };
  console.log(differentCurrencies, "different currencies...");
  useEffect(() => {
    const token = localStorage.getItem("mtm_user_token");
    if (!token) {
      navigate("/");
    }
    if (token) {
      const decodedToken = jwt_decode(token);
      if (decodedToken.exp < Date.now() / 1000) {
        // Token has expired
        navigate("/");
      }
    }

    getQuotationDetails();
  }, []);
  function getTomorrowDate() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const year = tomorrow.getFullYear();
    const month = String(tomorrow.getMonth() + 1).padStart(2, "0");
    const day = String(tomorrow.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
  console.log(firstTimeProfitMargin, "first time profit margin");
  function capitalizeFirstLetter(str) {
    if (!str) {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  const checkOriginAddressDisplay = () => {
    let service_type = getValue("service_type");
    let split = service_type?.split(" ");
    return split[0] == "Door";
  };
  const checkDestinationAddressDisplay = () => {
    let service_type = getValue("service_type");
    let split = service_type?.split(" ");
    return split[2] == "Door";
  };
  const deleteLableFn = async () => {
    try {
      setdeleting(true);
      let token = localStorage.getItem("mtm_user_token");
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmclick/quote/price/delete`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: {
          id: selectedDocId,
          freightify_id: selectedQuote?.freightify_id,
        },
      });

      if (response.status == 200) {
        setdeleting(false);
        if (response.data.error) {
          alert(response.data.message);
        } else {
          let id = { ...selectedQuote };
          setselectedTempQuoteId({ id: id.id });
          getQuotationDetails("from_new_edit", id.id);
          setselectedQuote({});
          setquotationDetails([]);
          handleCloseVendorNew();
        }
      }
    } catch (error) {
      setdeleting(false);
      console.log(error);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
    }
  };
  const deleteLableFnComment = async () => {
    try {
      setdeletingComment(true);
      let token = localStorage.getItem("mtm_user_token");
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmclick/quote/comment/delete`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: {
          freightify_id: selectedQuote?.freightify_id,
        },
      });

      if (response.status == 200) {
        setdeletingComment(false);
        if (response.data.error) {
          alert(response.data.message);
        } else {
          let id = { ...selectedQuote };
          getQuotationDetails("from_new_edit", id.id);
          setselectedTempQuoteId({ id: id.id });
          setselectedQuote({});
          handleCloseVendorNewComment();
        }
      }
    } catch (error) {
      setdeletingComment(false);
      console.log(error);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
    }
  };
  const onlyAirCaseTableUpdate = async () => {
    try {
      let token = localStorage.getItem("mtm_user_token");
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/quote/airprice/update`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: {
          quote_id: quotationDetails?.quote_form?.quote_id,
          freightify_id: selectedQuote?.freightify_id,
          weight_per_pallet: airObject?.weight_per_pallet,
          volume_weight: airObject?.volume_weight,
          gross_weight: airObject?.gross_weight_tons,
          cargo_type: quotationDetails?.quote_form?.cargo_type,
          commodity_description: selectedQuote?.commodity,
          route_via: airObject?.route_via,
          air_service: airObject?.air_service,
          transit_time_in_days: airObject?.transit_time_in_days,
          cargo_description: airObject?.cargo_description,
          no_of_pallet: airObject?.no_of_pallet,
          pallet_description: airObject?.pallet_description,
          transporter: airObject?.transporter,
          destination_country: airObject?.destination_country,
          border: airObject?.border,
        },
      });

      if (response.status == 200) {
        if (response.data.error) {
          alert(response.data.message);
        } else {
          return response.data.message;
        }
      }
    } catch (error) {
      setdeletingComment(false);
      console.log(error);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
    }
  };
  const inputFn = (value, custom = "normal") => {
    if (!value) return "";
    let val = custom == "normal" ? getValue(value) : value;
    return (
      <input
        type="text"
        value={val}
        style={{
          border: "none",
          outline: "none",
          background: "none",
        }}
      ></input>
    );
  };
  const checkSelectedQuoteLength = () => {
    return Object.keys(selectedQuote || {}).length > 0;
  };
  const checkDisplay = () => {
    return (
      checkSelectedQuoteLength() &&
      Object.keys(quotationDetails?.results || {})?.length > 0
    );
  };

  const ifEditSave = () => {
    return (
      <div>
        <button
          type="button"
          className="logout-btn-line me-3"
          onClick={() => {
            // setselectedQuotePriceDetails(selectedQuote?.quote_price_details);
            setselectedDate(
              selectedQuote?.valid_to ? selectedQuote?.valid_to : ""
            );
            setairObject({
              weight_per_pallet:
                quotationDetails?.quote_form?.total_weight || "",
              gross_weight_tons:
                quotationDetails?.quote_form?.gross_weight || "",
              volume_weight: quotationDetails?.quote_form?.total_volume || "",
              route_via: selectedQuote?.route_via || "",
              transit_time_in_days: selectedQuote?.transit_time_in_days,
              air_service: selectedQuote?.air_service || "",
              no_of_pallet: quotationDetails?.quote_form?.no_of_pallet || "",
              cargo_description:
                quotationDetails?.quote_form?.cargo_description || "",
              pallet_description:
                quotationDetails?.quote_form?.pallet_description || "",
              transporter: selectedQuote?.transporter || "",
              destination_country:
                quotationDetails?.quote_form?.destination_country || "",
              border: selectedQuote?.border || "",
            });
            // setno_of_qty("");
            setquotationDetails({
              ...quotationDetails,
              quote_form: {
                ...quotationDetails?.quote_form,
                no_of_qty: "",
              },
            });
            setEditSave(false);
          }}
        >
          Discard
        </button>
        <button
          type="button"
          className="logout-btn px-4"
          style={{ marginRight: "75px" }}
          onClick={handleShow}
        >
          Save
        </button>
      </div>
    );
  };
  const ifNotEditSave = () => {
    return (
      <button
        type="button"
        className="logout-btn px-4"
        style={{ marginRight: "75px" }}
        disabled={Object.keys(approvedQuoteDetails || {}).length > 0}
        onClick={() => setEditSave(true)}
      >
        Edit
      </button>
    );
  };

  const changeQuoteCurrencyFn = async (rate_type_code, currency) => {
    setchangeCurrency(true);
    const getIfCondition = (item) => {
      if (updateCurrency?.container_type) {
        return (
          item?.rate_type_code === rate_type_code &&
          item?.container_type === updateCurrency?.container_type
        );
      } else {
        return item?.rate_type_code === rate_type_code;
      }
    };
    let updatedDetails = [];
    if (
      updateCurrency?.container_type &&
      updateCurrency?.container_type != "other"
    ) {
      updatedDetails = await Promise.all(
        selectedQuotePriceDetails.map(async (item) => {
          if (
            item?.rate_type_code === rate_type_code &&
            item?.container_type === updateCurrency?.container_type
          ) {
            const amount_usd = await convertPrice(
              item?.amount_usd,
              updateCurrency?.currency_from,
              updateCurrency?.currency_to
            );
            const actual_amount_usd = await convertPrice(
              item?.actual_amount_usd,
              updateCurrency?.currency_from,
              updateCurrency?.currency_to
            );
            const actual_rate_usd = await convertPrice(
              item?.actual_rate_usd,
              updateCurrency?.currency_from,
              updateCurrency?.currency_to
            );
            const rate_usd = await convertPrice(
              item?.rate_usd,
              updateCurrency?.currency_from,
              updateCurrency?.currency_to
            );

            return {
              ...item,
              rate_currency: currency,
              amount_usd: parseFloat(amount_usd).toFixed(2),
              actual_amount_usd: parseFloat(actual_amount_usd).toFixed(2),
              actual_rate_usd: parseFloat(actual_rate_usd).toFixed(2),
              rate_usd: parseFloat(rate_usd).toFixed(2),
            };
          } else {
            return item;
          }
        })
      );
    } else {
      updatedDetails = await Promise.all(
        selectedQuotePriceDetails.map(async (item) => {
          if (item?.rate_type_code === rate_type_code) {
            const amount_usd = await convertPrice(
              item?.amount_usd,
              updateCurrency?.currency_from,
              updateCurrency?.currency_to
            );
            const actual_amount_usd = await convertPrice(
              item?.actual_amount_usd,
              updateCurrency?.currency_from,
              updateCurrency?.currency_to
            );
            const actual_rate_usd = await convertPrice(
              item?.actual_rate_usd,
              updateCurrency?.currency_from,
              updateCurrency?.currency_to
            );
            const rate_usd = await convertPrice(
              item?.rate_usd,
              updateCurrency?.currency_from,
              updateCurrency?.currency_to
            );

            return {
              ...item,
              rate_currency: currency,
              amount_usd: parseFloat(amount_usd).toFixed(2),
              actual_amount_usd: parseFloat(actual_amount_usd).toFixed(2),
              actual_rate_usd: parseFloat(actual_rate_usd).toFixed(2),
              rate_usd: parseFloat(rate_usd).toFixed(2),
            };
          } else {
            return item;
          }
        })
      );
    }
    let price_breakups = [];
    if (
      updateCurrency?.container_type &&
      updateCurrency?.container_type != "other"
    ) {
      price_breakups = updatedDetails
        .filter(
          (item) =>
            item?.rate_type_code === rate_type_code &&
            item?.container_type === updateCurrency?.container_type
        )
        .map((item) => {
          return {
            id: item?.id,
            amount_usd: item?.amount_usd,
            actual_amount_usd: item?.actual_amount_usd,
          };
        });
    } else {
      price_breakups = updatedDetails
        .filter((item) => item?.rate_type_code === rate_type_code)
        .map((item) => {
          return {
            id: item?.id,
            amount_usd: item?.amount_usd,
            actual_amount_usd: item?.actual_amount_usd,
          };
        });
    }

    try {
      let token = localStorage.getItem("mtm_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmclick/quote/price/update_block`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: {
          rate_currency: currency,
          freightify_id: selectedQuote?.freightify_id,
          pricebrackups: price_breakups,
        },
      });
      if (!response.data.error) {
        setselectedQuotePriceDetails(updatedDetails);
        getQuotationDetails();
        // if(quotationDetails?.quote_form?.no_of_qty&&quotationDetails?.quote_form?.no_of_qty!==""){
        //   postQuotationDetails("update_block")
        // }
        setchangeCurrency(false);
        setshowCurrency(false);
      } else {
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  };

  const getBlockCurrency = (rate_type_code, cont) => {
    const getforCondition = (element) => {
      if (cont && cont != "other") {
        return (
          element?.rate_type_code === rate_type_code &&
          element?.container_type === cont
        );
      } else {
        return element?.rate_type_code === rate_type_code;
      }
    };
    for (let i = 0; i < selectedQuotePriceDetails.length; i++) {
      const element = selectedQuotePriceDetails[i];
      if (getforCondition(element)) {
        return element?.rate_currency;
      }
    }
    return "";
  };
  const getButtons = () => {
    return editSave ? ifEditSave() : ifNotEditSave();
  };
  const downLoadButtonClicked = () => {
    return (
      <div>
        <input
          type="text"
          value={getFormattedDate(selectedDate)}
          style={{
            border: "none",
            outline: "none",
            background: "none",
          }}
        ></input>
      </div>
    );
  };
  const downloadButtonNotClickedEdit = () => {
    return (
      <input
        type="date"
        className=" w-100"
        placeholder=""
        id=""
        value={selectedDate}
        min={getTomorrowDate()}
        onChange={(e) => setselectedDate(e.target.value)}
      />
    );
  };
  const downloadButtonNotClickedCancel = () => {
    return (
      <input
        type="text"
        value={selectedDate?.length > 0 ? getFormattedDate(selectedDate) : ""}
        style={{
          border: "none",
          outline: "none",
          background: "none",
        }}
      ></input>
    );
  };
  const downLoadButtonNotClicked = () => {
    return editSave
      ? downloadButtonNotClickedEdit()
      : downloadButtonNotClickedCancel();
  };
  const downLoadFn = () => {
    return downloadButton
      ? downLoadButtonClicked()
      : downLoadButtonNotClicked();
  };
  const inputFieldValue = (value, else_value) => {
    return (
      <input
        type="text"
        value={value || else_value}
        style={{
          border: "none",
          outline: "none",
          background: "none",
        }}
      ></input>
    );
  };
  const getMappingFn = () => {
    if (getValue("container_type") == "20GP + 40GP") {
      return ["20GP", "40GP"];
    } else {
      return ["other"];
    }
  };
  const loaderFnSave = (state, value) => {
    return state ? <Loader /> : value;
  };
  useEffect(() => {
    if (
      Object.keys(quotationDetails || {}).length > 0 &&
      Object.keys(selectedQuote || {}).length > 0
    ) {
      if (getValue("container_type") == "20GP + 40GP") {
        let containertypes = [];
        for (let i = 0; i < selectedQuote?.quote_price_details?.length; i++) {
          const element = selectedQuote?.quote_price_details[i]?.container_type;
          if (element && element != "" && !containertypes?.includes(element)) {
            containertypes.push(element);
          }
        }
        setconatinerTypes(containertypes);
      }
      setairObject({
        weight_per_pallet: quotationDetails?.quote_form?.total_weight || "",
        gross_weight_tons: quotationDetails?.quote_form?.gross_weight || "",
        volume_weight: quotationDetails?.quote_form?.total_volume || "",
        route_via: selectedQuote?.route_via || "",
        transit_time_in_days: selectedQuote?.transit_time_in_days,
        air_service: selectedQuote?.air_service || "",
        no_of_pallet: quotationDetails?.quote_form?.no_of_pallet || "",
        cargo_description:
          quotationDetails?.quote_form?.cargo_description || "",
        pallet_description:
          quotationDetails?.quote_form?.pallet_description || "",
        transporter: selectedQuote?.transporter || "",
        destination_country:
          quotationDetails?.quote_form?.destination_country || "",
        border: selectedQuote?.border || "",
      });
      // setno_of_qty(selectedQuote?.no_of_qty);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // Object.keys(quotationDetails || {}).length,
    // Object.keys(selectedQuote || {}).length,
    quotationDetails,
    selectedQuote,
  ]);

  useEffect(() => {
    if (Object.keys(selectedQuote || {}).length > 0) {
      let quote_prices = selectedQuote?.quote_price_details;
      let rate_type_codes = [];

      for (const element of quote_prices) {
        if (!rate_type_codes.includes(element?.rate_type_code)) {
          rate_type_codes.push(element?.rate_type_code);
        }
      }
      setrateTypeCodes(rate_type_codes);
      setselectedDate(selectedQuote?.valid_to ? selectedQuote?.valid_to : "");
      setselectedQuotePriceDetails(selectedQuote?.quote_price_details);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQuote?.id]);

  console.log(quotationDetails, "quotation details");
  console.log(selectedQuote, "selected quote");
  const getDifferentCurrencies = (cont) => {
    let currencies = ["USD"];
    let quotePriceDetails = [];
    if (cont && cont != "other") {
      quotePriceDetails = selectedQuotePriceDetails?.filter(
        (item) => item?.container_type === cont
      );
    } else {
      quotePriceDetails = selectedQuotePriceDetails;
    }
    for (let i = 0; i < quotePriceDetails.length; i++) {
      const element = quotePriceDetails[i];
      if (!currencies?.includes(element.rate_currency)) {
        currencies.push(element?.rate_currency);
      }
    }
    return currencies;
  };

  useEffect(() => {
    if (selectedQuotePriceDetails.length > 0) {
      let different = getDifferentCurrencies();
      setdifferentCurrencies(different);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQuotePriceDetails]);
  const calculatePrice = async () => {
    setcalculatingTotal(true);
    let usd_sum = 0;
    let usd_sum_original = 0;
    let usd_sum_20gp = 0;
    let usd_sum_40gp = 0;
    let usd_sum_20gp_original = 0;
    let usd_sum_40gp_original = 0;

    let obj_individual = {};
    let obj = {};

    console.log("COMING HERE>>>>");
    if (selectedQuote?.quote_status == 2) {
      let blockPrices = selectedQuote?.blockprices;
      if (blockPrices?.length > 0) {
        let blockPricesGround = blockPrices?.filter(
          (item) => item?.rate_type_code == "GROUND_TOTAL"
        );
        if (quotationDetails?.quote_form?.container_type == "20GP + 40GP") {
          for (let i = 0; i < blockPricesGround.length; i++) {
            const element = blockPricesGround[i];
            if (element.container_type == "20GP") {
              obj[element?.rate_currency] = {
                ...obj[element?.rate_currency],
                other: element?.amount_usd,
                other_original: element?.actual_amount_usd,
                "20GP": element?.amount_usd,
                "20GP_original": element?.actual_amount_usd,
              };
            } else if (element.container_type == "40GP") {
              obj[element?.rate_currency] = {
                ...obj[element?.rate_currency],
                other: element?.amount_usd,
                other_original: element?.actual_amount_usd,
                "40GP": element?.amount_usd,
                "40GP_original": element?.actual_amount_usd,
              };
            }
          }
        } else {
          for (let i = 0; i < blockPricesGround.length; i++) {
            const element = blockPricesGround[i];
            obj[element?.rate_currency] = {
              other: element?.amount_usd,
              other_original: element?.actual_amount_usd,
              "20GP": "",
              "40GP": "",
            };
          }
        }
        let blockPricesUp = blockPrices?.filter(
          (item) => item?.rate_type_code != "GROUND_TOTAL"
        );
        if (quotationDetails?.quote_form?.container_type == "20GP + 40GP") {
          for (let i = 0; i < blockPricesUp.length; i++) {
            const element = blockPricesUp[i];
            if (element.container_type == "20GP") {
              obj_individual[element?.rate_type_code] = {
                ...obj_individual[element?.rate_type_code],
                other: element?.amount_usd,
                other_original: element?.actual_amount_usd,
                rate_currency: element?.rate_currency,
                "20GP": element?.amount_usd,
                "20GP_original": element?.actual_amount_usd,
              };
            } else {
              obj_individual[element?.rate_type_code] = {
                ...obj_individual[element?.rate_type_code],
                other: element?.amount_usd,
                other_original: element?.actual_amount_usd,
                rate_currency: element?.rate_currency,
                "40GP": element?.amount_usd,
                "40GP_original": element?.actual_amount_usd,
              };
            }
          }
        } else {
          for (let i = 0; i < blockPricesUp.length; i++) {
            const element = blockPricesUp[i];

            obj_individual[element?.rate_type_code] = {
              other: element?.amount_usd,
              other_original: element?.actual_amount_usd,
              rate_currency: element?.rate_currency,
              actual_amount_usd: "",
              amount_usd: "",
            };
          }
        }
      }
    } else {
      for (let i = 0; i < rateTypeCodes.length; i++) {
        const rate_type_code = rateTypeCodes[i];
        let block_currency = getBlockCurrency(rate_type_code);

        if (block_currency === "USD") {
          let value = getFrieghtRates(rate_type_code, "other");
          let value_original = getFrieghtRatesOriginal(rate_type_code, "other");
          usd_sum += value;
          usd_sum_original += value_original;
          obj_individual[rate_type_code] = {
            other: value,
            other_original: value_original,
            rate_currency: block_currency,
            actual_amount_usd: value_original,
            amount_usd: value,
          };
        } else {
          let current_price = getFrieghtRates(rate_type_code, "other");
          let converted_price = await convertPrice(
            current_price,
            block_currency,
            "USD"
          );
          let value_converted = parseFloat(converted_price || 0);
          usd_sum += value_converted;
          //
          let current_price_original = getFrieghtRatesOriginal(
            rate_type_code,
            "other"
          );
          let converted_price_original = await convertPrice(
            current_price_original,
            block_currency,
            "USD"
          );
          let value_converted_original = parseFloat(
            converted_price_original || 0
          );
          usd_sum_original += value_converted_original;
          obj_individual[rate_type_code] = {
            other: value_converted,
            other_original: value_converted_original,
            rate_currency: block_currency,
            actual_amount_usd: current_price_original,
            amount_usd: current_price,
          };
        }
        if (quotationDetails?.quote_form?.container_type == "20GP + 40GP") {
          let block_currency_20GP = getBlockCurrency(rate_type_code, "20GP");
          let block_currency_40GP = getBlockCurrency(rate_type_code, "40GP");
          if (block_currency_20GP === "USD") {
            let value_20gp = getFrieghtRates(rate_type_code, "20GP");
            let value_original_20gp = getFrieghtRatesOriginal(
              rate_type_code,
              "20GP"
            );
            usd_sum_20gp += value_20gp;
            usd_sum_20gp_original += value_original_20gp;
            obj_individual[rate_type_code] = {
              ...obj_individual[rate_type_code],
              "20GP": value_20gp,
              "20GP_original": value_original_20gp,
            };
          } else {
            let current_price = getFrieghtRates(rate_type_code, "20GP");
            let converted_price = await convertPrice(
              current_price,
              block_currency_20GP,
              "USD"
            );
            let value_converted = parseFloat(converted_price || 0);
            usd_sum_20gp += value_converted;
            //
            let current_price_original = getFrieghtRatesOriginal(
              rate_type_code,
              "20GP"
            );
            let converted_price_original = await convertPrice(
              current_price_original,
              block_currency_20GP,
              "USD"
            );
            let value_converted_original = parseFloat(
              converted_price_original || 0
            );
            usd_sum_20gp_original += value_converted_original;
            obj_individual[rate_type_code] = {
              ...obj_individual[rate_type_code],
              rate_currency_20GP: block_currency_20GP,
              "20GP": value_converted,
              "20GP_original": value_converted_original,
            };
          }
          if (block_currency_40GP === "USD") {
            let value_40gp = getFrieghtRates(rate_type_code, "40GP");
            let value_original_40gp = getFrieghtRatesOriginal(
              rate_type_code,
              "40GP"
            );
            usd_sum_40gp += value_40gp;
            usd_sum_40gp_original += value_original_40gp;
            obj_individual[rate_type_code] = {
              ...obj_individual[rate_type_code],
              "40GP": value_40gp,
              "40GP_original": value_original_40gp,
            };
          } else {
            let current_price = getFrieghtRates(rate_type_code, "40GP");
            let converted_price = await convertPrice(
              current_price,
              block_currency_20GP,
              "USD"
            );
            let value_converted = parseFloat(converted_price || 0);
            usd_sum_40gp += value_converted;
            //
            let current_price_original = getFrieghtRatesOriginal(
              rate_type_code,
              "40GP"
            );
            let converted_price_original = await convertPrice(
              current_price_original,
              block_currency_40GP,
              "USD"
            );
            let value_converted_original = parseFloat(
              converted_price_original || 0
            );
            usd_sum_40gp_original += value_converted_original;
            obj_individual[rate_type_code] = {
              ...obj_individual[rate_type_code],
              rate_currency_40GP: block_currency_40GP,
              "40GP": value_converted,
              "40GP_original": value_converted_original,
            };
          }
        }
      }

      obj = {
        USD: {
          other: usd_sum,
          other_original: usd_sum_original,
          "20GP": usd_sum_20gp,
          "20GP_original": usd_sum_20gp_original,
          "40GP": usd_sum_40gp,
          "40GP_original": usd_sum_40gp_original,
        },
      };
      let different = getDifferentCurrencies();
      for (let i = 0; i < different?.length; i++) {
        const currency = different[i];
        if (currency !== "USD") {
          let converted_price = await convertPrice(usd_sum, "USD", currency);
          let converted_price_original = await convertPrice(
            usd_sum_original,
            "USD",
            currency
          );

          obj[currency] = {
            other: converted_price,
            other_original: converted_price_original,
          };
          if (quotationDetails?.quote_form?.container_type == "20GP + 40GP") {
            let converted_price_20gp = await convertPrice(
              usd_sum_20gp,
              "USD",
              currency
            );
            let converted_price_original_20gp = await convertPrice(
              usd_sum_20gp_original,
              "USD",
              currency
            );
            // 40gp
            let converted_price_40gp = await convertPrice(
              usd_sum_40gp,
              "USD",
              currency
            );
            let converted_price_original_40gp = await convertPrice(
              usd_sum_40gp_original,
              "USD",
              currency
            );
            obj[currency] = {
              ...obj[currency],
              other: converted_price,
              other_original: converted_price_original,
              "20GP": converted_price_20gp,
              "20GP_original": converted_price_original_20gp,
              "40GP": converted_price_40gp,
              "40GP_original": converted_price_original_40gp,
            };
          }
        }
      }
      setasideBarQuotePrices({
        ...asideBarQuotePrices,
        [selectedQuote?.freightify_id]: {
          amount_usd: usd_sum,
          actual_amount_usd: usd_sum_original,
        },
      });
    }

    setgrossTotalCurrencies({
      ...grossTotalCurrencies,
      ...obj,
    });
    setgrossTotalCurrenciesIndividual({
      ...grossTotalCurrenciesIndidual,
      ...obj_individual,
    });
    setcalculatingTotal(false);
  };
  const checkInAddOn = () => {
    if (rateTypeCodes.includes("ADDON")) {
      return getBlockCurrency("ADDON");
    } else {
      return "USD";
    }
  };
  // useEffect(() => {
  //   // if(grossTotalCurrencies?.usd?.other!=null&&grossTotalCurrencies?.usd?.other!=""&&grossTotalCurrencies?.usd?.other_original!=null&&grossTotalCurrencies?.usd?.other_original!="")
  //   setasideBarQuotePrices({
  //     ...asideBarQuotePrices,
  //     [selectedQuote?.id]:{
  //       amount_usd:grossTotalCurrencies["USD"]?.other,
  //       actual_amount_usd:grossTotalCurrencies["USD"]?.other_original
  //     }
  //   })
  // }, [grossTotalCurrencies])

  console.log(grossTotalCurrenciesIndidual, "grossTotalCurrenciesIndidual....");
  useEffect(() => {
    setcalculatingTotal(true);
    if (
      Object.keys(selectedQuote || {})?.length > 0 &&
      selectedQuotePriceDetails.length > 0 &&
      rateTypeCodes.length > 0 &&
      Object.keys(asideBarQuotePrices || {}).length > 0
    ) {
      console.log("coming in use effect");
      calculatePrice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedQuote,
    selectedQuotePriceDetails,
    rateTypeCodes?.length,
    Object.keys(asideBarQuotePrices || {}).length,
  ]);
  console.log(grossTotalCurrencies, "gross total currencies");
  const grossTotalDisplay = (cont, currency) => {
    let value = parseFloat(grossTotalCurrencies[currency][cont] || 0);
    return value.toFixed(2);
  };
  const getIndividualRates = (rate_type_code, cont) => {
    if (
      Object.keys(grossTotalCurrenciesIndidual || {}).length == 0 ||
      !grossTotalCurrenciesIndidual[rate_type_code?.toUpperCase()]?.other
    ) {
      return "0.00";
    }
    let value;
    if (cont == "20GP") {
      value = parseFloat(
        grossTotalCurrenciesIndidual[rate_type_code?.toUpperCase()]["20GP"] || 0
      );
    } else if (cont == "40GP") {
      value = parseFloat(
        grossTotalCurrenciesIndidual[rate_type_code?.toUpperCase()]["40GP"] || 0
      );
    } else {
      value = parseFloat(
        grossTotalCurrenciesIndidual[rate_type_code?.toUpperCase()]?.other || 0
      );
    }
    return value.toFixed(2);
  };
  console.log("");
  const getIndividualRatesOriginal = (rate_type_code, cont) => {
    if (
      Object.keys(grossTotalCurrenciesIndidual || {}).length == 0 ||
      !grossTotalCurrenciesIndidual[rate_type_code?.toUpperCase()]
        ?.other_original
    ) {
      return "0.00";
    }
    let value;
    if (cont == "20GP") {
      value = parseFloat(
        grossTotalCurrenciesIndidual[rate_type_code?.toUpperCase()][
          "20GP_original"
        ] || 0
      );
    } else if (cont == "40GP") {
      value = parseFloat(
        grossTotalCurrenciesIndidual[rate_type_code?.toUpperCase()][
          "40GP_original"
        ] || 0
      );
    } else {
      value = parseFloat(
        grossTotalCurrenciesIndidual[rate_type_code?.toUpperCase()]
          ?.other_original || 0
      );
    }
    return value.toFixed(2);
  };
  const getIndividualBlockCurrencies = (rate_type_code, cont) => {
    if (
      Object.keys(grossTotalCurrenciesIndidual || {}).length == 0 ||
      !grossTotalCurrenciesIndidual[rate_type_code?.toUpperCase()][cont]
    ) {
      return "0.00";
    }
    let value = parseFloat(
      grossTotalCurrenciesIndidual[rate_type_code?.toUpperCase()][
        "amount_usd"
      ] || 0
    );
    return value.toFixed(2);
  };
  const getIndividualBlockCurrenciesOriginal = (rate_type_code, cont) => {
    if (
      Object.keys(grossTotalCurrenciesIndidual || {}).length == 0 ||
      !grossTotalCurrenciesIndidual[rate_type_code?.toUpperCase()][
        "other_original"
      ]
    ) {
      return "0.00";
    }
    let value = parseFloat(
      grossTotalCurrenciesIndidual[rate_type_code?.toUpperCase()][
        "actual_amount_usd"
      ] || 0
    );
    return value.toFixed(2);
  };
  const grossTotalDisplayOriginal = (cont, currency) => {
    let value;
    if (cont == "other" || cont == "other_original") {
      value = parseFloat(grossTotalCurrencies[currency]["other_original"] || 0);
    } else {
      value = parseFloat(
        grossTotalCurrencies[currency][cont + "_original"] || 0
      );
    }

    return value.toFixed(2);
  };

  return (
    <>
      {checkDisplay() && (
        <Container className="nav-btn">
          <Row>
            <Col md={12}>
              <div
                className="nav nav-home"
                // style={{ position: "relative", zIndex: "10000!important" }}
              >
                <div className="d-flex align-items-center w-100 text-center">
                  <div className="ms-auto text-white">
                    {getValue("container_type") == "20GP + 40GP" ? (
                      <div className="d-flex justify-between gap-5">
                        <div>
                          <strong>20GP Profit Margin</strong> :
                          {getProfitMargin2040("20GP")}
                        </div>
                        <div>
                          <strong>40GP Profit Margin</strong> :
                          {getProfitMargin2040("40GP")}
                        </div>
                      </div>
                    ) : (
                      <span>
                        <strong>Profit Margin</strong> : {getProfitMargin()}
                      </span>
                    )}
                  </div>
                  <div className="ms-auto">
                    {conditionalExp(
                      quotationDetails?.quote_form?.quote_status != 2,
                      getButtons(),
                      <>&nbsp;</>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      )}
      <Container fluid ref={componentRef} style={{ overflow: "hidden" }}>
        {checkSelectedQuoteLength() && (
          <Row>
            <Col className="col-12 py-3">
              <div
                style={{
                  width: "100%",
                  background: "#ffff",
                  padding: "20px 10px",
                }}
              >
                <table width="100%" style={{ borderCollapse: "collapse" }}>
                  <tbody>
                    <tr style={{ background: "#002981", color: "#ffff" }}>
                      <td style={{ width: "100%", textAlign: "center" }}>
                        <h4 className="py-2">Ocean Freight Import Quotation</h4>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table width="100%" style={{ borderCollapse: "collapse" }}>
                  <tbody>
                    <tr>
                      <td colSpan={3} style={{ width: "60%" }}>
                        <img
                          src={logo}
                          style={{ width: "180px", height: "60px" }}
                        />
                        <h4 className="pt-2" style={{ color: "#0c1a57" }}>
                          MTM Logix, S.A. de C. V
                        </h4>
                        <p className="py-2">Palmas 525, Lomas de Chapultepec</p>
                        <p className="pb-1">C.P 11000 Miguel Hidalgo</p>
                      </td>
                      <td style={{ width: "40%", margin: "0", padding: "0" }}>
                        <div
                          style={{
                            display: "inline-block",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            background: "#002981",
                            color: "#ffff",
                          }}
                        >
                          {rightSideArray?.map((item, index) => (
                            <div key={item}>
                              <input
                                type="text"
                                value={item}
                                style={{
                                  border: "none",
                                  outline: "none",
                                  background: "none",
                                  color: "white",
                                }}
                              ></input>
                            </div>
                          ))}
                          {quotationDetails?.quote_form?.product_type_name ==
                            "Air" && (
                            <div>
                              <input
                                type="text"
                                value="CBM"
                                style={{
                                  border: "none",
                                  outline: "none",
                                  background: "none",
                                  color: "white",
                                }}
                              ></input>
                            </div>
                          )}
                        </div>
                        <div
                          style={{
                            display: "inline-block",
                          }}
                        >
                          <div>
                            <input
                              type="text"
                              value={
                                quotationDetails?.quote_form?.custom_task_id
                              }
                              style={{
                                border: "none",
                                outline: "none",
                                background: "none",
                              }}
                            ></input>
                          </div>
                          <div>
                            <input
                              type="text"
                              value={getFormattedDateToday()}
                              style={{
                                border: "none",
                                outline: "none",
                                background: "none",
                              }}
                            ></input>
                          </div>
                          {downLoadFn()}

                          <div>
                            {inputFieldValue(
                              quotationDetails?.quote_form?.analyst_name,
                              JSON.parse(localStorage.getItem("mtm_user_data"))
                                ?.user_full_name
                            )}
                          </div>
                          <div>
                            {inputFieldValue(
                              quotationDetails?.quote_form?.analyst_email,
                              JSON.parse(localStorage.getItem("mtm_user_data"))
                                ?.email
                            )}
                          </div>
                          <div>
                            {inputFieldValue(
                              quotationDetails?.quote_form?.contact_person,
                              " - "
                            )}
                          </div>

                          <div>
                            {quotationDetails?.quote_form
                              ?.contact_person_email ? (
                              <a
                                href={`mailto:${quotationDetails?.quote_form?.contact_person_email}`}
                              >
                                {
                                  quotationDetails?.quote_form
                                    ?.contact_person_email
                                }
                              </a>
                            ) : (
                              <input
                                value={" - "}
                                style={{
                                  border: "none",
                                  outline: "none",
                                  background: "none",
                                }}
                              ></input>
                            )}
                          </div>
                          {quotationDetails?.quote_form?.product_type_name ==
                            "Air" && (
                            <div>
                              {inputFieldValue(
                                quotationDetails?.quote_form?.cbm,
                                " - "
                              )}
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table width="100%" style={{ borderCollapse: "collapse" }}>
                  <tbody>
                    <tr>
                      <td>
                        <div
                          style={{
                            textAlign: "center",
                            display: "inline-block",
                            background: "#002981",
                            color: "#ffff",
                            padding: "10px 40px",
                            margin: "0",
                          }}
                        >
                          <h5 style={{ padding: "0", margin: "0" }}>
                            Customer Name
                          </h5>
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            display: "inline-block",
                            background: "#f6f5e3",
                            padding: "10px 40px",
                            margin: "0",
                          }}
                        >
                          <h5 style={{ padding: "0", margin: "0" }}>
                            {quotationDetails?.quote_form?.opportunity_name}
                          </h5>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="py-3">
                          <strong>Dear Customer:</strong> We are grateful for
                          your request and for trusting us to move your cargo
                          around the world .
                          <strong>Thanks for working with us!</strong>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "center",
                          background: "#002981",
                          color: "#ffff",
                          padding: "5px",
                        }}
                      >
                        <p style={{ padding: "0", margin: "0" }}>
                          Shipment Information
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {!quotationDetails?.quote_form?.product_type_name?.includes(
                  "TRUCK"
                ) && (
                  <>
                    <table width="100%" style={{ borderCollapse: "collapse" }}>
                      <tbody>
                        <tr
                          style={{
                            width: "100%",
                            borderRight: "1px solid #92a9bc",
                          }}
                        >
                          <td
                            style={{
                              width: "20%",
                              paddingLeft: "5px",
                              background: "#e8e8e8",
                              borderLeft: "1px solid #92a9bc",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <p>Service</p>
                          </td>

                          <td colSpan={2} style={{ width: "32%" }}>
                            <div>{inputFn("product_type_name")}</div>
                          </td>

                          <td
                            style={{
                              width: "16%",
                              background: "#e8e8e8",
                              paddingLeft: "5px",
                            }}
                          >
                            <p>Modal Type</p>
                          </td>
                          <td colSpan={2} style={{ width: "32px" }}>
                            <div>
                              <div>{inputFn("service_type")}</div>
                            </div>
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "100%",
                            borderRight: "1px solid #92a9bc",
                          }}
                        >
                          <td
                            style={{
                              width: "20%",
                              paddingLeft: "5px",
                              background: "#e8e8e8",
                              borderLeft: "1px solid #92a9bc",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <p>Origin Country</p>
                          </td>
                          <td colSpan={2} style={{ width: "32%" }}>
                            <div>{inputFn("origin_country")}</div>
                          </td>

                          <td
                            style={{
                              width: "16%",
                              background: "#e8e8e8",
                              paddingLeft: "5px",
                            }}
                          >
                            <p>Destination Country</p>
                          </td>
                          <td colSpan={2} style={{ width: "32px" }}>
                            <div>
                              <input
                                type="text"
                                value={getValue("destination_country")}
                                style={{
                                  border: "none",
                                  outline: "none",
                                  background: "none",
                                }}
                              ></input>
                            </div>
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "100%",
                            borderRight: "1px solid #92a9bc",
                          }}
                        >
                          <td
                            style={{
                              width: "20%",
                              paddingLeft: "5px",
                              background: "#e8e8e8",
                              borderLeft: "1px solid #92a9bc",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            {checkOriginAddressDisplay() && (
                              <p>Origin Address</p>
                            )}
                          </td>
                          <td colSpan={2} style={{ width: "32%" }}>
                            {checkOriginAddressDisplay() && (
                              <div style={{ paddingLeft: "2px" }}>
                                {getPickUpData()}
                              </div>
                            )}
                          </td>

                          <td
                            style={{
                              width: "16%",
                              background: "#e8e8e8",
                              paddingLeft: "5px",
                            }}
                          >
                            {checkDestinationAddressDisplay() && (
                              <p>Destination Address</p>
                            )}
                          </td>
                          <td colSpan={2} style={{ width: "32px" }}>
                            {checkDestinationAddressDisplay() && (
                              <div style={{ paddingLeft: "2px" }}>
                                {getDestinationData()}
                              </div>
                            )}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "100%",
                            borderRight: "1px solid #92a9bc",
                          }}
                        >
                          <td
                            style={{
                              width: "20%",
                              paddingLeft: "5px",
                              background: "#e8e8e8",
                              borderLeft: "1px solid #92a9bc",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <p>Origin Port</p>
                          </td>
                          <td colSpan={2} style={{ width: "32%" }}>
                            <div>{getValue("loading_port_name")}</div>
                          </td>

                          <td
                            style={{
                              width: "16%",
                              background: "#e8e8e8",
                              paddingLeft: "5px",
                            }}
                          >
                            <p>Destination Port</p>
                          </td>
                          <td colSpan={2} style={{ width: "32px" }}>
                            <div>{getValue("discharge_port_name")}</div>
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "100%",
                            borderRight: "1px solid #92a9bc",
                          }}
                        >
                          <td
                            style={{
                              width: "20%",
                              paddingLeft: "5px",
                              background: "#e8e8e8",
                              borderLeft: "1px solid #92a9bc",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <p>Transit Time</p>
                          </td>
                          <td colSpan={2} style={{ width: "32%" }}>
                            <div>
                              <input
                                type="text"
                                id="transit_time_in_days"
                                value={airObject?.transit_time_in_days}
                                style={{
                                  border: "none",
                                  outline: "none",
                                  background: "none",
                                }}
                                disabled={!editSave}
                                onChange={(e) => {
                                  if (
                                    quotationDetails?.quote_form
                                      ?.product_type_name != "Air"
                                  ) {
                                    return;
                                  }

                                  handleAirObject(e);
                                }}
                              ></input>
                            </div>
                          </td>

                          <td
                            style={{
                              width: "16%",
                              background: "#e8e8e8",
                              paddingLeft: "5px",
                            }}
                          >
                            {quotationDetails?.quote_form?.product_type_name ==
                              "Air" && <p>Stackable Cargo</p>}
                          </td>
                          <td colSpan={2} style={{ width: "32px" }}>
                            {quotationDetails?.quote_form?.product_type_name ==
                              "Air" && (
                              <div>
                                <input
                                  type="text"
                                  value={
                                    quotationDetails?.quote_form
                                      ?.stackable_cargo
                                  }
                                  style={{
                                    border: "none",
                                    outline: "none",
                                    background: "none",
                                  }}
                                ></input>
                              </div>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table width="100%" style={{ borderCollapse: "collapse" }}>
                      <tbody>
                        <tr
                          style={{
                            width: "100%",
                            borderRight: "1px solid #92a9bc",
                          }}
                        >
                          <td
                            colSpan={6}
                            style={{
                              textAlign: "center",
                              background: "#002981",
                              color: "#ffff",
                              padding: "5px",
                            }}
                          >
                            <p style={{ padding: "0", margin: "0" }}>
                              Cargo Information
                            </p>
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "100%",
                            borderRight: "1px solid #92a9bc",
                          }}
                        >
                          <td
                            style={{
                              width: "20%",
                              paddingLeft: "5px",
                              background: "#e8e8e8",
                              borderLeft: "1px solid #92a9bc",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <p>Commodity Description</p>
                          </td>

                          <td colSpan={2} style={{ width: "32%" }}>
                            <div>
                              <input
                                type="text"
                                id="cargo_description"
                                value={airObject?.cargo_description}
                                style={{
                                  border: "none",
                                  outline: "none",
                                  background: "none",
                                }}
                                onChange={handleAirObject}
                              ></input>
                            </div>
                          </td>

                          <td
                            style={{
                              width: "16%",
                              background: "#e8e8e8",
                              paddingLeft: "5px",
                            }}
                          >
                            {quotationDetails?.quote_form?.product_type_name ==
                            "Air" ? (
                              <p>General cargo</p>
                            ) : (
                              <p>Container Type</p>
                            )}
                          </td>
                          <td colSpan={2} style={{ width: "32px" }}>
                            <div>
                              <input
                                type="text"
                                value={
                                  quotationDetails?.quote_form
                                    ?.product_type_name != "Air"
                                    ? getValue("container_type")
                                    : getValue("cargo_type")
                                }
                                style={{
                                  border: "none",
                                  outline: "none",
                                  background: "none",
                                }}
                              ></input>
                            </div>
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "100%",
                            borderRight: "1px solid #92a9bc",
                          }}
                        >
                          <td
                            style={{
                              width: "20%",
                              paddingLeft: "5px",
                              background: "#e8e8e8",
                              borderLeft: "1px solid #92a9bc",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <p>Customs Clearance</p>
                          </td>

                          <td colSpan={2} style={{ width: "32%" }}>
                            <div>{inputFn("customer_clearance")}</div>
                          </td>

                          <td
                            style={{
                              width: "16%",
                              background: "#e8e8e8",
                              paddingLeft: "5px",
                            }}
                          >
                            {quotationDetails?.quote_form?.product_type_name ==
                            "Air" ? (
                              <p>Air Service</p>
                            ) : (
                              <p>Free Days</p>
                            )}
                          </td>
                          <td colSpan={2} style={{ width: "32px" }}>
                            {quotationDetails?.quote_form?.product_type_name ==
                            "Air" ? (
                              <div>
                                <input
                                  type="text"
                                  id="air_service"
                                  value={airObject?.air_service}
                                  style={{
                                    border: "none",
                                    outline: "none",
                                    background: "none",
                                  }}
                                  onChange={handleAirObject}
                                ></input>
                              </div>
                            ) : (
                              <p className="text-decoration-underline">
                                {selectedQuote?.free_days}
                                {/* days at destination */}
                              </p>
                            )}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "100%",
                            borderRight: "1px solid #92a9bc",
                          }}
                        >
                          <td
                            style={{
                              width: "20%",
                              paddingLeft: "5px",
                              background: "#e8e8e8",
                              borderLeft: "1px solid #92a9bc",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <p>
                              {quotationDetails?.quote_form
                                ?.product_type_name == "Air" && "Via"}
                            </p>
                          </td>

                          <td colSpan={2} style={{ width: "32%" }}>
                            <div>
                              <input
                                type="text"
                                value={airObject?.route_via}
                                id="route_via"
                                style={{
                                  border: "none",
                                  outline: "none",
                                  background: "none",
                                }}
                                onChange={handleAirObject}
                              ></input>
                            </div>
                          </td>

                          <td
                            style={{
                              width: "16%",
                              background: "#e8e8e8",
                              paddingLeft: "5px",
                            }}
                          >
                            <p>Insurance</p>
                          </td>
                          <td colSpan={2} style={{ width: "32px" }}>
                            <span>
                              <strong>{getValue("require_insurance")}</strong>
                            </span>
                            {getValue("require_insurance")?.toLowerCase() ==
                              "yes" && (
                              <span
                                style={{
                                  background: "#e8e8e8",
                                  padding: "5px 50px 5px 10px",
                                  marginLeft: "50px",
                                }}
                              >
                                {getValue("require_insurance")?.toLowerCase() ==
                                  "yes" &&
                                  getValue("incase_insurance_required")}
                              </span>
                            )}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "100%",
                            borderRight: "1px solid #92a9bc",
                          }}
                        >
                          <td
                            style={{
                              width: "20%",
                              paddingLeft: "5px",
                              background: "#e8e8e8",
                              borderLeft: "1px solid #92a9bc",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <p>Number of units</p>
                          </td>

                          <td colSpan={2} style={{ width: "32%" }}>
                            <div>
                              <input
                                type="number"
                                value={
                                  quotationDetails?.quote_form?.no_of_qty
                                    ? quotationDetails?.quote_form?.no_of_qty
                                    : ""
                                }
                                id="route_via"
                                style={{
                                  border: "none",
                                  outline: "none",
                                  background: "none",
                                }}
                                disabled={!editSave}
                                onChange={(e) => {
                                  // setno_of_qty(e.target.value);
                                  setquotationDetails({
                                    ...quotationDetails,
                                    quote_form: {
                                      ...quotationDetails?.quote_form,
                                      no_of_qty: e.target.value,
                                    },
                                  });
                                }}
                              ></input>
                            </div>
                          </td>

                          <td
                            style={{
                              width: "16%",
                              background: "#e8e8e8",
                              paddingLeft: "5px",
                            }}
                          >
                            {quotationDetails?.quote_form?.container_type ==
                            "20GP + 40GP" ? (
                              <>
                                <p className="py-2">Cost per unit (20GP)</p>
                                <p>Cost per unit (40GP)</p>
                              </>
                            ) : (
                              <p>Cost per unit</p>
                            )}
                          </td>
                          <td colSpan={2} style={{ width: "32px" }}>
                            {quotationDetails?.quote_form?.container_type ==
                            "20GP + 40GP" ? (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "5px",
                                  }}
                                  className="py-2"
                                >
                                  {quotationDetails?.quote_form?.no_of_qty &&
                                    quotationDetails?.quote_form?.no_of_qty !=
                                      "" && (
                                      <>
                                        {getDifferentCurrencies("20GP")?.map(
                                          (currency, index, arr) => (
                                            <div>
                                              <span>
                                                {(
                                                  grossTotalCurrencies[
                                                    currency
                                                  ]["20GP"] /
                                                  parseInt(
                                                    quotationDetails?.quote_form
                                                      ?.no_of_qty || 1
                                                  )
                                                )?.toFixed(2) || ""}
                                              </span>
                                              <span
                                                className="fw-bold"
                                                style={{ paddingLeft: "2px" }}
                                              >
                                                ({currency})
                                              </span>
                                              <span>
                                                {index !== arr.length - 1 &&
                                                  ","}
                                              </span>
                                            </div>
                                          )
                                        )}
                                      </>
                                    )}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "5px",
                                  }}
                                  // className="py-2"
                                >
                                  {quotationDetails?.quote_form?.no_of_qty &&
                                    quotationDetails?.quote_form?.no_of_qty !=
                                      "" && (
                                      <>
                                        {getDifferentCurrencies("40GP")?.map(
                                          (currency, index, arr) => (
                                            <div>
                                              <span>
                                                {(
                                                  grossTotalCurrencies[
                                                    currency
                                                  ]["40GP"] /
                                                  parseInt(
                                                    quotationDetails?.quote_form
                                                      ?.no_of_qty || 1
                                                  )
                                                )?.toFixed(2) || ""}
                                              </span>
                                              <span
                                                className="fw-bold"
                                                style={{ paddingLeft: "2px" }}
                                              >
                                                ({currency})
                                              </span>
                                              <span>
                                                {index !== arr.length - 1 &&
                                                  ","}
                                              </span>
                                            </div>
                                          )
                                        )}
                                      </>
                                    )}
                                </div>
                              </>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: "5px",
                                }}
                              >
                                {quotationDetails?.quote_form?.no_of_qty &&
                                  quotationDetails?.quote_form?.no_of_qty !=
                                    "" && (
                                    <>
                                      {getDifferentCurrencies()?.map(
                                        (currency, index, arr) => (
                                          <div>
                                            <span>
                                              {(
                                                grossTotalCurrencies[currency][
                                                  "other"
                                                ] /
                                                parseInt(
                                                  quotationDetails?.quote_form
                                                    ?.no_of_qty || 1
                                                )
                                              )?.toFixed(2) || ""}
                                            </span>
                                            <span
                                              className="fw-bold"
                                              style={{ paddingLeft: "2px" }}
                                            >
                                              ({currency})
                                            </span>
                                            <span>
                                              {index !== arr.length - 1 && ","}
                                            </span>
                                          </div>
                                        )
                                      )}
                                    </>
                                  )}
                              </div>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                )}
                {quotationDetails?.quote_form?.product_type_name?.includes(
                  "TRUCK"
                ) && (
                  <>
                    <table width="100%" style={{ borderCollapse: "collapse" }}>
                      <tbody>
                        <tr
                          style={{
                            width: "100%",
                            borderRight: "1px solid #92a9bc",
                          }}
                        >
                          <td
                            style={{
                              width: "20%",
                              paddingLeft: "5px",
                              background: "#e8e8e8",
                              borderLeft: "1px solid #92a9bc",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <p>Pickup Address</p>
                          </td>

                          <td colSpan={2} style={{ width: "32%" }}>
                            <div>{getPickUpData()}</div>
                          </td>

                          <td
                            style={{
                              width: "16%",
                              background: "#e8e8e8",
                              paddingLeft: "5px",
                            }}
                          >
                            <p>Destination Address</p>
                          </td>
                          <td colSpan={2} style={{ width: "32px" }}>
                            <div>
                              <div>{getDestinationData()}</div>
                            </div>
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "100%",
                            borderRight: "1px solid #92a9bc",
                          }}
                        >
                          <td
                            style={{
                              width: "20%",
                              paddingLeft: "5px",
                              background: "#e8e8e8",
                              borderLeft: "1px solid #92a9bc",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <p>Service Type</p>
                          </td>
                          <td colSpan={2} style={{ width: "32%" }}>
                            <div>{inputFn("product_type_name")}</div>
                          </td>

                          <td
                            style={{
                              width: "16%",
                              background: "#e8e8e8",
                              paddingLeft: "5px",
                            }}
                          >
                            <p>Crossing Border</p>
                          </td>
                          <td colSpan={2} style={{ width: "32px" }}>
                            <div>
                              <input
                                type="text"
                                id="border"
                                value={airObject?.border}
                                style={{
                                  border: "none",
                                  outline: "none",
                                  background: "none",
                                }}
                                onChange={handleAirObject}
                              ></input>
                            </div>
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "100%",
                            borderRight: "1px solid #92a9bc",
                          }}
                        >
                          <td
                            style={{
                              width: "20%",
                              paddingLeft: "5px",
                              background: "#e8e8e8",
                              borderLeft: "1px solid #92a9bc",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <p>Transit Time</p>
                          </td>
                          <td colSpan={2} style={{ width: "32%" }}>
                            <p>
                              <input
                                type="text"
                                id="transit_time_in_days"
                                value={airObject?.transit_time_in_days}
                                style={{
                                  border: "none",
                                  outline: "none",
                                  background: "none",
                                }}
                                onChange={(e) => {
                                  handleAirObject(e);
                                }}
                              ></input>
                            </p>
                          </td>

                          <td
                            style={{
                              width: "16%",
                              background: "#e8e8e8",
                              paddingLeft: "5px",
                            }}
                          >
                            <p>Transport</p>
                          </td>
                          <td colSpan={2} style={{ width: "32px" }}>
                            <input
                              type="text"
                              id="transporter"
                              value={airObject?.transporter}
                              style={{
                                border: "none",
                                outline: "none",
                                background: "none",
                              }}
                              onChange={handleAirObject}
                            ></input>
                          </td>
                        </tr>

                        {/* <tr
                          style={{
                            width: "100%",
                            borderRight: "1px solid #92a9bc",
                          }}
                        >
                          <td
                            style={{
                              width: "20%",
                              paddingLeft: "5px",
                              background: "#e8e8e8",
                              borderLeft: "1px solid #92a9bc",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <p>Transit Time</p>
                          </td>
                          <td colSpan={2} style={{ width: "32%" }}>
                            <div>
                              <input
                                type="text"
                                id="transit_time_in_days"
                                value={airObject?.transit_time_in_days}
                                style={{
                                  border: "none",
                                  outline: "none",
                                  background: "none",
                                }}
                                onChange={(e)=>{
                                  handleAirObject(e);
                                }}
                              ></input>
                            </div>
                          </td>

                          <td
                            style={{
                              width: "16%",
                              background: "#e8e8e8",
                              paddingLeft: "5px",
                            }}
                          >
                            <p></p>
                          </td>
                          <td colSpan={2} style={{ width: "32px" }}></td>
                        </tr> */}
                      </tbody>
                    </table>

                    <table width="100%" style={{ borderCollapse: "collapse" }}>
                      <tbody>
                        <tr
                          style={{
                            width: "100%",
                            borderRight: "1px solid #92a9bc",
                          }}
                        >
                          <td
                            colSpan={6}
                            style={{
                              textAlign: "center",
                              background: "#002981",
                              color: "#ffff",
                              padding: "5px",
                            }}
                          >
                            <p style={{ padding: "0", margin: "0" }}>
                              Cargo Information
                            </p>
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "100%",
                            borderRight: "1px solid #92a9bc",
                          }}
                        >
                          <td
                            style={{
                              width: "20%",
                              paddingLeft: "5px",
                              background: "#e8e8e8",
                              borderLeft: "1px solid #92a9bc",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <p>Commodity Description</p>
                          </td>

                          <td colSpan={2} style={{ width: "32%" }}>
                            <div>
                              <input
                                type="text"
                                id="cargo_description"
                                value={
                                  quotationDetails?.quote_form
                                    ?.cargo_description
                                }
                                style={{
                                  border: "none",
                                  outline: "none",
                                  background: "none",
                                }}
                              ></input>
                            </div>
                          </td>

                          <td
                            style={{
                              width: "16%",
                              background: "#e8e8e8",
                              paddingLeft: "5px",
                            }}
                          >
                            Dangerous Goods
                          </td>
                          <td colSpan={2} style={{ width: "32px" }}>
                            {quotationDetails?.quote_form?.dangerous_goods}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "100%",
                            borderRight: "1px solid #92a9bc",
                          }}
                        >
                          <td
                            style={{
                              width: "20%",
                              paddingLeft: "5px",
                              background: "#e8e8e8",
                              borderLeft: "1px solid #92a9bc",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <p>Stackable</p>
                          </td>

                          <td colSpan={2} style={{ width: "32%" }}>
                            <div>
                              {quotationDetails?.quote_form?.stackable_cargo}
                            </div>
                          </td>

                          <td
                            style={{
                              width: "16%",
                              background: "#e8e8e8",
                              paddingLeft: "5px",
                            }}
                          >
                            {" "}
                            <p>Insurance</p>
                          </td>
                          <td colSpan={2} style={{ width: "32px" }}>
                            {quotationDetails?.quote_form?.require_insurance}
                          </td>
                        </tr>
                        <tr
                          style={{
                            width: "100%",
                            borderRight: "1px solid #92a9bc",
                          }}
                        >
                          <td
                            style={{
                              width: "20%",
                              paddingLeft: "5px",
                              background: "#e8e8e8",
                              borderLeft: "1px solid #92a9bc",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <p>Number of units</p>
                          </td>

                          <td colSpan={2} style={{ width: "32%" }}>
                            <div>
                              <input
                                type="number"
                                value={
                                  quotationDetails?.quote_form?.no_of_qty
                                    ? quotationDetails?.quote_form?.no_of_qty
                                    : ""
                                }
                                id="route_via"
                                style={{
                                  border: "none",
                                  outline: "none",
                                  background: "none",
                                }}
                                disabled={!editSave}
                                onChange={(e) => {
                                  // setno_of_qty(e.target.value);
                                  setquotationDetails({
                                    ...quotationDetails,
                                    quote_form: {
                                      ...quotationDetails?.quote_form,
                                      no_of_qty: e.target.value,
                                    },
                                  });
                                }}
                              ></input>
                            </div>
                          </td>

                          <td
                            style={{
                              width: "16%",
                              background: "#e8e8e8",
                              paddingLeft: "5px",
                            }}
                          >
                            {quotationDetails?.quote_form?.container_type ==
                            "20GP + 40GP" ? (
                              <>
                                <p className="py-2">Cost per unit (20GP)</p>
                                <p>Cost per unit (40GP)</p>
                              </>
                            ) : (
                              <p>Cost per unit</p>
                            )}
                          </td>
                          <td colSpan={2} style={{ width: "32px" }}>
                            {quotationDetails?.quote_form?.container_type ==
                            "20GP + 40GP" ? (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "5px",
                                  }}
                                  className="py-2"
                                >
                                  {quotationDetails?.quote_form?.no_of_qty &&
                                    quotationDetails?.quote_form?.no_of_qty !=
                                      "" && (
                                      <>
                                        {getDifferentCurrencies("20GP")?.map(
                                          (currency, index, arr) => (
                                            <div>
                                              <span>
                                                {(
                                                  grossTotalCurrencies[
                                                    currency
                                                  ]["20GP"] /
                                                  parseInt(
                                                    quotationDetails?.quote_form
                                                      ?.no_of_qty || 1
                                                  )
                                                )?.toFixed(2) || ""}
                                              </span>
                                              <span
                                                className="fw-bold"
                                                style={{ paddingLeft: "2px" }}
                                              >
                                                ({currency})
                                              </span>
                                              <span>
                                                {index !== arr.length - 1 &&
                                                  ","}
                                              </span>
                                            </div>
                                          )
                                        )}
                                      </>
                                    )}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "5px",
                                  }}
                                  // className="py-2"
                                >
                                  {quotationDetails?.quote_form?.no_of_qty &&
                                    quotationDetails?.quote_form?.no_of_qty !=
                                      "" && (
                                      <>
                                        {getDifferentCurrencies("40GP")?.map(
                                          (currency, index, arr) => (
                                            <div>
                                              <span>
                                                {(
                                                  grossTotalCurrencies[
                                                    currency
                                                  ]["40GP"] /
                                                  parseInt(
                                                    quotationDetails?.quote_form
                                                      ?.no_of_qty || 1
                                                  )
                                                )?.toFixed(2) || ""}
                                              </span>
                                              <span
                                                className="fw-bold"
                                                style={{ paddingLeft: "2px" }}
                                              >
                                                ({currency})
                                              </span>
                                              <span>
                                                {index !== arr.length - 1 &&
                                                  ","}
                                              </span>
                                            </div>
                                          )
                                        )}
                                      </>
                                    )}
                                </div>
                              </>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: "5px",
                                }}
                              >
                                {quotationDetails?.quote_form?.no_of_qty &&
                                  quotationDetails?.quote_form?.no_of_qty !=
                                    "" && (
                                    <>
                                      {getDifferentCurrencies()?.map(
                                        (currency, index, arr) => (
                                          <div>
                                            <span>
                                              {(
                                                grossTotalCurrencies[currency][
                                                  "other"
                                                ] /
                                                parseInt(
                                                  quotationDetails?.quote_form
                                                    ?.no_of_qty || 1
                                                )
                                              )?.toFixed(2) || ""}
                                            </span>
                                            <span
                                              className="fw-bold"
                                              style={{ paddingLeft: "2px" }}
                                            >
                                              ({currency})
                                            </span>
                                            <span>
                                              {index !== arr.length - 1 && ","}
                                            </span>
                                          </div>
                                        )
                                      )}
                                    </>
                                  )}
                              </div>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                )}

                {/* Only in case of AIR */}
                {quotationDetails?.quote_form?.product_type_name == "Air" && (
                  <div className="d-flex ">
                    <table
                      width="80%"
                      style={{ borderCollapse: "collapse" }}
                      className="air_case mt-3 me-3"
                    >
                      <tr>
                        <th style={{ background: "#e8e8e8" }}>
                          {" "}
                          <input
                            id="no_of_pallet"
                            value={airObject?.pallet_description}
                            disabled={!editSave}
                            onChange={(e) => {
                              if (!editSave) return;
                              setairObject({
                                ...airObject,
                                pallet_description: e.target.value,
                              });
                            }}
                            style={{
                              border: "none",
                              outline: "none",
                              background: "none",
                              textAlign: "center",
                              fontWeight: "bold",
                            }}
                          ></input>
                        </th>
                        <th style={{ background: "#e8e8e8" }}>Length CM</th>
                        <th style={{ background: "#e8e8e8" }}>Width CM</th>
                        <th style={{ background: "#e8e8e8" }}>Height CM</th>
                        <th style={{ background: "#e8e8e8" }}>
                          Weight Per Pallet in Kg
                        </th>
                        <th style={{ background: "#e8e8e8" }}>Gross Weight</th>
                        <th style={{ background: "#e8e8e8" }}>Volume Weight</th>
                      </tr>
                      <tr>
                        <td>
                          <input
                            id="no_of_pallet"
                            value={airObject?.no_of_pallet}
                            disabled={!editSave}
                            onChange={(e) => {
                              // handleAirObject(e);
                              if (!editSave) return;
                              setairObject({
                                ...airObject,
                                no_of_pallet: e.target.value,
                                gross_weight_tons:
                                  (parseFloat(e.target.value) || 0) *
                                    (parseFloat(airObject?.weight_per_pallet) ||
                                      0) || "",
                              });
                            }}
                            style={{
                              border: "none",
                              outline: "none",
                              background: "none",
                              textAlign: "center",
                            }}
                          ></input>
                        </td>
                        <td>{getCargoMesurements("length")}</td>
                        <td>{getCargoMesurements("width")}</td>
                        <td>{getCargoMesurements("height")}</td>
                        <td>
                          <input
                            id="weight_per_pallet"
                            disabled
                            value={airObject?.weight_per_pallet}
                            onChange={handleAirObject}
                            style={{
                              border: "none",
                              outline: "none",
                              background: "none",
                              textAlign: "center",
                            }}
                          ></input>
                        </td>
                        <td>
                          <input
                            id="gross_weight_tons"
                            value={airObject?.gross_weight_tons}
                            onChange={handleAirObject}
                            style={{
                              border: "none",
                              outline: "none",
                              background: "none",
                              textAlign: "center",
                            }}
                          ></input>
                        </td>
                        <td>
                          <input
                            id="volume_weight"
                            value={airObject?.volume_weight}
                            onChange={handleAirObject}
                            style={{
                              border: "none",
                              outline: "none",
                              background: "none",
                              textAlign: "center",
                            }}
                          ></input>
                        </td>
                        {/* <span> +1000 KG</span> */}
                      </tr>
                    </table>
                    <table
                      style={{ borderCollapse: "collapse" }}
                      className="air_case mt-3"
                    >
                      <tr>
                        {" "}
                        <th style={{ background: "#e8e8e8" }}>Basis Quote</th>
                      </tr>
                      <tr>
                        <td style={{ background: "#002981", color: "#ffff" }}>
                          {Math.max(
                            parseFloat(airObject?.volume_weight) || 0,
                            parseFloat(airObject?.gross_weight_tons) || 0
                          ) || ""}
                        </td>
                      </tr>
                    </table>
                  </div>
                )}

                {/* Part Two */}
                {/* In case of air */}
                {quotationDetails?.quote_form?.product_type_name == "Air" && (
                  <Row>
                    <Col md={8}>
                      <div>
                        <table style={{ borderCollapse: "collapse" }}>
                          <tbody>
                            <tr>
                              <td colSpan={6} className="">
                                <p>&nbsp;</p>
                              </td>
                            </tr>
                            {rateTypeCodes?.map(
                              (rate_type_code, rate_type_code_index) => (
                                <>
                                  <tr key={rate_type_code}>
                                    <td
                                      style={{
                                        background: "#002981",
                                        color: "#ffff",
                                        borderBottom: "4px solid #e9b217",
                                        padding: "5px 0",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        alignContent: "center",
                                      }}
                                    >
                                      <div></div>
                                      <div>
                                        {rate_type_code?.toUpperCase()} RATES
                                      </div>
                                      <div>
                                        {editSave && (
                                          <div className="d-flex gap-2">
                                            <div>
                                              {/* <Select
                                              value={getBlockCurrency(
                                                rate_type_code
                                              )}
                                              onChange={(e) => {
                                                // console.log(
                                                //   e,
                                                //   "selected fropdown"
                                                // );
                                                setupdateCurrency({
                                                  rate_type_code:
                                                    rate_type_code,
                                                  currency_to: e,
                                                  currency_from:
                                                    getBlockCurrency(
                                                      rate_type_code
                                                    ),
                                                });
                                                setshowCurrency(true);
                                              }}
                                              style={{ zIndex: "10 !important" }}
                                              options={currencyOptions}
                                            /> */}
                                              {/* <select>
                                              <option>USD</option>
                                              <option>INR</option>
                                            </select> */}
                                              <Form.Select
                                                value={getBlockCurrency(
                                                  rate_type_code
                                                )}
                                                aria-label="Default select example"
                                                style={{ width: "150px" }}
                                                onChange={(e) => {
                                                  console.log(
                                                    e.target.value,
                                                    "e.targt.value"
                                                  );
                                                  setupdateCurrency({
                                                    rate_type_code:
                                                      rate_type_code,
                                                    currency_to: e.target.value,
                                                    currency_from:
                                                      getBlockCurrency(
                                                        rate_type_code
                                                      ),
                                                  });
                                                  setshowCurrency(true);
                                                }}
                                              >
                                                {currencyOptions.map((item) => (
                                                  <option value={item?.value}>
                                                    {item?.label}
                                                  </option>
                                                ))}
                                              </Form.Select>
                                            </div>
                                            <div
                                              style={{
                                                cursor: "pointer",
                                                paddingRight: "5px",
                                              }}
                                              onClick={() => {
                                                setselectedLabel({
                                                  name: `ADD ${rate_type_code} RATES`,
                                                  freightify_id: trade,
                                                  quote_id: quote_id,
                                                  description: "",
                                                  rate_type_code:
                                                    rate_type_code,
                                                  rate_usd: "",
                                                  amount_usd: "",
                                                  rate_currency:
                                                    getBlockCurrency(
                                                      rate_type_code
                                                    ),
                                                  container_type:
                                                    getValue(
                                                      "container_type"
                                                    ) == "20GP + 40GP"
                                                      ? ""
                                                      : getValue(
                                                          "container_type"
                                                        ),
                                                  actual_amount_usd: "",
                                                  actual_rate_usd: "",
                                                  rate_description: "",
                                                });
                                                handleShowCustomer();
                                              }}
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-plus-circle-fill"
                                                viewBox="0 0 16 16"
                                              >
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                              </svg>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    {rate_type_code_index == 0 && (
                                      <div className="w-100">
                                        <div
                                          style={{
                                            display: "inline-block",
                                            width: "29%",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          CONCEPT
                                        </div>

                                        <div
                                          className="table-div"
                                          style={{
                                            display: "inline-block",
                                            fontWeight: "bold",
                                            width: "13%",
                                          }}
                                        >
                                          CURRENCY
                                        </div>

                                        <div
                                          className="table-div"
                                          style={{
                                            display: !downloadButton
                                              ? "inline-block"
                                              : "none",
                                            fontWeight: "bold",
                                            width: "13%",
                                          }}
                                        >
                                          RATE PER KG
                                        </div>

                                        <div
                                          className="table-div"
                                          style={{
                                            display: "inline-block",
                                            fontWeight: "bold",
                                            width: "13%",
                                          }}
                                        >
                                          {!downloadButton
                                            ? " MARKUP RATE PER KG"
                                            : "RATE PER KG"}
                                        </div>

                                        <div
                                          className="table-div"
                                          style={{
                                            display: !downloadButton
                                              ? "inline-block"
                                              : "none",
                                            fontWeight: "bold",
                                            width: "13%",
                                          }}
                                        >
                                          AMOUNT
                                        </div>
                                        <div
                                          className="table-div"
                                          style={{
                                            display: "inline-block",
                                            fontWeight: "bold",
                                            width: "13%",
                                          }}
                                        >
                                          {!downloadButton
                                            ? "MARKUP AMOUNT"
                                            : "AMOUNT"}
                                        </div>
                                      </div>
                                    )}

                                    {/* Freight */}
                                    {rate_type_code?.toUpperCase() ==
                                      "FREIGHT" && (
                                      <>
                                        {selectedQuotePriceDetails
                                          ?.filter((quote) => {
                                            return quote?.rate_type_code?.includes(
                                              rate_type_code
                                            );
                                          })
                                          ?.map((quote, quote_index) => (
                                            <div
                                              className="w-100"
                                              key={quote?.description}
                                            >
                                              <div
                                                style={{
                                                  display: "inline-block",
                                                  width: "29%",
                                                }}
                                              >
                                                {quote?.description}
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  width: "13%",
                                                }}
                                              >
                                                <input
                                                  type="text"
                                                  value={
                                                    quote?.rate_currency
                                                      ? quote?.rate_currency
                                                      : ""
                                                  }
                                                  style={{
                                                    border: "none",
                                                    outline: "none",
                                                    background: "none",
                                                  }}
                                                ></input>
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: !downloadButton
                                                    ? "inline-block"
                                                    : "none",
                                                  width: "13%",
                                                }}
                                              >
                                                {quote?.actual_rate_usd
                                                  ? quote?.actual_rate_usd
                                                  : ""}
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  width: "13%",
                                                }}
                                              >
                                                {quote?.rate_usd
                                                  ? quote?.rate_usd
                                                  : ""}
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: !downloadButton
                                                    ? "inline-block"
                                                    : "none",
                                                  width: "13%",
                                                }}
                                              >
                                                {quote?.actual_amount_usd
                                                  ? quote?.actual_amount_usd
                                                  : ""}
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  width: "13%",
                                                }}
                                              >
                                                {quote?.amount_usd
                                                  ? quote?.amount_usd
                                                  : ""}
                                              </div>
                                              {editSave && (
                                                <div
                                                  style={{
                                                    display: "inline-block",
                                                    marginLeft: "-10px",
                                                    height: "18px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    setselectedLabel({
                                                      name: `EDIT ${rate_type_code} RATES`,
                                                      freightify_id:
                                                        selectedQuote?.freightify_id,
                                                      description:
                                                        quote?.description,
                                                      rate_type_code:
                                                        rate_type_code,
                                                      rate_usd: quote?.rate_usd,
                                                      amount_usd:
                                                        quote?.amount_usd,
                                                      rate_currency:
                                                        quote?.rate_currency,
                                                      container_type:
                                                        quote?.container_type,
                                                      id: quote?.id,
                                                      actual_amount_usd:
                                                        quote?.actual_amount_usd,
                                                      actual_rate_usd:
                                                        quote?.actual_rate_usd,
                                                      rate_description:
                                                        quote?.rate_description,
                                                      rate_currency:
                                                        getBlockCurrency(
                                                          rate_type_code
                                                        ),
                                                    });
                                                    handleShowCustomer();
                                                  }}
                                                >
                                                  <svg
                                                    className="icon"
                                                    style={{
                                                      height: "18px",
                                                    }}
                                                  >
                                                    <use href="#icon_edit"></use>
                                                  </svg>
                                                </div>
                                              )}
                                              {editSave && (
                                                <div
                                                  style={{
                                                    display: "inline-block",
                                                    height: "18px",
                                                    cursor: "pointer",
                                                    marginLeft: "20px",
                                                  }}
                                                  onClick={() => {
                                                    setselectedDocId(quote?.id);
                                                    handleShowVendorNew();
                                                  }}
                                                >
                                                  <svg
                                                    className="icon"
                                                    style={{
                                                      height: "18px",
                                                    }}
                                                  >
                                                    <use href="#icon_delete"></use>
                                                  </svg>
                                                </div>
                                              )}
                                            </div>
                                          ))}
                                      </>
                                    )}
                                    {/* origin */}
                                    {rate_type_code?.toUpperCase() ==
                                      "ORIGIN" && (
                                      <>
                                        {selectedQuotePriceDetails
                                          ?.filter((quote) => {
                                            return quote?.rate_type_code?.includes(
                                              rate_type_code
                                            );
                                          })
                                          ?.map((quote, quote_index) => (
                                            <div
                                              className="w-100"
                                              key={quote?.description}
                                            >
                                              <div
                                                style={{
                                                  display: "inline-block",
                                                  width: "20%",
                                                }}
                                              >
                                                {quote?.description}
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  width: "10.8%",
                                                }}
                                              >
                                                <input
                                                  type="text"
                                                  value={
                                                    quote?.rate_currency
                                                      ? quote?.rate_currency
                                                      : ""
                                                  }
                                                  style={{
                                                    border: "none",
                                                    outline: "none",
                                                    background: "none",
                                                  }}
                                                ></input>
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: !downloadButton
                                                    ? "inline-block"
                                                    : "none",
                                                  width: "10.8%",
                                                }}
                                              >
                                                {quote?.actual_rate_usd
                                                  ? quote?.actual_rate_usd
                                                  : ""}
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  width: "10.8%",
                                                }}
                                              >
                                                {quote?.rate_usd
                                                  ? quote?.rate_usd
                                                  : ""}
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  width: "20%",
                                                }}
                                              >
                                                {quote?.rate_description}
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: !downloadButton
                                                    ? "inline-block"
                                                    : "none",
                                                  width: "10.8%",
                                                }}
                                              >
                                                {quote?.actual_amount_usd
                                                  ? quote?.actual_amount_usd
                                                  : ""}
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  width: "10.8%",
                                                }}
                                              >
                                                {quote?.amount_usd
                                                  ? quote?.amount_usd
                                                  : ""}
                                              </div>
                                              {editSave && (
                                                <div
                                                  style={{
                                                    display: "inline-block",
                                                    marginLeft: "-10px",
                                                    height: "18px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    setselectedLabel({
                                                      name: `EDIT ${rate_type_code} RATES`,
                                                      freightify_id:
                                                        selectedQuote?.freightify_id,
                                                      description:
                                                        quote?.description,
                                                      rate_type_code:
                                                        rate_type_code,
                                                      rate_usd: quote?.rate_usd,
                                                      amount_usd:
                                                        quote?.amount_usd,
                                                      rate_currency:
                                                        quote?.rate_currency,
                                                      container_type:
                                                        quote?.container_type,
                                                      id: quote?.id,
                                                      actual_amount_usd:
                                                        quote?.actual_amount_usd,
                                                      actual_rate_usd:
                                                        quote?.actual_rate_usd,
                                                      rate_description:
                                                        quote?.rate_description,
                                                      rate_currency:
                                                        getBlockCurrency(
                                                          rate_type_code
                                                        ),
                                                    });
                                                    handleShowCustomer();
                                                  }}
                                                >
                                                  <svg
                                                    className="icon"
                                                    style={{
                                                      height: "18px",
                                                    }}
                                                  >
                                                    <use href="#icon_edit"></use>
                                                  </svg>
                                                </div>
                                              )}
                                              {editSave && (
                                                <div
                                                  style={{
                                                    display: "inline-block",
                                                    height: "18px",
                                                    cursor: "pointer",
                                                    marginLeft: "20px",
                                                  }}
                                                  onClick={() => {
                                                    setselectedDocId(quote?.id);
                                                    handleShowVendorNew();
                                                  }}
                                                >
                                                  <svg
                                                    className="icon"
                                                    style={{
                                                      height: "18px",
                                                    }}
                                                  >
                                                    <use href="#icon_delete"></use>
                                                  </svg>
                                                </div>
                                              )}
                                            </div>
                                          ))}
                                      </>
                                    )}
                                    {/* destination */}
                                    {rate_type_code?.toUpperCase() ==
                                      "DESTINATION" && (
                                      <>
                                        {selectedQuotePriceDetails
                                          ?.filter((quote) => {
                                            return quote?.rate_type_code?.includes(
                                              rate_type_code
                                            );
                                          })
                                          ?.map((quote, quote_index) => (
                                            <div
                                              className="w-100"
                                              key={quote?.description}
                                            >
                                              <div
                                                style={{
                                                  display: "inline-block",
                                                  width: "20%",
                                                }}
                                              >
                                                {quote?.description}
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  width: "10.8%",
                                                }}
                                              >
                                                <input
                                                  type="text"
                                                  value={
                                                    quote?.rate_currency
                                                      ? quote?.rate_currency
                                                      : ""
                                                  }
                                                  style={{
                                                    border: "none",
                                                    outline: "none",
                                                    background: "none",
                                                  }}
                                                ></input>
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: !downloadButton
                                                    ? "inline-block"
                                                    : "none",
                                                  width: "10.8%",
                                                }}
                                              >
                                                {quote?.actual_amount_usd
                                                  ? quote?.actual_amount_usd
                                                  : ""}
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  width: "10.8%",
                                                }}
                                              >
                                                {quote?.amount_usd
                                                  ? quote?.amount_usd
                                                  : ""}
                                              </div>
                                              {editSave && (
                                                <div
                                                  style={{
                                                    display: "inline-block",
                                                    marginLeft: "-10px",
                                                    height: "18px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    setselectedLabel({
                                                      name: `EDIT ${rate_type_code} RATES`,
                                                      freightify_id:
                                                        selectedQuote?.freightify_id,
                                                      description:
                                                        quote?.description,
                                                      rate_type_code:
                                                        rate_type_code,
                                                      rate_usd: quote?.rate_usd,
                                                      amount_usd:
                                                        quote?.amount_usd,
                                                      rate_currency:
                                                        quote?.rate_currency,
                                                      container_type:
                                                        quote?.container_type,
                                                      id: quote?.id,
                                                      actual_amount_usd:
                                                        quote?.actual_amount_usd,
                                                      actual_rate_usd:
                                                        quote?.actual_rate_usd,
                                                      rate_description:
                                                        quote?.rate_description,
                                                      rate_currency:
                                                        getBlockCurrency(
                                                          rate_type_code
                                                        ),
                                                    });
                                                    handleShowCustomer();
                                                  }}
                                                >
                                                  <svg
                                                    className="icon"
                                                    style={{
                                                      height: "18px",
                                                    }}
                                                  >
                                                    <use href="#icon_edit"></use>
                                                  </svg>
                                                </div>
                                              )}
                                              {editSave && (
                                                <div
                                                  style={{
                                                    display: "inline-block",
                                                    height: "18px",
                                                    cursor: "pointer",
                                                    marginLeft: "20px",
                                                  }}
                                                  onClick={() => {
                                                    setselectedDocId(quote?.id);
                                                    handleShowVendorNew();
                                                  }}
                                                >
                                                  <svg
                                                    className="icon"
                                                    style={{
                                                      height: "18px",
                                                    }}
                                                  >
                                                    <use href="#icon_delete"></use>
                                                  </svg>
                                                </div>
                                              )}
                                            </div>
                                          ))}
                                      </>
                                    )}
                                    {rate_type_code?.toUpperCase() ==
                                      "ADDON" && (
                                      <>
                                        {selectedQuotePriceDetails
                                          ?.filter((quote) => {
                                            return quote?.rate_type_code?.includes(
                                              rate_type_code
                                            );
                                          })
                                          ?.map((quote, quote_index) => (
                                            <div
                                              className="w-100"
                                              key={quote?.description}
                                            >
                                              <div
                                                style={{
                                                  display: "inline-block",
                                                  width: "20%",
                                                }}
                                              >
                                                {quote?.description}
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  width: "10.8%",
                                                }}
                                              >
                                                <input
                                                  type="text"
                                                  value={
                                                    quote?.rate_currency
                                                      ? quote?.rate_currency
                                                      : ""
                                                  }
                                                  style={{
                                                    border: "none",
                                                    outline: "none",
                                                    background: "none",
                                                  }}
                                                ></input>
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: !downloadButton
                                                    ? "inline-block"
                                                    : "none",
                                                  width: "10.8%",
                                                }}
                                              >
                                                {quote?.actual_amount_usd
                                                  ? quote?.actual_amount_usd
                                                  : ""}
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: !downloadButton
                                                    ? "inline-block"
                                                    : "none",
                                                  width: "10.8%",
                                                }}
                                              >
                                                {quote?.amount_usd
                                                  ? quote?.amount_usd
                                                  : ""}
                                              </div>
                                              {editSave && (
                                                <div
                                                  style={{
                                                    display: "inline-block",
                                                    marginLeft: "-10px",
                                                    height: "18px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    setselectedLabel({
                                                      name: `EDIT ${rate_type_code} RATES`,
                                                      freightify_id:
                                                        selectedQuote?.freightify_id,
                                                      description:
                                                        quote?.description,
                                                      rate_type_code:
                                                        rate_type_code,
                                                      rate_usd: quote?.rate_usd,
                                                      amount_usd:
                                                        quote?.amount_usd,
                                                      container_type:
                                                        quote?.container_type,
                                                      id: quote?.id,
                                                      actual_amount_usd:
                                                        quote?.actual_amount_usd,
                                                      actual_rate_usd:
                                                        quote?.actual_rate_usd,
                                                      rate_description:
                                                        quote?.rate_description,
                                                      rate_currency:
                                                        getBlockCurrency(
                                                          rate_type_code
                                                        ),
                                                    });
                                                    handleShowCustomer();
                                                  }}
                                                >
                                                  <svg
                                                    className="icon"
                                                    style={{
                                                      height: "18px",
                                                    }}
                                                  >
                                                    <use href="#icon_edit"></use>
                                                  </svg>
                                                </div>
                                              )}
                                              {editSave && (
                                                <div
                                                  style={{
                                                    display: "inline-block",
                                                    height: "18px",
                                                    cursor: "pointer",
                                                    marginLeft: "20px",
                                                  }}
                                                  onClick={() => {
                                                    setselectedDocId(quote?.id);
                                                    handleShowVendorNew();
                                                  }}
                                                >
                                                  <svg
                                                    className="icon"
                                                    style={{
                                                      height: "18px",
                                                    }}
                                                  >
                                                    <use href="#icon_delete"></use>
                                                  </svg>
                                                </div>
                                              )}
                                            </div>
                                          ))}
                                      </>
                                    )}

                                    {/* Frieght */}

                                    {rate_type_code?.toUpperCase() ==
                                      "FREIGHT" && (
                                      <div className="w-100 bg-gray">
                                        <div
                                          style={{
                                            display: "inline-block",
                                            width: "29%",
                                          }}
                                        >
                                          <p className="p-0 m-0">
                                            Total{" "}
                                            {capitalizeFirstLetter(
                                              rate_type_code
                                            )}{" "}
                                            Rates
                                          </p>
                                        </div>
                                        <div
                                          className="table-div"
                                          style={{
                                            display: "inline-block",
                                            width: "13%",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={getBlockCurrency(
                                              rate_type_code
                                            )}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>
                                        <div
                                          className="table-div"
                                          style={{
                                            display: !downloadButton
                                              ? "inline-block"
                                              : "none",
                                            width: "13%",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={getAirRates(
                                              rate_type_code,
                                              "actual_rate_usd"
                                            )?.toFixed(2)}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>

                                        <div
                                          className="table-div"
                                          style={{
                                            width: "13%",
                                            display: "inline-block",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={getAirRates(
                                              rate_type_code,
                                              "rate_usd"
                                            )?.toFixed(2)}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>
                                        <div
                                          className="table-div"
                                          style={{
                                            width: "13%",
                                            display: !downloadButton
                                              ? "inline-block"
                                              : "none",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={getAirRates(
                                              rate_type_code,
                                              "actual_amount_usd"
                                            )?.toFixed(2)}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>
                                        <div
                                          className="table-div"
                                          style={{
                                            width: "13%",
                                            display: "inline-block",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={getAirRates(
                                              rate_type_code,
                                              "amount_usd"
                                            )?.toFixed(2)}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>
                                      </div>
                                    )}
                                    {/* Origin */}
                                    {rate_type_code?.toUpperCase() ==
                                      "ORIGIN" && (
                                      <div className="w-100 bg-gray">
                                        <div
                                          style={{
                                            display: "inline-block",
                                            width: "20%",
                                          }}
                                        >
                                          <p className="p-0 m-0">
                                            Total{" "}
                                            {capitalizeFirstLetter(
                                              rate_type_code
                                            )}{" "}
                                            Rates
                                          </p>
                                        </div>
                                        <div
                                          className="table-div"
                                          style={{
                                            display: "inline-block",
                                            width: "10.8%",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={getBlockCurrency(
                                              rate_type_code
                                            )}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>
                                        <div
                                          className="table-div"
                                          style={{
                                            display: !downloadButton
                                              ? "inline-block"
                                              : "none",
                                            width: "10.8%",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={getAirRates(
                                              rate_type_code,
                                              "actual_rate_usd"
                                            )?.toFixed(2)}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>

                                        <div
                                          className="table-div"
                                          style={{
                                            width: "10.8%",
                                            display: "inline-block",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={getAirRates(
                                              rate_type_code,
                                              "rate_usd"
                                            )?.toFixed(2)}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>
                                        <div
                                          className="table-div"
                                          style={{
                                            width: "20%",
                                            display: "inline-block",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={""}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>
                                        <div
                                          className="table-div"
                                          style={{
                                            width: "10.8%",
                                            display: !downloadButton
                                              ? "inline-block"
                                              : "none",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={getAirRates(
                                              rate_type_code,
                                              "actual_amount_usd"
                                            )?.toFixed(2)}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>
                                        <div
                                          className="table-div"
                                          style={{
                                            width: "10.8%",
                                            display: "inline-block",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={getAirRates(
                                              rate_type_code,
                                              "amount_usd"
                                            )?.toFixed(2)}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>
                                      </div>
                                    )}
                                    {/* Destination */}
                                    {rate_type_code?.toUpperCase() ==
                                      "DESTINATION" && (
                                      <div className="w-100 bg-gray">
                                        <div
                                          style={{
                                            display: "inline-block",
                                            width: "20%",
                                          }}
                                        >
                                          <p className="p-0 m-0">
                                            Total{" "}
                                            {capitalizeFirstLetter(
                                              rate_type_code
                                            )}{" "}
                                            Rates
                                          </p>
                                        </div>
                                        <div
                                          className="table-div"
                                          style={{
                                            display: "inline-block",
                                            width: "10.8%",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={getBlockCurrency(
                                              rate_type_code
                                            )}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>
                                        <div
                                          className="table-div"
                                          style={{
                                            display: !downloadButton
                                              ? "inline-block"
                                              : "none",
                                            width: "10.8%",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={getAirRates(
                                              rate_type_code,
                                              "actual_amount_usd"
                                            )?.toFixed(2)}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>

                                        <div
                                          className="table-div"
                                          style={{
                                            width: "10.8%",
                                            display: "inline-block",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={getAirRates(
                                              rate_type_code,
                                              "amount_usd"
                                            )?.toFixed(2)}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>
                                      </div>
                                    )}
                                    {/* Destination */}
                                    {rate_type_code?.toUpperCase() ==
                                      "ADDON" && (
                                      <div className="w-100 bg-gray">
                                        <div
                                          style={{
                                            display: "inline-block",
                                            width: "20%",
                                          }}
                                        >
                                          <p className="p-0 m-0">
                                            Total{" "}
                                            {capitalizeFirstLetter(
                                              rate_type_code
                                            )}{" "}
                                            Rates
                                          </p>
                                        </div>
                                        <div
                                          className="table-div"
                                          style={{
                                            display: "inline-block",
                                            width: "10.8%",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value="USD"
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>
                                        <div
                                          className="table-div"
                                          style={{
                                            display: !downloadButton
                                              ? "inline-block"
                                              : "none",
                                            width: "10.8%",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={getAirRates(
                                              rate_type_code,
                                              "actual_amount_usd"
                                            )?.toFixed(2)}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>

                                        <div
                                          className="table-div"
                                          style={{
                                            width: "10.8%",
                                            display: "inline-block",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={getAirRates(
                                              rate_type_code,
                                              "amount_usd"
                                            )?.toFixed(2)}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>
                                      </div>
                                    )}
                                  </tr>
                                </>
                              )
                            )}

                            {/* total */}
                            <tr className="w-100">
                              <td
                                style={{
                                  textAlign: "center",
                                  background: "#002981",
                                  color: "#ffff",
                                  borderTop: "4px solid #e9b217",
                                  padding: "5px 0",
                                }}
                              >
                                TOTAL
                              </td>
                            </tr>
                            {calculatingTotal ? (
                              <>
                                <div className="text-center">
                                  <Loader />
                                </div>
                              </>
                            ) : (
                              <>
                                <tr className="w-100">
                                  <td>
                                    <div className="w-100">
                                      <div
                                        className="bg-gray"
                                        style={{
                                          display: "inline-block",
                                          width: "31%",
                                        }}
                                      >
                                        {rateTypeCodes?.map(
                                          (
                                            rate_type_code,
                                            rate_type_code_index
                                          ) => (
                                            <div key={rate_type_code}>
                                              <p className="p-0 m-0">
                                                {capitalizeFirstLetter(
                                                  rate_type_code
                                                )}
                                                Rates
                                              </p>
                                            </div>
                                          )
                                        )}
                                      </div>

                                      <div
                                        className="table-div"
                                        style={{
                                          display: !downloadButton
                                            ? "inline-block"
                                            : "none",
                                          width: "10.8%",
                                        }}
                                      >
                                        {rateTypeCodes?.map(
                                          (
                                            rate_type_code,
                                            rate_type_code_index
                                          ) => (
                                            <div key={rate_type_code}>
                                              <input
                                                type="text"
                                                // value={finalRates(rate_type_code)}
                                                value={getIndividualRatesOriginal(
                                                  rate_type_code,
                                                  "other_original"
                                                )}
                                                style={{
                                                  border: "none",
                                                  outline: "none",
                                                  background: "none",
                                                }}
                                              ></input>
                                            </div>
                                          )
                                        )}
                                      </div>
                                      <div
                                        className="table-div"
                                        style={{
                                          display: "inline-block",
                                          width: "15%",
                                        }}
                                      >
                                        {rateTypeCodes?.map(
                                          (
                                            rate_type_code,
                                            rate_type_code_index
                                          ) => (
                                            <div key={rate_type_code}>
                                              <input
                                                type="text"
                                                // value={finalRatesMarkUp(
                                                //   rate_type_code
                                                // )}
                                                value={getIndividualRates(
                                                  rate_type_code,
                                                  "other"
                                                )}
                                                style={{
                                                  border: "none",
                                                  outline: "none",
                                                  background: "none",
                                                }}
                                              ></input>
                                            </div>
                                          )
                                        )}
                                      </div>
                                      <div
                                        className="table-div"
                                        style={{
                                          display: "inline-block",
                                          width: "13%",
                                        }}
                                      >
                                        {rateTypeCodes?.map(
                                          (
                                            rate_type_code,
                                            rate_type_code_index
                                          ) => (
                                            <div key={rate_type_code}>
                                              <input
                                                type="text"
                                                value="USD"
                                                style={{
                                                  border: "none",
                                                  outline: "none",
                                                  background: "none",
                                                }}
                                              ></input>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                {getDifferentCurrencies()?.map(
                                  (currency, index, arr) => (
                                    <tr className="w-100">
                                      <td
                                        style={{
                                          background: "#002981",
                                          color: "#ffff",
                                          borderBottom: "4px solid #e9b217",
                                          padding: "5px 0",
                                        }}
                                      >
                                        <div className="w-100">
                                          <div
                                            style={{
                                              display: "inline-block",
                                              width: "31%",
                                            }}
                                          >
                                            <p>GROSS TOTAL {currency}</p>
                                          </div>

                                          <div
                                            className="table-div"
                                            style={{
                                              display: !downloadButton
                                                ? "inline-block"
                                                : "none",
                                              width: "10.8%",
                                            }}
                                          >
                                            {/* <p>{getFinalRatesAir()?.toFixed(2)}</p> */}
                                            <p>
                                              {grossTotalDisplayOriginal(
                                                "other_original",
                                                currency
                                              )}
                                            </p>
                                          </div>
                                          <div
                                            className="table-div"
                                            style={{
                                              display: "inline-block",
                                              width: "15%",
                                            }}
                                          >
                                            {/* <p>
                                                {getFinalRatesAirMarkUp()?.toFixed(2)}
                                              </p> */}

                                            <p>
                                              {grossTotalDisplay(
                                                "other",
                                                currency
                                              )}
                                            </p>
                                          </div>
                                          <div
                                            className="table-div"
                                            style={{
                                              width: "13%",
                                              display: "inline-block",
                                            }}
                                          >
                                            <p>{currency}</p>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </Col>

                    <Col md={4} className="mt-4">
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          marginTop: "2px",
                        }}
                      >
                        {editSave && (
                          <button
                            type="button"
                            className="px-4"
                            style={{
                              background: "#0e2074",
                              border: "1px solid #0e2074",
                              color: "#ffffff",
                              borderRadius: "4px",
                              width: "max-content",
                            }}
                            onClick={() => {
                              setselectedLabel({
                                name: `ADDON`,
                                freightify_id: selectedQuote?.freightify_id,
                                quote_id: quote_id,
                                description: "",
                                rate_type_code: "ADDON",
                                rate_usd: "",
                                amount_usd: "",
                                rate_currency: checkInAddOn(),
                                container_type: "",
                                actual_amount_usd: "",
                                actual_rate_usd: "",
                                rate_description: "",
                              });
                              if (
                                quotationDetails?.quote_form?.product_type_name?.includes(
                                  "TRUCK"
                                )
                              ) {
                                handleShowCustomer2();
                              } else {
                                handleShowCustomer();
                              }
                            }}
                          >
                            Addon
                          </button>
                        )}
                        {!selectedQuote?.comment && editSave && (
                          <button
                            type="button"
                            className="px-4"
                            style={{
                              background: "#0e2074",
                              border: "1px solid #0e2074",
                              color: "#ffffff",
                              borderRadius: "4px",
                              width: "max-content",
                            }}
                            onClick={() => {
                              setselectedLabel({
                                freightify_id: selectedQuote?.freightify_id,
                                comment: "",
                              });
                              setCommentData("");
                              handleShowCustomerComments();
                            }}
                          >
                            Add Comments
                          </button>
                        )}
                      </div>

                      {selectedQuote?.comment && (
                        <div>
                          <div
                            style={{
                              textAlign: "center",
                              background: "#002981",
                              color: "#ffff",
                              borderBottom: "4px solid #e9b217",
                              padding: "5px 4px",
                              width: "max-content",
                              marginTop: editSave && "10px",
                            }}
                          >
                            COMMENTS
                          </div>
                          <div
                            style={{
                              marginTop: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  minWidth: "textContent",
                                  maxWidth: "90%",
                                  wordWrap: "break-word",
                                }}
                              >
                                <button
                                  type="button"
                                  onClick={() => {
                                    setselectedLabel({
                                      freightify_id:
                                        selectedQuote?.freightify_id,
                                      comment: selectedQuote?.comment,
                                    });
                                    setCommentData(selectedQuote?.comment);
                                    handleShowCustomerComments();
                                  }}
                                  className="tracking-btn d-flex justify-content-center px-2 w-100"
                                >
                                  <span>View Comments</span>
                                </button>
                              </div>
                              {editSave && (
                                <span>
                                  <div
                                    style={{
                                      display: "inline-block",
                                      width: "18px",
                                      height: "18px",
                                      cursor: "pointer",
                                      marginLeft: "5px",
                                    }}
                                    onClick={() => {
                                      setselectedLabel({
                                        freightify_id:
                                          selectedQuote?.freightify_id,
                                        comment: selectedQuote?.comment,
                                      });
                                      setCommentData(selectedQuote?.comment);
                                      handleShowCustomerComments();
                                    }}
                                  >
                                    <svg
                                      className="icon"
                                      style={{ width: "18px", height: "18px" }}
                                    >
                                      <use href="#icon_edit"></use>
                                    </svg>
                                  </div>
                                  <div
                                    style={{
                                      display: "inline-block",
                                      cursor: "pointer",
                                      marginLeft: "5px",
                                    }}
                                    onClick={() => {
                                      handleShowVendorNewComment();
                                    }}
                                  >
                                    <svg
                                      className="icon"
                                      style={{ width: "18px", height: "18px" }}
                                    >
                                      <use href="#icon_delete"></use>
                                    </svg>
                                  </div>
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </Col>
                    {getValue("container_type") == "20GP + 40GP" && (
                      <Col md={6} className="mt-4 d-flex flex-column">
                        <div className="mt-auto ms-auto text-right">
                          <img src={logo} alt="Logo" />
                        </div>
                      </Col>
                    )}
                  </Row>
                )}

                {/* In case of truck */}
                {quotationDetails?.quote_form?.product_type_name?.includes(
                  "TRUCK"
                ) && (
                  <Row>
                    {getMappingFn()?.map((cont, cont_index) => (
                      <Col md={8}>
                        <div className="mt-4">
                          <table
                            style={{
                              borderCollapse: "collapse",
                              width: downloadButton && "80%",
                            }}
                          >
                            <tbody>
                              {rateTypeCodes?.map(
                                (rate_type_code, rate_type_code_index) => (
                                  <>
                                    <tr key={rate_type_code}>
                                      <td
                                        style={{
                                          background: "#002981",
                                          color: "#ffff",
                                          borderBottom: "4px solid #e9b217",
                                          padding: "5px 0",
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          alignContent: "center",
                                        }}
                                      >
                                        <div></div>
                                        <div>
                                          {rate_type_code?.toUpperCase()} RATES
                                        </div>
                                        <div>
                                          {editSave && (
                                            <div className="d-flex gap-2">
                                              <div>
                                                {/* <Select
                                                    value={getBlockCurrency(
                                                      rate_type_code
                                                    )}
                                                    onChange={(e) => {
                                                      // console.log(
                                                      //   e,
                                                      //   "selected fropdown"
                                                      // );
                                                      setupdateCurrency({
                                                        rate_type_code:
                                                          rate_type_code,
                                                        currency_to: e,
                                                        currency_from:
                                                          getBlockCurrency(
                                                            rate_type_code
                                                          ),
                                                      });
                                                      setshowCurrency(true);
                                                    }}
                                                    style={{ zIndex: "10" }}
                                                    options={currencyOptions}
                                                  /> */}
                                                {/* <select className="">
                                              <option>USD</option>
                                              <option>INR</option>
                                            </select> */}
                                                <Form.Select
                                                  value={getBlockCurrency(
                                                    rate_type_code
                                                  )}
                                                  aria-label="Default select example"
                                                  style={{ width: "150px" }}
                                                  onChange={(e) => {
                                                    console.log(
                                                      e.target.value,
                                                      "e.targt.value"
                                                    );
                                                    setupdateCurrency({
                                                      rate_type_code:
                                                        rate_type_code,
                                                      currency_to:
                                                        e.target.value,
                                                      currency_from:
                                                        getBlockCurrency(
                                                          rate_type_code
                                                        ),
                                                    });
                                                    setshowCurrency(true);
                                                  }}
                                                >
                                                  {currencyOptions.map(
                                                    (item) => (
                                                      <option
                                                        value={item?.value}
                                                      >
                                                        {item?.label}
                                                      </option>
                                                    )
                                                  )}
                                                </Form.Select>
                                              </div>
                                              <div
                                                style={{
                                                  cursor: "pointer",
                                                  paddingRight: "5px",
                                                }}
                                                onClick={() => {
                                                  setselectedLabel({
                                                    name: `ADD ${rate_type_code} RATES`,
                                                    freightify_id: trade,
                                                    quote_id: quote_id,
                                                    description: "",
                                                    rate_type_code:
                                                      rate_type_code,
                                                    rate_usd: "",
                                                    amount_usd: "",
                                                    rate_currency:
                                                      getBlockCurrency(
                                                        rate_type_code
                                                      ),
                                                    container_type:
                                                      getValue(
                                                        "container_type"
                                                      ) == "20GP + 40GP"
                                                        ? cont
                                                        : getValue(
                                                            "container_type"
                                                          ),
                                                    actual_amount_usd: "",
                                                    actual_rate_usd: "",
                                                    rate_description: "",
                                                    pallet: "",
                                                    qty: "",
                                                  });
                                                  handleShowCustomer2();
                                                }}
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="16"
                                                  height="16"
                                                  fill="currentColor"
                                                  class="bi bi-plus-circle-fill"
                                                  viewBox="0 0 16 16"
                                                >
                                                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                                </svg>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                    </tr>

                                    <tr>
                                      {rate_type_code_index == 0 && (
                                        <div className="w-100">
                                          <div
                                            className="table-div"
                                            style={{
                                              display: "inline-block",
                                              fontWeight: "bold",
                                              width: "16%",
                                            }}
                                          >
                                            Concept
                                          </div>

                                          <div
                                            className="table-div"
                                            style={{
                                              display: "inline-block",
                                              fontWeight: "bold",
                                              width: "11.8%",
                                            }}
                                          >
                                            Currency
                                          </div>
                                          <div
                                            className="table-div"
                                            style={{
                                              display: !downloadButton
                                                ? "inline-block"
                                                : "none",
                                              fontWeight: "bold",
                                              width: "11.8%",
                                            }}
                                          >
                                            Original
                                          </div>
                                          <div
                                            className="table-div"
                                            style={{
                                              display: "inline-block",
                                              fontWeight: "bold",
                                              width: "11.8%",
                                            }}
                                          >
                                            {!downloadButton
                                              ? "Markup"
                                              : "Amount"}
                                          </div>
                                          <div
                                            className="table-div"
                                            style={{
                                              display: "inline-block",
                                              fontWeight: "bold",
                                              width: "10.9%",
                                            }}
                                          >
                                            #Palletes
                                          </div>
                                          <div
                                            className="table-div"
                                            style={{
                                              display: "inline-block",
                                              fontWeight: "bold",
                                              width: "10.9%",
                                            }}
                                          >
                                            #Pieces
                                          </div>
                                          <div
                                            className="table-div"
                                            style={{
                                              display: !downloadButton
                                                ? "inline-block"
                                                : "none",
                                              fontWeight: "bold",
                                              width: "10%",
                                            }}
                                          >
                                            Cost per piece
                                          </div>
                                          <div
                                            className="table-div"
                                            style={{
                                              display: "inline-block",
                                              fontWeight: "bold",
                                              width: "10%",
                                            }}
                                          >
                                            {!downloadButton
                                              ? "Markup Cost per piece"
                                              : "Cost per piece"}
                                          </div>
                                        </div>
                                      )}

                                      {selectedQuotePriceDetails
                                        ?.filter((quote) => {
                                          if (cont == "other") {
                                            return quote?.rate_type_code?.includes(
                                              rate_type_code
                                            );
                                          } else {
                                            return (
                                              quote?.rate_type_code?.includes(
                                                rate_type_code
                                              ) && quote?.container_type == cont
                                            );
                                          }
                                        })
                                        ?.map((quote, quote_index) => (
                                          <div
                                            className="w-100"
                                            key={quote?.description}
                                          >
                                            <div
                                              style={{
                                                display: "inline-block",
                                                width: "16%",
                                              }}
                                            >
                                              {quote?.description}
                                            </div>

                                            <div
                                              className="table-div"
                                              style={{
                                                display: "inline-block",
                                                width: "11.8%",
                                              }}
                                            >
                                              {quote?.rate_currency}
                                            </div>
                                            <div
                                              className="table-div"
                                              style={{
                                                display: !downloadButton
                                                  ? "inline-block"
                                                  : "none",
                                                width: "11.8%",
                                              }}
                                            >
                                              {quote?.actual_amount_usd
                                                ? quote?.actual_amount_usd
                                                : ""}
                                            </div>
                                            <div
                                              className="table-div"
                                              style={{
                                                display: "inline-block",
                                                width: "11.8%",
                                              }}
                                            >
                                              {quote?.amount_usd
                                                ? quote?.amount_usd
                                                : ""}
                                            </div>
                                            {rate_type_code?.toLowerCase() !=
                                              "addon" && (
                                              <>
                                                <div
                                                  className="table-div"
                                                  style={{
                                                    display: "inline-block",
                                                    width: "10.9%",
                                                  }}
                                                >
                                                  {quote?.pallet
                                                    ? quote?.pallet
                                                    : ""}
                                                </div>
                                                <div
                                                  className="table-div"
                                                  style={{
                                                    display: "inline-block",
                                                    width: "10.9%",
                                                  }}
                                                >
                                                  {quote?.qty
                                                    ? parseInt(quote?.qty || 0)
                                                    : ""}
                                                </div>
                                                <div
                                                  className="table-div"
                                                  style={{
                                                    display: !downloadButton
                                                      ? "inline-block"
                                                      : "none",
                                                    width: "10%",
                                                  }}
                                                >
                                                  {(
                                                    parseFloat(
                                                      quote?.actual_amount_usd
                                                    ) / parseFloat(quote?.qty)
                                                  )?.toFixed(2)}
                                                </div>
                                                <div
                                                  className="table-div"
                                                  style={{
                                                    display: "inline-block",
                                                    width: "10%",
                                                  }}
                                                >
                                                  {(
                                                    parseFloat(
                                                      quote?.amount_usd
                                                    ) / parseFloat(quote?.qty)
                                                  )?.toFixed(2)}
                                                </div>
                                              </>
                                            )}

                                            {editSave && (
                                              <div
                                                style={{
                                                  display: "inline-block",
                                                  marginLeft: "-10px",
                                                  height: "18px",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  setselectedLabel({
                                                    name: `EDIT ${rate_type_code} RATES`,
                                                    freightify_id:
                                                      selectedQuote?.freightify_id,
                                                    description:
                                                      quote?.description,
                                                    rate_type_code:
                                                      rate_type_code,
                                                    rate_usd: quote?.rate_usd,
                                                    amount_usd:
                                                      quote?.amount_usd,
                                                    rate_currency:
                                                      getBlockCurrency(
                                                        rate_type_code
                                                      ),
                                                    container_type:
                                                      quote?.container_type,
                                                    id: quote?.id,
                                                    actual_amount_usd:
                                                      quote?.actual_amount_usd,
                                                    actual_rate_usd:
                                                      quote?.actual_rate_usd,
                                                    rate_description:
                                                      quote?.rate_description,
                                                    pallet: quote?.pallet,
                                                    qty: quote?.qty,
                                                  });
                                                  handleShowCustomer2();
                                                }}
                                              >
                                                <svg
                                                  className="icon"
                                                  style={{
                                                    height: "18px",
                                                  }}
                                                >
                                                  <use href="#icon_edit"></use>
                                                </svg>
                                              </div>
                                            )}
                                            {editSave && (
                                              <div
                                                style={{
                                                  display: "inline-block",
                                                  height: "18px",
                                                  cursor: "pointer",
                                                  marginLeft: "20px",
                                                }}
                                                onClick={() => {
                                                  setselectedDocId(quote?.id);
                                                  handleShowVendorNew();
                                                }}
                                              >
                                                <svg
                                                  className="icon"
                                                  style={{
                                                    height: "18px",
                                                  }}
                                                >
                                                  <use href="#icon_delete"></use>
                                                </svg>
                                              </div>
                                            )}
                                          </div>
                                        ))}

                                      <div className="w-100 bg-gray">
                                        <div
                                          style={{
                                            display: "inline-block",
                                            width: "15.8%",
                                          }}
                                        >
                                          <p className="p-0 m-0">
                                            {capitalizeFirstLetter(
                                              rate_type_code
                                            )}
                                            &nbsp; Rates
                                          </p>
                                        </div>

                                        <div
                                          className="table-div"
                                          style={{
                                            display: "inline-block",
                                            width: "11.8%",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={getBlockCurrency(
                                              rate_type_code
                                            )}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>
                                        <div
                                          className="table-div"
                                          style={{
                                            display: !downloadButton
                                              ? "inline-block"
                                              : "none",
                                            width: "11.8%",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={getTruckRates(
                                              rate_type_code,
                                              "other",
                                              "actual_amount_usd"
                                            )?.toFixed(2)}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>

                                        <div
                                          className="table-div"
                                          style={{
                                            width: "15%",
                                            display: "inline-block",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={getTruckRates(
                                              rate_type_code,
                                              "other",
                                              "amount_usd"
                                            )?.toFixed(2)}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>
                                      </div>
                                    </tr>
                                  </>
                                )
                              )}

                              {/* total */}
                              <tr className="w-100">
                                <td
                                  style={{
                                    textAlign: "center",
                                    background: "#002981",
                                    color: "#ffff",
                                    borderTop: "4px solid #e9b217",
                                    padding: "5px 0",
                                  }}
                                >
                                  TOTAL
                                </td>
                              </tr>
                              {calculatingTotal ? (
                                <>
                                  <div className="text-center">
                                    <Loader />
                                  </div>
                                </>
                              ) : (
                                <>
                                  <tr className="w-100">
                                    <td>
                                      <div className="w-100">
                                        <div
                                          className="bg-gray"
                                          style={{
                                            display: "inline-block",
                                            width: "15.5%",
                                          }}
                                        >
                                          {rateTypeCodes?.map(
                                            (
                                              rate_type_code,
                                              rate_type_code_index
                                            ) => (
                                              <div key={rate_type_code}>
                                                <p className="p-0 m-0">
                                                  {capitalizeFirstLetter(
                                                    rate_type_code
                                                  )}
                                                  &nbsp; Rates
                                                </p>
                                              </div>
                                            )
                                          )}
                                        </div>
                                        <div
                                          className="table-div"
                                          style={{
                                            display: "inline-block",
                                            width: "12%",
                                          }}
                                        >
                                          {rateTypeCodes?.map(
                                            (
                                              rate_type_code,
                                              rate_type_code_index
                                            ) => (
                                              <div key={rate_type_code}>
                                                <input
                                                  type="text"
                                                  value={getBlockCurrency(
                                                    rate_type_code
                                                  )}
                                                  style={{
                                                    border: "none",
                                                    outline: "none",
                                                    background: "none",
                                                  }}
                                                ></input>
                                              </div>
                                            )
                                          )}
                                        </div>

                                        <div
                                          className="table-div"
                                          style={{
                                            display: !downloadButton
                                              ? "inline-block"
                                              : "none",
                                            width: "11.8%",
                                          }}
                                        >
                                          {rateTypeCodes?.map(
                                            (
                                              rate_type_code,
                                              rate_type_code_index
                                            ) => (
                                              <div key={rate_type_code}>
                                                <input
                                                  type="text"
                                                  value={getTruckRates(
                                                    rate_type_code,
                                                    cont,
                                                    "actual_amount_usd"
                                                  )?.toFixed(2)}
                                                  style={{
                                                    border: "none",
                                                    outline: "none",
                                                    background: "none",
                                                  }}
                                                ></input>
                                              </div>
                                            )
                                          )}
                                        </div>
                                        <div
                                          className="table-div"
                                          style={{
                                            width: "11.8%",
                                            display: "inline-block",
                                          }}
                                        >
                                          {rateTypeCodes?.map(
                                            (
                                              rate_type_code,
                                              rate_type_code_index
                                            ) => (
                                              <div key={rate_type_code}>
                                                <input
                                                  type="text"
                                                  value={getTruckRates(
                                                    rate_type_code,
                                                    cont,
                                                    "amount_usd"
                                                  )?.toFixed(2)}
                                                  style={{
                                                    border: "none",
                                                    outline: "none",
                                                    background: "none",
                                                  }}
                                                ></input>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  {getDifferentCurrencies()?.map(
                                    (currency, index, arr) => (
                                      <tr className="w-100">
                                        <td
                                          style={{
                                            background: "#002981",
                                            color: "#ffff",
                                            borderBottom: "4px solid #e9b217",
                                            padding: "5px 0",
                                          }}
                                        >
                                          <div className="w-100">
                                            <div
                                              style={{
                                                display: "inline-block",
                                                width: "27.5%",
                                              }}
                                            >
                                              <p>GROSS TOTAL {currency}</p>
                                            </div>

                                            <div
                                              className="table-div"
                                              style={{
                                                display: !downloadButton
                                                  ? "inline-block"
                                                  : "none",
                                                width: "12%",
                                              }}
                                            >
                                              {/* <p>{grossTotalTruck()}</p> */}
                                              <p>
                                                {grossTotalDisplayOriginal(
                                                  "other_original",
                                                  currency
                                                )}
                                              </p>
                                            </div>

                                            <div
                                              className="table-div"
                                              style={{
                                                width: "15%",
                                                display: "inline-block",
                                              }}
                                            >
                                              {/* <p>{grossTotalOriginalTruck()}</p> */}
                                              <p>
                                                {grossTotalDisplay(
                                                  "other",
                                                  currency
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    ))}
                    <Col md={4} className="mt-4">
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          marginTop: "2px",
                        }}
                      >
                        {editSave && (
                          <button
                            type="button"
                            className="px-4"
                            style={{
                              background: "#0e2074",
                              border: "1px solid #0e2074",
                              color: "#ffffff",
                              borderRadius: "4px",
                              width: "max-content",
                            }}
                            onClick={() => {
                              setselectedLabel({
                                name: `ADDON`,
                                freightify_id: selectedQuote?.freightify_id,
                                quote_id: quote_id,
                                description: "",
                                rate_type_code: "ADDON",
                                rate_usd: "",
                                amount_usd: "",
                                rate_currency: checkInAddOn(),
                                container_type: "",
                                actual_amount_usd: "",
                                actual_rate_usd: "",
                                rate_description: "",
                              });
                              if (
                                quotationDetails?.quote_form?.product_type_name?.includes(
                                  "TRUCK"
                                )
                              ) {
                                handleShowCustomer2();
                              } else {
                                handleShowCustomer();
                              }
                            }}
                          >
                            Addon
                          </button>
                        )}
                        {!selectedQuote?.comment && editSave && (
                          <button
                            type="button"
                            className="px-4"
                            style={{
                              background: "#0e2074",
                              border: "1px solid #0e2074",
                              color: "#ffffff",
                              borderRadius: "4px",
                              width: "max-content",
                            }}
                            onClick={() => {
                              setselectedLabel({
                                freightify_id: selectedQuote?.freightify_id,
                                comment: "",
                              });
                              setCommentData("");
                              handleShowCustomerComments();
                            }}
                          >
                            Add Comments
                          </button>
                        )}
                      </div>

                      {selectedQuote?.comment && (
                        <div>
                          <div
                            style={{
                              textAlign: "center",
                              background: "#002981",
                              color: "#ffff",
                              borderBottom: "4px solid #e9b217",
                              padding: "5px 4px",
                              width: "max-content",
                              marginTop: editSave && "10px",
                            }}
                          >
                            COMMENTS
                          </div>
                          <div
                            style={{
                              marginTop: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {/* <span
                                style={{
                                  width: "100px",
                                  wordWrap: "break-word",
                                }}
                              >
                                {selectedQuote?.comment}
                              </span> */}
                              <div
                                style={{
                                  minWidth: "textContent",
                                  maxWidth: "90%",
                                  wordWrap: "break-word",
                                }}
                              >
                                <button
                                  type="button"
                                  onClick={() => {
                                    setselectedLabel({
                                      freightify_id:
                                        selectedQuote?.freightify_id,
                                      comment: selectedQuote?.comment,
                                    });
                                    setCommentData(selectedQuote?.comment);
                                    handleShowCustomerComments();
                                  }}
                                  className="tracking-btn d-flex justify-content-center px-2 w-100"
                                >
                                  <span>View Comments</span>
                                </button>
                              </div>
                              {editSave && (
                                <span>
                                  <div
                                    style={{
                                      display: "inline-block",
                                      width: "18px",
                                      height: "18px",
                                      cursor: "pointer",
                                      marginLeft: "5px",
                                    }}
                                    onClick={() => {
                                      setselectedLabel({
                                        freightify_id:
                                          selectedQuote?.freightify_id,
                                        comment: selectedQuote?.comment,
                                      });
                                      setCommentData(selectedQuote?.comment);
                                      handleShowCustomerComments();
                                    }}
                                  >
                                    <svg
                                      className="icon"
                                      style={{
                                        width: "18px",
                                        height: "18px",
                                      }}
                                    >
                                      <use href="#icon_edit"></use>
                                    </svg>
                                  </div>
                                  <div
                                    style={{
                                      display: "inline-block",
                                      cursor: "pointer",
                                      marginLeft: "5px",
                                    }}
                                    onClick={() => {
                                      handleShowVendorNewComment();
                                    }}
                                  >
                                    <svg
                                      className="icon"
                                      style={{
                                        width: "18px",
                                        height: "18px",
                                      }}
                                    >
                                      <use href="#icon_delete"></use>
                                    </svg>
                                  </div>
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </Col>
                    {getValue("container_type") == "20GP + 40GP" && (
                      <Col md={6} className="mt-4 d-flex flex-column">
                        <div className="mt-auto ms-auto text-right">
                          <img src={logo} alt="Logo" />
                        </div>
                      </Col>
                    )}
                  </Row>
                )}
                {/* In case of other */}

                {quotationDetails?.quote_form?.product_type_name != "Air" &&
                  !quotationDetails?.quote_form?.product_type_name?.includes(
                    "TRUCK"
                  ) && (
                    <Row>
                      {getMappingFn()?.map((cont, cont_index) => (
                        <Col md={6}>
                          <div>
                            {cont != "other" && (
                              <h4 className="text-center pt-3 pb-2">
                                CONTAINER TYPE {cont}
                              </h4>
                            )}
                            <table style={{ borderCollapse: "collapse" }}>
                              <tbody>
                                {cont == "other" && (
                                  <tr>
                                    <td colSpan={6} className="">
                                      <p>&nbsp;</p>
                                    </td>
                                  </tr>
                                )}
                                {rateTypeCodes?.map(
                                  (rate_type_code, rate_type_code_index) => (
                                    <>
                                      <tr key={rate_type_code}>
                                        <td
                                          style={{
                                            background: "#002981",
                                            color: "#ffff",
                                            borderBottom: "4px solid #e9b217",
                                            padding: "5px 0",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            alignContent: "center",
                                          }}
                                        >
                                          <div></div>
                                          <div>
                                            {rate_type_code?.toUpperCase()}{" "}
                                            RATES
                                          </div>
                                          <div>
                                            {editSave && (
                                              <div className="d-flex gap-2">
                                                <div>
                                                  <Form.Select
                                                    value={getBlockCurrency(
                                                      rate_type_code,
                                                      cont
                                                    )}
                                                    aria-label="Default select example"
                                                    style={{ width: "150px" }}
                                                    onChange={(e) => {
                                                      console.log(
                                                        e.target.value,
                                                        "e.targt.value"
                                                      );
                                                      setupdateCurrency({
                                                        rate_type_code:
                                                          rate_type_code,
                                                        currency_to:
                                                          e.target.value,
                                                        currency_from:
                                                          getBlockCurrency(
                                                            rate_type_code,
                                                            cont
                                                          ),
                                                        container_type: cont,
                                                      });
                                                      setshowCurrency(true);
                                                    }}
                                                  >
                                                    {currencyOptions.map(
                                                      (item) => (
                                                        <option
                                                          value={item?.value}
                                                        >
                                                          {item?.label}
                                                        </option>
                                                      )
                                                    )}
                                                  </Form.Select>
                                                </div>

                                                <div
                                                  style={{
                                                    cursor: "pointer",
                                                    paddingRight: "5px",
                                                  }}
                                                  onClick={() => {
                                                    setselectedLabel({
                                                      name: `ADD ${rate_type_code} RATES`,
                                                      freightify_id: trade,
                                                      quote_id: quote_id,
                                                      description: "",
                                                      rate_type_code:
                                                        rate_type_code,
                                                      rate_usd: "",
                                                      amount_usd: "",
                                                      rate_currency:
                                                        getBlockCurrency(
                                                          rate_type_code,
                                                          cont
                                                        ),
                                                      container_type:
                                                        getValue(
                                                          "container_type"
                                                        ) == "20GP + 40GP"
                                                          ? cont
                                                          : getValue(
                                                              "container_type"
                                                            ),
                                                      actual_amount_usd: "",
                                                      actual_rate_usd: "",
                                                      rate_description: "",
                                                    });
                                                    handleShowCustomer();
                                                  }}
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    class="bi bi-plus-circle-fill"
                                                    viewBox="0 0 16 16"
                                                  >
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                                  </svg>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        {rate_type_code_index == 0 && (
                                          <div className="w-100">
                                            <div
                                              style={{
                                                display: "inline-block",
                                                width: "45%",
                                              }}
                                            >
                                              <p className="p-0 m-0"></p>
                                            </div>

                                            {!downloadButton && (
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  fontWeight: "bold",
                                                  width: "15%",
                                                }}
                                              >
                                                Markup Price
                                              </div>
                                            )}
                                            {!downloadButton && (
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  fontWeight: "bold",
                                                  width: "15%",
                                                }}
                                              >
                                                Original Price
                                              </div>
                                            )}
                                          </div>
                                        )}

                                        {selectedQuotePriceDetails
                                          ?.filter((quote) => {
                                            if (cont == "other") {
                                              return quote?.rate_type_code?.includes(
                                                rate_type_code
                                              );
                                            } else {
                                              return (
                                                quote?.rate_type_code?.includes(
                                                  rate_type_code
                                                ) &&
                                                quote?.container_type == cont
                                              );
                                            }
                                          })
                                          ?.map((quote, quote_index) => (
                                            <div
                                              className="w-100"
                                              key={quote?.description}
                                            >
                                              <div
                                                style={{
                                                  display: "inline-block",
                                                  width: "45%",
                                                }}
                                              >
                                                {quote?.description}
                                              </div>

                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  width: "15%",
                                                }}
                                              >
                                                {quote?.amount_usd
                                                  ? quote?.amount_usd
                                                  : ""}
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: !downloadButton
                                                    ? "inline-block"
                                                    : "none",
                                                  width: "15%",
                                                }}
                                              >
                                                {quote?.actual_amount_usd
                                                  ? quote?.actual_amount_usd
                                                  : ""}
                                              </div>

                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  width: "15%",
                                                }}
                                              >
                                                <input
                                                  type="text"
                                                  value={
                                                    quote?.rate_currency
                                                      ? quote?.rate_currency
                                                      : ""
                                                  }
                                                  style={{
                                                    border: "none",
                                                    outline: "none",
                                                    background: "none",
                                                  }}
                                                ></input>
                                              </div>

                                              {editSave && (
                                                <div
                                                  style={{
                                                    display: "inline-block",
                                                    marginLeft: "-10px",
                                                    height: "18px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    setselectedLabel({
                                                      name: `EDIT ${rate_type_code} RATES`,
                                                      freightify_id:
                                                        selectedQuote?.freightify_id,
                                                      description:
                                                        quote?.description,
                                                      rate_type_code:
                                                        rate_type_code,
                                                      rate_usd: quote?.rate_usd,
                                                      amount_usd:
                                                        quote?.amount_usd,
                                                      rate_currency:
                                                        getBlockCurrency(
                                                          rate_type_code,
                                                          cont
                                                        ),
                                                      container_type:
                                                        quote?.container_type,
                                                      id: quote?.id,
                                                      actual_amount_usd:
                                                        quote?.actual_amount_usd,
                                                      actual_rate_usd:
                                                        quote?.actual_rate_usd,
                                                      rate_description:
                                                        quote?.rate_description,
                                                    });
                                                    handleShowCustomer();
                                                  }}
                                                >
                                                  <svg
                                                    className="icon"
                                                    style={{
                                                      height: "18px",
                                                    }}
                                                  >
                                                    <use href="#icon_edit"></use>
                                                  </svg>
                                                </div>
                                              )}
                                              {editSave && (
                                                <div
                                                  style={{
                                                    display: "inline-block",
                                                    height: "18px",
                                                    cursor: "pointer",
                                                    marginLeft: "20px",
                                                  }}
                                                  onClick={() => {
                                                    setselectedDocId(quote?.id);
                                                    handleShowVendorNew();
                                                  }}
                                                >
                                                  <svg
                                                    className="icon"
                                                    style={{
                                                      height: "18px",
                                                    }}
                                                  >
                                                    <use href="#icon_delete"></use>
                                                  </svg>
                                                </div>
                                              )}
                                            </div>
                                          ))}

                                        <div className="w-100 bg-gray">
                                          <div
                                            style={{
                                              display: "inline-block",
                                              width: "45%",
                                            }}
                                          >
                                            <p className="p-0 m-0">
                                              Total{" "}
                                              {capitalizeFirstLetter(
                                                rate_type_code
                                              )}{" "}
                                              Rates
                                            </p>
                                          </div>

                                          <div
                                            className="table-div"
                                            style={{
                                              display: "inline-block",
                                              width: "15%",
                                            }}
                                          >
                                            <input
                                              type="text"
                                              value={getFrieghtRates(
                                                rate_type_code,
                                                cont
                                              ).toFixed(2)}
                                              style={{
                                                border: "none",
                                                outline: "none",
                                                background: "none",
                                              }}
                                            ></input>
                                          </div>

                                          <div
                                            className="table-div"
                                            style={{
                                              width: "15%",
                                              display: !downloadButton
                                                ? "inline-block"
                                                : "none",
                                            }}
                                          >
                                            <input
                                              type="text"
                                              value={getFrieghtRatesOriginal(
                                                rate_type_code,
                                                cont
                                              ).toFixed(2)}
                                              style={{
                                                border: "none",
                                                outline: "none",
                                                background: "none",
                                              }}
                                            ></input>
                                          </div>
                                          <div
                                            className="table-div"
                                            style={{
                                              display: "inline-block",
                                              width: "15%",
                                            }}
                                          >
                                            <input
                                              type="text"
                                              value={getBlockCurrency(
                                                rate_type_code,
                                                cont
                                              )}
                                              style={{
                                                border: "none",
                                                outline: "none",
                                                background: "none",
                                              }}
                                            ></input>
                                          </div>
                                        </div>
                                      </tr>
                                    </>
                                  )
                                )}

                                {/* total */}
                                <tr className="w-100">
                                  <td
                                    style={{
                                      textAlign: "center",
                                      background: "#002981",
                                      color: "#ffff",
                                      borderTop: "4px solid #e9b217",
                                      padding: "5px 0",
                                    }}
                                  >
                                    TOTAL
                                  </td>
                                </tr>
                                {calculatingTotal ? (
                                  <>
                                    <div className="text-center">
                                      <Loader />
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <tr className="w-100">
                                      <td>
                                        <div className="w-100">
                                          <div
                                            className="bg-gray"
                                            style={{
                                              display: "inline-block",
                                              width: "45%",
                                            }}
                                          >
                                            {rateTypeCodes?.map(
                                              (
                                                rate_type_code,
                                                rate_type_code_index
                                              ) => (
                                                <div key={rate_type_code}>
                                                  <p className="p-0 m-0">
                                                    {capitalizeFirstLetter(
                                                      rate_type_code
                                                    )}
                                                    Rates
                                                  </p>
                                                </div>
                                              )
                                            )}
                                          </div>

                                          <div
                                            className="table-div"
                                            style={{
                                              display: "inline-block",
                                              width: "15%",
                                            }}
                                          >
                                            {rateTypeCodes?.map(
                                              (
                                                rate_type_code,
                                                rate_type_code_index
                                              ) => (
                                                <div key={rate_type_code}>
                                                  <input
                                                    type="text"
                                                    value={getIndividualRates(
                                                      rate_type_code,
                                                      cont
                                                    )}
                                                    style={{
                                                      border: "none",
                                                      outline: "none",
                                                      background: "none",
                                                    }}
                                                  ></input>
                                                </div>
                                              )
                                            )}
                                          </div>
                                          <div
                                            className="table-div"
                                            style={{
                                              width: "15%",
                                              display: !downloadButton
                                                ? "inline-block"
                                                : "none",
                                            }}
                                          >
                                            {rateTypeCodes?.map(
                                              (
                                                rate_type_code,
                                                rate_type_code_index
                                              ) => (
                                                <div key={rate_type_code}>
                                                  <input
                                                    type="text"
                                                    value={getIndividualRatesOriginal(
                                                      rate_type_code,
                                                      cont
                                                    )}
                                                    // value={""}
                                                    style={{
                                                      border: "none",
                                                      outline: "none",
                                                      background: "none",
                                                    }}
                                                  ></input>
                                                </div>
                                              )
                                            )}
                                          </div>

                                          <div
                                            className="table-div"
                                            style={{
                                              display: "inline-block",
                                              width: "15%",
                                            }}
                                          >
                                            {rateTypeCodes?.map(
                                              (
                                                rate_type_code,
                                                rate_type_code_index
                                              ) => (
                                                <div key={rate_type_code}>
                                                  <input
                                                    type="text"
                                                    value="USD"
                                                    style={{
                                                      border: "none",
                                                      outline: "none",
                                                      background: "none",
                                                    }}
                                                  ></input>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                    {getDifferentCurrencies(cont)?.map(
                                      (currency, index, arr) => (
                                        <tr className="w-100">
                                          <td
                                            style={{
                                              background: "#002981",
                                              color: "#ffff",
                                              borderBottom: "4px solid #e9b217",
                                              padding: "5px 0",
                                            }}
                                          >
                                            <div className="w-100">
                                              <div
                                                style={{
                                                  display: "inline-block",
                                                  width: "45%",
                                                }}
                                              >
                                                <p>GROSS TOTAL {currency}</p>
                                              </div>

                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  width: "15%",
                                                }}
                                              >
                                                {/* <p>{grossTotal(cont, currency)}</p> */}
                                                <p>
                                                  {grossTotalDisplay(
                                                    cont,
                                                    currency
                                                  )}
                                                </p>
                                              </div>

                                              <div
                                                className="table-div"
                                                style={{
                                                  width: "15%",
                                                  display: !downloadButton
                                                    ? "inline-block"
                                                    : "none",
                                                }}
                                              >
                                                {/* <p>
                                              {grossTotalOriginal(
                                                cont,
                                                currency
                                              )}
                                            </p> */}
                                                <p>
                                                  {grossTotalDisplayOriginal(
                                                    cont,
                                                    currency
                                                  )}
                                                </p>
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  width: "15%",
                                                  display: "inline-block",
                                                }}
                                              >
                                                <p>{currency}</p>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </Col>
                      ))}
                      <Col md={6} className="mt-4">
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            marginTop: "2px",
                          }}
                        >
                          {editSave && (
                            <button
                              type="button"
                              className="px-4"
                              style={{
                                background: "#0e2074",
                                border: "1px solid #0e2074",
                                color: "#ffffff",
                                borderRadius: "4px",
                                width: "max-content",
                              }}
                              onClick={() => {
                                setselectedLabel({
                                  name: `ADDON`,
                                  freightify_id: selectedQuote?.freightify_id,
                                  quote_id: quote_id,
                                  description: "",
                                  rate_type_code: "ADDON",
                                  rate_usd: "",
                                  amount_usd: "",
                                  rate_currency: checkInAddOn(),
                                  container_type: "",
                                  actual_amount_usd: "",
                                  actual_rate_usd: "",
                                  rate_description: "",
                                });
                                if (
                                  quotationDetails?.quote_form?.product_type_name?.includes(
                                    "TRUCK"
                                  )
                                ) {
                                  handleShowCustomer2();
                                } else {
                                  handleShowCustomer();
                                }
                              }}
                            >
                              Addon
                            </button>
                          )}
                          {!selectedQuote?.comment && editSave && (
                            <button
                              type="button"
                              className="px-4"
                              style={{
                                background: "#0e2074",
                                border: "1px solid #0e2074",
                                color: "#ffffff",
                                borderRadius: "4px",
                                width: "max-content",
                              }}
                              onClick={() => {
                                setselectedLabel({
                                  freightify_id: selectedQuote?.freightify_id,
                                  comment: "",
                                });
                                setCommentData("");
                                handleShowCustomerComments();
                              }}
                            >
                              Add Comments
                            </button>
                          )}
                        </div>

                        {selectedQuote?.comment && (
                          <div>
                            <div
                              style={{
                                textAlign: "center",
                                background: "#002981",
                                color: "#ffff",
                                borderBottom: "4px solid #e9b217",
                                padding: "5px 4px",
                                width: "max-content",
                                marginTop: editSave && "10px",
                              }}
                            >
                              COMMENTS
                            </div>
                            <div
                              style={{
                                marginTop: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {/* <span
                                  style={{
                                    width: "100px",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {selectedQuote?.comment}
                                </span> */}
                                <div
                                  style={{
                                    minWidth: "textContent",
                                    maxWidth: "90%",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setselectedLabel({
                                        freightify_id:
                                          selectedQuote?.freightify_id,
                                        comment: selectedQuote?.comment,
                                      });
                                      setCommentData(selectedQuote?.comment);
                                      handleShowCustomerComments();
                                    }}
                                    className="tracking-btn d-flex justify-content-center px-2 w-100"
                                  >
                                    <span>View Comments</span>
                                  </button>
                                </div>
                                {editSave && (
                                  <span>
                                    <div
                                      style={{
                                        display: "inline-block",
                                        width: "18px",
                                        height: "18px",
                                        cursor: "pointer",
                                        marginLeft: "5px",
                                      }}
                                      onClick={() => {
                                        setselectedLabel({
                                          freightify_id:
                                            selectedQuote?.freightify_id,
                                          comment: selectedQuote?.comment,
                                        });
                                        setCommentData(selectedQuote?.comment);
                                        handleShowCustomerComments();
                                      }}
                                    >
                                      <svg
                                        className="icon"
                                        style={{
                                          width: "18px",
                                          height: "18px",
                                        }}
                                      >
                                        <use href="#icon_edit"></use>
                                      </svg>
                                    </div>
                                    <div
                                      style={{
                                        display: "inline-block",
                                        cursor: "pointer",
                                        marginLeft: "5px",
                                      }}
                                      onClick={() => {
                                        handleShowVendorNewComment();
                                      }}
                                    >
                                      <svg
                                        className="icon"
                                        style={{
                                          width: "18px",
                                          height: "18px",
                                        }}
                                      >
                                        <use href="#icon_delete"></use>
                                      </svg>
                                    </div>
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </Col>
                      {getValue("container_type") == "20GP + 40GP" && (
                        <Col md={6} className="mt-4 d-flex flex-column">
                          <div className="mt-auto ms-auto text-right">
                            <img src={logo} alt="Logo" />
                          </div>
                        </Col>
                      )}
                    </Row>
                  )}
              </div>
            </Col>
            {getValue("container_type") != "20GP + 40GP" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  paddingBottom: "20px",
                  marginTop: "-80px",
                  paddingRight: "25px",
                  position: "relative",
                  top: "-10px",
                }}
              >
                <img src={logo} />
              </div>
            )}
          </Row>
        )}
        {!checkSelectedQuoteLength() && (
          <div className="w-100 d-flex justify-content-center aligin-items-center mt-5">
            <Loader />
          </div>
        )}
      </Container>
      <Modal show={show} onHide={handleClose} animation={true} centered>
        <Modal.Body>
          <h3>Are you sure you want to Update this Quote ? </h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant={!postEditedDataFetched ? "" : "primary"}
            onClick={() => postQuotationDetails("save_button")}
          >
            {!postEditedDataFetched ? <Loader /> : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showCurrency}
        onHide={() => setshowCurrency(false)}
        animation={true}
        size="lg"
        centered
      >
        <Modal.Body>
          <h3>
            Are you sure you want to Update{" "}
            {updateCurrency?.rate_type_code?.toUpperCase()} RATES
            {updateCurrency?.container_type &&
              quotationDetails?.quote_form?.container_type == "20GP + 40GP" &&
              `(${updateCurrency?.container_type})`}{" "}
            currency from {updateCurrency?.currency_from} to{" "}
            {updateCurrency?.currency_to}?{" "}
          </h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setshowCurrency(false)}>
            Cancel
          </Button>
          <Button
            variant={changeCurrency ? "" : "primary"}
            onClick={() => {
              changeQuoteCurrencyFn(
                updateCurrency?.rate_type_code,
                updateCurrency?.currency_to
              );
            }}
          >
            {changeCurrency ? <Loader /> : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showCustomer}
        centered
        onHide={handleCloseCustomer}
        className="tracking-modal"
      >
        <Modal.Header closeButton style={{ border: "none" }} onClick={() => {}}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <h2 className="fw-600 fs-26 text-center">
            {selectedLabel?.name} ({selectedLabel?.rate_currency})
          </h2>
          <form className="p-4">
            <div className="input-box mb-4">
              {selectedLabel.description && selectedLabel.description != "" && (
                <label htmlFor="description">Description</label>
              )}
              <input
                required
                type="text"
                className="w-100 border-bottom pb-3"
                placeholder="Enter Description"
                id="description"
                value={selectedLabel?.description}
                onChange={(e) => {
                  setselectedLabel({
                    ...selectedLabel,
                    description: e.target.value,
                  });
                }}
              />
            </div>
            <>
              {!quotationDetails?.quote_form?.product_type_name?.includes(
                "TRUCK"
              ) && (
                <>
                  {quotationDetails?.quote_form?.product_type_name != "Air" ? (
                    <>
                        <div className="input-box mb-4">
                          {selectedLabel.rate_usd &&
                            selectedLabel.rate_usd != "" && (
                              <label htmlFor="rate_usd">Original Price</label>
                            )}

                          <input
                            required
                            type="number"
                            className="w-100 border-bottom pb-3"
                            placeholder="Enter Original Price"
                            id="rate_usd"
                            value={selectedLabel?.actual_amount_usd}
                            onChange={(e) => {
                              let trade_percent = parseFloat(
                                quotationDetails?.results?.trade_percent_obj
                                  ?.trade_percent
                              );
                              let amount_usd =
                                (parseFloat(e.target.value) * trade_percent) /
                                  100 +
                                parseFloat(e.target.value);
                              setselectedLabel({
                                ...selectedLabel,
                                actual_amount_usd: e.target.value,
                                amount_usd: amount_usd.toFixed(2),
                              });
                              // setselectedLabel({
                              //   ...selectedLabel,
                              //   rate_usd: e.target.value,
                              // });
                            }}
                          />
                        </div>
                        <div className="input-box mb-4">
                          {selectedLabel.amount_usd &&
                            selectedLabel.amount_usd != "" && (
                              <label htmlFor="amount_usd">Markup Price</label>
                            )}

                          <input
                            required
                            type="number"
                            className="w-100 border-bottom pb-3"
                            placeholder="Enter Markup Price"
                            id="amount_usd"
                            value={selectedLabel?.amount_usd}
                            onChange={(e) => {
                              setselectedLabel({
                                ...selectedLabel,
                                amount_usd: e.target.value,
                              });
                            }}
                          />
                        </div>

                      {getValue("container_type") == "20GP + 40GP" && (
                        <div className="me-4">
                          {selectedLabel.container_type &&
                            selectedLabel.container_type != "" && (
                              <label htmlFor="container_type" className="mb-1">
                                Container Type
                              </label>
                            )}
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            value={selectedLabel?.container_type}
                            onChange={(e) => {
                              setselectedLabel({
                                ...selectedLabel,
                                container_type: e.target.value,
                              });
                            }}
                          >
                            <option value="">
                              Please Select Container Type
                            </option>
                            {conatinerTypes?.map((item) => (
                              <option value={item} key={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {(selectedLabel?.name?.includes("ORIGIN") ||
                        selectedLabel?.name?.includes("FREIGHT")) && (
                        <>
                          <div className="input-box mb-4">
                            {selectedLabel.actual_rate_usd &&
                              selectedLabel.actual_rate_usd != "" && (
                                <label htmlFor="actual_rate_usd">
                                  {!selectedLabel?.name?.includes("FREIGHT") ? (
                                    <span>AMOUNT</span>
                                  ) : (
                                    <span>RATE PER KG</span>
                                  )}
                                </label>
                              )}
                            <input
                              required
                              type="number"
                              className="w-100 border-bottom pb-3"
                              placeholder={
                                !selectedLabel?.name?.includes("FREIGHT")
                                  ? "Enter Amount"
                                  : "Enter Rate PER KG"
                              }
                              id="actual_rate_usd"
                              value={selectedLabel?.actual_rate_usd}
                              onChange={(e) => {
                                let trade_percent = parseFloat(
                                  quotationDetails?.results?.trade_percent_obj
                                    ?.trade_percent
                                );
                                let rate_usd =
                                  (parseFloat(e.target.value) * trade_percent) /
                                    100 +
                                  parseFloat(e.target.value);
                                setselectedLabel({
                                  ...selectedLabel,
                                  actual_rate_usd: e.target.value,
                                  rate_usd: rate_usd
                                    ? rate_usd?.toFixed(2)
                                    : "",
                                });
                              }}
                            />
                          </div>
                          <div className="input-box mb-4">
                            {selectedLabel.rate_usd &&
                              selectedLabel.rate_usd != "" && (
                                <label htmlFor="rate_usd">
                                  {!selectedLabel?.name?.includes("FREIGHT") ? (
                                    <span> MARKUP AMOUNT</span>
                                  ) : (
                                    <span>MARKUP RATE PER KG</span>
                                  )}
                                </label>
                              )}
                            <input
                              required
                              type="number"
                              className="w-100 border-bottom pb-3"
                              placeholder={
                                !selectedLabel?.name?.includes("FREIGHT")
                                  ? "Enter MARKUP AMOUNT"
                                  : "Enter MARKUP RATE PER KG"
                              }
                              id="rate_usd"
                              value={selectedLabel?.rate_usd}
                              onChange={(e) => {
                                setselectedLabel({
                                  ...selectedLabel,
                                  rate_usd: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </>
                      )}

                      {selectedLabel?.name?.includes("ORIGIN") && (
                        <div className="input-box mb-4">
                          {selectedLabel.rate_description &&
                            selectedLabel.rate_description != "" && (
                              <label htmlFor="rate_description">
                                RATE DESCRIPTION
                              </label>
                            )}
                          <input
                            required
                            type="text"
                            className="w-100 border-bottom pb-3"
                            placeholder="Enter MARKUP RATE PER KG"
                            id="rate_description"
                            value={selectedLabel?.rate_description}
                            onChange={(e) => {
                              setselectedLabel({
                                ...selectedLabel,
                                rate_description: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )}

                      <div className="input-box mb-4">
                        {selectedLabel.actual_amount_usd &&
                          selectedLabel.actual_amount_usd != "" && (
                            <label htmlFor="actual_amount_usd">AMOUNT</label>
                          )}
                        <input
                          required
                          type="number"
                          className="w-100 border-bottom pb-3"
                          placeholder="Enter AMOUNT"
                          id="actual_amount_usd"
                          value={selectedLabel?.actual_amount_usd}
                          onChange={(e) => {
                            let trade_percent = parseFloat(
                              quotationDetails?.results?.trade_percent_obj
                                ?.trade_percent
                            );
                            let amount_usd =
                              (parseFloat(e.target.value) * trade_percent) /
                                100 +
                              parseFloat(e.target.value);

                            setselectedLabel({
                              ...selectedLabel,
                              actual_amount_usd: e.target.value,
                              amount_usd: amount_usd
                                ? amount_usd?.toFixed(2)
                                : "",
                            });
                          }}
                        />
                      </div>
                      <div className="input-box mb-4">
                        {selectedLabel.amount_usd &&
                          selectedLabel.amount_usd != "" && (
                            <label htmlFor="amount_usd">MARKUP AMOUNT</label>
                          )}
                        <input
                          required
                          type="number"
                          className="w-100 border-bottom pb-3"
                          placeholder="Enter MARKUP AMOUNT"
                          id="amount_usd"
                          value={selectedLabel?.amount_usd}
                          onChange={(e) => {
                            setselectedLabel({
                              ...selectedLabel,
                              amount_usd: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </>

            {quotationDetails?.quote_form?.product_type_name?.includes(
              "TRUCK"
            ) && (
              <>
                {selectedLabel.amount_usd && selectedLabel.amount_usd != "" && (
                  <label htmlFor="amount_usd">AMOUNT</label>
                )}
                <input
                  required
                  type="number"
                  className="w-100 border-bottom pb-3"
                  placeholder={"Enter Amount"}
                  id="rate_usd"
                  value={selectedLabel?.rate_usd}
                  onChange={(e) => {
                    setselectedLabel({
                      ...selectedLabel,
                      rate_usd: e.target.value,
                    });
                  }}
                />
              </>
            )}

            <div className="d-flex justify-content-end">
              {sendingPlusDetailsError && (
                <div className="text-danger">{sendingPlusDetailsError}</div>
              )}
              <div>
                <button
                  type="submit"
                  className="mt-4 py-2 px-4 grd-btn fs-20 rounded-pill fw-600 d-flex text-center"
                  disabled={sendingPlusDetails}
                  onClick={() => {
                    if (selectedLabel?.id) {
                      postPlusIcon("edit");
                    } else {
                      postPlusIcon("add");
                    }
                  }}
                >
                  {loaderFnSave(sendingPlusDetails, "Save")}
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal
        show={showCustomer2}
        centered
        onHide={handleCloseCustomer2}
        className="tracking-modal"
      >
        <Modal.Header closeButton style={{ border: "none" }} onClick={() => {}}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <h2 className="fw-600 fs-26 text-center">
            {selectedLabel?.name} {selectedLabel?.rate_currency}
          </h2>
          <form className="p-4">
            <div className="input-box mb-4">
              {selectedLabel.description && selectedLabel.description != "" && (
                <label htmlFor="description">Description</label>
              )}
              <input
                required
                type="text"
                className="w-100 border-bottom pb-3"
                placeholder="Enter Description"
                id="description"
                value={selectedLabel?.description}
                onChange={(e) => {
                  setselectedLabel({
                    ...selectedLabel,
                    description: e.target.value,
                  });
                }}
              />
            </div>
            <div className="input-box mb-4">
              {selectedLabel.actual_amount_usd &&
                selectedLabel.actual_amount_usd != "" && (
                  <label htmlFor="actual_amount_usd">AMOUNT</label>
                )}
              <input
                required
                type="number"
                className="w-100 border-bottom pb-3"
                placeholder={"Enter Amount"}
                id="actual_amount_usd"
                value={selectedLabel?.actual_amount_usd}
                onChange={(e) => {
                  let trade_percent = parseFloat(
                    quotationDetails?.results?.trade_percent_obj?.trade_percent
                  );
                  let amount_usd =
                    (parseFloat(e.target.value) * trade_percent) / 100 +
                    parseFloat(e.target.value);

                  setselectedLabel({
                    ...selectedLabel,
                    actual_amount_usd: e.target.value,
                    amount_usd: amount_usd ? amount_usd?.toFixed(2) : "",
                  });
                }}
              />
            </div>
            <div className="input-box mb-4">
              {selectedLabel.amount_usd && selectedLabel.amount_usd != "" && (
                <label htmlFor="amount_usd">MARKUP AMOUNT</label>
              )}
              <input
                required
                type="number"
                className="w-100 border-bottom pb-3"
                placeholder={"Enter Markup Amount"}
                id="amount_usd"
                value={selectedLabel?.amount_usd}
                onChange={(e) => {
                  setselectedLabel({
                    ...selectedLabel,
                    amount_usd: e.target.value,
                  });
                }}
              />
            </div>
            {!selectedLabel?.name?.includes("ADDON") && (
              <>
                <div className="input-box mb-4">
                  {selectedLabel.pallet && selectedLabel.pallet != "" && (
                    <label htmlFor="pallet">#Palletes</label>
                  )}
                  <input
                    required
                    type="number"
                    className="w-100 border-bottom pb-3"
                    placeholder={"Enter #pallets"}
                    id="pallet"
                    value={selectedLabel?.pallet}
                    onChange={(e) => {
                      setselectedLabel({
                        ...selectedLabel,
                        pallet: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="input-box mb-4">
                  {selectedLabel.qty && selectedLabel.qty != "" && (
                    <label htmlFor="qty">#Pieces</label>
                  )}
                  <input
                    required
                    type="number"
                    className="w-100 border-bottom pb-3"
                    placeholder={"Enter #pieces"}
                    id="qty"
                    value={selectedLabel?.qty}
                    onChange={(e) => {
                      setselectedLabel({
                        ...selectedLabel,
                        qty: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="input-box mb-4">
                  <label htmlFor="qty">Cost per piece</label>
                  <input
                    required
                    type="number"
                    className="w-100 border-bottom pb-3"
                    placeholder={""}
                    id=""
                    value={(
                      parseFloat(selectedLabel?.actual_amount_usd) /
                      parseFloat(selectedLabel?.qty)
                    )?.toFixed(2)}
                  />
                </div>
                <div className="input-box mb-4">
                  <label htmlFor="qty">Markup Cost per piece</label>
                  <input
                    required
                    type="number"
                    className="w-100 border-bottom pb-3"
                    placeholder={""}
                    id=""
                    value={(
                      parseFloat(selectedLabel?.amount_usd) /
                      parseFloat(selectedLabel?.qty)
                    )?.toFixed(2)}
                  />
                </div>
              </>
            )}

            <div className="d-flex justify-content-end">
              {sendingPlusDetailsError && (
                <div className="text-danger">{sendingPlusDetailsError}</div>
              )}
              <div>
                <button
                  type="submit"
                  className="mt-4 py-2 px-4 grd-btn fs-20 rounded-pill fw-600 d-flex text-center"
                  disabled={sendingPlusDetails}
                  onClick={() => {
                    if (selectedLabel?.id) {
                      postPlusIcon("edit");
                    } else {
                      postPlusIcon("add");
                    }
                  }}
                >
                  {loaderFnSave(sendingPlusDetails, "Save")}
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal
        size="xl"
        backdrop="static"
        show={showCustomerComments}
        centered
        scrollable
        onHide={() => {
          // setCommentData("")
          handleCloseCustomerComments();
        }}
        className="tracking-modal"
      >
        <Modal.Header closeButton style={{ border: "none" }} onClick={() => {}}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <h2 className="fw-600 fs-26 text-center">Add Comments</h2>
          <form className="p-4">
            {/* <div className="input-box mb-4">
              <textarea
                required
                type="text"
                className="w-100 border-bottom pb-3"
                placeholder="Add Comment"
                id="comments"
                value={selectedLabel?.comment}
                onChange={(e) => {
                  setselectedLabel({
                    ...selectedLabel,
                    comment: e.target.value,
                  });
                }}
              />
            </div> */}
            <Ckeditor
              commentData={commentData}
              setCommentData={setCommentData}
              editSave={editSave}
              quotestatus={selectedQuote?.quote_status}
              from="filter"
            />

            <div className="d-flex justify-content-end">
              {sendingPlusDetailsErrorComments && (
                <div className="text-danger">
                  {sendingPlusDetailsErrorComments}
                </div>
              )}
              {editSave && (
                <div>
                  <button
                    type="submit"
                    className="mt-3 py-2 px-4 grd-btn fs-20 rounded-pill fw-600 d-flex text-center"
                    disabled={sendingPlusDetailsComments}
                    onClick={() => {
                      postCommentApi();
                    }}
                  >
                    {loaderFnSave(sendingPlusDetailsComments, "Save")}
                  </button>
                </div>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal show={showVendorNew} onHide={handleCloseVendorNew} centered>
        <Modal.Body className="p-4">
          <p className="fs-4 text-center">
            Are you sure you want to delete this Detail?
          </p>
          <div className="d-flex justify-content-center mt-2">
            <Button
              variant="secondary"
              className="me-3"
              onClick={handleCloseVendorNew}
            >
              Close
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                deleteLableFn();
              }}
            >
              {loaderFnSave(deleting, "Delete")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showVendorNewComment}
        onHide={handleCloseVendorNewComment}
        centered
      >
        <Modal.Body className="p-4">
          <p className="fs-4 text-center">
            Are you sure you want to delete this Comment?
          </p>
          <div className="d-flex justify-content-center mt-2">
            <Button
              variant="secondary"
              className="me-3"
              onClick={handleCloseVendorNewComment}
            >
              Close
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                deleteLableFnComment();
              }}
            >
              {loaderFnSave(deletingComment, "Delete")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Home2;
