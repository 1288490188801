import React from 'react'
import { Outlet } from "react-router-dom";

function Wrapper() {
  return (
      <div className="wrapper">
            <Outlet></Outlet>
      </div>
  )
}

export default Wrapper
