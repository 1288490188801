import { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import UserContext from "../context/UserContext";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import Loader from "./Loader";
import download_icon from "../pages/srcimages/download_icon.png";
import { allAiseBarPrices } from "../utils/commonFn";

function AsideBar() {
  const context = useContext(UserContext);
  const {
    quotationDetails,
    setquotationDetails,
    selectedQuote,
    setselectedQuote,
    editSave,
    profitMargin,
    approvedQuoteDetails,
    setapprovedQuoteDetails,
    setfirstTimeProfitMargin,
    selectedTempQuoteId,
    handlePrint,
    selectedQuotePriceDetails,
    setselectedQuotePriceDetails,
    setclkData,
    grossTotalCurrencies, setgrossTotalCurrencies,
    grossTotalCurrenciesIndidual, setgrossTotalCurrenciesIndividual,
    asideBarQuotePrices, setasideBarQuotePrices
  } = context;
  const navigate = useNavigate();
  const location = useLocation();
  const [mounted, setmounted] = useState(false);
  const params = useParams();
  // let { quote_range } = location?.state;
  const [show, setShow] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [showApproveCheck, setShowApproveCheck] = useState(false);
  const [approvedFetched, setapprovedFetched] = useState(true);
  const [remark, setremark] = useState({});
  const { quote_id, task_id } = params;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseApproveCheck = () => setShowApproveCheck(false);
  const handleShowApproveCheck = () => setShowApproveCheck(true);

  const handleCloseIcon = () => setShowIcon(false);
  const handleShowIcon = () => setShowIcon(true);

  const checkDisplayPercentage2 = (quote_price_details) => {
    return (
      getProfitMargin2(quote_price_details) <
      parseFloat(
        quotationDetails?.results?.trade_percent_obj?.min_trade_percent
      )
    );
  };
  const checkUserTypeOne = (quote) => {
    if (Object.keys(approvedQuoteDetails || {}).length > 0) {
      if (!checkApprovedFn(quote)) {
        return true;
      }
    } else {
      return false;
    }
  };
  const checkTrue1 = () => {
    return Object.keys(approvedQuoteDetails || {}).length > 0;
  };
  const checkTrue2 = (quote) => {
    return selectedQuote?.id == quote?.id && profitMargin;
  };
  const checkTrue3 = (quote) => {
    return (
      selectedQuote?.id != quote?.id &&
      checkDisplayPercentage2(quote?.quote_price_details)
    );
  };
  const checkTrue4 = (quote) => {
    return editSave && selectedQuote?.id != quote?.id;
  };
  // check disability
  const checkDisability = (quote) => {
    if(editSave){
      return true;
    }
    if (JSON.parse(localStorage.getItem("mtm_user_data"))?.user_type == 1) {
      return checkUserTypeOne(quote);
    } else if (
      checkTrue1() ||
      checkTrue2(quote) ||
      checkTrue3(quote) ||
      checkTrue4(quote)
    ) {
      return true;
    } else {
      return false;
    }
  };
  console.log(grossTotalCurrencies,"grossTotalCurrencies in aside bar");
  console.log(grossTotalCurrenciesIndidual,"grossTotalCurrenciesIndidual...")
  const getQuotationDetails = async () => {
    try {
      let token = localStorage.getItem("mtm_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmclick/quote/details`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: {
          quote_id: quote_id,
          task_id: task_id,
        },
      });

      if (!response.data.error) {
        let data = response?.data?.results?.quote_details;
        for (let i = 0; i < data?.length; i++) {
          const element = data[i];
          if (element.quote_status == 2) {
            setapprovedQuoteDetails(element);
            break;
          }
        }
        setquotationDetails(response?.data);
      }
    } catch (error) {
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  };
  const checkApprovedFn = (quote) => {
    return quote?.freightify_id == approvedQuoteDetails?.freightify_id;
  };
  const callApproveFn = async () => {
    setapprovedFetched(false);
    try {
      let blockPrices=[];
      if(quotationDetails?.quote_form["container_type"]=="20GP + 40GP"){
        for (const [rateTypeCode, item] of Object.entries(grossTotalCurrenciesIndidual||{})) {
          if(item?.other==0){
            continue;
          }
          let transformedItem20GP = {
              "rate_currency": "USD",
              "rate_type_code": rateTypeCode,
              "container_type":"20GP",
              "actual_amount_usd": item["20GP_original"],
              "amount_usd": item["20GP"]
          };
          let transformedItem40GP = {
              "rate_currency": "USD",
              "rate_type_code": rateTypeCode,
              "container_type":"40GP",
              "actual_amount_usd": item["40GP_original"],
              "amount_usd": item["40GP"]
          };
      
          blockPrices.push(transformedItem20GP);
          blockPrices.push(transformedItem40GP);
        }
      }else{
        for (const [rateTypeCode, item] of Object.entries(grossTotalCurrenciesIndidual||{})) {
          if(item?.other==0){
            continue;
          }
          let transformedItem = {
              "rate_currency": "USD",
              "rate_type_code": rateTypeCode,
              "container_type":quotationDetails?.quote_form["container_type"],
              "actual_amount_usd": item.other_original,
              "amount_usd": item.other
          };
      
          blockPrices.push(transformedItem);
        }
      }
      const getDifferentCurrencies = (cont) => {
        let currencies = ["USD"];
        let quotePriceDetails=[];
        if(cont){
          quotePriceDetails=selectedQuotePriceDetails?.filter((item)=>item?.container_type===cont);
        }else{
          quotePriceDetails=selectedQuotePriceDetails;
        }
        for (let i = 0; i < quotePriceDetails.length; i++) {
          const element = quotePriceDetails[i];
          if (!currencies?.includes(element.rate_currency)) {
            currencies.push(element?.rate_currency);
          }
        }
        return currencies;
      };
      let currencies=getDifferentCurrencies();
      let filteredData = Object.keys(grossTotalCurrencies)
        .filter(key => currencies.includes(key))
        .reduce((obj, key) => {
            obj[key] = grossTotalCurrencies[key];
            return obj;
        }, {});
      console.log(currencies,"currencies...")
      // 
      let currencies_20GP=getDifferentCurrencies("20GP");
      let currencies_40GP=getDifferentCurrencies("40GP");


      for (const [rate_currency, item] of Object.entries(filteredData||{})) {
        if(quotationDetails?.quote_form["container_type"]=="20GP + 40GP"){
          if(currencies_20GP.includes(rate_currency)){
            let transformedItem20GP = {
              "rate_currency": rate_currency,
              "rate_type_code": "GROUND_TOTAL",
              "container_type":"20GP",
              "actual_amount_usd": item["20GP_original"],
              "amount_usd": item["20GP"]
            };
            blockPrices.push(transformedItem20GP);
          }
          if(currencies_40GP.includes(rate_currency)){
            let transformedItem40GP = {
              "rate_currency": rate_currency,
              "rate_type_code": "GROUND_TOTAL",
              "container_type":"40GP",
              "actual_amount_usd": item["40GP_original"],
              "amount_usd": item["40GP"]
            };
            blockPrices.push(transformedItem40GP);
          }
        }else{
          let transformedItem={}
          transformedItem = {
             "rate_currency": rate_currency,
             "rate_type_code": "GROUND_TOTAL",
             "container_type":quotationDetails?.quote_form?.container_type,
             "actual_amount_usd": item.other_original,
             "amount_usd": item.other
          };
          blockPrices.push(transformedItem);
        }
      }
      const data={
        quote_id: quotationDetails?.quote_form?.quote_id,
        freightify_id: selectedQuote?.freightify_id,
        analyst_name: quotationDetails?.quote_form?.analyst_name
          ? quotationDetails?.quote_form?.analyst_name
          : JSON.parse(localStorage.getItem("mtm_user_data"))?.user_full_name,
        analyst_email: quotationDetails?.quote_form?.analyst_email
          ? quotationDetails?.quote_form?.analyst_email
          : JSON.parse(localStorage.getItem("mtm_user_data"))?.email,
        blockprices:blockPrices
      }
      // console.log(data,"data block prices...")
      let token = localStorage.getItem("mtm_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmclick/quote/approve`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: data,
      });
      if (!response?.data?.error) {
        getQuotationDetails();
        handleClose();
        setapprovedFetched(true);
      }
    } catch (error) {
      setapprovedFetched(true);
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  };

  const grossTotal = (quote_price_details) => {
    let sum = 0;
    for (let i = 0; i < quote_price_details?.length; i++) {
      const element = quote_price_details[i];
      sum += element?.amount_usd ? parseFloat(element?.amount_usd) : 0;
    }

    return parseFloat(sum).toFixed(2);
  };
  const grossTotalOriginal = (quote_price_details) => {
    let sum = 0;
    for (let i = 0; i < quote_price_details?.length; i++) {
      const element = quote_price_details[i];
      sum += element?.actual_amount_usd ? parseFloat(element?.actual_amount_usd) : 0;
    }
    return parseFloat(sum).toFixed(2);
  };
  const getAirRates = (rate_type_code, type) => {
    let allRrieghtDetails = selectedQuotePriceDetails?.filter((quote) => {
      return quote?.rate_type_code?.includes(rate_type_code);
    });
    let sum = 0;

    for (const element of allRrieghtDetails) {
      if (element[type]) {
        sum += parseFloat(element[type]);
      }
    }
    return sum;
  };
  const getFinalRatesAir = () => {
    return (
      getAirRates("FREIGHT", "actual_amount_usd") +
      getAirRates("ORIGIN", "actual_amount_usd") +
      getAirRates("DESTINATION", "actual_amount_usd")
    );
  };
  const getFinalRatesAirMarkUp = () => {
    return (
      getAirRates("FREIGHT", "amount_usd") +
      getAirRates("ORIGIN", "amount_usd") +
      getAirRates("DESTINATION", "amount_usd")
    );
  };
  const grossTotalTruck = () => {
    let sum = 0;
    let array = [];

      array = selectedQuotePriceDetails;

    for (const element of array) {
      sum += element?.actual_amount_usd ? parseFloat(element?.actual_amount_usd) : 0;
    }
    return parseFloat(sum).toFixed(2);
  };
  const grossTotalOriginalTruck = () => {
    let sum = 0;
    let array = [];
    
   
      array = selectedQuotePriceDetails;

    for (const element of array) {
      sum += element?.amount_usd ? parseFloat(element?.amount_usd) : 0;
    }
    return parseFloat(sum).toFixed(2);
  };
  const getProfitMargin2 = (quote_price_details) => {
    let diff;
    let percentage;
    if (quotationDetails?.quote_form?.product_type_name == "Air") {
      diff = getFinalRatesAirMarkUp() - getFinalRatesAir();
      percentage = (diff / getFinalRatesAir()) * 100;
    } else if(quotationDetails?.quote_form?.product_type_name?.includes("TRUCK")) {
      diff =
        parseFloat(grossTotalOriginalTruck()) -
        parseFloat(grossTotalTruck());
      percentage = (diff / parseFloat(grossTotalTruck())) * 100;
    }else {
      diff =
        parseFloat(grossTotal(quote_price_details)) -
        parseFloat(grossTotalOriginal(quote_price_details));
      percentage =
        (diff / parseFloat(grossTotalOriginal(quote_price_details))) * 100;
    }
 
  };
  const getProfitMarginNew=(quote)=>{
    if(Object.keys(asideBarQuotePrices||{}).length==0||Object.keys(quotationDetails||{}).length==0&&Object.keys(asideBarQuotePrices||{}).length>0&&asideBarQuotePrices[quote?.freightify_id]){
      return 0;
    }
    
    let diff;
    let percentage;
    if(!asideBarQuotePrices[quote?.freightify_id]?.amount_usd||!asideBarQuotePrices[quote?.freightify_id]?.actual_amount_usd){
      return 0;
    }
    diff=asideBarQuotePrices[quote?.freightify_id]["amount_usd"]-asideBarQuotePrices[quote?.freightify_id]["actual_amount_usd"];
    percentage = (diff / asideBarQuotePrices[quote?.freightify_id]["actual_amount_usd"]) * 100;
    console.log(percentage,quote?.id,asideBarQuotePrices[quote?.freightify_id]["amount_usd"],asideBarQuotePrices[quote?.freightify_id]["actual_amount_usd"],"percentage quote id")
    return percentage;
  }
  const checkQuoteDetailsDisplay = () => {
    return quotationDetails && Object.keys(quotationDetails || {})?.length > 0;
  };
  const checkDisplayPercentage = (quote_price_details) => {

    return (
      getProfitMargin2(quote_price_details)?.toFixed(2) <
      parseFloat(quotationDetails?.results?.trade_percent_obj?.trade_percent)
    );
  };
  const checkDisplayPercentageNew = (quote) => {
    if(Object.keys(asideBarQuotePrices||{}).length==0||Object.keys(quotationDetails||{}).length==0){
      return false;
    }
    console.log(getProfitMarginNew(quote)?.toFixed(2),"getProfitMarginNew(quote)...")
    return (
      getProfitMarginNew(quote)?.toFixed(2) <
      parseFloat(quotationDetails?.results?.trade_percent_obj?.trade_percent)
    );
  };
  const getStylesInQuoteDisplay = (from, quote) => {
    if (from == "main") {
      return {
        borderRadius: "9px",
        border: quote?.price_type == "self" && "1px solid #C7CFFF",
        background: quote?.price_type == "self" && "#EBEEFF",
        padding: quote?.price_type == "self" && "4px",
      };
    } else {
      return {
        color: quote?.id == selectedQuote?.id && "#e2b211",
      };
    }
  };

  const checkIf = () => {
    return (
      Object.keys(quotationDetails?.results || {})?.length > 0 &&
      Object.keys(selectedTempQuoteId || {}).length == 0
    );
  };
  const checkApprovedElement = () => {
    let approvedQuoteElement = null;
    let data = quotationDetails?.results?.quote_details;

    for (const element of data) {
      if (element.quote_status == 2) {
        approvedQuoteElement = element;
        break;
      }
    }
    return approvedQuoteElement;
  };
  const setapprovedQuoteFn = () => {
    let selectedquote = null;
    let data = quotationDetails?.results?.quote_details;
    let approvedQuoteElement = checkApprovedElement();

    for (const element of data) {
      if (element.freightify_id == approvedQuoteElement?.freightify_id) {
        selectedquote = element;
        break;
      }
    }
    if (selectedquote) {
      setselectedQuote(selectedquote);
    }
    setapprovedQuoteDetails(approvedQuoteElement);
  };
  const quoteDetailsLengthGreaterFn = () => {
    let value = getProfitMarginNew(
      quotationDetails?.results?.quote_details[0]
    );
    if (Object.keys(selectedTempQuoteId || {}).length == 0) {
      setselectedQuote(quotationDetails?.results?.quote_details[0]);
    }
    setfirstTimeProfitMargin(value);
  };
  const grossTotalThroughConversionLogic=(quote)=>{
    // return ""
    if(Object.keys(asideBarQuotePrices||{}).length>0){
      return asideBarQuotePrices[quote?.freightify_id]?.amount_usd?.toFixed(2);
    }else{
      return ""
    }
  }
  useEffect(() => {
    if (checkIf()) {
      if (checkApprovedElement()) {
        setapprovedQuoteFn();
        return;
      }
      if (quotationDetails?.results?.quote_details?.length > 0) {
        quoteDetailsLengthGreaterFn();
        
      }
    }
  }, [Object.keys(quotationDetails?.results || {})?.length]);
  useEffect(() => {
    if(mounted) return;
    if (Object.keys(quotationDetails?.results || {})?.length>0) {
      // console.log()
        allAiseBarPrices(quotationDetails?.results?.quote_details,setasideBarQuotePrices,quotationDetails?.quote_form["container_type"]);
        setmounted(true)
    }
  }, [Object.keys(quotationDetails?.results || {})?.length]);

console.log(asideBarQuotePrices,"setasideBarQuotePrices....")
  
  console.log(selectedQuote, "selected quote");
  return (
    <div className="aside_bar">
      <aside>
        <div
          className="w-100 py-3 d-flex align-items-center"
          style={{ paddingLeft: "30px" }}
        >
          <button
            type="button"
            className="back-btn"
            onClick={() => {
              if (editSave) {
                return;
              }
              setclkData([]);
              navigate("/filter");
            }}
            disabled={editSave}
          >
            <svg className="icon me-2 fs-22">
              <use href="#icon_leftarrow"></use>
            </svg>
          </button>
          <strong className="fs-20">
            {quotationDetails?.quote_form?.opportunity_name}
          </strong>
        </div>
        <ul className="pb-5" style={{ paddingBottom: "50px" }}>
          {checkQuoteDetailsDisplay() &&
            quotationDetails?.results?.quote_details?.map(
              (quote, quote_index) => (
                <li key={quote?.id}>
                  <div className="d-flex ">
                    <div style={getStylesInQuoteDisplay("main", quote)}>
                      <div className="d-flex">
                        <button
                          type="button"
                          className="txt-btn fw-bold"
                          style={getStylesInQuoteDisplay("button", quote)}
                          onClick={() => {
                            if (editSave) return;
                            if (selectedQuote?.id == quote?.id) return;
                            setselectedQuotePriceDetails([]);
                            setselectedQuote(quote);
                          }}
                        >
                          Quote {quote_index + 1}&nbsp;
                        </button>
                        {quote?.id == selectedQuote?.id && !editSave && (
                          <span
                            onClick={() => {
                              handlePrint();
                            }}
                            className=""
                          >
                            <img width="27" height="18" src={download_icon} />
                          </span>
                        )}
                      </div>
                      <div style={getStylesInQuoteDisplay("button", quote)}>
                        {/* ${grossTotal(quote?.quote_price_details)} USD */}
                        ${grossTotalThroughConversionLogic(quote)} USD 
                      </div>
                      <div style={getStylesInQuoteDisplay("button", quote)}>
                        {quote?.price_type == "self" && (
                          <>{quote?.service_type}</>
                        )}
                      </div>
                    </div>
                    {checkApprovedFn(quote) ? (
                      <button type="button" className="btn-green  ms-auto">
                        Approved
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn-green  ms-auto"
                        disabled={checkDisability(quote)}
                        onClick={() => {
                          if (!quotationDetails?.quote_form.custom_task_id) {
                            handleShowApproveCheck();
                            return;
                          }
                          setselectedQuote(quote);
                          handleShow();
                        }}
                      >
                        Approve
                      </button>
                    )}
                  </div>
                  <div
                    style={{
                      color: quote?.id == selectedQuote?.id && "#e2b211",
                    }}
                  >
                    {quote?.carrier_name} {quote?.quote_type&&`(${quote?.quote_type})`}
                    <span>
                      {quote?.remark && (
                        <button
                          type="button"
                          className="p-0 m-0 border-0"
                          style={{
                            background: "none",
                          }}
                          onMouseEnter={() => {
                            setremark(quote);
                            handleShowIcon();
                          }}
                        >
                          <svg className="icon fs-18">
                            <use href="#icon_info"></use>
                          </svg>
                        </button>
                      )}
                    </span>
                  </div>
                  {checkDisplayPercentageNew(quote) &&
                    quote.quote_status == 1 && (
                      <div
                        style={{
                          color: "red",
                        }}
                      >
                        {getProfitMarginNew(quote)?.toFixed(2)} %
                      </div>
                    )}
                </li>
              )
            )}
        </ul>
      </aside>
      <Modal show={show} onHide={handleClose} animation={true} centered>
        <Modal.Body>
          <h3>Are you sure you want to Approve this Quote ? </h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          {!approvedFetched ? (
            <div className="mx-4">
              <Loader />
            </div>
          ) : (
            <Button variant="primary" onClick={callApproveFn}>
              Approve
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal
        show={showApproveCheck}
        onHide={handleCloseApproveCheck}
        animation={true}
        centered
      >
        <Modal.Body>
          <h3>
            Custom Quote ID is not generated yet, please try after few minutes
          </h3>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={showIcon}
        onHide={handleCloseIcon}
        animation={true}
        centered
        // className="tracking-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div>Remark</div>
            <div>{remark?.carrier_name}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>
            <div
              style={{ textAlign: "justify" }}
              dangerouslySetInnerHTML={{
                __html: remark?.remark,
              }}
            />
          </h3>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default AsideBar;
