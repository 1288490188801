import NavBar from "../components/NavBar";
import AsideBar from "../components/AsideBar";
import Wrapper from "./Wrapper";

function LayoutOutlet() {
    return(<>
        <AsideBar></AsideBar>
        <NavBar ></NavBar>
        <Wrapper/>
    </>)
}
export default LayoutOutlet;