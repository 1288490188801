import React, { useEffect, useState } from "react";
import { Container, Row, Col, Navbar, Modal } from "react-bootstrap";
import logo from "../images/logo.png";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../components/Loader";
import Ckeditor from "../components/Ckeditor";

function Pricing() {
  const params = useParams();
  const { quote_id, task_id, trade } = params;
  const [quotationDetails, setquotationDetails] = useState([]);
  const [selectedQuote, setselectedQuote] = useState({});
  const [selectedQuotePriceDetails, setselectedQuotePriceDetails] = useState(
    []
  );
  const [selectedTempQuoteId, setselectedTempQuoteId] = useState({});

  const [firstTimeProfitMargin, setfirstTimeProfitMargin] = useState(null);

  const [editSave, setEditSave] = useState(false);
  const [showCustomerComments, setshowCustomerComments] = useState(false);
  const handleCloseCustomerComments = () => setshowCustomerComments(false);
  const handleShowCustomerComments = () => setshowCustomerComments(true);

  const [selectedDate, setselectedDate] = useState("");
  // Modal
  const [rateTypeCodes, setrateTypeCodes] = useState([]);

  const navigate = useNavigate();
  const [commentData, setCommentData] = useState("");

  const [conatinerTypes, setconatinerTypes] = useState([]);
  const [airObject, setairObject] = useState({
    weight_per_pallet: "",
    gross_weight_tons: "",
    volume_weight: "",
    route_via: "",
    transit_time_in_days: "",
    air_service: "",
  });
  const [grossTotalCurrencies, setgrossTotalCurrencies] = useState({
    USD: {
      other: "",
      other_original: "",
      "20GP": "",
      "40GP": "",
    },
    EUR: {
      other: "",
      other_original: "",
      "20GP": "",
      "40GP": "",
    },
    GBP: {
      other: "",
      other_original: "",
      "20GP": "",
      "40GP": "",
    },
    CAD: {
      other: "",
      other_original: "",
      "20GP": "",
      "40GP": "",
    },
    AUD: {
      other: "",
      other_original: "",
      "20GP": "",
      "40GP": "",
    },
    JPY: {
      other: "",
      other_original: "",
      "20GP": "",
      "40GP": "",
    },
    INR: {
      other: "",
      other_original: "",
      "20GP": "",
      "40GP": "",
    },
    NZD: {
      other: "",
      other_original: "",
      "20GP": "",
      "40GP": "",
    },
    CHF: {
      other: "",
      other_original: "",
      "20GP": "",
      "40GP": "",
    },
    ZAR: {
      other: "",
      other_original: "",
      "20GP": "",
      "40GP": "",
    },
    RUB: {
      other: "",
      other_original: "",
      "20GP": "",
      "40GP": "",
    },
    CNY: {
      other: "",
      other_original: "",
      "20GP": "",
      "40GP": "",
    },
    MXN: {
      other: "",
      other_original: "",
      "20GP": "",
      "40GP": "",
    },
    BRL: {
      other: "",
      other_original: "",
      "20GP": "",
      "40GP": "",
    },
  });
  const [grossTotalCurrenciesIndidual, setgrossTotalCurrenciesIndividual] =
    useState({});
  const finalRatesMarkUp = (rate_type_code) => {
    let name = rate_type_code?.toUpperCase();
    if (name?.includes("FREIGHT")) {
      return getAirRates(rate_type_code, "amount_usd")?.toFixed(2);
    } else if (name?.includes("ORIGIN")) {
      return getAirRates(rate_type_code, "amount_usd")?.toFixed(2);
    } else if (name?.includes("DESTINATION")) {
      return getAirRates(rate_type_code, "amount_usd")?.toFixed(2);
    } else if (name?.includes("ADDON")) {
      return getAirRates(rate_type_code, "amount_usd")?.toFixed(2);
    }
  };
  const getFormattedDateToday = () => {
    const date = new Date();

    const day = date.getDate();
    const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
      date
    );
    const year = date.getFullYear().toString().slice(-2);

    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  };
  const getFormattedDateTodayValidTo = (date1) => {
    const date = new Date(date1);

    const day = date.getDate();
    const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
      date
    );
    const year = date.getFullYear().toString().slice(-2);

    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  };

  const getQuotationDetails1 = async () => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/pricing/${quote_id}/${task_id}/${trade}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.data.error) {
        setquotationDetails(response.data);
        setselectedQuote(response?.data?.results?.quote_details);
        console.log(response.data, "sudhir sir  data");
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log(selectedTempQuoteId, "quote temp selected");
  const getCargoMesurements = (type) => {
    let value = quotationDetails?.quote_form?.cargo_measurements;
    if (!value) return "";
    let splitMeasurements = value?.split("X");
    let measurement = {};
    measurement["length"] = splitMeasurements[0];
    measurement["width"] = splitMeasurements[1];
    measurement["height"] = splitMeasurements[2];
    return measurement[type];
  };

  const getFrieghtRates = (rate_type_code, cont) => {
    let allRrieghtDetails = selectedQuotePriceDetails?.filter((quote) => {
      if (cont == "other") {
        return quote?.rate_type_code?.includes(rate_type_code);
      } else {
        return (
          quote?.rate_type_code?.includes(rate_type_code) &&
          quote?.container_type == cont
        );
      }
    });
    let sum = 0;

    for (const element of allRrieghtDetails) {
      if (element?.amount_usd) {
        sum += parseFloat(element?.amount_usd);
      }
    }
    return sum;
  };
  const getFinalRatesAirMarkUp = () => {
    return (
      getAirRates("FREIGHT", "amount_usd") +
      getAirRates("ORIGIN", "amount_usd") +
      getAirRates("DESTINATION", "amount_usd") +
      getAirRates("ADDON", "amount_usd")
    );
  };
  const getBlockCurrency = (rate_type_code, cont) => {
    const getforCondition = (element) => {
      if (cont && cont != "other") {
        return (
          element?.rate_type_code === rate_type_code &&
          element?.container_type === cont
        );
      } else {
        return element?.rate_type_code === rate_type_code;
      }
    };
    for (let i = 0; i < selectedQuotePriceDetails.length; i++) {
      const element = selectedQuotePriceDetails[i];
      if (getforCondition(element)) {
        return element?.rate_currency;
      }
    }
    return "";
  };
  const getTruckRates = (rate_type_code, cont, key) => {
    let allRrieghtDetails = selectedQuotePriceDetails?.filter((quote) => {
      if (cont == "other") {
        return quote?.rate_type_code?.includes(rate_type_code);
      } else {
        return (
          quote?.rate_type_code?.includes(rate_type_code) &&
          quote?.container_type == cont
        );
      }
    });
    let sum = 0;

    for (const element of allRrieghtDetails) {
      if (element[key]) {
        sum += parseFloat(element[key]);
      }
    }
    return sum;
  };
  const grossTotal = (cont) => {
    let sum = 0;
    let array = [];
    if (cont != "other") {
      array = selectedQuotePriceDetails?.filter((item) => {
        return item?.container_type == cont;
      });
    } else {
      array = selectedQuotePriceDetails;
    }

    for (const element of array) {
      sum += element?.amount_usd ? parseFloat(element?.amount_usd) : 0;
    }
    return parseFloat(sum).toFixed(2);
  };
  // ist part table values
  const getValue = (req_key) => {
    if (Object.keys(quotationDetails || {}).length == 0) {
      return;
    }
    return quotationDetails?.quote_form[req_key];
  };

  const getPickUpData = () => {
    let data = "";
    data += getValue("pickup_address1") && getValue("pickup_address1") + ", ";
    data += getValue("pickup_address2") && getValue("pickup_address2") + ", ";
    data += getValue("pickup_city") && getValue("pickup_city") + ", ";
    data += getValue("pickup_state") && getValue("pickup_state") + ", ";
    data += getValue("pickup_zip_code") ? getValue("pickup_zip_code") : "";
    return data;
  };
  const getDestinationData = () => {
    let data = "";
    data +=
      getValue("delivery_address1") && getValue("delivery_address1") + ", ";
    data +=
      getValue("delivery_address2") && getValue("delivery_address2") + ", ";
    data += getValue("delivery_city") && getValue("delivery_city") + ", ";
    data += getValue("delivery_state") && getValue("delivery_state") + ", ";
    data += getValue("delivery_zip_code") ? getValue("delivery_zip_code") : "";
    return data;
  };
  // modify rates
  const modifyRates = (e, quote) => {
    if (!editSave) {
      return;
    }
    setselectedQuotePriceDetails((prev) => {
      return prev?.map((item) => {
        if (item?.id == quote.id) {
          return { ...item, amount_usd: e.target.value };
        } else {
          return item;
        }
      });
    });
  };
  useEffect(() => {
    getQuotationDetails1();
  }, []);

  console.log(firstTimeProfitMargin, "first time profit margin");
  function capitalizeFirstLetter(str) {
    if (!str) {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  const inputFn = (value, custom = "normal") => {
    if (!value) return "";
    let val = custom == "normal" ? getValue(value) : value;
    return (
      <input
        type="text"
        value={val}
        style={{
          border: "none",
          outline: "none",
          background: "none",
        }}
      ></input>
    );
  };
  const checkSelectedQuoteLength = () => {
    return Object.keys(selectedQuote || {}).length > 0;
  };

  const inputFieldValue = (value, else_value) => {
    return (
      <input
        type="text"
        value={value || else_value}
        style={{
          border: "none",
          outline: "none",
          background: "none",
        }}
      ></input>
    );
  };

  useEffect(() => {
    if (
      Object.keys(quotationDetails || {}).length > 0 &&
      Object.keys(selectedQuote || {}).length > 0
    ) {
      if (getValue("container_type") == "20GP + 40GP") {
        let containertypes = [];
        for (let i = 0; i < selectedQuote?.quote_price_details?.length; i++) {
          const element = selectedQuote?.quote_price_details[i]?.container_type;
          if (element && element != "" && !containertypes?.includes(element)) {
            containertypes.push(element);
          }
        }
        setconatinerTypes(containertypes);
      }
    }
  }, [
    Object.keys(quotationDetails || {}).length,
    Object.keys(selectedQuote || {}).length,
  ]);

  const getPrice = (rate_type_code) => {
    let sumAmountUsd = getAirRates(rate_type_code, "amount_usd");
    let sumrateUsd = getAirRates(rate_type_code, "rate_usd");
    if (parseInt(sumAmountUsd) == 0 && parseInt(sumrateUsd) == 0) {
      return 0;
    } else {
      return 1;
    }
  };
  useEffect(() => {
    if (Object.keys(selectedQuote || {}).length > 0) {
      let quote_prices = selectedQuote?.quote_price_details;
      let rate_type_codes = [];
      for (const element of quote_prices) {
        if (!rate_type_codes.includes(element?.rate_type_code)) {
          rate_type_codes.push(element?.rate_type_code);
        }
      }
      if (quotationDetails?.quote_form?.product_type_name == "Air") {
        let rate_type_codes_modified = [];
        for (let i = 0; i < rate_type_codes.length; i++) {
          const element = rate_type_codes[i];
          let priceOfRateTypeCode = getPrice(element);
          if (priceOfRateTypeCode != 0) {
            rate_type_codes_modified.push(element);
          }
        }
        setrateTypeCodes(rate_type_codes_modified);
      } else {
        setrateTypeCodes(rate_type_codes);
      }

      setselectedDate(selectedQuote?.valid_to ? selectedQuote?.valid_to : "");
      setselectedQuotePriceDetails(selectedQuote?.quote_price_details);
    }
  }, [selectedQuote, quotationDetails]);
  console.log(rateTypeCodes, "rate type codes");

  console.log(selectedQuote, "selected quote");
  const getMappingFn = () => {
    if (getValue("container_type") == "20GP + 40GP") {
      return ["20GP", "40GP"];
    } else {
      return ["other"];
    }
  };
  const grossTotalTruck = () => {
    let sum = 0;
    let array = [];

    array = selectedQuotePriceDetails;

    for (const element of array) {
      sum += element?.actual_amount_usd
        ? parseFloat(element?.actual_amount_usd)
        : 0;
    }
    return parseFloat(sum).toFixed(2);
  };
  const grossTotalOriginalTruck = () => {
    let sum = 0;
    let array = [];

    array = selectedQuotePriceDetails;

    for (const element of array) {
      sum += element?.amount_usd ? parseFloat(element?.amount_usd) : 0;
    }
    return parseFloat(sum).toFixed(2);
  };
  const checkOriginAddressDisplay = () => {
    let service_type = getValue("service_type");
    let split = service_type?.split(" ");
    return split[0] == "Door";
  };
  const checkDestinationAddressDisplay = () => {
    let service_type = getValue("service_type");
    let split = service_type?.split(" ");
    return split[2] == "Door";
  };
  const getAirRates = (rate_type_code, type) => {
    let allRrieghtDetails = selectedQuote?.quote_price_details?.filter(
      (quote) => {
        return quote?.rate_type_code?.includes(rate_type_code);
      }
    );
    let sum = 0;

    for (const element of allRrieghtDetails) {
      if (element[type]) {
        sum += parseFloat(element[type]);
      }
    }
    return sum;
  };
  const grossTotalAir = (type) => {
    let sum = 0;
    let array = [];

    array = selectedQuotePriceDetails;

    for (const element of array) {
      sum += element[type] ? parseFloat(element[type]) : 0;
    }
    return parseFloat(sum).toFixed(2);
  };
  const getDifferentCurrencies = (cont) => {
    let currencies = ["USD"];
    let quotePriceDetails = [];
    if (cont && cont != "other") {
      quotePriceDetails = selectedQuotePriceDetails?.filter(
        (item) => item?.container_type === cont
      );
    } else {
      quotePriceDetails = selectedQuotePriceDetails;
    }
    for (let i = 0; i < quotePriceDetails.length; i++) {
      const element = quotePriceDetails[i];
      if (!currencies?.includes(element.rate_currency)) {
        currencies.push(element?.rate_currency);
      }
    }
    return currencies;
  };

  const calculatePrice = async () => {
    let usd_sum = 0;
    let usd_sum_original = 0;
    let usd_sum_20gp = 0;
    let usd_sum_40gp = 0;
    let usd_sum_20gp_original = 0;
    let usd_sum_40gp_original = 0;

    let obj_individual = {};
    let obj = {};

    if (selectedQuote?.quote_status == 2) {
      let blockPrices = selectedQuote?.blockprices;
      if (blockPrices?.length > 0) {
        let blockPricesGround = blockPrices?.filter(
          (item) => item?.rate_type_code == "GROUND_TOTAL"
        );
        if (quotationDetails?.quote_form?.container_type == "20GP + 40GP") {
          for (let i = 0; i < blockPricesGround.length; i++) {
            const element = blockPricesGround[i];
            if (element.container_type == "20GP") {
              obj[element?.rate_currency] = {
                ...obj[element?.rate_currency],
                other: element?.amount_usd,
                other_original: element?.actual_amount_usd,
                "20GP": element?.amount_usd,
                "20GP_original": element?.actual_amount_usd,
              };
            } else if (element.container_type == "40GP") {
              obj[element?.rate_currency] = {
                ...obj[element?.rate_currency],
                other: element?.amount_usd,
                other_original: element?.actual_amount_usd,
                "40GP": element?.amount_usd,
                "40GP_original": element?.actual_amount_usd,
              };
            }
          }
        } else {
          for (let i = 0; i < blockPricesGround.length; i++) {
            const element = blockPricesGround[i];
            obj[element?.rate_currency] = {
              other: element?.amount_usd,
              other_original: element?.actual_amount_usd,
              "20GP": "",
              "40GP": "",
            };
          }
        }
        let blockPricesUp = blockPrices?.filter(
          (item) => item?.rate_type_code != "GROUND_TOTAL"
        );
        if (quotationDetails?.quote_form?.container_type == "20GP + 40GP") {
          for (let i = 0; i < blockPricesUp.length; i++) {
            const element = blockPricesUp[i];
            if (element.container_type == "20GP") {
              obj_individual[element?.rate_type_code] = {
                ...obj_individual[element?.rate_type_code],
                other: element?.amount_usd,
                other_original: element?.actual_amount_usd,
                rate_currency: element?.rate_currency,
                "20GP": element?.amount_usd,
                "20GP_original": element?.actual_amount_usd,
              };
            } else {
              obj_individual[element?.rate_type_code] = {
                ...obj_individual[element?.rate_type_code],
                other: element?.amount_usd,
                other_original: element?.actual_amount_usd,
                rate_currency: element?.rate_currency,
                "40GP": element?.amount_usd,
                "40GP_original": element?.actual_amount_usd,
              };
            }
          }
        } else {
          for (let i = 0; i < blockPricesUp.length; i++) {
            const element = blockPricesUp[i];

            obj_individual[element?.rate_type_code] = {
              other: element?.amount_usd,
              other_original: element?.actual_amount_usd,
              rate_currency: element?.rate_currency,
              actual_amount_usd: "",
              amount_usd: "",
            };
          }
        }
      }
    }

    setgrossTotalCurrencies({
      ...grossTotalCurrencies,
      ...obj,
    });
    setgrossTotalCurrenciesIndividual({
      ...grossTotalCurrenciesIndidual,
      ...obj_individual,
    });
  };
  useEffect(() => {
    if (Object.keys(selectedQuote || {})?.length > 0) {
      calculatePrice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQuote]);
  console.log(grossTotalCurrencies, "gross total currencies");
  const grossTotalDisplay = (cont, currency) => {
    let value = parseFloat(grossTotalCurrencies[currency][cont] || 0);
    return value.toFixed(2);
  };
  const getIndividualRates = (rate_type_code, cont) => {
    if (
      Object.keys(grossTotalCurrenciesIndidual || {}).length == 0 ||
      !grossTotalCurrenciesIndidual[rate_type_code?.toUpperCase()]?.other
    ) {
      return "0.00";
    }
    let value;
    if (cont == "20GP") {
      value = parseFloat(
        grossTotalCurrenciesIndidual[rate_type_code?.toUpperCase()]["20GP"] || 0
      );
    } else if (cont == "40GP") {
      value = parseFloat(
        grossTotalCurrenciesIndidual[rate_type_code?.toUpperCase()]["40GP"] || 0
      );
    } else {
      value = parseFloat(
        grossTotalCurrenciesIndidual[rate_type_code?.toUpperCase()]?.other || 0
      );
    }
    return value.toFixed(2);
  };
  console.log(
    grossTotalCurrenciesIndidual,
    "grossTotalCurrenciesIndidual...email"
  );

  return (
    <>
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand href="#">
            <img src={logo} style={{ width: "140px", height: "52px" }}></img>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
        </Container>
      </Navbar>
      <Container fluid className="mt-5 ">
        <div style={{ maxWidth: "1000px", margin: "0 auto" }} className="w-100">
          {checkSelectedQuoteLength() && (
            <Row>
              <Col className="col-12 py-3">
                <div
                  style={{
                    width: "100%",
                    background: "#ffff",
                    padding: "20px 10px",
                  }}
                >
                  <table width="100%" style={{ borderCollapse: "collapse" }}>
                    <tbody>
                      <tr style={{ background: "#002981", color: "#ffff" }}>
                        <td style={{ width: "100%", textAlign: "center" }}>
                          <h4 className="py-2">
                            Ocean Freight Import Quotation
                          </h4>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table width="100%" style={{ borderCollapse: "collapse" }}>
                    <tbody>
                      <tr>
                        <td colSpan={3} style={{ width: "60%" }}>
                          <img
                            src={logo}
                            style={{ width: "180px", height: "60px" }}
                          />
                          <h4 className="pt-2" style={{ color: "#0c1a57" }}>
                            MTM Logix, S.A. de C. V
                          </h4>
                          <p className="py-2">
                            Palmas 525, Lomas de Chapultepec
                          </p>
                          <p className="pb-1">C.P 11000 Miguel Hidalgo</p>
                        </td>
                        <td style={{ width: "40%", margin: "0", padding: "0" }}>
                          <div
                            style={{
                              display: "inline-block",
                              paddingLeft: "10px",
                              paddingRight: "10px",
                              background: "#002981",
                              color: "#ffff",
                            }}
                          >
                            {[
                              "Quote Number",
                              "Date",
                              "Validity",
                              "Pricing Analyst",
                              "Contact",
                              "Sales Person",
                              "Contact",
                            ].map((item, index) => (
                              <div key={item}>
                                <input
                                  type="text"
                                  value={item}
                                  style={{
                                    border: "none",
                                    outline: "none",
                                    background: "none",
                                    color: "white",
                                  }}
                                ></input>
                              </div>
                            ))}
                            {quotationDetails?.quote_form?.product_type_name ==
                              "Air" && (
                              <div>
                                <input
                                  type="text"
                                  value="CBM"
                                  style={{
                                    border: "none",
                                    outline: "none",
                                    background: "none",
                                    color: "white",
                                  }}
                                ></input>
                              </div>
                            )}
                          </div>
                          <div
                            style={{
                              display: "inline-block",
                            }}
                          >
                            <div>
                              <input
                                type="text"
                                value={
                                  quotationDetails?.quote_form?.custom_task_id
                                }
                                style={{
                                  border: "none",
                                  outline: "none",
                                  background: "none",
                                }}
                              ></input>
                            </div>
                            <div>
                              <input
                                type="text"
                                value={getFormattedDateToday()}
                                style={{
                                  border: "none",
                                  outline: "none",
                                  background: "none",
                                }}
                              ></input>
                            </div>
                            <div>
                              <input
                                type="text"
                                value={getFormattedDateTodayValidTo(
                                  selectedQuote?.valid_to
                                )}
                                style={{
                                  border: "none",
                                  outline: "none",
                                  background: "none",
                                }}
                              ></input>
                            </div>

                            <div>
                              {inputFieldValue(
                                quotationDetails?.quote_form?.analyst_name,
                                " - "
                              )}
                            </div>
                            <div>
                              {inputFieldValue(
                                quotationDetails?.quote_form?.analyst_email,
                                " - "
                              )}
                            </div>
                            <div>
                              {inputFieldValue(
                                quotationDetails?.quote_form?.contact_person,
                                " - "
                              )}
                            </div>

                            <div>
                              {quotationDetails?.quote_form
                                ?.contact_person_email ? (
                                <a
                                  href={`mailto:${quotationDetails?.quote_form?.contact_person_email}`}
                                >
                                  {
                                    quotationDetails?.quote_form
                                      ?.contact_person_email
                                  }
                                </a>
                              ) : (
                                <input
                                  value={" - "}
                                  style={{
                                    border: "none",
                                    outline: "none",
                                    background: "none",
                                  }}
                                ></input>
                              )}
                            </div>
                            {quotationDetails?.quote_form?.product_type_name ==
                              "Air" && (
                              <div>
                                {inputFieldValue(
                                  quotationDetails?.quote_form?.cbm,
                                  " - "
                                )}
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table width="100%" style={{ borderCollapse: "collapse" }}>
                    <tbody>
                      <tr>
                        <td>
                          <div
                            style={{
                              textAlign: "center",
                              display: "inline-block",
                              background: "#002981",
                              color: "#ffff",
                              padding: "10px 40px",
                              margin: "0",
                            }}
                          >
                            <h5 style={{ padding: "0", margin: "0" }}>
                              Customer Name
                            </h5>
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              display: "inline-block",
                              background: "#f6f5e3",
                              padding: "10px 40px",
                              margin: "0",
                            }}
                          >
                            <h5 style={{ padding: "0", margin: "0" }}>
                              {quotationDetails?.quote_form?.opportunity_name}
                            </h5>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="py-3">
                            <strong>Dear Customer:</strong> We are grateful for
                            your request and for trusting us to move your cargo
                            around the world .
                            <strong>Thanks for working with us!</strong>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            textAlign: "center",
                            background: "#002981",
                            color: "#ffff",
                            padding: "5px",
                          }}
                        >
                          <p style={{ padding: "0", margin: "0" }}>
                            Shipment Information
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  {!quotationDetails?.quote_form?.product_type_name?.includes(
                    "TRUCK"
                  ) && (
                    <>
                      <table
                        width="100%"
                        style={{ borderCollapse: "collapse" }}
                      >
                        <tbody>
                          <tr
                            style={{
                              width: "100%",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <td
                              style={{
                                width: "20%",
                                paddingLeft: "5px",
                                background: "#e8e8e8",
                                borderLeft: "1px solid #92a9bc",
                                borderRight: "1px solid #92a9bc",
                              }}
                            >
                              <p>Service</p>
                            </td>

                            <td colSpan={2} style={{ width: "32%" }}>
                              <div>{inputFn("product_type_name")}</div>
                            </td>

                            <td
                              style={{
                                width: "16%",
                                background: "#e8e8e8",
                                paddingLeft: "5px",
                              }}
                            >
                              <p>Modal Type</p>
                            </td>
                            <td colSpan={2} style={{ width: "32px" }}>
                              <div>
                                <div>{inputFn("service_type")}</div>
                              </div>
                            </td>
                          </tr>
                          <tr
                            style={{
                              width: "100%",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <td
                              style={{
                                width: "20%",
                                paddingLeft: "5px",
                                background: "#e8e8e8",
                                borderLeft: "1px solid #92a9bc",
                                borderRight: "1px solid #92a9bc",
                              }}
                            >
                              <p>Origin Country</p>
                            </td>
                            <td colSpan={2} style={{ width: "32%" }}>
                              <div>{inputFn("origin_country")}</div>
                            </td>

                            <td
                              style={{
                                width: "16%",
                                background: "#e8e8e8",
                                paddingLeft: "5px",
                              }}
                            >
                              <p>Destination Country</p>
                            </td>
                            <td colSpan={2} style={{ width: "32px" }}>
                              <div>
                                <input
                                  type="text"
                                  value={getValue("destination_country")}
                                  style={{
                                    border: "none",
                                    outline: "none",
                                    background: "none",
                                  }}
                                ></input>
                              </div>
                            </td>
                          </tr>
                          <tr
                            style={{
                              width: "100%",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <td
                              style={{
                                width: "20%",
                                paddingLeft: "5px",
                                background: "#e8e8e8",
                                borderLeft: "1px solid #92a9bc",
                                borderRight: "1px solid #92a9bc",
                              }}
                            >
                              {checkOriginAddressDisplay() && (
                                <p>Origin Address</p>
                              )}
                            </td>
                            <td colSpan={2} style={{ width: "32%" }}>
                              {checkOriginAddressDisplay() && (
                                <div style={{ paddingLeft: "2px" }}>
                                  {getPickUpData()}
                                </div>
                              )}
                            </td>

                            <td
                              style={{
                                width: "16%",
                                background: "#e8e8e8",
                                paddingLeft: "5px",
                              }}
                            >
                              {checkDestinationAddressDisplay() && (
                                <p>Destination Address</p>
                              )}
                            </td>
                            <td colSpan={2} style={{ width: "32px" }}>
                              {checkDestinationAddressDisplay() && (
                                <div style={{ paddingLeft: "2px" }}>
                                  {getDestinationData()}
                                </div>
                              )}
                            </td>
                          </tr>
                          <tr
                            style={{
                              width: "100%",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <td
                              style={{
                                width: "20%",
                                paddingLeft: "5px",
                                background: "#e8e8e8",
                                borderLeft: "1px solid #92a9bc",
                                borderRight: "1px solid #92a9bc",
                              }}
                            >
                              <p>Origin Port</p>
                            </td>
                            <td colSpan={2} style={{ width: "32%" }}>
                              <div>{getValue("loading_port_name")}</div>
                            </td>

                            <td
                              style={{
                                width: "16%",
                                background: "#e8e8e8",
                                paddingLeft: "5px",
                              }}
                            >
                              <p>Destination Port</p>
                            </td>
                            <td colSpan={2} style={{ width: "32px" }}>
                              <div>{getValue("discharge_port_name")}</div>
                            </td>
                          </tr>
                          <tr
                            style={{
                              width: "100%",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <td
                              style={{
                                width: "20%",
                                paddingLeft: "5px",
                                background: "#e8e8e8",
                                borderLeft: "1px solid #92a9bc",
                                borderRight: "1px solid #92a9bc",
                              }}
                            >
                              <p>Transit Time</p>
                            </td>
                            <td colSpan={2} style={{ width: "32%" }}>
                              <div>
                                <input
                                  type="text"
                                  id="transit_time_in_days"
                                  value={selectedQuote?.transit_time_in_days}
                                  style={{
                                    border: "none",
                                    outline: "none",
                                    background: "none",
                                  }}
                                ></input>
                              </div>
                            </td>

                            <td
                              style={{
                                width: "16%",
                                background: "#e8e8e8",
                                paddingLeft: "5px",
                              }}
                            >
                              {quotationDetails?.quote_form
                                ?.product_type_name == "Air" && (
                                <p>Stackable Cargo</p>
                              )}
                            </td>
                            <td colSpan={2} style={{ width: "32px" }}>
                              {quotationDetails?.quote_form
                                ?.product_type_name == "Air" && (
                                <div>
                                  <input
                                    type="text"
                                    value={
                                      quotationDetails?.quote_form
                                        ?.stackable_cargo
                                    }
                                    style={{
                                      border: "none",
                                      outline: "none",
                                      background: "none",
                                    }}
                                  ></input>
                                </div>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table
                        width="100%"
                        style={{ borderCollapse: "collapse" }}
                      >
                        <tbody>
                          <tr
                            style={{
                              width: "100%",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <td
                              colSpan={6}
                              style={{
                                textAlign: "center",
                                background: "#002981",
                                color: "#ffff",
                                padding: "5px",
                              }}
                            >
                              <p style={{ padding: "0", margin: "0" }}>
                                Cargo Information
                              </p>
                            </td>
                          </tr>
                          <tr
                            style={{
                              width: "100%",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <td
                              style={{
                                width: "20%",
                                paddingLeft: "5px",
                                background: "#e8e8e8",
                                borderLeft: "1px solid #92a9bc",
                                borderRight: "1px solid #92a9bc",
                              }}
                            >
                              <p>Commodity Description</p>
                            </td>

                            <td colSpan={2} style={{ width: "32%" }}>
                              <div>
                                <input
                                  type="text"
                                  id="cargo_description"
                                  value={
                                    quotationDetails?.quote_form
                                      ?.cargo_description
                                  }
                                  style={{
                                    border: "none",
                                    outline: "none",
                                    background: "none",
                                  }}
                                ></input>
                              </div>
                            </td>

                            <td
                              style={{
                                width: "16%",
                                background: "#e8e8e8",
                                paddingLeft: "5px",
                              }}
                            >
                              {quotationDetails?.quote_form
                                ?.product_type_name == "Air" ? (
                                <p>General cargo</p>
                              ) : (
                                <p>Container Type</p>
                              )}
                            </td>
                            <td colSpan={2} style={{ width: "32px" }}>
                              <div>
                                <input
                                  type="text"
                                  value={
                                    quotationDetails?.quote_form
                                      ?.product_type_name != "Air"
                                      ? getValue("container_type")
                                      : getValue("cargo_type")
                                  }
                                  style={{
                                    border: "none",
                                    outline: "none",
                                    background: "none",
                                  }}
                                ></input>
                              </div>
                            </td>
                          </tr>
                          <tr
                            style={{
                              width: "100%",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <td
                              style={{
                                width: "20%",
                                paddingLeft: "5px",
                                background: "#e8e8e8",
                                borderLeft: "1px solid #92a9bc",
                                borderRight: "1px solid #92a9bc",
                              }}
                            >
                              <p>Customs Clearance</p>
                            </td>

                            <td colSpan={2} style={{ width: "32%" }}>
                              <div>{inputFn("customer_clearance")}</div>
                            </td>

                            <td
                              style={{
                                width: "16%",
                                background: "#e8e8e8",
                                paddingLeft: "5px",
                              }}
                            >
                              {quotationDetails?.quote_form
                                ?.product_type_name == "Air" ? (
                                <p>Air Service</p>
                              ) : (
                                <p>Free Days</p>
                              )}
                            </td>
                            <td colSpan={2} style={{ width: "32px" }}>
                              {quotationDetails?.quote_form
                                ?.product_type_name == "Air" ? (
                                <div>
                                  <input
                                    type="text"
                                    id="air_service"
                                    value={selectedQuote?.air_service}
                                    style={{
                                      border: "none",
                                      outline: "none",
                                      background: "none",
                                    }}
                                  ></input>
                                </div>
                              ) : (
                                <p className="text-decoration-underline">
                                  {selectedQuote?.free_days}
                                  {/* days at destination */}
                                </p>
                              )}
                            </td>
                          </tr>
                          <tr
                            style={{
                              width: "100%",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <td
                              style={{
                                width: "20%",
                                paddingLeft: "5px",
                                background: "#e8e8e8",
                                borderLeft: "1px solid #92a9bc",
                                borderRight: "1px solid #92a9bc",
                              }}
                            >
                              <p>
                                {quotationDetails?.quote_form
                                  ?.product_type_name == "Air" && "Via"}
                              </p>
                            </td>

                            <td colSpan={2} style={{ width: "32%" }}>
                              <div>
                                <input
                                  type="text"
                                  value={selectedQuote?.route_via}
                                  id="route_via"
                                  style={{
                                    border: "none",
                                    outline: "none",
                                    background: "none",
                                  }}
                                ></input>
                              </div>
                            </td>

                            <td
                              style={{
                                width: "16%",
                                background: "#e8e8e8",
                                paddingLeft: "5px",
                              }}
                            >
                              <p>Insurance</p>
                            </td>
                            <td colSpan={2} style={{ width: "32px" }}>
                              <span>
                                <strong>{getValue("require_insurance")}</strong>
                              </span>
                              {getValue("require_insurance")?.toLowerCase() ==
                                "yes" && (
                                <span
                                  style={{
                                    background: "#e8e8e8",
                                    padding: "5px 50px 5px 10px",
                                    marginLeft: "50px",
                                  }}
                                >
                                  {getValue(
                                    "require_insurance"
                                  )?.toLowerCase() == "yes" &&
                                    getValue("incase_insurance_required")}
                                </span>
                              )}
                            </td>
                          </tr>
                          {quotationDetails?.quote_form?.no_of_qty && (
                            <tr
                              style={{
                                width: "100%",
                                borderRight: "1px solid #92a9bc",
                              }}
                            >
                              <td
                                style={{
                                  width: "20%",
                                  paddingLeft: "5px",
                                  background: "#e8e8e8",
                                  borderLeft: "1px solid #92a9bc",
                                  borderRight: "1px solid #92a9bc",
                                }}
                              >
                                <p>Number of units</p>
                              </td>

                              <td colSpan={2} style={{ width: "32%" }}>
                                <div>
                                  <input
                                    type="number"
                                    value={
                                      quotationDetails?.quote_form?.no_of_qty
                                        ? quotationDetails?.quote_form
                                            ?.no_of_qty
                                        : ""
                                    }
                                    id="route_via"
                                    style={{
                                      border: "none",
                                      outline: "none",
                                      background: "none",
                                    }}
                                    disabled={!editSave}
                                    onChange={(e) => {
                                      // setno_of_qty(e.target.value);
                                      setquotationDetails({
                                        ...quotationDetails,
                                        quote_form: {
                                          ...quotationDetails?.quote_form,
                                          no_of_qty: e.target.value,
                                        },
                                      });
                                    }}
                                  ></input>
                                </div>
                              </td>

                              <td
                                style={{
                                  width: "16%",
                                  background: "#e8e8e8",
                                  paddingLeft: "5px",
                                }}
                              >
                                {quotationDetails?.quote_form?.container_type ==
                                "20GP + 40GP" ? (
                                  <>
                                    <p className="py-2">Cost per unit (20GP)</p>
                                    <p>Cost per unit (40GP)</p>
                                  </>
                                ) : (
                                  <p>Cost per unit</p>
                                )}
                              </td>
                              <td colSpan={2} style={{ width: "32px" }}>
                                {quotationDetails?.quote_form?.container_type ==
                                "20GP + 40GP" ? (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: "5px",
                                      }}
                                      className="py-2"
                                    >
                                      {quotationDetails?.quote_form
                                        ?.no_of_qty &&
                                        quotationDetails?.quote_form
                                          ?.no_of_qty != "" && (
                                          <>
                                            {getDifferentCurrencies(
                                              "20GP"
                                            )?.map((currency, index, arr) => (
                                              <div>
                                                <span>
                                                  {(
                                                    grossTotalCurrencies[
                                                      currency
                                                    ]["20GP"] /
                                                    parseInt(
                                                      quotationDetails
                                                        ?.quote_form
                                                        ?.no_of_qty || 1
                                                    )
                                                  )?.toFixed(2) || ""}
                                                </span>
                                                <span
                                                  className="fw-bold"
                                                  style={{ paddingLeft: "2px" }}
                                                >
                                                  ({currency})
                                                </span>
                                                <span>
                                                  {index !== arr.length - 1 &&
                                                    ","}
                                                </span>
                                              </div>
                                            ))}
                                          </>
                                        )}
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: "5px",
                                      }}
                                      // className="py-2"
                                    >
                                      {quotationDetails?.quote_form
                                        ?.no_of_qty &&
                                        quotationDetails?.quote_form
                                          ?.no_of_qty != "" && (
                                          <>
                                            {getDifferentCurrencies(
                                              "40GP"
                                            )?.map((currency, index, arr) => (
                                              <div>
                                                <span>
                                                  {(
                                                    grossTotalCurrencies[
                                                      currency
                                                    ]["40GP"] /
                                                    parseInt(
                                                      quotationDetails
                                                        ?.quote_form
                                                        ?.no_of_qty || 1
                                                    )
                                                  )?.toFixed(2) || ""}
                                                </span>
                                                <span
                                                  className="fw-bold"
                                                  style={{ paddingLeft: "2px" }}
                                                >
                                                  ({currency})
                                                </span>
                                                <span>
                                                  {index !== arr.length - 1 &&
                                                    ","}
                                                </span>
                                              </div>
                                            ))}
                                          </>
                                        )}
                                    </div>
                                  </>
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: "5px",
                                    }}
                                  >
                                    {quotationDetails?.quote_form?.no_of_qty &&
                                      quotationDetails?.quote_form?.no_of_qty !=
                                        "" && (
                                        <>
                                          {getDifferentCurrencies()?.map(
                                            (currency, index, arr) => (
                                              <div>
                                                <span>
                                                  {(
                                                    grossTotalCurrencies[
                                                      currency
                                                    ]["other"] /
                                                    parseInt(
                                                      quotationDetails
                                                        ?.quote_form
                                                        ?.no_of_qty || 1
                                                    )
                                                  )?.toFixed(2) || ""}
                                                </span>
                                                <span
                                                  className="fw-bold"
                                                  style={{ paddingLeft: "2px" }}
                                                >
                                                  ({currency})
                                                </span>
                                                <span>
                                                  {index !== arr.length - 1 &&
                                                    ","}
                                                </span>
                                              </div>
                                            )
                                          )}
                                        </>
                                      )}
                                  </div>
                                )}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </>
                  )}
                  {quotationDetails?.quote_form?.product_type_name?.includes(
                    "TRUCK"
                  ) && (
                    <>
                      <table
                        width="100%"
                        style={{ borderCollapse: "collapse" }}
                      >
                        <tbody>
                          <tr
                            style={{
                              width: "100%",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <td
                              style={{
                                width: "20%",
                                paddingLeft: "5px",
                                background: "#e8e8e8",
                                borderLeft: "1px solid #92a9bc",
                                borderRight: "1px solid #92a9bc",
                              }}
                            >
                              <p>Pickup Address</p>
                            </td>

                            <td colSpan={2} style={{ width: "32%" }}>
                              <div>{getPickUpData()}</div>
                            </td>

                            <td
                              style={{
                                width: "16%",
                                background: "#e8e8e8",
                                paddingLeft: "5px",
                              }}
                            >
                              <p>Destination Address</p>
                            </td>
                            <td colSpan={2} style={{ width: "32px" }}>
                              <div>
                                <div>{getDestinationData()}</div>
                              </div>
                            </td>
                          </tr>
                          <tr
                            style={{
                              width: "100%",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <td
                              style={{
                                width: "20%",
                                paddingLeft: "5px",
                                background: "#e8e8e8",
                                borderLeft: "1px solid #92a9bc",
                                borderRight: "1px solid #92a9bc",
                              }}
                            >
                              <p>Service Type</p>
                            </td>
                            <td colSpan={2} style={{ width: "32%" }}>
                              <div>{inputFn("product_type_name")}</div>
                            </td>

                            <td
                              style={{
                                width: "16%",
                                background: "#e8e8e8",
                                paddingLeft: "5px",
                              }}
                            >
                              <p>Crossing Border</p>
                            </td>
                            <td colSpan={2} style={{ width: "32px" }}>
                              <div>
                                <input
                                  type="text"
                                  id="border"
                                  value={selectedQuote?.border}
                                  style={{
                                    border: "none",
                                    outline: "none",
                                    background: "none",
                                  }}
                                ></input>
                              </div>
                            </td>
                          </tr>
                          <tr
                            style={{
                              width: "100%",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <td
                              style={{
                                width: "20%",
                                paddingLeft: "5px",
                                background: "#e8e8e8",
                                borderLeft: "1px solid #92a9bc",
                                borderRight: "1px solid #92a9bc",
                              }}
                            >
                              <p>Transit Time</p>
                            </td>
                            <td colSpan={2} style={{ width: "32%" }}>
                              <p>
                                <input
                                  type="text"
                                  id="transit_time_in_days"
                                  value={selectedQuote?.transit_time_in_days}
                                  style={{
                                    border: "none",
                                    outline: "none",
                                    background: "none",
                                  }}
                                ></input>
                              </p>
                            </td>

                            <td
                              style={{
                                width: "16%",
                                background: "#e8e8e8",
                                paddingLeft: "5px",
                              }}
                            >
                              <p>Transport</p>
                            </td>
                            <td colSpan={2} style={{ width: "32px" }}>
                              <input
                                type="text"
                                id="transporter"
                                value={selectedQuote?.transporter}
                                style={{
                                  border: "none",
                                  outline: "none",
                                  background: "none",
                                }}
                              ></input>
                            </td>
                          </tr>

                          {/* <tr
                          style={{
                            width: "100%",
                            borderRight: "1px solid #92a9bc",
                          }}
                        >
                          <td
                            style={{
                              width: "20%",
                              paddingLeft: "5px",
                              background: "#e8e8e8",
                              borderLeft: "1px solid #92a9bc",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <p>Transit Time</p>
                          </td>
                          <td colSpan={2} style={{ width: "32%" }}>
                            <div>
                              <input
                                type="text"
                                id="transit_time_in_days"
                                value={airObject?.transit_time_in_days}
                                style={{
                                  border: "none",
                                  outline: "none",
                                  background: "none",
                                }}
                                onChange={(e)=>{
                                  handleAirObject(e);
                                }}
                              ></input>
                            </div>
                          </td>

                          <td
                            style={{
                              width: "16%",
                              background: "#e8e8e8",
                              paddingLeft: "5px",
                            }}
                          >
                            <p></p>
                          </td>
                          <td colSpan={2} style={{ width: "32px" }}></td>
                        </tr> */}
                        </tbody>
                      </table>

                      <table
                        width="100%"
                        style={{ borderCollapse: "collapse" }}
                      >
                        <tbody>
                          <tr
                            style={{
                              width: "100%",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <td
                              colSpan={6}
                              style={{
                                textAlign: "center",
                                background: "#002981",
                                color: "#ffff",
                                padding: "5px",
                              }}
                            >
                              <p style={{ padding: "0", margin: "0" }}>
                                Cargo Information
                              </p>
                            </td>
                          </tr>
                          <tr
                            style={{
                              width: "100%",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <td
                              style={{
                                width: "20%",
                                paddingLeft: "5px",
                                background: "#e8e8e8",
                                borderLeft: "1px solid #92a9bc",
                                borderRight: "1px solid #92a9bc",
                              }}
                            >
                              <p>Commodity Description</p>
                            </td>

                            <td colSpan={2} style={{ width: "32%" }}>
                              <div>
                                <input
                                  type="text"
                                  id="cargo_description"
                                  value={
                                    quotationDetails?.quote_form
                                      ?.cargo_description
                                  }
                                  style={{
                                    border: "none",
                                    outline: "none",
                                    background: "none",
                                  }}
                                ></input>
                              </div>
                            </td>

                            <td
                              style={{
                                width: "16%",
                                background: "#e8e8e8",
                                paddingLeft: "5px",
                              }}
                            >
                              Dangerous Goods
                            </td>
                            <td colSpan={2} style={{ width: "32px" }}>
                              {quotationDetails?.quote_form?.dangerous_goods}
                            </td>
                          </tr>
                          <tr
                            style={{
                              width: "100%",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <td
                              style={{
                                width: "20%",
                                paddingLeft: "5px",
                                background: "#e8e8e8",
                                borderLeft: "1px solid #92a9bc",
                                borderRight: "1px solid #92a9bc",
                              }}
                            >
                              <p>Stackable</p>
                            </td>

                            <td colSpan={2} style={{ width: "32%" }}>
                              <div>
                                {quotationDetails?.quote_form?.stackable_cargo}
                              </div>
                            </td>

                            <td
                              style={{
                                width: "16%",
                                background: "#e8e8e8",
                                paddingLeft: "5px",
                              }}
                            >
                              {" "}
                              <p>Insurance</p>
                            </td>
                            <td colSpan={2} style={{ width: "32px" }}>
                              {quotationDetails?.quote_form?.require_insurance}
                            </td>
                          </tr>
                          {quotationDetails?.quote_form?.no_of_qty && (
                            <tr
                              style={{
                                width: "100%",
                                borderRight: "1px solid #92a9bc",
                              }}
                            >
                              <td
                                style={{
                                  width: "20%",
                                  paddingLeft: "5px",
                                  background: "#e8e8e8",
                                  borderLeft: "1px solid #92a9bc",
                                  borderRight: "1px solid #92a9bc",
                                }}
                              >
                                <p>Number of units</p>
                              </td>

                              <td colSpan={2} style={{ width: "32%" }}>
                                <div>
                                  <input
                                    type="number"
                                    value={
                                      quotationDetails?.quote_form?.no_of_qty
                                        ? quotationDetails?.quote_form
                                            ?.no_of_qty
                                        : ""
                                    }
                                    id="route_via"
                                    style={{
                                      border: "none",
                                      outline: "none",
                                      background: "none",
                                    }}
                                    disabled={!editSave}
                                    onChange={(e) => {
                                      // setno_of_qty(e.target.value);
                                      setquotationDetails({
                                        ...quotationDetails,
                                        quote_form: {
                                          ...quotationDetails?.quote_form,
                                          no_of_qty: e.target.value,
                                        },
                                      });
                                    }}
                                  ></input>
                                </div>
                              </td>

                              <td
                                style={{
                                  width: "16%",
                                  background: "#e8e8e8",
                                  paddingLeft: "5px",
                                }}
                              >
                                {quotationDetails?.quote_form?.container_type ==
                                "20GP + 40GP" ? (
                                  <>
                                    <p className="py-2">Cost per unit (20GP)</p>
                                    <p>Cost per unit (40GP)</p>
                                  </>
                                ) : (
                                  <p>Cost per unit</p>
                                )}
                              </td>
                              <td colSpan={2} style={{ width: "32px" }}>
                                {quotationDetails?.quote_form?.container_type ==
                                "20GP + 40GP" ? (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: "5px",
                                      }}
                                      className="py-2"
                                    >
                                      {quotationDetails?.quote_form
                                        ?.no_of_qty &&
                                        quotationDetails?.quote_form
                                          ?.no_of_qty != "" && (
                                          <>
                                            {getDifferentCurrencies(
                                              "20GP"
                                            )?.map((currency, index, arr) => (
                                              <div>
                                                <span>
                                                  {(
                                                    grossTotalCurrencies[
                                                      currency
                                                    ]["20GP"] /
                                                    parseInt(
                                                      quotationDetails
                                                        ?.quote_form
                                                        ?.no_of_qty || 1
                                                    )
                                                  )?.toFixed(2) || ""}
                                                </span>
                                                <span
                                                  className="fw-bold"
                                                  style={{ paddingLeft: "2px" }}
                                                >
                                                  ({currency})
                                                </span>
                                                <span>
                                                  {index !== arr.length - 1 &&
                                                    ","}
                                                </span>
                                              </div>
                                            ))}
                                          </>
                                        )}
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: "5px",
                                      }}
                                      // className="py-2"
                                    >
                                      {quotationDetails?.quote_form
                                        ?.no_of_qty &&
                                        quotationDetails?.quote_form
                                          ?.no_of_qty != "" && (
                                          <>
                                            {getDifferentCurrencies(
                                              "40GP"
                                            )?.map((currency, index, arr) => (
                                              <div>
                                                <span>
                                                  {(
                                                    grossTotalCurrencies[
                                                      currency
                                                    ]["40GP"] /
                                                    parseInt(
                                                      quotationDetails
                                                        ?.quote_form
                                                        ?.no_of_qty || 1
                                                    )
                                                  )?.toFixed(2) || ""}
                                                </span>
                                                <span
                                                  className="fw-bold"
                                                  style={{ paddingLeft: "2px" }}
                                                >
                                                  ({currency})
                                                </span>
                                                <span>
                                                  {index !== arr.length - 1 &&
                                                    ","}
                                                </span>
                                              </div>
                                            ))}
                                          </>
                                        )}
                                    </div>
                                  </>
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: "5px",
                                    }}
                                  >
                                    {quotationDetails?.quote_form?.no_of_qty &&
                                      quotationDetails?.quote_form?.no_of_qty !=
                                        "" && (
                                        <>
                                          {getDifferentCurrencies()?.map(
                                            (currency, index, arr) => (
                                              <div>
                                                <span>
                                                  {(
                                                    grossTotalCurrencies[
                                                      currency
                                                    ]["other"] /
                                                    parseInt(
                                                      quotationDetails
                                                        ?.quote_form
                                                        ?.no_of_qty || 1
                                                    )
                                                  )?.toFixed(2) || ""}
                                                </span>
                                                <span
                                                  className="fw-bold"
                                                  style={{ paddingLeft: "2px" }}
                                                >
                                                  ({currency})
                                                </span>
                                                <span>
                                                  {index !== arr.length - 1 &&
                                                    ","}
                                                </span>
                                              </div>
                                            )
                                          )}
                                        </>
                                      )}
                                  </div>
                                )}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </>
                  )}
                  {quotationDetails?.quote_form?.product_type_name == "Air" && (
                    <div className="d-flex ">
                      <table
                        width="80%"
                        style={{ borderCollapse: "collapse" }}
                        className="air_case mt-3 me-3"
                      >
                        <tr>
                          <th style={{ background: "#e8e8e8" }}>
                            {quotationDetails?.quote_form?.pallet_description}
                          </th>
                          <th style={{ background: "#e8e8e8" }}>Length CM</th>
                          <th style={{ background: "#e8e8e8" }}>Width CM</th>
                          <th style={{ background: "#e8e8e8" }}>Height CM</th>
                          <th style={{ background: "#e8e8e8" }}>
                            Weight Per Pallet in Kg
                          </th>
                          <th style={{ background: "#e8e8e8" }}>
                            Gross Weight
                          </th>
                          <th style={{ background: "#e8e8e8" }}>
                            Volume Weight
                          </th>
                        </tr>
                        <tr>
                          <td>{quotationDetails?.quote_form?.no_of_pallet}</td>
                          <td>{getCargoMesurements("length")}</td>
                          <td>{getCargoMesurements("width")}</td>
                          <td>{getCargoMesurements("height")}</td>
                          <td>
                            <input
                              id="weight_per_pallet"
                              value={quotationDetails?.quote_form?.total_weight}
                              style={{
                                border: "none",
                                outline: "none",
                                background: "none",
                                textAlign: "center",
                              }}
                            ></input>
                          </td>
                          <td>
                            <input
                              id="gross_weight_tons"
                              value={quotationDetails?.quote_form?.gross_weight}
                              style={{
                                border: "none",
                                outline: "none",
                                background: "none",
                                textAlign: "center",
                              }}
                            ></input>
                          </td>
                          <td>
                            <input
                              id="volume_weight"
                              value={quotationDetails?.quote_form?.total_volume}
                              style={{
                                border: "none",
                                outline: "none",
                                background: "none",
                                textAlign: "center",
                              }}
                            ></input>
                          </td>
                          {/* <span> +1000 KG</span> */}
                        </tr>
                      </table>
                      <table
                        style={{ borderCollapse: "collapse" }}
                        className="air_case mt-3"
                      >
                        <tr>
                          {" "}
                          <th style={{ background: "#e8e8e8" }}>Basis Quote</th>
                        </tr>
                        <tr>
                          <td style={{ background: "#002981", color: "#ffff" }}>
                            {Math.max(
                              parseFloat(
                                quotationDetails?.quote_form?.total_volume
                              ) || 0,
                              parseFloat(
                                quotationDetails?.quote_form?.gross_weight
                              ) || 0
                            ) || ""}
                          </td>
                        </tr>
                      </table>
                    </div>
                  )}
                  {/* Part Two */}
                  {/* In case of air */}
                  {quotationDetails?.quote_form?.product_type_name == "Air" && (
                    <Row>
                      <Col md={8}>
                        <div>
                          <table style={{ borderCollapse: "collapse" }}>
                            <tbody>
                              <tr>
                                <td colSpan={6} className="">
                                  <p>&nbsp;</p>
                                </td>
                              </tr>
                              {rateTypeCodes?.map(
                                (rate_type_code, rate_type_code_index) => (
                                  <>
                                    <tr key={rate_type_code}>
                                      <td
                                        style={{
                                          background: "#002981",
                                          color: "#ffff",
                                          borderBottom: "4px solid #e9b217",
                                          padding: "5px 0",
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          alignContent: "center",
                                        }}
                                      >
                                        <div></div>
                                        <div>
                                          {rate_type_code?.toUpperCase()} RATES
                                        </div>
                                        <div>
                                          {editSave && (
                                            <div
                                              style={{
                                                cursor: "pointer",
                                                paddingRight: "5px",
                                              }}
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-plus-circle-fill"
                                                viewBox="0 0 16 16"
                                              >
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                              </svg>
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                    </tr>

                                    <tr>
                                      {rate_type_code_index == 0 && (
                                        <div className="w-100">
                                          <div
                                            style={{
                                              display: "inline-block",
                                              width: "40%",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            CONCEPT
                                          </div>

                                          <div
                                            className="table-div"
                                            style={{
                                              display: "inline-block",
                                              fontWeight: "bold",
                                              width: "20%",
                                            }}
                                          >
                                            CURRENCY
                                          </div>

                                          <div
                                            className="table-div"
                                            style={{
                                              display: "inline-block",
                                              fontWeight: "bold",
                                              width: "20%",
                                            }}
                                          >
                                            RATE PER KG
                                          </div>

                                          <div
                                            className="table-div"
                                            style={{
                                              display: "inline-block",
                                              fontWeight: "bold",
                                              width: "20%",
                                            }}
                                          >
                                            AMOUNT
                                          </div>
                                        </div>
                                      )}
                                      {/* Freight */}
                                      {rate_type_code?.toUpperCase() ==
                                        "FREIGHT" && (
                                        <>
                                          {selectedQuotePriceDetails
                                            ?.filter((quote) => {
                                              return (
                                                quote?.rate_type_code?.includes(
                                                  rate_type_code
                                                ) &&
                                                (parseInt(quote?.amount_usd) ||
                                                  0) != 0 &&
                                                (parseInt(quote?.rate_usd) ||
                                                  0) != 0
                                              );
                                            })
                                            ?.map((quote, quote_index) => (
                                              <div
                                                className="w-100"
                                                key={quote?.description}
                                              >
                                                <div
                                                  style={{
                                                    display: "inline-block",
                                                    width: "40%",
                                                  }}
                                                >
                                                  {quote?.description}
                                                </div>
                                                <div
                                                  className="table-div"
                                                  style={{
                                                    display: "inline-block",
                                                    width: "20%",
                                                  }}
                                                >
                                                  <input
                                                    type="text"
                                                    value={
                                                      quote?.rate_currency
                                                        ? quote?.rate_currency
                                                        : ""
                                                    }
                                                    style={{
                                                      border: "none",
                                                      outline: "none",
                                                      background: "none",
                                                    }}
                                                  ></input>
                                                </div>

                                                <div
                                                  className="table-div"
                                                  style={{
                                                    display: "inline-block",

                                                    width: "20%",
                                                  }}
                                                >
                                                  {quote?.rate_usd
                                                    ? quote?.rate_usd
                                                    : ""}
                                                </div>

                                                <div
                                                  className="table-div"
                                                  style={{
                                                    display: "inline-block",
                                                    width: "20%",
                                                  }}
                                                >
                                                  {quote?.amount_usd
                                                    ? quote?.amount_usd
                                                    : ""}
                                                </div>
                                              </div>
                                            ))}
                                        </>
                                      )}
                                      {/* origin */}
                                      {rate_type_code?.toUpperCase() ==
                                        "ORIGIN" && (
                                        <>
                                          {selectedQuotePriceDetails
                                            ?.filter((quote) => {
                                              return (
                                                quote?.rate_type_code?.includes(
                                                  rate_type_code
                                                ) &&
                                                (parseInt(quote?.amount_usd) ||
                                                  0) != 0 &&
                                                (parseInt(quote?.rate_usd) ||
                                                  0) != 0
                                              );
                                            })
                                            ?.map((quote, quote_index) => (
                                              <div
                                                className="w-100"
                                                key={quote?.description}
                                              >
                                                <div
                                                  style={{
                                                    display: "inline-block",
                                                    width: "35%",
                                                  }}
                                                >
                                                  {quote?.description}
                                                </div>
                                                <div
                                                  className="table-div"
                                                  style={{
                                                    display: "inline-block",
                                                    width: "12%",
                                                  }}
                                                >
                                                  <input
                                                    type="text"
                                                    value={
                                                      quote?.rate_currency
                                                        ? quote?.rate_currency
                                                        : ""
                                                    }
                                                    style={{
                                                      border: "none",
                                                      outline: "none",
                                                      background: "none",
                                                    }}
                                                  ></input>
                                                </div>

                                                <div
                                                  className="table-div"
                                                  style={{
                                                    display: "inline-block",

                                                    width: "12%",
                                                  }}
                                                >
                                                  {quote?.rate_usd
                                                    ? quote?.rate_usd
                                                    : ""}
                                                </div>
                                                <div
                                                  className="table-div"
                                                  style={{
                                                    display: "inline-block",

                                                    width: "25%",
                                                  }}
                                                >
                                                  {quote?.rate_description
                                                    ? quote?.rate_description
                                                    : ""}
                                                </div>

                                                <div
                                                  className="table-div"
                                                  style={{
                                                    display: "inline-block",
                                                    width: "12%",
                                                  }}
                                                >
                                                  {quote?.amount_usd
                                                    ? quote?.amount_usd
                                                    : ""}
                                                </div>
                                              </div>
                                            ))}
                                        </>
                                      )}
                                      {/* destination */}
                                      {rate_type_code?.toUpperCase() ==
                                        "DESTINATION" && (
                                        <>
                                          {selectedQuotePriceDetails
                                            ?.filter((quote) => {
                                              return (
                                                quote?.rate_type_code?.includes(
                                                  rate_type_code
                                                ) &&
                                                (parseInt(quote?.amount_usd) ||
                                                  0) != 0
                                              );
                                            })
                                            ?.map((quote, quote_index) => (
                                              <div
                                                className="w-100"
                                                key={quote?.description}
                                              >
                                                <div
                                                  style={{
                                                    display: "inline-block",
                                                    width: "35%",
                                                  }}
                                                >
                                                  {quote?.description}
                                                </div>
                                                <div
                                                  className="table-div"
                                                  style={{
                                                    display: "inline-block",
                                                    width: "12%",
                                                  }}
                                                >
                                                  <input
                                                    type="text"
                                                    value={
                                                      quote?.rate_currency
                                                        ? quote?.rate_currency
                                                        : ""
                                                    }
                                                    style={{
                                                      border: "none",
                                                      outline: "none",
                                                      background: "none",
                                                    }}
                                                  ></input>
                                                </div>

                                                <div
                                                  className="table-div"
                                                  style={{
                                                    display: "inline-block",

                                                    width: "12%",
                                                  }}
                                                >
                                                  {quote?.amount_usd
                                                    ? quote?.amount_usd
                                                    : ""}
                                                </div>
                                              </div>
                                            ))}
                                        </>
                                      )}
                                      {rate_type_code?.toUpperCase() ==
                                        "ADDON" && (
                                        <>
                                          {selectedQuotePriceDetails
                                            ?.filter((quote) => {
                                              return (
                                                quote?.rate_type_code?.includes(
                                                  rate_type_code
                                                ) &&
                                                (parseInt(quote?.amount_usd) ||
                                                  0) != 0
                                              );
                                            })
                                            ?.map((quote, quote_index) => (
                                              <div
                                                className="w-100"
                                                key={quote?.description}
                                              >
                                                <div
                                                  style={{
                                                    display: "inline-block",
                                                    width: "35%",
                                                  }}
                                                >
                                                  {quote?.description}
                                                </div>
                                                <div
                                                  className="table-div"
                                                  style={{
                                                    display: "inline-block",
                                                    width: "12%",
                                                  }}
                                                >
                                                  <input
                                                    type="text"
                                                    value={
                                                      quote?.rate_currency
                                                        ? quote?.rate_currency
                                                        : ""
                                                    }
                                                    style={{
                                                      border: "none",
                                                      outline: "none",
                                                      background: "none",
                                                    }}
                                                  ></input>
                                                </div>

                                                <div
                                                  className="table-div"
                                                  style={{
                                                    display: "inline-block",

                                                    width: "12%",
                                                  }}
                                                >
                                                  {quote?.amount_usd
                                                    ? quote?.amount_usd
                                                    : ""}
                                                </div>
                                              </div>
                                            ))}
                                        </>
                                      )}

                                      {/* Freight */}
                                      {rate_type_code?.toUpperCase() ==
                                        "FREIGHT" && (
                                        <div className="w-100 bg-gray">
                                          <div
                                            style={{
                                              display: "inline-block",
                                              width: "40%",
                                            }}
                                          >
                                            <p className="p-0 m-0">
                                              Total{" "}
                                              {capitalizeFirstLetter(
                                                rate_type_code
                                              )}{" "}
                                              Rates
                                            </p>
                                          </div>
                                          <div
                                            className="table-div"
                                            style={{
                                              display: "inline-block",
                                              width: "20%",
                                            }}
                                          >
                                            <input
                                              type="text"
                                              value={getBlockCurrency(
                                                rate_type_code
                                              )}
                                              style={{
                                                border: "none",
                                                outline: "none",
                                                background: "none",
                                              }}
                                            ></input>
                                          </div>

                                          <div
                                            className="table-div"
                                            style={{
                                              width: "20%",
                                              display: "inline-block",
                                            }}
                                          >
                                            <input
                                              type="text"
                                              value={getAirRates(
                                                rate_type_code,
                                                "rate_usd"
                                              )?.toFixed(2)}
                                              style={{
                                                border: "none",
                                                outline: "none",
                                                background: "none",
                                              }}
                                            ></input>
                                          </div>

                                          <div
                                            className="table-div"
                                            style={{
                                              width: "20%",
                                              display: "inline-block",
                                            }}
                                          >
                                            <input
                                              type="text"
                                              value={getAirRates(
                                                rate_type_code,
                                                "amount_usd"
                                              )?.toFixed(2)}
                                              style={{
                                                border: "none",
                                                outline: "none",
                                                background: "none",
                                              }}
                                            ></input>
                                          </div>
                                        </div>
                                      )}
                                      {/* origin */}
                                      {rate_type_code?.toUpperCase() ==
                                        "ORIGIN" && (
                                        <div className="w-100 bg-gray">
                                          <div
                                            style={{
                                              display: "inline-block",
                                              width: "35%",
                                            }}
                                          >
                                            <p className="p-0 m-0">
                                              Total{" "}
                                              {capitalizeFirstLetter(
                                                rate_type_code
                                              )}{" "}
                                              Rates
                                            </p>
                                          </div>
                                          <div
                                            className="table-div"
                                            style={{
                                              display: "inline-block",
                                              width: "12%",
                                            }}
                                          >
                                            <input
                                              type="text"
                                              value={getBlockCurrency(
                                                rate_type_code
                                              )}
                                              style={{
                                                border: "none",
                                                outline: "none",
                                                background: "none",
                                              }}
                                            ></input>
                                          </div>

                                          <div
                                            className="table-div"
                                            style={{
                                              width: "12%",
                                              display: "inline-block",
                                            }}
                                          >
                                            <input
                                              type="text"
                                              value={getAirRates(
                                                rate_type_code,
                                                "rate_usd"
                                              )?.toFixed(2)}
                                              style={{
                                                border: "none",
                                                outline: "none",
                                                background: "none",
                                              }}
                                            ></input>
                                          </div>
                                          <div
                                            className="table-div"
                                            style={{
                                              display: "inline-block",

                                              width: "25%",
                                            }}
                                          ></div>
                                          <div
                                            className="table-div"
                                            style={{
                                              width: "12%",
                                              display: "inline-block",
                                            }}
                                          >
                                            <input
                                              type="text"
                                              value={getAirRates(
                                                rate_type_code,
                                                "amount_usd"
                                              )?.toFixed(2)}
                                              style={{
                                                border: "none",
                                                outline: "none",
                                                background: "none",
                                              }}
                                            ></input>
                                          </div>
                                        </div>
                                      )}
                                      {/* destination */}
                                      {rate_type_code?.toUpperCase() ==
                                        "DESTINATION" && (
                                        <div className="w-100 bg-gray">
                                          <div
                                            style={{
                                              display: "inline-block",
                                              width: "35%",
                                            }}
                                          >
                                            <p className="p-0 m-0">
                                              Total{" "}
                                              {capitalizeFirstLetter(
                                                rate_type_code
                                              )}{" "}
                                              Rates
                                            </p>
                                          </div>
                                          <div
                                            className="table-div"
                                            style={{
                                              display: "inline-block",
                                              width: "12%",
                                            }}
                                          >
                                            <input
                                              type="text"
                                              value={getBlockCurrency(
                                                rate_type_code
                                              )}
                                              style={{
                                                border: "none",
                                                outline: "none",
                                                background: "none",
                                              }}
                                            ></input>
                                          </div>

                                          <div
                                            className="table-div"
                                            style={{
                                              width: "12%",
                                              display: "inline-block",
                                            }}
                                          >
                                            <input
                                              type="text"
                                              value={getAirRates(
                                                rate_type_code,
                                                "amount_usd"
                                              )?.toFixed(2)}
                                              style={{
                                                border: "none",
                                                outline: "none",
                                                background: "none",
                                              }}
                                            ></input>
                                          </div>
                                        </div>
                                      )}
                                      {rate_type_code?.toUpperCase() ==
                                        "ADDON" && (
                                        <div className="w-100 bg-gray">
                                          <div
                                            style={{
                                              display: "inline-block",
                                              width: "35%",
                                            }}
                                          >
                                            <p className="p-0 m-0">
                                              Total{" "}
                                              {capitalizeFirstLetter(
                                                rate_type_code
                                              )}{" "}
                                              Rates
                                            </p>
                                          </div>
                                          <div
                                            className="table-div"
                                            style={{
                                              display: "inline-block",
                                              width: "12%",
                                            }}
                                          >
                                            <input
                                              type="text"
                                              value={getBlockCurrency(
                                                rate_type_code
                                              )}
                                              style={{
                                                border: "none",
                                                outline: "none",
                                                background: "none",
                                              }}
                                            ></input>
                                          </div>

                                          <div
                                            className="table-div"
                                            style={{
                                              width: "12%",
                                              display: "inline-block",
                                            }}
                                          >
                                            <input
                                              type="text"
                                              value={getAirRates(
                                                rate_type_code,
                                                "amount_usd"
                                              )?.toFixed(2)}
                                              style={{
                                                border: "none",
                                                outline: "none",
                                                background: "none",
                                              }}
                                            ></input>
                                          </div>
                                        </div>
                                      )}
                                    </tr>
                                  </>
                                )
                              )}

                              {/* total */}
                              <tr className="w-100">
                                <td
                                  style={{
                                    textAlign: "center",
                                    background: "#002981",
                                    color: "#ffff",
                                    borderTop: "4px solid #e9b217",
                                    padding: "5px 0",
                                  }}
                                >
                                  TOTAL
                                </td>
                              </tr>
                              <tr className="w-100">
                                <td>
                                  <div className="w-100">
                                    <div
                                      className="bg-gray"
                                      style={{
                                        display: "inline-block",
                                        width: "45%",
                                      }}
                                    >
                                      {rateTypeCodes?.map(
                                        (
                                          rate_type_code,
                                          rate_type_code_index
                                        ) => (
                                          <div key={rate_type_code}>
                                            <p className="p-0 m-0">
                                              {capitalizeFirstLetter(
                                                rate_type_code
                                              )}
                                              Rates
                                            </p>
                                          </div>
                                        )
                                      )}
                                    </div>

                                    <div
                                      className="table-div"
                                      style={{
                                        display: "inline-block",
                                        width: "25%",
                                      }}
                                    >
                                      {rateTypeCodes?.map(
                                        (
                                          rate_type_code,
                                          rate_type_code_index
                                        ) => (
                                          <div key={rate_type_code}>
                                            <input
                                              type="text"
                                              // value={finalRatesMarkUp(
                                              //   rate_type_code
                                              // )}
                                              value={getIndividualRates(
                                                rate_type_code,
                                                "other"
                                              )}
                                              style={{
                                                border: "none",
                                                outline: "none",
                                                background: "none",
                                              }}
                                            ></input>
                                          </div>
                                        )
                                      )}
                                    </div>
                                    <div
                                      className="table-div"
                                      style={{
                                        display: "inline-block",
                                        width: "15%",
                                      }}
                                    >
                                      {rateTypeCodes?.map(
                                        (
                                          rate_type_code,
                                          rate_type_code_index
                                        ) => (
                                          <div key={rate_type_code}>
                                            <input
                                              type="text"
                                              value="USD"
                                              style={{
                                                border: "none",
                                                outline: "none",
                                                background: "none",
                                              }}
                                            ></input>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              {getDifferentCurrencies()?.map(
                                (currency, index, arr) => (
                                  <tr className="w-100">
                                    <td
                                      style={{
                                        background: "#002981",
                                        color: "#ffff",
                                        borderBottom: "4px solid #e9b217",
                                        padding: "5px 0",
                                      }}
                                    >
                                      <div className="w-100">
                                        <div
                                          style={{
                                            display: "inline-block",
                                            width: "45%",
                                          }}
                                        >
                                          <p>GROSS TOTAL {currency}</p>
                                        </div>

                                        <div
                                          className="table-div"
                                          style={{
                                            width: "25%",
                                            display: "inline-block",
                                          }}
                                        >
                                          <p>
                                            {grossTotalDisplay(
                                              "other",
                                              currency
                                            )}
                                          </p>
                                        </div>

                                        <div
                                          className="table-div"
                                          style={{
                                            width: "20%",
                                            display: "inline-block",
                                          }}
                                        >
                                          <p>{currency}</p>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Col>

                      <Col md={4} className="mt-4">
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            marginTop: "2px",
                          }}
                        >
                          {editSave && (
                            <button
                              type="button"
                              className="px-4"
                              style={{
                                background: "#0e2074",
                                border: "1px solid #0e2074",
                                color: "#ffffff",
                                borderRadius: "4px",
                                width: "max-content",
                              }}
                            >
                              Addon
                            </button>
                          )}
                          {!selectedQuote?.comment && editSave && (
                            <button
                              type="button"
                              className="px-4"
                              style={{
                                background: "#0e2074",
                                border: "1px solid #0e2074",
                                color: "#ffffff",
                                borderRadius: "4px",
                                width: "max-content",
                              }}
                            >
                              Add Comments
                            </button>
                          )}
                        </div>

                        {selectedQuote?.comment && (
                          <div>
                            <div
                              style={{
                                textAlign: "center",
                                background: "#002981",
                                color: "#ffff",
                                borderBottom: "4px solid #e9b217",
                                padding: "5px 4px",
                                width: "max-content",
                                marginTop: editSave && "10px",
                              }}
                            >
                              COMMENTS
                            </div>
                            <div
                              style={{
                                marginTop: "10px",
                              }}
                            >
                              <div
                                style={{
                                  width: "30%",
                                }}
                              >
                                <button
                                  type="button"
                                  onClick={() => {
                                    setCommentData(selectedQuote?.comment);
                                    handleShowCustomerComments();
                                  }}
                                  className="tracking-btn d-flex justify-content-center px-2 w-100"
                                >
                                  <span>View Comments</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </Col>
                      {getValue("container_type") == "20GP + 40GP" && (
                        <Col md={6} className="mt-4 d-flex flex-column">
                          <div className="mt-auto ms-auto text-right">
                            <img src={logo} alt="Logo" />
                          </div>
                        </Col>
                      )}
                    </Row>
                  )}
                  {/* In case of truck */}
                  {quotationDetails?.quote_form?.product_type_name?.includes(
                    "TRUCK"
                  ) && (
                    <Row>
                      {getMappingFn()?.map((cont, cont_index) => (
                        <Col md={8}>
                          <div className="mt-4">
                            <table style={{ borderCollapse: "collapse" }}>
                              <tbody>
                                {rateTypeCodes?.map(
                                  (rate_type_code, rate_type_code_index) => (
                                    <>
                                      <tr key={rate_type_code}>
                                        <td
                                          style={{
                                            background: "#002981",
                                            color: "#ffff",
                                            borderBottom: "4px solid #e9b217",
                                            padding: "5px 0",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            alignContent: "center",
                                          }}
                                        >
                                          <div></div>
                                          <div>
                                            {rate_type_code?.toUpperCase()}{" "}
                                            RATES
                                          </div>
                                          <div>
                                            {editSave && (
                                              <div
                                                style={{
                                                  cursor: "pointer",
                                                  paddingRight: "5px",
                                                }}
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="16"
                                                  height="16"
                                                  fill="currentColor"
                                                  class="bi bi-plus-circle-fill"
                                                  viewBox="0 0 16 16"
                                                >
                                                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                                </svg>
                                              </div>
                                            )}
                                          </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        {rate_type_code_index == 0 && (
                                          <div className="w-100">
                                            <div
                                              className="table-div"
                                              style={{
                                                display: "inline-block",
                                                fontWeight: "bold",
                                                width: "26%",
                                              }}
                                            >
                                              Concept
                                            </div>

                                            <div
                                              className="table-div"
                                              style={{
                                                display: "inline-block",
                                                fontWeight: "bold",
                                                width: "15%",
                                              }}
                                            >
                                              Currency
                                            </div>

                                            <div
                                              className="table-div"
                                              style={{
                                                display: "inline-block",
                                                fontWeight: "bold",
                                                width: "14%",
                                              }}
                                            >
                                              Amount
                                            </div>
                                            <div
                                              className="table-div"
                                              style={{
                                                display: "inline-block",
                                                fontWeight: "bold",
                                                width: "15%",
                                              }}
                                            >
                                              #Palletes
                                            </div>
                                            <div
                                              className="table-div"
                                              style={{
                                                display: "inline-block",
                                                fontWeight: "bold",
                                                width: "13.5%",
                                              }}
                                            >
                                              #Pieces
                                            </div>

                                            <div
                                              className="table-div"
                                              style={{
                                                display: "inline-block",
                                                fontWeight: "bold",
                                                width: "13%",
                                              }}
                                            >
                                              Cost per piece
                                            </div>
                                          </div>
                                        )}

                                        {selectedQuotePriceDetails
                                          ?.filter((quote) => {
                                            if (cont == "other") {
                                              return quote?.rate_type_code?.includes(
                                                rate_type_code
                                              );
                                            } else {
                                              return (
                                                quote?.rate_type_code?.includes(
                                                  rate_type_code
                                                ) &&
                                                quote?.container_type == cont
                                              );
                                            }
                                          })
                                          ?.map((quote, quote_index) => (
                                            <div
                                              className="w-100"
                                              key={quote?.description}
                                            >
                                              <div
                                                style={{
                                                  display: "inline-block",
                                                  width: "26%",
                                                }}
                                              >
                                                {quote?.description}
                                              </div>

                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  width: "15%",
                                                }}
                                              >
                                                {quote?.rate_currency}
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  width: "14%",
                                                }}
                                              >
                                                {quote?.amount_usd
                                                  ? quote?.amount_usd
                                                  : ""}
                                              </div>
                                              {rate_type_code?.toLowerCase() !=
                                                "addon" && (
                                                <>
                                                  <div
                                                    className="table-div"
                                                    style={{
                                                      display: "inline-block",
                                                      width: "15%",
                                                    }}
                                                  >
                                                    {quote?.pallet
                                                      ? quote?.pallet
                                                      : ""}
                                                  </div>
                                                  <div
                                                    className="table-div"
                                                    style={{
                                                      display: "inline-block",
                                                      width: "13%",
                                                    }}
                                                  >
                                                    {quote?.qty
                                                      ? parseInt(
                                                          quote?.qty || 0
                                                        )
                                                      : ""}
                                                  </div>

                                                  <div
                                                    className="table-div"
                                                    style={{
                                                      display: "inline-block",
                                                      width: "13%",
                                                    }}
                                                  >
                                                    {(
                                                      parseFloat(
                                                        quote?.amount_usd
                                                      ) / parseFloat(quote?.qty)
                                                    )?.toFixed(2)}
                                                  </div>
                                                </>
                                              )}

                                              {editSave && (
                                                <div
                                                  style={{
                                                    display: "inline-block",
                                                    marginLeft: "-10px",
                                                    height: "18px",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  <svg
                                                    className="icon"
                                                    style={{
                                                      height: "18px",
                                                    }}
                                                  >
                                                    <use href="#icon_edit"></use>
                                                  </svg>
                                                </div>
                                              )}
                                              {editSave && (
                                                <div
                                                  style={{
                                                    display: "inline-block",
                                                    height: "18px",
                                                    cursor: "pointer",
                                                    marginLeft: "20px",
                                                  }}
                                                >
                                                  <svg
                                                    className="icon"
                                                    style={{
                                                      height: "18px",
                                                    }}
                                                  >
                                                    <use href="#icon_delete"></use>
                                                  </svg>
                                                </div>
                                              )}
                                            </div>
                                          ))}

                                        <div className="w-100 bg-gray">
                                          <div
                                            style={{
                                              display: "inline-block",
                                              width: "25.8%",
                                            }}
                                          >
                                            <p className="p-0 m-0">
                                              {capitalizeFirstLetter(
                                                rate_type_code
                                              )}
                                              &nbsp; Rates
                                            </p>
                                          </div>

                                          <div
                                            className="table-div"
                                            style={{
                                              display: "inline-block",
                                              width: "15%",
                                            }}
                                          >
                                            <input
                                              type="text"
                                              value={getBlockCurrency(
                                                rate_type_code
                                              )}
                                              style={{
                                                border: "none",
                                                outline: "none",
                                                background: "none",
                                              }}
                                            ></input>
                                          </div>

                                          <div
                                            className="table-div"
                                            style={{
                                              width: "15%",
                                              display: "inline-block",
                                            }}
                                          >
                                            <input
                                              type="text"
                                              value={getTruckRates(
                                                rate_type_code,
                                                "other",
                                                "amount_usd"
                                              )?.toFixed(2)}
                                              style={{
                                                border: "none",
                                                outline: "none",
                                                background: "none",
                                              }}
                                            ></input>
                                          </div>
                                        </div>
                                      </tr>
                                    </>
                                  )
                                )}

                                {/* total */}
                                <tr className="w-100">
                                  <td
                                    style={{
                                      textAlign: "center",
                                      background: "#002981",
                                      color: "#ffff",
                                      borderTop: "4px solid #e9b217",
                                      padding: "5px 0",
                                    }}
                                  >
                                    TOTAL
                                  </td>
                                </tr>
                                <tr className="w-100">
                                  <td>
                                    <div className="w-100">
                                      <div
                                        className="bg-gray"
                                        style={{
                                          display: "inline-block",
                                          width: "25.8%",
                                        }}
                                      >
                                        {rateTypeCodes?.map(
                                          (
                                            rate_type_code,
                                            rate_type_code_index
                                          ) => (
                                            <div key={rate_type_code}>
                                              <p className="p-0 m-0">
                                                {capitalizeFirstLetter(
                                                  rate_type_code
                                                )}
                                                &nbsp; Rates
                                              </p>
                                            </div>
                                          )
                                        )}
                                      </div>
                                      <div
                                        className="table-div"
                                        style={{
                                          display: "inline-block",
                                          width: "15%",
                                        }}
                                      >
                                        {rateTypeCodes?.map(
                                          (
                                            rate_type_code,
                                            rate_type_code_index
                                          ) => (
                                            <div key={rate_type_code}>
                                              <input
                                                type="text"
                                                value={getBlockCurrency(
                                                  rate_type_code
                                                )}
                                                style={{
                                                  border: "none",
                                                  outline: "none",
                                                  background: "none",
                                                }}
                                              ></input>
                                            </div>
                                          )
                                        )}
                                      </div>

                                      <div
                                        className="table-div"
                                        style={{
                                          width: "11.8%",
                                          display: "inline-block",
                                        }}
                                      >
                                        {rateTypeCodes?.map(
                                          (
                                            rate_type_code,
                                            rate_type_code_index
                                          ) => (
                                            <div key={rate_type_code}>
                                              <input
                                                type="text"
                                                value={getTruckRates(
                                                  rate_type_code,
                                                  cont,
                                                  "amount_usd"
                                                )?.toFixed(2)}
                                                style={{
                                                  border: "none",
                                                  outline: "none",
                                                  background: "none",
                                                }}
                                              ></input>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                {getDifferentCurrencies()?.map(
                                  (currency, index, arr) => (
                                    <tr className="w-100">
                                      <td
                                        style={{
                                          background: "#002981",
                                          color: "#ffff",
                                          borderBottom: "4px solid #e9b217",
                                          padding: "5px 0",
                                        }}
                                      >
                                        <div className="w-100">
                                          <div
                                            style={{
                                              display: "inline-block",
                                              width: "41%",
                                            }}
                                          >
                                            <p>GROSS TOTAL {currency}</p>
                                          </div>

                                          <div
                                            className="table-div"
                                            style={{
                                              width: "15%",
                                              display: "inline-block",
                                            }}
                                          >
                                            {/* <p>{grossTotalOriginalTruck()}</p> */}
                                            <p>
                                              {grossTotalDisplay(
                                                "other",
                                                currency
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </Col>
                      ))}
                      <Col md={4} className="mt-4">
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            marginTop: "2px",
                          }}
                        >
                          {editSave && (
                            <button
                              type="button"
                              className="px-4"
                              style={{
                                background: "#0e2074",
                                border: "1px solid #0e2074",
                                color: "#ffffff",
                                borderRadius: "4px",
                                width: "max-content",
                              }}
                            >
                              Addon
                            </button>
                          )}
                          {!selectedQuote?.comment && editSave && (
                            <button
                              type="button"
                              className="px-4"
                              style={{
                                background: "#0e2074",
                                border: "1px solid #0e2074",
                                color: "#ffffff",
                                borderRadius: "4px",
                                width: "max-content",
                              }}
                            >
                              Add Comments
                            </button>
                          )}
                        </div>

                        {selectedQuote?.comment && (
                          <div>
                            <div
                              style={{
                                textAlign: "center",
                                background: "#002981",
                                color: "#ffff",
                                borderBottom: "4px solid #e9b217",
                                padding: "5px 4px",
                                width: "max-content",
                                marginTop: editSave && "10px",
                              }}
                            >
                              COMMENTS
                            </div>
                            <div
                              style={{
                                marginTop: "10px",
                              }}
                            >
                              <div
                                style={{
                                  width: "30%",
                                }}
                              >
                                <button
                                  type="button"
                                  onClick={() => {
                                    setCommentData(selectedQuote?.comment);
                                    handleShowCustomerComments();
                                  }}
                                  className="tracking-btn d-flex justify-content-center px-2 w-100"
                                >
                                  <span>View Comments</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </Col>
                      {getValue("container_type") == "20GP + 40GP" && (
                        <Col md={6} className="mt-4 d-flex flex-column">
                          <div className="mt-auto ms-auto text-right">
                            <img src={logo} alt="Logo" />
                          </div>
                        </Col>
                      )}
                    </Row>
                  )}
                  {quotationDetails?.quote_form?.product_type_name != "Air" &&
                    !quotationDetails?.quote_form?.product_type_name?.includes(
                      "TRUCK"
                    ) && (
                      <Row>
                        {getMappingFn()?.map((cont, cont_index) => (
                          <Col md={6}>
                            <div>
                              {cont != "other" && (
                                <h4 className="text-center pt-3 pb-2">
                                  CONTAINER TYPE {cont}
                                </h4>
                              )}
                              <table style={{ borderCollapse: "collapse" }}>
                                <tbody>
                                  {cont == "other" && (
                                    <tr>
                                      <td colSpan={6} className="">
                                        <p>&nbsp;</p>
                                      </td>
                                    </tr>
                                  )}
                                  {rateTypeCodes?.map(
                                    (rate_type_code, rate_type_code_index) => (
                                      <>
                                        <tr key={rate_type_code}>
                                          <td
                                            style={{
                                              textAlign: "center",
                                              background: "#002981",
                                              color: "#ffff",
                                              borderBottom: "4px solid #e9b217",
                                              padding: "5px 0",
                                              // position: "relative",
                                              alignItems: "center",
                                              alignContent: "center",
                                            }}
                                          >
                                            <div></div>
                                            {rate_type_code?.toUpperCase()}{" "}
                                            RATES
                                          </td>
                                        </tr>

                                        <tr>
                                          {rate_type_code_index == 0 && (
                                            <div className="w-100">
                                              <div
                                                style={{
                                                  display: "inline-block",
                                                  width: "60%",
                                                }}
                                              >
                                                <p className="p-0 m-0"></p>
                                              </div>

                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                Price
                                              </div>
                                            </div>
                                          )}

                                          {selectedQuotePriceDetails
                                            ?.filter((quote) => {
                                              if (cont == "other") {
                                                return quote?.rate_type_code?.includes(
                                                  rate_type_code
                                                );
                                              } else {
                                                return (
                                                  quote?.rate_type_code?.includes(
                                                    rate_type_code
                                                  ) &&
                                                  quote?.container_type == cont
                                                );
                                              }
                                            })
                                            ?.map((quote, quote_index) => (
                                              <div
                                                className="w-100"
                                                // style={{ position: "relative" }}
                                                key={quote?.description}
                                              >
                                                <div
                                                  style={{
                                                    display: "inline-block",
                                                    width: "60%",
                                                  }}
                                                >
                                                  {quote?.description}
                                                </div>

                                                <div
                                                  className="table-div"
                                                  style={{
                                                    display: "inline-block",
                                                    width: "22%",
                                                  }}
                                                >
                                                  <input
                                                    type="number"
                                                    value={
                                                      quote?.amount_usd
                                                        ? quote?.amount_usd
                                                        : ""
                                                    }
                                                    className="arrows_hide"
                                                    onChange={(e) =>
                                                      modifyRates(e, quote)
                                                    }
                                                    style={{
                                                      border: "none",
                                                      outline: "none",
                                                      background: "none",
                                                    }}
                                                  ></input>
                                                </div>

                                                <div
                                                  className="table-div"
                                                  style={{
                                                    display: "inline-block",
                                                    width: "18%",
                                                  }}
                                                >
                                                  <input
                                                    type="text"
                                                    value={
                                                      quote?.rate_currency
                                                        ? quote?.rate_currency
                                                        : ""
                                                    }
                                                    style={{
                                                      border: "none",
                                                      outline: "none",
                                                      background: "none",
                                                    }}
                                                  ></input>
                                                </div>
                                              </div>
                                            ))}

                                          <div className="w-100 bg-gray">
                                            <div
                                              style={{
                                                display: "inline-block",
                                                width: "60%",
                                              }}
                                            >
                                              <p className="p-0 m-0">
                                                Total{" "}
                                                {capitalizeFirstLetter(
                                                  rate_type_code
                                                )}{" "}
                                                Rates
                                              </p>
                                            </div>

                                            <div
                                              className="table-div"
                                              style={{
                                                display: "inline-block",
                                                width: "22%",
                                              }}
                                            >
                                              <input
                                                type="text"
                                                value={getFrieghtRates(
                                                  rate_type_code,
                                                  cont
                                                )?.toFixed(2)}
                                                style={{
                                                  border: "none",
                                                  outline: "none",
                                                  background: "none",
                                                }}
                                              ></input>
                                            </div>

                                            <div
                                              className="table-div"
                                              style={{
                                                display: "inline-block",
                                                width: "18%",
                                              }}
                                            >
                                              <input
                                                type="text"
                                                value={getBlockCurrency(
                                                  rate_type_code,
                                                  cont
                                                )}
                                                style={{
                                                  border: "none",
                                                  outline: "none",
                                                  background: "none",
                                                }}
                                              ></input>
                                            </div>
                                          </div>
                                        </tr>
                                      </>
                                    )
                                  )}

                                  {/* total */}
                                  <tr className="w-100">
                                    <td
                                      style={{
                                        textAlign: "center",
                                        background: "#002981",
                                        color: "#ffff",
                                        borderTop: "4px solid #e9b217",
                                        padding: "5px 0",
                                      }}
                                    >
                                      TOTAL
                                    </td>
                                  </tr>
                                  <tr className="w-100">
                                    <td>
                                      <div className="w-100">
                                        <div
                                          className="bg-gray"
                                          style={{
                                            display: "inline-block",
                                            width: "60%",
                                          }}
                                        >
                                          {rateTypeCodes?.map(
                                            (
                                              rate_type_code,
                                              rate_type_code_index
                                            ) => (
                                              <div key={rate_type_code}>
                                                <p className="p-0 m-0">
                                                  {capitalizeFirstLetter(
                                                    rate_type_code
                                                  )}
                                                  Rates
                                                </p>
                                              </div>
                                            )
                                          )}
                                        </div>

                                        <div
                                          className="table-div"
                                          style={{
                                            display: "inline-block",
                                            width: "22%",
                                          }}
                                        >
                                          {rateTypeCodes?.map(
                                            (
                                              rate_type_code,
                                              rate_type_code_index
                                            ) => (
                                              <div key={rate_type_code}>
                                                <input
                                                  type="text"
                                                  // value={getFrieghtRates(
                                                  //   rate_type_code,
                                                  //   cont
                                                  // )?.toFixed(2)}
                                                  value={getIndividualRates(
                                                    rate_type_code,
                                                    "other"
                                                  )}
                                                  style={{
                                                    border: "none",
                                                    outline: "none",
                                                    background: "none",
                                                  }}
                                                ></input>
                                              </div>
                                            )
                                          )}
                                        </div>

                                        <div
                                          className="table-div"
                                          style={{
                                            display: "inline-block",
                                            width: "18%",
                                          }}
                                        >
                                          {rateTypeCodes?.map(
                                            (
                                              rate_type_code,
                                              rate_type_code_index
                                            ) => (
                                              <div key={rate_type_code}>
                                                <input
                                                  type="text"
                                                  value="USD"
                                                  style={{
                                                    border: "none",
                                                    outline: "none",
                                                    background: "none",
                                                  }}
                                                ></input>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  {getDifferentCurrencies(cont)?.map(
                                    (currency, index, arr) => (
                                      <tr className="w-100">
                                        <td
                                          style={{
                                            background: "#002981",
                                            color: "#ffff",
                                            borderBottom: "4px solid #e9b217",
                                            padding: "5px 0",
                                          }}
                                        >
                                          <div className="w-100">
                                            <div
                                              style={{
                                                display: "inline-block",
                                                width: "60%",
                                              }}
                                            >
                                              <p>GROSS TOTAL {currency}</p>
                                            </div>

                                            <div
                                              className="table-div"
                                              style={{
                                                display: "inline-block",
                                                width: "22%",
                                              }}
                                            >
                                              {/* <p>{grossTotal(cont)}</p> */}
                                              <p>
                                                {grossTotalDisplay(
                                                  cont,
                                                  currency
                                                )}
                                              </p>
                                            </div>

                                            <div
                                              className="table-div"
                                              style={{
                                                display: "inline-block",
                                                width: "18%",
                                              }}
                                            >
                                              <p>{currency}</p>
                                            </div>
                                          </div>
                                        </td>
                                        {/* <td style={{ width: "53%" }}>
                                    <div style={{ float: "right" }}>
                                      <img src={logo}></img>
                                    </div>
                                  </td> */}
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </Col>
                        ))}
                        <Col md={6} className="mt-4">
                          {selectedQuote?.comment && (
                            <div>
                              <div
                                style={{
                                  textAlign: "center",
                                  background: "#002981",
                                  color: "#ffff",
                                  borderBottom: "4px solid #e9b217",
                                  padding: "5px 4px",
                                  width: "max-content",
                                }}
                              >
                                COMMENTS
                              </div>
                              <div
                                style={{
                                  marginTop: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    width: "30%",
                                  }}
                                >
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setCommentData(selectedQuote?.comment);
                                      handleShowCustomerComments();
                                    }}
                                    className="tracking-btn d-flex justify-content-center px-2 w-100"
                                  >
                                    <span>View Comments</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </Col>
                        {getValue("container_type") == "20GP + 40GP" && (
                          <Col md={6} className="mt-4 d-flex flex-column">
                            <div className="mt-auto ms-auto text-right">
                              <img src={logo} alt="Logo" />
                            </div>
                          </Col>
                        )}
                      </Row>
                    )}
                </div>
              </Col>
              {getValue("container_type") != "20GP + 40GP" && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    paddingBottom: "20px",
                    marginTop: "-80px",
                    paddingRight: "25px",
                    // position: "relative",
                    top: "-10px",
                  }}
                >
                  <img src={logo} />
                </div>
              )}
            </Row>
          )}

          {/* accept reject button */}
          {checkSelectedQuoteLength() && (
            <Row>
              <Col sm={12} className=" pt-3 pb-5">
                <div className="text-center">
                  <a
                    href={`/pricing/accept/${task_id}/${trade}/${quotationDetails?.quote_form?.approval_uniq_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-success px-5 rounded-pill"
                  >
                    Accept
                  </a>

                  <a
                    href={`/pricing/rejection/${task_id}/${trade}/${quotationDetails?.quote_form?.approval_uniq_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-danger ms-4 px-5 rounded-pill"
                  >
                    Reject
                  </a>
                </div>
              </Col>
            </Row>
          )}
          {/* terms and condi */}
          {checkSelectedQuoteLength() && (
            <table style={{ fontSize: "smaller" }}>
              <thead>
                <th style={{ borderRight: "1px dashed #000" }}>
                  Términos y condiciones
                </th>
                <th>Terms and Conditions</th>
              </thead>
              <tbody>
                <tr>
                  <td style={{ borderRight: "1px dashed #000" }}>
                    <b>
                      Nuestra Oferta está sujeta a los siguientes Términos y
                      Condiciones:
                    </b>
                  </td>
                  <td>
                    <b>
                      Our Offer is subject to following Terms and Conditions:
                    </b>
                  </td>
                </tr>
                <tr>
                  <td style={{ borderRight: "1px dashed #000" }}>
                    En caso de cambios en los pesos y dimensiones proporcionados
                    originalmente, la cotización puede sufrir cambios. Los
                    costos informados se facturan en local nacional están
                    sujetos a IVA (16%) Servicio Sujeto a Disponibilidad de
                    equipo y espacio En caso de aceptar esta cotización se
                    tomará el tipo de cambio al día de la facturación Todas las
                    tarifas aplican para Carga General, no sobrepesada ni
                    sobredimensionada. Estas tarifas solo serán válidas hasta el
                    día de vigencia que muestra la cotización. Todos los cargos
                    están sujetos a revisión en el momento de la adjudicación
                    del negocio. El tiempo de tránsito es estimado y no se
                    considerará como una garantía de tiempo de entrega. El
                    cliente será responsable de pagar cualquier cargo adicional,
                    si esto no se agregó en la cotización principal. Nuestra
                    Oferta no incluye eventuales servicios o cargos adicionales
                    que no se indiquen claramente en este documento.
                  </td>
                  <td>
                    In case of changes in weights and dimensions provided
                    originally, the quote may suffer changes. The reported costs
                    are invoiced in national local are subject to VAT (16%)
                    Service Subject to Availability of equipment and space In
                    case of accepting this quote the exchange rate will be taken
                    at the day of invoicing All rates apply to General cargo,
                    non overweighted or overdimensional. These rates will only
                    be valid until the day of validity that shows the quote. All
                    charges are subject to revision at the time of adjudgement
                    of the business. Transit Time is estimated and shall not be
                    considered as a delivery time guarantee. Customer shall be
                    responsible to pay any accessorial charge, if this wasn’t
                    added in the principal quote. Our Offer does not include any
                    eventual additional services or charges not clearly stated
                    herein.
                  </td>
                </tr>
                <tr>
                  <td style={{ borderRight: "1px dashed #000" }}>
                    <b>Esta cotización no incluye:</b>
                  </td>
                  <td>
                    <b>This quote does not include:</b>
                  </td>
                </tr>
                <tr>
                  <td style={{ borderRight: "1px dashed #000" }}>
                    <ul>
                      <li>*Embalaje o tarimas de madera</li>
                      <li>*Impuestos *Maniobras o servicios extraordinarios</li>
                      <li>*Seguro de la mercancía</li>
                      <li>
                        *Inspección, Fumigación y Almacenamiento en Bodega
                      </li>
                      <li>
                        *Para exceder el peso, consulte caso por caso con
                        nuestro departamento de precios
                      </li>
                      <li>*Sujeto a GRI</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>*Packaging or wooden pallets</li>
                      <li>*Taxes *Maneuvers or extraordinary services</li>
                      <li>*Insurance of the merchandise</li>
                      <li>*Inspection, Fumigation, and Warehouse Storage</li>
                      <li>
                        *For exceeding weight, please check on case-by-case
                        basis with our pricing department
                      </li>
                      <li>*Subject to GRI</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td style={{ borderRight: "1px dashed #000" }}>
                    <ol>
                      <li>
                        Las cotizaciones se basan en el envío a través de MTM
                        Logix y su red mundial a través de las líneas de envío
                        de nuestra elección, a menos que se acuerde lo
                        contrario.
                      </li>
                      <li>
                        Todas las cotizaciones son válidas por 15 días a menos
                        que se especifique lo contrario, sujetas a cambios y
                        presentación dentro de los 14 días posteriores a la
                        cotización.
                      </li>
                      <li>
                        La configuración del contenedor refrigerado se confirma
                        según lo indicado en la cotización. Si no se proporciona
                        representación, el remitente debe informar esas
                        configuraciones durante el proceso de reserva.
                      </li>
                      <li>
                        Todas las cotizaciones se basan en la información
                        proporcionada en el momento de la solicitud de tarifa. A
                        menos que se especifique lo contrario, las cotizaciones
                        solo aplican para carga general y no para objetos de
                        valor, carga sobredimensionada, carga peligrosa,
                        perecederos y otras mercancías, no siendo reconocidas
                        como carga general debido a las particularidades de
                        dichas mercancías en cuanto a su peso, tamaño,
                        exposición a las condiciones climáticas, temperatura o
                        humedad, vicios inherentes o cualquier otro elemento, ya
                        sea que tengan un impacto en dichas mercancías desde el
                        exterior o que resulten de que dichas mercancías tengan
                        un efecto sobre las propias mercancías, los intereses de
                        terceros o la unidad de carga, o ambos.
                      </li>
                      <li>
                        Los detalles del servicio mencionado (por ejemplo,
                        tiempos de tránsito, fechas de salida, horas estimadas
                        de llegada y salida) y el tipo de cambio son puramente
                        indicativos y están sujetos a cambios.
                      </li>
                      <li>
                        Todas las cotizaciones están sujetas a todos los cargos,
                        recargos, tarifas, reglas, regulaciones y excepciones
                        vigentes al momento del envío. Dichos recargos
                        adicionales pueden incluir, entre otros, recargos de
                        emergencia, recargos de búnker, factor de ajuste de
                        moneda, recargos de temporada alta, recargos de riesgo
                        de piratería, recargos de prima de riesgo de guerra,
                        aumentos de tarifas generales, cargos por escaneo. Todas
                        las cotizaciones están sujetas a disponibilidad de
                        espacio y equipo y sujetas al cumplimiento de todas las
                        restricciones de peso de carga aplicables.
                      </li>
                      <li>
                        Toda carga peligrosa está sujeta a aprobación en el
                        momento de la reserva, sujeta a inspección en el puerto
                        de carga y sujeta a recargos adicionales.
                      </li>
                      <li>
                        Para transacciones sujetas a la Ley de envío de EE. UU.,
                        ninguna tarifa o cargo será vinculante para MTM Logix o
                        los miembros de su red, a menos que se incluya en una
                        presentación ante la Comisión Marítima Federal.
                      </li>
                      <li>
                        Para envíos FCL, el remitente es responsable de sellar
                        el contenedor con un sello de alta seguridad.
                      </li>
                      <li>
                        Las cotizaciones de transporte terrestre están sujetas a
                        los aumentos de terceros válidos en el momento del
                        envío/llegada, sujetas a cualquier recargo por
                        combustible válido en el momento del envío/llegada,
                        sujetas a limitaciones de peso y requisitos de
                        distribución de peso según las normas y reglamentos
                        locales y nacionales del país/países de tránsito,
                        sujetas a la disponibilidad del transportista terrestre
                        en el momento de la reserva
                      </li>
                      <li>
                        La facturación se realizará en USD (dólares
                        estadounidenses) y el pago deberá efectuarse de acuerdo
                        con los términos y condiciones generales de pago, a
                        menos que se acuerde lo contrario. Los aranceles e
                        impuestos de importación siempre vencen inmediatamente
                      </li>
                    </ol>
                  </td>
                  <td>
                    <ol>
                      <li>
                        Quotations are based on shipping via MTM Logix and its
                        worldwide network via shipping lines of our choice
                        unless otherwise agreed.
                      </li>
                      <li>
                        All quotations are valid for 15 days unless otherwise
                        specified, subject to change and filing within 14 days
                        of quote.
                      </li>
                      <li>
                        {" "}
                        Reefer Container Settings are as confirmed as per
                        indication in the quote. If no representation is
                        provided, the shipper must inform those settings during
                        the booking process.
                      </li>
                      <li>
                        All quotations are based on information provided at the
                        time of the rate request. Unless otherwise specified,
                        quotes are only applicable for general cargo and not for
                        valuables, oversized cargo, dangerous cargo,
                        perishables, and other goods, not being recognized as
                        general cargo due to the particulars of such goods in
                        terms of its weight, size, exposure to weather
                        conditions, temperature or humidity, inherent vices or
                        any other items, having either an impact on such goods
                        from outside or resulting from such goods having an
                        effect on the goods itself, interests of third parties
                        or the loading unit, or both.
                      </li>
                      <li>
                        The mentioned service details (e.g., transit times,
                        sailing dates, estimated arrival and departure times),
                        and the exchange rate is purely indications and subject
                        to changes.
                      </li>
                      <li>
                        All quotations are subject to all applicable charges,
                        surcharges, fees, rules, regulations, and exceptions
                        valid at the time of shipment. Such additional
                        surcharges may include but are not limited to emergency
                        surcharges, bunker surcharges, currency adjustment
                        factor, peak season surcharges, piracy risk surcharges,
                        war risk premium surcharges, general rate increases,
                        scanning charges. All quotations are subject to space
                        and equipment availability and subject to compliance
                        with all applicable cargo weight restrictions.
                      </li>
                      <li>
                        All hazardous cargo is subject to approval at the time
                        of booking, subject to inspection at the loading port,
                        and subject to additional surcharges.
                      </li>
                      <li>
                        For trades subject to the US Shipping Act, no rates or
                        charges shall be binding on MTM Logix or members of its
                        network unless included in a filing with the Federal
                        Maritime Commission.
                      </li>
                      <li>
                        For FCL shipments, the shipper is responsible for
                        sealing the container with a high-security seal.
                      </li>
                      <li>
                        Inland haulage quotations are subject to the third party
                        increases valid at the time of shipment/arrival, subject
                        to any fuel surcharges valid at the time of
                        shipment/arrival, subject to weight limitations and
                        weight distribution requirements under the local and
                        national rules and regulations of the country/countries
                        of transit, subject to availability of inland carrier at
                        the time of booking
                      </li>
                      <li>
                        Invoicing will occur in USD (United States Dollars), and
                        payment should be effected according to the general
                        payment terms and conditions unless otherwise agreed
                        upon. Import duties and taxes are always immediately due
                      </li>
                    </ol>
                  </td>
                </tr>
              </tbody>
            </table>
          )}

          {!checkSelectedQuoteLength() && (
            <div className="w-100 d-flex justify-content-center aligin-items-center mt-5">
              <div className="mt-5">
                <Loader />
              </div>
            </div>
          )}
        </div>
      </Container>
      <Modal
        size="xl"
        backdrop="static"
        show={showCustomerComments}
        centered
        scrollable
        onHide={() => {
          // setCommentData("")
          handleCloseCustomerComments();
        }}
        className="tracking-modal"
      >
        <Modal.Header closeButton style={{ border: "none" }} onClick={() => {}}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <h2 className="fw-600 fs-26 text-center">Comments</h2>
          <form className="p-4">
            {/* <div className="input-box mb-4">
              <textarea
                required
                type="text"
                className="w-100 border-bottom pb-3"
                placeholder="Add Comment"
                id="comments"
                value={selectedLabel?.comment}
                onChange={(e) => {
                  setselectedLabel({
                    ...selectedLabel,
                    comment: e.target.value,
                  });
                }}
              />
            </div> */}
            <Ckeditor
              commentData={commentData}
              setCommentData={setCommentData}
              editSave={editSave}
              quotestatus={selectedQuote?.quote_status}
              from="pricing"
            />
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Pricing;
