import { useMemo, useRef, useState } from "react";
import UserContext from "./UserContext";
import domtoimage from "dom-to-image";
import jsPDF from "jspdf";
import html2pdf from "html2pdf.js";
import 'jspdf-autotable';
import { useReactToPrint } from 'react-to-print';

const UserState = (props) => {
  const [quotationDetails, setquotationDetails] = useState([]);
  const [dataFetched, setdataFetched] = useState(true);
  const [selectedQuote, setselectedQuote] = useState({});
  const [selectedQuotePriceDetails, setselectedQuotePriceDetails] = useState(
    []
  );
  const [selectedTempQuoteId, setselectedTempQuoteId] = useState({})


  const [selectedDate, setselectedDate] = useState([]);

  const [clkData, setclkData] = useState([]);

  const [firstTimeProfitMargin, setfirstTimeProfitMargin] = useState(null);
  const [selectedProductType, setselectedProductType] = useState("");
  const [selectedStatus, setselectedStatus] = useState("");
  const [selectedCustomerId, setselectedCustomerId] = useState();
  const [approvedQuoteDetails, setapprovedQuoteDetails] = useState({});
  const [editSave, setEditSave] = useState(false);
  // profit margin disbled
  const [profitMargin, setprofitMargin] = useState(false);
  const [downloadButton, setdownloadButton] = useState(false);
  const [grossTotalCurrencies, setgrossTotalCurrencies] = useState({
    "USD":{
      other:"",
      other_original:"",
      "20GP":"",
      "40GP":""
    },
    "EUR":{
      other:"",
      other_original:"",
      "20GP":"",
      "40GP":""
    },
    "GBP":{
      other:"",
      other_original:"",
      "20GP":"",
      "40GP":""
    },
    "CAD":{
      other:"",
      other_original:"",
      "20GP":"",
      "40GP":""
    },
    "AUD":{
      other:"",
      other_original:"",
      "20GP":"",
      "40GP":""
    },
    "JPY":{
      other:"",
      other_original:"",
      "20GP":"",
      "40GP":""
    },
    "INR":{
      other:"",
      other_original:"",
      "20GP":"",
      "40GP":""
    },
    "NZD":{
      other:"",
      other_original:"",
      "20GP":"",
      "40GP":""
    },
    "CHF":{
      other:"",
      other_original:"",
      "20GP":"",
      "40GP":""
    },
    "ZAR":{
      other:"",
      other_original:"",
      "20GP":"",
      "40GP":""
    },
    "RUB":{
      other:"",
      other_original:"",
      "20GP":"",
      "40GP":""
    },
    "CNY":{
      other:"",
      other_original:"",
      "20GP":"",
      "40GP":""
    },
    "MXN":{
      other:"",
      other_original:"",
      "20GP":"",
      "40GP":""
    },
    "BRL":{
      other:"",
      other_original:"",
      "20GP":"",
      "40GP":""
    },
    "GTQ":{
      other:"",
      other_original:"",
      "20GP":"",
      "40GP":""
    },
  });
  const [grossTotalCurrenciesIndidual, setgrossTotalCurrenciesIndividual] = useState({
    
  });
  const [asideBarQuotePrices, setasideBarQuotePrices] = useState({

  });

  const componentRef = useRef();
 
  // const handlePrint = async () => {
  //   const browser = await puppeteer.launch();
  //   const page = await browser.newPage();

  //   // Your component's URL or HTML content goes here
  //   await page.setContent('<div>Your component content here</div>');

  //   // Generate PDF
  //   await page.pdf({ path: 'Quote_details.pdf', format: 'A4' });

  //   await browser.close();
  // };
  // const handlePrint = () => {
  //   setdownloadButton(true);
  
  //   const component = componentRef.current;
  
  //   html2pdf(component)
  //     .from(component)
  //     .outputPdf()
  //     .then((pdf) => {
  //       pdf.save("Quote details");
  //       setdownloadButton(false);
  //     })
  //     .catch((error) => {
  //       console.error("Error generating PDF:", error);
  //       setdownloadButton(false);
  //     });
  // };


  // const handlePrint = () => {
  //   setdownloadButton(true);
  //   setTimeout(() => {
  //     domtoimage.toPng(componentRef.current).then((dataUrl) => {
  //       const pdf = new jsPDF();
  //       const img = new Image();
  //       img.src = dataUrl;

  //       img.onload = () => {
  //         const imgWidth = 210; // A4 page width in mm
  //         const imgHeight = (img.height * imgWidth) / img.width;

  //         pdf.addImage(dataUrl, "PNG", 0, 0, imgWidth, imgHeight);
  //         pdf.save("Quote details"); // Change the filename as needed
  //       };
  //     });
  //   }, 30);
  //   setTimeout(() => {
  //     setdownloadButton(false);
  //   }, 40);
  // };
  const handlePrint2 = useReactToPrint({
    content: () => componentRef.current,
  });
  const handlePrint=()=>{
    setdownloadButton(true);
    setTimeout(() => {

      handlePrint2()
    }, 200);
    setTimeout(() => {
      setdownloadButton(false);
    }, 300);
  }
  

  
  

  
  const contextValue = useMemo(() => ({
    quotationDetails,
    setquotationDetails,
    dataFetched,
    setdataFetched,
    selectedQuote,
    setselectedQuote,
    selectedDate,
    setselectedDate,
    clkData,
    setclkData,
    editSave,
    setEditSave,
    profitMargin,
    setprofitMargin,
    selectedProductType,
    setselectedProductType,
    selectedStatus,
    setselectedStatus,
    selectedCustomerId,
    setselectedCustomerId,
    approvedQuoteDetails,
    setapprovedQuoteDetails,
    selectedQuotePriceDetails,
    setselectedQuotePriceDetails,
    firstTimeProfitMargin,
    setfirstTimeProfitMargin,
    handlePrint,
    selectedTempQuoteId,
    setselectedTempQuoteId,
    componentRef,
    downloadButton,
    grossTotalCurrencies, setgrossTotalCurrencies,
    grossTotalCurrenciesIndidual, setgrossTotalCurrenciesIndividual,
    asideBarQuotePrices, setasideBarQuotePrices

  }), [
    quotationDetails,
    setquotationDetails,
    dataFetched,
    setdataFetched,
    selectedQuote,
    setselectedQuote,
    selectedDate,
    setselectedDate,
    clkData,
    setclkData,
    editSave,
    setEditSave,
    profitMargin,
    setprofitMargin,
    selectedProductType,
    setselectedProductType,
    selectedStatus,
    setselectedStatus,
    selectedCustomerId,
    setselectedCustomerId,
    approvedQuoteDetails,
    setapprovedQuoteDetails,
    selectedQuotePriceDetails,
    setselectedQuotePriceDetails,
    firstTimeProfitMargin,
    setfirstTimeProfitMargin,
    handlePrint,
    selectedTempQuoteId,
    setselectedTempQuoteId,
    componentRef,
    downloadButton,
    grossTotalCurrencies, setgrossTotalCurrencies,
    grossTotalCurrenciesIndidual, setgrossTotalCurrenciesIndividual,
    asideBarQuotePrices, setasideBarQuotePrices
  ]);
  return (
    <UserContext.Provider
    value={contextValue}
 
    >
      {props?.children}
    </UserContext.Provider>
  );
};
export default UserState;
