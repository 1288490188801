import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import save from "./srcimages/save.png";
import { useLocation } from "react-router-dom";

function Save2() {
  const location = useLocation();

  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <div className="wrapper-box">
              <div className="d-flex justify-content-center">
                <div style={{ width: "420px" }}>
                  <img className="w-100" src={save} />
                </div>
              </div>
              <p className="fw-bold fs-3 text-center py-4">
                {location.state.message}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Save2;
