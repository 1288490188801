import { React, useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Details({
  quoteDetailsCameFromLocalStorage,
  tempClickupData,
  questionData,
  setQuestionData,
  editSave,
  quoteDetailsCameModified,
  setquoteDetailsCameModified,
  getQuestions,
  allCountries,
  discardFunction,
}) {
  const navigate = useNavigate();
  const [dropdownView, setDropdownView] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);
  const [questionDataModified, setquestionDataModified] = useState([]);

  const [clickupData, setClickupData] = useState([]);
  const [optionsPort, setoptionsPort] = useState([]);
  const [optionsPortDestination, setoptionsPortDestination] = useState([]);
  const [selectedOptionPort, setSelectedOptionPort] = useState(null);
  const [options, setoptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allModals, setallModals] = useState([]);
  const [commodityData, setcommodityData] = useState([]);
  const [airportCodes, setAirportCodes] = useState([]);
  const inputRef = useRef(null);
  const convertDateFormat = (inputDate) => {
    const dateObj = new Date(inputDate);
    const day = dateObj.getDate().toString().padStart(2, "0");
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // Add 1 to month because it's zero-based
    const year = dateObj.getFullYear().toString();

    return `${day}-${month}-${year}`;
  };
  const handleChangePort = (option) => {
    setquoteDetailsCameModified({
      ...quoteDetailsCameModified,
      loading_port_name: option?.label,
      loading_port: option?.label?.split("|")[1]?.trim(),
    });
  };
  const handleChangeDest = (option) => {
    setquoteDetailsCameModified({
      ...quoteDetailsCameModified,
      discharge_port_name: option?.label,
      discharge_port: option?.label?.split("|")[1]?.trim(),
    });
  };
  const handleChangeContainerType = (option) => {
    setquoteDetailsCameModified({
      ...quoteDetailsCameModified,
      container_type: option?.label,
    });
  };
  const getCountriesFn = async () => {
    try {
      let token = localStorage.getItem("mtm_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/country/list`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
      });

      if (response.data.status) {
        let optionsForCountries = [];
        let countries = response?.data?.country;
        for (const element of countries) {
          let obj = {
            label: element?.country,
            value: element?.country,
          };
          optionsForCountries.push(obj);
        }
        setoptions(optionsForCountries);

        // setallCountries(response?.data?.country);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  };
  function conditionalExp(condition, a, b) {
    if (condition) {
      return a;
    } else {
      return b;
    }
  }
  const postQuoteDetailsFn = async (data, navigate_to, quote_states) => {
    try {
      let token = localStorage.getItem("mtm_user_token");
      let dataAdd = {
        ...data,
        quote_action: data?.task_id ? "update" : "add",
        quote_states: quote_states,
      };

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/opportunity/quote/create`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: dataAdd,
      });
      if (navigate_to == "home") {
        return response;
      }
      if (!response.data.error) {
        delete data.quote_action;
        data["quote_id"] = response?.data?.quote_id;
        localStorage.setItem("quoteDetailsStored", JSON.stringify(data));
        navigate(navigate_to);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  };

  useEffect(() => {
    if (quoteDetailsCameModified) {
      let data = quoteDetailsCameModified;

      let dropdownServiceType = data?.product_type
        ? tempClickupData?.find(
            (clickup) => clickup.clickup_product_id === data?.product_type
          )?.clickup_product_name
        : "";
      let dropdownImpExp = data?.import_export
        ? tempClickupData?.find(
            (clickup) => clickup.clickup_product_id === data?.import_export
          )?.clickup_product_name
        : "";
      let dropdownTrade = data?.trade
        ? tempClickupData?.find(
            (clickup) => clickup.clickup_product_id === data?.trade
          )?.clickup_product_name
        : "";
      let clickupId = data?.container_type
        ? tempClickupData?.find(
            (clickup) =>
              clickup.dropdown_type == "container_type" &&
              clickup.container_type == data?.container_type
          )
        : "";

      setDropdownView({
        product_type: dropdownServiceType,
        import_export: dropdownImpExp,
        trade: dropdownTrade,
        clickupId: clickupId?.clickup_product_id,
        container_type: clickupId?.clickup_product_name,
      });
    }
  }, []);
  const getPortDetails = async (countryCode, from) => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/country/port/search`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
        data: {
          country_sort_code: countryCode,
        },
      });

      handlePortDetailsResponse(response.data, from);
    } catch (error) {
      handlePortDetailsError(error);
    }
  };
  const handlePortDetailsResponse = (data, from) => {
    if (data.status) {
      const optionsForPorts = data.port_details.map((element) => ({
        label: `${element.port_name} | ${element.global_port_code}`,
        value: `${element.port_name} | ${element.global_port_code}`,
      }));
      if (from == "origin") {
        setoptionsPort(optionsForPorts);
      } else {
        setoptionsPortDestination(optionsForPorts);
      }
    }
  };
  const findPortByCode = (ports, portCode) => {
    return ports.find((element) => element.global_port_code === portCode);
  };
  console.log(clickupData, "clikup data");

  const handlePortDetailsError = (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/");
    } else if (error.code === "ECONNABORTED") {
      console.log("Request timed out");
    } else {
      console.log("An error occurred:", error);
    }
  };
  async function getModalTypes(product_type) {
    try {
      const token = localStorage.getItem("mtm_user_token");
      let currentClickup = tempClickupData?.find(
        (clickup) => clickup.clickup_product_id == product_type
      );
      let clickup_data = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/quote/dropdowns/service_type`,
        {
          product_id: currentClickup?.product_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );
      // console.log(clickup_data?.data?.dropdown_details);
      setallModals(clickup_data?.data?.dropdown_details || []);
    } catch (err) {
      if (err.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      console.log(err);
    }
  }
  async function getCommodity() {
    try {
      const token = localStorage.getItem("mtm_user_token");

      let response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/mtm/commodity/list_commodity`,
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );
      if (!response.data.error) {
        setcommodityData(response.data?.commodities || []);
      }
    } catch (err) {
      if (err.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      console.log(err);
    }
  }
  useEffect(() => {
    if (!editSave) {
      setquoteDetailsCameModified(quoteDetailsCameFromLocalStorage);
    }
  }, [editSave]);
  useEffect(() => {
    async function getDropdownData() {
      // const tempClickupData = await getDropdown();
      // let filteredData = tempClickupData
      //   ?.filter((item) => item.dropdown_type == "container_type")
      //   ?.map((item) => {
      //     return { label: item?.container_type, value: item?.container_type };
      //   });
      // setClickupData(filteredData);
    }
    getDropdownData();
    getCommodity();
  }, []);
  useEffect(() => {
    let split = ["", "", ""];
    if (quoteDetailsCameModified.cargo_measurements) {
      split = quoteDetailsCameModified.cargo_measurements?.split("X");
    }
    setquoteDetailsCameModified({
      ...quoteDetailsCameModified,
      measurements_length: split[0] || "",
      measurements_width: split[1] || "",
      measurements_height: split[2] || "",
    });
  }, []);
  useEffect(() => {
    if (quoteDetailsCameModified?.cargo_description) {
      let cargo_description = quoteDetailsCameModified?.cargo_description;
      if (cargo_description && cargo_description.length > 0) {
        let splitted_cargo_description = cargo_description?.split(" | ");
        if (
          splitted_cargo_description &&
          splitted_cargo_description?.length > 1
        ) {
          let hs_code = splitted_cargo_description[0];

          setquoteDetailsCameModified({
            ...quoteDetailsCameModified,
            hs_code: hs_code,
          });
        }
      }
    }
  }, [quoteDetailsCameModified?.cargo_description]);

  const handleChange = (e) => {
    if (!editSave) return;
    setquoteDetailsCameModified({
      ...quoteDetailsCameModified,
      [e.target.id]: e.target.value,
    });
  };
  const incotermOptions = [
    { label: "EXW", value: "EXW" },
    { label: "CIF", value: "CIF" },
    { label: "FOB", value: "FOB" },
    { label: "FAS", value: "FAS" },
    { label: "CFR", value: "CFR" },
    { label: "DDP", value: "DDP" },
    { label: "DAP", value: "DAP" },
    { label: "DPU", value: "DPU" },
    { label: "CIP", value: "CIP" },
    { label: "CPT", value: "CPT" },
    { label: "FCA", value: "FCA" },
    { label: "EXW-FCA", value: "EXW-FCA" },
  ];
  const getDyanamicInput = (content_key, input_type = "text") => {
    if (editSave) {
      if (content_key == "loading_port_name") {
        return (
          <Select
            value={
              quoteDetailsCameModified["loading_port_name"]
                ? {
                    label: quoteDetailsCameModified["loading_port_name"],
                    value: quoteDetailsCameModified["loading_port_name"],
                  }
                : null
            }
            onChange={handleChangePort}
            options={optionsPort}
            isSearchable={true}
            placeholder="Port of Loading*"
            isClearable
          />
        );
      } else if (content_key == "discharge_port_name") {
        return (
          <Select
            value={
              quoteDetailsCameModified["discharge_port_name"]
                ? {
                    label: quoteDetailsCameModified["discharge_port_name"],
                    value: quoteDetailsCameModified["discharge_port_name"],
                  }
                : null
            }
            onChange={handleChangeDest}
            options={optionsPortDestination}
            isSearchable={true}
            placeholder="Port of Discharge*"
            isClearable
          />
        );
      } else if (content_key == "origin_country") {
        return (
          <Select
            value={
              quoteDetailsCameModified["origin_country"]
                ? {
                    label: quoteDetailsCameModified["origin_country"],
                    value: quoteDetailsCameModified["origin_country"],
                  }
                : null
            }
            onChange={(val) => {
              setquoteDetailsCameModified({
                ...quoteDetailsCameModified,
                origin_country: val?.value,
                import_export: null,
                import_export_name: null,
                loading_port_name: null,
              });
            }}
            options={allCountries?.map((item) => {
              return {
                label: item?.country,
                value: item?.country,
              };
            })}
            isSearchable={true}
            placeholder="Origin Country"
            isClearable
          />
        );
      } else if (content_key == "destination_country") {
        return (
          <Select
            value={
              quoteDetailsCameModified["destination_country"]
                ? {
                    label: quoteDetailsCameModified["destination_country"],
                    value: quoteDetailsCameModified["destination_country"],
                  }
                : null
            }
            onChange={(val) => {
              setquoteDetailsCameModified({
                ...quoteDetailsCameModified,
                destination_country: val?.value,
                import_export_name: null,
                import_export: null,
                discharge_port_name: null,
              });
            }}
            options={allCountries?.map((item) => {
              return {
                label: item?.country,
                value: item?.country,
              };
            })}
            isSearchable={true}
            placeholder="Destination Country"
            isClearable
          />
        );
      } else if (content_key == "cargo_description") {
        return (
          <Select
            value={
              quoteDetailsCameModified["cargo_description"]
                ? {
                    label: quoteDetailsCameModified["cargo_description"],
                    value: quoteDetailsCameModified["cargo_description"],
                  }
                : null
            }
            onChange={(val) => {
              setquoteDetailsCameModified({
                ...quoteDetailsCameModified,
                cargo_description: val?.value,
              });
            }}
            options={commodityData?.map((item) => {
              return {
                label: `${item?.hs_code} | ${item?.commodity_name}`,
                value: `${item?.hs_code} | ${item?.commodity_name}`,
              };
            })}
            isSearchable={true}
            placeholder="Destination Country"
            isClearable
          />
        );
      } else if (content_key == "incoterm") {
        return (
          <Select
            value={
              quoteDetailsCameModified["incoterm"]
                ? {
                    label: quoteDetailsCameModified["incoterm"],
                    value: quoteDetailsCameModified["incoterm"],
                  }
                : null
            }
            onChange={(val) => {
              setquoteDetailsCameModified({
                ...quoteDetailsCameModified,
                incoterm: val?.value,
              });
            }}
            options={incotermOptions}
            isSearchable={true}
            placeholder="Incoterm"
            isClearable
          />
        );
      } else if (input_type == "confirmation_radio") {
        return (
          <div>
            <input
              className="mx-2"
              type="radio"
              // name={question?.content}
              // defaultChecked={question?.value == "yes"}
              id={content_key + "yes"}
              onChange={(val) => {
                setquoteDetailsCameModified({
                  ...quoteDetailsCameModified,
                  [content_key]: "yes",
                });
              }}
              checked={quoteDetailsCameModified[content_key] === "yes"}
            />
            <label htmlFor={content_key + "yes"}>Yes</label>

            <input
              className="mx-2"
              type="radio"
              // name={question?.content}
              // defaultChecked={question?.value == "no"}
              id={content_key + "no"}
              onChange={(val) => {
                setquoteDetailsCameModified({
                  ...quoteDetailsCameModified,
                  [content_key]: "no",
                });
              }}
              checked={quoteDetailsCameModified[content_key] === "no"}
            />
            <label htmlFor={content_key + "no"}>No</label>
          </div>
        );
      } else {
        if (content_key == "special_request") {
          return (
            <textarea
              style={{
                outline: "none",
              }}
              className="w-100"
              placeholder=""
              id={content_key}
              ref={inputRef}
              value={quoteDetailsCameModified[content_key]}
              onChange={(e) => {
                setquoteDetailsCameModified({
                  ...quoteDetailsCameModified,
                  [content_key]: e.target.value,
                });
              }}
            />
          );
        } else {
          return (
            <input
              type={input_type}
              className="w-100"
              placeholder=""
              id={content_key}
              ref={inputRef}
              value={quoteDetailsCameModified[content_key]}
              onChange={(e) => {
                setquoteDetailsCameModified({
                  ...quoteDetailsCameModified,
                  [content_key]: e.target.value,
                });
              }}
            />
          );
        }
      }
    } else {
      return (
        <>
          <div
            className="w-100"
            style={{
              resize: "none",
              whiteSpace: "pre-wrap",
              overflowWrap: "break-word",
            }}
          >
            {quoteDetailsCameModified[content_key]}
          </div>
        </>
      );
    }
  };
  console.log(selectedOptionPort, "selected option port");
  const getCountryCode = (country) => {
    let countryCode = "";
    for (let i = 0; i < allCountries?.length; i++) {
      const element = allCountries[i];
      if (element?.country == country) {
        countryCode = element?.country_sort_code;
        break;
      }
    }
    return countryCode;
  };
  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const getAirPort = async () => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/country/airport_get`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
      });

      if (!response.data?.error) {
        setAirportCodes(response.data?.port_details || []);
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      console.log(error);
    }
  };

  useEffect(() => {
    getAirPort();
  }, []);
  useEffect(() => {
    if (quoteDetailsCameModified?.origin_country) {
      if (quoteDetailsCameModified?.product_type_name == "Air") {
        const countryAirports = airportCodes?.filter(
          (ap) =>
            ap?.country?.toLowerCase() ==
              quoteDetailsCameModified?.origin_country?.toLowerCase() &&
            ap?.iata
        );
        // Map the airport data to the format required by react-select options
        const mappedAirports = countryAirports.map((airport) => ({
          value: `${airport.name} | ${airport.iata}`,
          label: `${airport.name} | ${airport.iata}`,
        }));
        setoptionsPort(mappedAirports);
      } else {
        let countryCode = getCountryCode(
          quoteDetailsCameModified?.origin_country
        );
        getPortDetails(countryCode, "origin");
      }
    }
  }, [quoteDetailsCameModified?.origin_country]);
  useEffect(() => {
    if (quoteDetailsCameModified?.destination_country) {
      if (quoteDetailsCameModified?.product_type_name == "Air") {
        const countryAirports = airportCodes?.filter(
          (ap) =>
            ap?.country?.toLowerCase() ==
              quoteDetailsCameModified?.destination_country?.toLowerCase() &&
            ap?.iata
        );
        // Map the airport data to the format required by react-select options
        const mappedAirports = countryAirports.map((airport) => ({
          value: `${airport.name} | ${airport.iata}`,
          label: `${airport.name} | ${airport.iata}`,
        }));
        setoptionsPortDestination(mappedAirports);
      } else {
        let countryCode = getCountryCode(
          quoteDetailsCameModified?.destination_country
        );
        getPortDetails(countryCode, "destination");
      }
    }
  }, [quoteDetailsCameModified?.destination_country]);

  console.log(quoteDetailsCameModified, "qutotion details came modified");
  console.log(allModals, "all modals...");
  console.log(questionData, "questions data...");

  return (
    <>
      <div>
        <Container>
          <Row>
            <Col md={12}>
              <div>
                <div className="content-wrapper mt-0">
                  <form>
                    <div className="row gap-sm-5 gap-0 pb-3">
                      <div className="col-5">
                        <div className="border-bottom py-3">
                          <label className="form-label fw-600 fs-20">
                            Opportunity Detail
                          </label>
                          <input
                            type="text"
                            className=" w-100"
                            placeholder=""
                            id="opportunity_name"
                            disabled={!editSave}
                            value={quoteDetailsCameModified?.opportunity_name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-5">
                        <div className="border-bottom py-3">
                          <label className="form-label fw-600 fs-20">
                            Quote Receiver Email
                          </label>
                          <input
                            type="text"
                            className=" w-100"
                            placeholder=""
                            id="quote_receiver_email"
                            value={
                              quoteDetailsCameModified?.quote_receiver_email
                            }
                            disabled={!editSave}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row gap-sm-5 gap-0 pb-3">
                      <div className="col-5">
                        <div className="border-bottom py-3">
                          <label className="form-label fw-600 fs-20">
                            Departure Date
                          </label>
                          {editSave ? (
                            <input
                              type="date"
                              className=" w-100"
                              placeholder=""
                              id="departure_date"
                              value={quoteDetailsCameModified?.departure_date}
                              min={getCurrentDate()}
                              onChange={(e) => {
                                setquoteDetailsCameModified({
                                  ...quoteDetailsCameModified,
                                  departure_date: e.target.value,
                                });
                              }}
                            />
                          ) : (
                            <input
                              type="text"
                              disabled
                              className=" w-100"
                              placeholder=""
                              value={convertDateFormat(
                                quoteDetailsCameModified?.departure_date
                              )}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col"></div>
                    </div>
                    <div className="row gap-sm-5 gap-0 pb-3">
                      <div className="col-5">
                        <div className="border-bottom py-3">
                          <label className="form-label fw-600 fs-20">
                            Type of product(service)
                          </label>
                          {!editSave ? (
                            <input
                              type="text"
                              className=" w-100"
                              placeholder=""
                              id="product_type"
                              disabled
                              value={
                                quoteDetailsCameModified?.product_type_name
                              }
                            />
                          ) : (
                            <Select
                              value={
                                quoteDetailsCameModified?.product_type_name
                                  ? {
                                      label:
                                        quoteDetailsCameModified?.product_type_name,
                                      value:
                                        quoteDetailsCameModified?.product_type,
                                    }
                                  : null
                              }
                              onChange={(val) => {
                                setquoteDetailsCameModified({
                                  ...quoteDetailsCameModified,
                                  product_type: val?.value,
                                  product_type_name: val?.label,
                                  service_type: null,
                                  modal_id: null,
                                  modal_name: null,
                                  loading_port_name: null,
                                  discharge_port_name: null,
                                  container_type: null,
                                });
                                getModalTypes(val?.value);
                              }}
                              options={tempClickupData
                                ?.filter(
                                  (item) => item.dropdown_type == "product"
                                )
                                ?.map((item) => {
                                  return {
                                    label: item?.clickup_product_name,
                                    value: item?.clickup_product_id,
                                  };
                                })}
                              isSearchable={true}
                              isClearable
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-5">
                        <div className="border-bottom py-3">
                          <label className="form-label fw-600 fs-20">
                            Service Details
                          </label>
                          {!editSave ? (
                            <input
                              type="text"
                              className=" w-100"
                              placeholder=""
                              disabled
                              value={quoteDetailsCameModified?.service_type}
                              id="service_type"
                            />
                          ) : (
                            <Select
                              value={
                                quoteDetailsCameModified?.service_type
                                  ? {
                                      label:
                                        quoteDetailsCameModified?.service_type,
                                      value: quoteDetailsCameModified?.modal_id,
                                    }
                                  : null
                              }
                              onChange={(val) => {
                                setquoteDetailsCameModified({
                                  ...quoteDetailsCameModified,
                                  service_type: val?.label,
                                  modal_id: val?.value,
                                  modal_name: val?.label,
                                });
                                if (val?.value && val?.value !== "") {
                                  getQuestions(val?.value);
                                }
                              }}
                              options={allModals?.map((item) => {
                                return {
                                  label: item?.modal_name,
                                  value: item?.modal_id,
                                };
                              })}
                              isSearchable={true}
                              isClearable
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row gap-sm-5 gap-0 pb-3">
                      {questionData?.map((question) => {
                        if (question?.dependent_question_id) {
                          return conditionalExp(
                            questionData?.find(
                              (q) => q?.id == question?.dependent_question_id
                            )?.value == "yes",
                            <div className="col-5 border-bottom  py-3">
                              <label className="form-label fw-600 fs-20">
                                {question?.content}
                              </label>
                              <>
                                {!editSave ? (
                                  <div
                                    className="w-100"
                                    style={{
                                      resize: "none",
                                      whiteSpace: "pre-wrap",
                                      overflowWrap: "break-word",
                                    }}
                                  >
                                    {
                                      quoteDetailsCameModified[
                                        question?.content_key
                                      ]
                                    }
                                  </div>
                                ) : (
                                  <input
                                    type="text"
                                    className="w-100"
                                    placeholder=""
                                    id={question?.content_key}
                                    value={
                                      quoteDetailsCameModified[
                                        question?.content_key
                                      ]
                                    }
                                    onChange={(e) => {
                                      setquoteDetailsCameModified({
                                        ...quoteDetailsCameModified,
                                        [e.target.id]: e.target.value,
                                      });
                                    }}
                                  />
                                )}
                              </>
                            </div>
                          );
                        } else if (question?.content_key == "container_type") {
                          return (
                            <div className="col-5 border-bottom  py-3">
                              <label className="form-label fw-600 fs-20">
                                {question?.content}
                              </label>
                              {editSave ? (
                                <Select
                                  value={
                                    quoteDetailsCameModified[
                                      question?.content_key
                                    ]
                                      ? {
                                          label:
                                            quoteDetailsCameModified[
                                              question?.content_key
                                            ],
                                          value:
                                            quoteDetailsCameModified[
                                              question?.content_key
                                            ],
                                        }
                                      : null
                                  }
                                  onChange={handleChangeContainerType}
                                  options={tempClickupData
                                    ?.filter((item) => {
                                      if (
                                        quoteDetailsCameModified?.product_type_name
                                          ?.toLowerCase()
                                          .includes("ocean")
                                      ) {
                                        return (
                                          item.dropdown_type == "container_type"
                                        );
                                      } else if (
                                        quoteDetailsCameModified?.product_type_name
                                          ?.toLowerCase()
                                          .includes("truck")
                                      ) {
                                        return (
                                          item.dropdown_type ==
                                          "truck_container_type_both"
                                        );
                                      } else {
                                        return (
                                          item.dropdown_type == "container_type"
                                        );
                                      }
                                    })
                                    ?.map((item) => {
                                      return {
                                        label: item?.container_type,
                                        value: item?.container_type,
                                      };
                                    })}
                                  isSearchable={true}
                                  placeholder="Container Type*"
                                  isClearable
                                />
                              ) : (
                                <input
                                  readOnly
                                  disabled
                                  type="text"
                                  className="w-100"
                                  placeholder=""
                                  value={
                                    dropdownView[question?.content_key] ||
                                    quoteDetailsCameModified[
                                      question?.content_key
                                    ]
                                  }
                                />
                              )}
                            </div>
                          );
                        } else if (question?.content_key == "import_export") {
                          return (
                            <div className="col-5 border-bottom  py-3">
                              <label className="form-label fw-600 fs-20">
                                {question?.content}
                              </label>
                              {editSave ? (
                                <Select
                                  value={
                                    quoteDetailsCameModified[
                                      "import_export_name"
                                    ]
                                      ? {
                                          label:
                                            quoteDetailsCameModified[
                                              "import_export_name"
                                            ],
                                          value:
                                            quoteDetailsCameModified[
                                              "import_export"
                                            ],
                                        }
                                      : null
                                  }
                                  onChange={(val) => {
                                    let tradeArr = tempClickupData?.filter(
                                      (item) => item.dropdown_type === "trade"
                                    );
                                    let tradeId = ""; // Declare tradeId outside of if-else blocks to make it accessible

                                    if (
                                      quoteDetailsCameModified?.origin_country ===
                                      quoteDetailsCameModified?.destination_country
                                    ) {
                                      let foundTrade = tradeArr.find(
                                        (t) =>
                                          t?.clickup_product_name === "Nacional"
                                      );
                                      tradeId = foundTrade?.clickup_product_id;
                                    } else if (val?.label === "Import") {
                                      let foundTrade = tradeArr.find(
                                        (t) =>
                                          t?.clickup_product_name ===
                                          allCountries?.find(
                                            (c) =>
                                              c?.country ===
                                              quoteDetailsCameModified?.origin_country
                                          )?.trade
                                      );
                                      tradeId = foundTrade?.clickup_product_id;
                                    } else if (val?.label === "Export") {
                                      let foundTrade = tradeArr.find(
                                        (t) =>
                                          t?.clickup_product_name ===
                                          allCountries?.find(
                                            (c) =>
                                              c?.country ===
                                              quoteDetailsCameModified?.destination_country
                                          )?.trade
                                      );
                                      tradeId = foundTrade?.clickup_product_id;
                                    }

                                    setquoteDetailsCameModified({
                                      ...quoteDetailsCameModified,
                                      import_export_name: val?.label,
                                      import_export: val?.value,
                                      trade: tradeId, // Corrected variable name here
                                    });
                                  }}
                                  options={tempClickupData
                                    ?.filter((item) => {
                                      if (
                                        quoteDetailsCameModified?.origin_country ==
                                        quoteDetailsCameModified?.destination_country
                                      ) {
                                        return (
                                          item?.dropdown_type == "route" &&
                                          item?.clickup_product_name ==
                                            "National"
                                        );
                                      } else {
                                        return (
                                          item?.dropdown_type == "route" &&
                                          item?.clickup_product_name !=
                                            "National"
                                        );
                                      }
                                    })
                                    ?.map((item) => {
                                      return {
                                        label: item?.clickup_product_name,
                                        value: item?.clickup_product_id,
                                      };
                                    })}
                                  isSearchable={true}
                                  placeholder="Import / Export"
                                  isClearable
                                />
                              ) : (
                                <input
                                  readOnly
                                  disabled
                                  type="text"
                                  className="w-100"
                                  placeholder=""
                                  value={
                                    dropdownView[question?.content_key] ||
                                    quoteDetailsCameModified[
                                      question?.content_key
                                    ]
                                  }
                                />
                              )}
                            </div>
                          );
                        } else if (question?.content_key == "trade") {
                          return (
                            <div className="col-5 border-bottom  py-3">
                              <label className="form-label fw-600 fs-20">
                                {question?.content}
                              </label>
                              {editSave ? (
                                <Select
                                  value={
                                    quoteDetailsCameModified["trade"]
                                      ? {
                                          label: tempClickupData?.find(
                                            (item) =>
                                              item?.dropdown_type == "trade" &&
                                              item?.clickup_product_id ==
                                                quoteDetailsCameModified[
                                                  "trade"
                                                ]
                                          )?.clickup_product_name,
                                          value:
                                            quoteDetailsCameModified["trade"],
                                        }
                                      : null
                                  }
                                  onChange={(val) => {
                                    setquoteDetailsCameModified({
                                      ...quoteDetailsCameModified,
                                      trade: val?.value,
                                    });
                                  }}
                                  options={tempClickupData
                                    ?.filter((item) => {
                                      return item?.dropdown_type == "trade";
                                    })
                                    ?.map((item) => {
                                      return {
                                        label: item?.clickup_product_name,
                                        value: item?.clickup_product_id,
                                      };
                                    })}
                                  isSearchable={true}
                                  placeholder="trade"
                                  isClearable
                                />
                              ) : (
                                <input
                                  readOnly
                                  disabled
                                  type="text"
                                  className="w-100"
                                  placeholder=""
                                  value={
                                    dropdownView[question?.content_key] ||
                                    quoteDetailsCameModified[
                                      question?.content_key
                                    ]
                                  }
                                />
                              )}
                            </div>
                          );
                        } else if (
                          question?.content_key == "cargo_measurements"
                        ) {
                          return (
                            <div className="col-5 ">
                              <label className="form-label fw-600 fs-20">
                                {question?.content}
                              </label>
                              <div className="d-flex  gap-2 justify-content-between align-items-center">
                                <div className="border-bottom py-3">
                                  {" "}
                                  <input
                                    type="number"
                                    className="w-100"
                                    value={
                                      quoteDetailsCameModified[
                                        "measurements_length"
                                      ] || ""
                                    }
                                    onChange={(e) => {
                                      setquoteDetailsCameModified({
                                        ...quoteDetailsCameModified,
                                        measurements_length: e.target.value,
                                      });
                                    }}
                                    placeholder="length"
                                  />
                                </div>
                                <div className="border-bottom py-3">
                                  {" "}
                                  <input
                                    type="number"
                                    className="w-100"
                                    placeholder="width"
                                    value={
                                      quoteDetailsCameModified[
                                        "measurements_width"
                                      ] || ""
                                    }
                                    onChange={(e) => {
                                      setquoteDetailsCameModified({
                                        ...quoteDetailsCameModified,
                                        measurements_width: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                                <div className="border-bottom py-3">
                                  {" "}
                                  <input
                                    type="number"
                                    className="w-100"
                                    placeholder="height"
                                    value={
                                      quoteDetailsCameModified[
                                        "measurements_height"
                                      ] || ""
                                    }
                                    onChange={(e) => {
                                      setquoteDetailsCameModified({
                                        ...quoteDetailsCameModified,
                                        measurements_height: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div
                              className="col-5 border-bottom  py-3"
                              onClick={() => {
                                document
                                  .getElementById(question?.content_key)
                                  ?.focus();
                              }}
                            >
                              <label className="form-label fw-600 fs-20">
                                {question?.content}
                              </label>
                              {getDyanamicInput(
                                question?.content_key,
                                question?.input_type
                              )}
                            </div>
                          );
                        }
                      })}
                    </div>
                  </form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Details;
