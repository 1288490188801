import React, { useState } from "react";
import Loader from "./Loader";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
function AddWarehouseModal({
  showwarehouseAdd,
  handleClosewarehouseAdd,
  addNewWarehouseDetails,
  setaddNewWarehouseDetails,
customerId,
getWarehouseList,
setwarehouseListDetailsRaw,
everest_type,
setselectedWarehouse
}) {
  const [submittingAddNewWarehouse, setsubmittingAddNewWarehouse] = useState(false);
  const [addNewWarehouseErrors, setaddNewWarehouseErrors] = useState({
    company_name: false,
    warehouse_code: false,
    warehouse_name: false,
    city: false,
    state: false,
    country: false,
    zip_cdoe: false,
  });
  const [submitAddNewWarehouseError, setsubmitAddNewWarehouseError] = useState(null);

  const handleAddNewWarehouseChange = (e) => {
    setaddNewWarehouseDetails({
      ...addNewWarehouseDetails,
      [e.target.id]: e.target.value,
    });
    setaddNewWarehouseErrors({
      ...addNewWarehouseErrors,
      [e.target.id]: false,
    });
  };

  const navigate = useNavigate()

  
  

  function smallCheckFnForWarehouse() {
    let company_name = false
    // if(everest_type){
    //  company_name =
    //   addNewWarehouseDetails?.company_name?.trim() == "" ? true : false;
    // }
    let warehouse_code =
      addNewWarehouseDetails?.warehouse_code?.trim() == "" ? true : false;
    let warehouse_name =
      addNewWarehouseDetails?.warehouse_name?.trim() == "" ? true : false;
    let city =
      addNewWarehouseDetails?.city?.trim() == "" ? true : false;
    let state =
      addNewWarehouseDetails?.state?.trim() == "" ? true : false;
    let country =
      addNewWarehouseDetails?.country?.trim() == "" ? true : false;
    let zip_cdoe =
      addNewWarehouseDetails?.zip_cdoe?.trim() == "" ? true : false;

    if (company_name || warehouse_code || warehouse_name || city || state || country || zip_cdoe) {
      setaddNewWarehouseErrors({
        company_name,
        warehouse_code,
        warehouse_name,
        city,
        state,
        country,
        zip_cdoe,
      });
      return true;
    } else {
      return false;
    }
  }
  const onSubmitAddNewWarehouse = async (from) => {
    const token = localStorage.getItem("mtm_user_token");

    if (smallCheckFnForWarehouse()) {
      return;
    }
    setsubmittingAddNewWarehouse(true);
    setsubmitAddNewWarehouseError(null);
   


    let data = { ...addNewWarehouseDetails };
   

    data["customer_id"] = customerId;
    try {
      let response = await axios({
        url:
          from == "add"
            ? `${process.env.REACT_APP_BASE_URL}/mtmwarehouse/create`
            : `${process.env.REACT_APP_BASE_URL}/mtmwarehouse/edit`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: data,
      });
      if (response.status == 200) {
        if (!response.data.error) {
            setwarehouseListDetailsRaw && setwarehouseListDetailsRaw([])
          setTimeout(() => {
            handleClosewarehouseAdd();
            setsubmittingAddNewWarehouse(false);
            setaddNewWarehouseDetails({
              customer_id: customerId,
              company_name: "",
              warehouse_code: "",
              warehouse_name: "",
              city: "",
              state: "",
              country: "",
              zip_cdoe: "",
            });
          }, 2000);

          
         await getWarehouseList();
         setselectedWarehouse && setselectedWarehouse({
          label:addNewWarehouseDetails?.warehouse_code + " | "+ addNewWarehouseDetails?.warehouse_name,
          value:addNewWarehouseDetails?.warehouse_code,
         })
        } else {
          setsubmittingAddNewWarehouse(false);
          setsubmitAddNewWarehouseError(response.data.message);
        }
      }
    } catch (error) {
      setsubmittingAddNewWarehouse(false);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      console.log(error);
    }
  };

  
  return (
    <Modal
      show={showwarehouseAdd}
      centered
      onHide={() => {
        setaddNewWarehouseErrors({
          company_name: false,
          warehouse_code: false,
          warehouse_name: false,
          city: false,
          state: false,
          country: false,
          zip_cdoe: false,
        });
        setaddNewWarehouseDetails({
            customer_id: customerId,
            company_name: "",
            warehouse_code: "",
            warehouse_name: "",
            city: "",
            state: "",
            country: "",
            zip_cdoe: "",
          });
        handleClosewarehouseAdd();
      }}
      scrollable={true}
    >
      <Modal.Header className="border-0" closeButton></Modal.Header>
      <Modal.Body className="px-4">
        <p className="text-center fs-26 fw-bold pb-2">{addNewWarehouseDetails?.warehouse_id ? "Edit Warehouse":"Add Warehouse"}</p>
       
        <div className="input-box-2 mb-3">
          {addNewWarehouseDetails.warehouse_code &&
            addNewWarehouseDetails.warehouse_code != "" && (
              <label htmlFor="warehouse_code">Warehouse Code *</label>
            )}
          <input
            type="text"
            className="w-100"
            onChange={handleAddNewWarehouseChange}
            id="warehouse_code"
            value={addNewWarehouseDetails.warehouse_code}
            placeholder="Enter Warehouse Code*"
          />
        </div>
        {addNewWarehouseErrors.warehouse_code && (
          <div className="text-danger mb-3">
            Please enter Warehouse Code
          </div>
        )}
        <div className="input-box-2 mb-3">
          {addNewWarehouseDetails.warehouse_name &&
            addNewWarehouseDetails.warehouse_name != "" && (
              <label htmlFor="warehouse_name">Warehouse Name *</label>
            )}
          <input
            type="text"
            id="warehouse_name"
            onChange={handleAddNewWarehouseChange}
            value={addNewWarehouseDetails.warehouse_name}
            className="w-100"
            placeholder="Enter Warehouse Name*"
          />
        </div>
        {addNewWarehouseErrors.warehouse_name && (
          <div className="text-danger mb-3">
            Please enter Warehouse Name
          </div>
        )}
         {everest_type ? <><div className="input-box-2 mb-3">
          {addNewWarehouseDetails.company_name &&
            addNewWarehouseDetails.company_name != "" && (
              <label htmlFor="company_name">Company Name *</label>
            )}
          <input
            type="text"
            className="w-100"
            id="company_name"
            value={addNewWarehouseDetails.company_name}
            onChange={handleAddNewWarehouseChange}
            placeholder="Enter Company Name*"
          />
        </div>
        {addNewWarehouseErrors.company_name && (
          <div className="text-danger mb-3">Please enter Company Name</div>
        )}</>:""}
        <div className="input-box-2 mb-3">
          {addNewWarehouseDetails.city &&
            addNewWarehouseDetails.city != "" && (
              <label htmlFor="city">City *</label>
            )}
          <input
            type="text"
            className="w-100"
            onChange={handleAddNewWarehouseChange}
            id="city"
            value={addNewWarehouseDetails.city}
            placeholder="Enter City*"
          />
        </div>
        {addNewWarehouseErrors.city && (
          <div className="text-danger mb-3">
            Please enter City
          </div>
        )}
        <div className="input-box-2 mb-3">
          {addNewWarehouseDetails.state &&
            addNewWarehouseDetails.state != "" && (
              <label htmlFor="state">State *</label>
            )}
          <input
            type="text"
            id="state"
            onChange={handleAddNewWarehouseChange}
            value={addNewWarehouseDetails.state}
            className="w-100"
            placeholder="Enter State*"
          />
        </div>
        {addNewWarehouseErrors.state && (
          <div className="text-danger mb-3">
            Please enter State
          </div>
        )}
        <div className="input-box-2 mb-3">
          {addNewWarehouseDetails.country &&
            addNewWarehouseDetails.country != "" && (
              <label htmlFor="country">Country *</label>
            )}
          <input
            type="text"
            className="w-100"
            onChange={handleAddNewWarehouseChange}
            id="country"
            value={addNewWarehouseDetails.country}
            placeholder="Enter Country*"
          />
        </div>
        {addNewWarehouseErrors.country && (
          <div className="text-danger mb-3">
            Please enter Country
          </div>
        )}
        <div className="input-box-2 mb-3">
          {addNewWarehouseDetails.zip_cdoe &&
            addNewWarehouseDetails.zip_cdoe != "" && (
              <label htmlFor="zip_cdoe">Zip Code *</label>
            )}
          <input
            type="number"
            className="w-100"
            onChange={handleAddNewWarehouseChange}
            id="zip_cdoe"
            value={addNewWarehouseDetails.zip_cdoe}
            placeholder="Enter Zip Code*"
          />
        </div>
        {addNewWarehouseErrors.zip_cdoe && (
          <div className="text-danger mb-3">
            Please enter Zip Code
          </div>
        )}
       
        
        {submitAddNewWarehouseError && (
          <div className="text-danger mb-3">{submitAddNewWarehouseError}</div>
        )}
        <div className="d-flex">
          <div className="ms-auto">
            <button
              className="grd-btn rounded-pill"
              onClick={() => {
                if (addNewWarehouseDetails?.warehouse_id) {
                  onSubmitAddNewWarehouse("edit");
                } else {
                  onSubmitAddNewWarehouse("add");
                }
              }}
              disabled={submittingAddNewWarehouse}
            >
              {submittingAddNewWarehouse ? <Loader /> : "Submit"}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AddWarehouseModal;
