import React, { useState } from "react";
import Loader from "./Loader";
import { Modal } from "react-bootstrap";
import validator from "validator";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import { conditionalExp } from "../utils/commonFn";
function AddVendorModal({
  showvendorAdd,
  setSelectedOption,
  handleClosevendorAdd,
  addNewVendorDetails,
  setaddNewVendorDetails,
  setoptionsForPorts,
  setSelectedOptionPort,
  setselectedOptionCountry,
  selectedOptionCountry,
optionsForCountries,
selectedOptionPort,
optionsForPorts,
customerId,
getVendorList,
setvendorListDetailsRaw
}) {
  const [submittingAddNewVendor, setsubmittingAddNewVendor] = useState(false);
  const [addNewVendorErrors, setaddNewVendorErrors] = useState({
    company_name: false,
    contact_1_name: false,
    contact_2_name: false,
    contact_1_email: false,
    contact_2_email: false,
    contact_phone: false,
    Address: false,
  });
  const [submitAddNewVendorError, setsubmitAddNewVendorError] = useState(null);

  const handleAddNewVendorChange = (e) => {
    setaddNewVendorDetails({
      ...addNewVendorDetails,
      [e.target.id]: e.target.value,
    });
    setaddNewVendorErrors({
      ...addNewVendorErrors,
      [e.target.id]: false,
    });
  };

  const navigate = useNavigate()

  const validateEmail = (email) => {
    return validator.isEmail(email);
  };
  const handleChange2 = (e) => {
    const { id, value } = e.target;
    if (id === "contact_1_email" ) {
      if (!validateEmail(value)) {
        setaddNewVendorErrors((prevErrors) => ({
          ...prevErrors,
          contact_1_email: true,
        }));
      } else {
        setaddNewVendorErrors((prevErrors) => ({
          ...prevErrors,
          contact_1_email: false,
        }));
      }
    }
    if (id === "contact_2_email" ) {
      if (!validateEmail(value)) {
        setaddNewVendorErrors((prevErrors) => ({
          ...prevErrors,
          contact_2_email: true,
        }));
      } else {
        setaddNewVendorErrors((prevErrors) => ({
          ...prevErrors,
          contact_2_email: false,
        }));
      }
    }
  };

  function smallCheckFnForVendor() {
    let company_name =
      addNewVendorDetails?.company_name?.trim() == "" ? true : false;
    let Address =
      addNewVendorDetails?.Address?.trim() == "" ? true : false;
    let contact_1_name =
      addNewVendorDetails?.contact_1_name?.trim() == "" ? true : false;
    let contact_1_email =
      addNewVendorDetails?.contact_1_email?.trim() == "" ? true : false;
    let contact_phone =
      addNewVendorDetails?.contact_phone?.trim() == "" ? true : false;

    if (company_name || contact_1_name || contact_1_email  || contact_phone || Address) {
      setaddNewVendorErrors({
        company_name,
        contact_1_email,
        contact_1_name,
        contact_phone,
        Address,
      });
      return true;
    } else {
      return false;
    }
  }
  const onSubmitAddNewVendor = async (from) => {
    const token = localStorage.getItem("mtm_user_token");

    if (smallCheckFnForVendor()) {
      return;
    }
    setsubmittingAddNewVendor(true);
    setsubmitAddNewVendorError(null);
    let portOfLoading = "";
    if (selectedOptionPort) {
      portOfLoading = selectedOptionPort?.label;
    }

    portOfLoading = portOfLoading?.split("|") || ["",""];

    let data = { ...addNewVendorDetails };
    data.origin_country = selectedOptionCountry?.label || "";
    data.nearest_sea_port_name = portOfLoading[0]?.trim() || "";
    data.nearest_sea_port_code = portOfLoading[1]?.trim() || "";

    data["customer_id"] = customerId;
    try {
      let response = await axios({
        url:
          from == "add"
            ? `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/vendor/add`
            : `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/vendor/edit`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: data,
      });
      if (response.status == 200) {
        if (!response.data.error) {
          setvendorListDetailsRaw && setvendorListDetailsRaw([])
          setTimeout(() => {
            handleClosevendorAdd();
            setsubmittingAddNewVendor(false);
            setSelectedOption({
              label: conditionalExp(addNewVendorDetails?.Address, addNewVendorDetails?.company_name + " | " + addNewVendorDetails?.Address ,addNewVendorDetails?.company_name),
              value: addNewVendorDetails?.company_name,
            });
            setaddNewVendorDetails({
              customer_id: customerId,
              company_name: "",
              origin_country: "",
              contact_1_name: "",
              contact_2_name: "",
              contact_1_email: "",
              contact_2_email: "",
              contact_phone: "",
              communication_mode: "",
              nearest_sea_port_code: "",
              nearest_sea_port_name: "",
              nearest_air_port_code: "",
              nearest_air_port_name: "",
              nearest_land_port_code: "",
              nearest_land_port_name: "",
              Address:""
            });
          }, 2000);

          setselectedOptionCountry(null);
          setSelectedOptionPort(null);
          getVendorList();
        } else {
          setsubmittingAddNewVendor(false);
          setsubmitAddNewVendorError(response.data.message);
        }
      }
    } catch (error) {
      setsubmittingAddNewVendor(false);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      console.log(error);
    }
  };

  
  return (
    <Modal
      show={showvendorAdd}
      centered
      onHide={() => {
        setSelectedOption(null);
        setselectedOptionCountry(null);
          setSelectedOptionPort(null);
        setaddNewVendorErrors({
          company_name: false,
          contact_1_name: false,
          contact_2_name: false,
          contact_1_email: false,
          contact_2_email: false,
          contact_phone: false,
        });
        setaddNewVendorDetails({
          customer_id: customerId,
          company_name: "",
          origin_country: "",
          contact_1_name: "",
          contact_2_name: "",
          contact_1_email: "",
          contact_2_email: "",
          contact_phone: "",
          communication_mode: "",
          nearest_sea_port_code: "",
          nearest_sea_port_name: "",
          nearest_air_port_code: "",
          nearest_air_port_name: "",
          nearest_land_port_code: "",
          nearest_land_port_name: "",
          Address:""
        });
        handleClosevendorAdd();
      }}
      scrollable={true}
    >
      <Modal.Header className="border-0" closeButton></Modal.Header>
      <Modal.Body className="px-4">
        <p className="text-center fs-26 fw-bold pb-2">{addNewVendorDetails?.vendor_id ? `Edit Vendor` :"Add Vendor"}</p>
        {addNewVendorDetails?.vendor_code && <div className="input-box-2 mb-3">
         
              <label htmlFor="company_name">Vendor Code</label>
           
          <input
            type="text"
            className="w-100"
            id="vendor_code"
            value={addNewVendorDetails?.vendor_code}
            disabled
          />
        </div>}
        <div className="input-box-2 mb-3">
          {addNewVendorDetails.company_name &&
            addNewVendorDetails.company_name != "" && (
              <label htmlFor="company_name">Company Name *</label>
            )}
          <input
            type="text"
            className="w-100"
            id="company_name"
            value={addNewVendorDetails.company_name}
            onChange={handleAddNewVendorChange}
            placeholder="Enter Company Name*"
          />
        </div>
        {addNewVendorErrors.company_name && (
          <div className="text-danger mb-3">Please enter Company Name</div>
        )}
        <div className="input-box-2 mb-3">
          {addNewVendorDetails.contact_1_name &&
            addNewVendorDetails.contact_1_name != "" && (
              <label htmlFor="contact_1_name">Contact Person 1 Name *</label>
            )}
          <input
            type="text"
            className="w-100"
            onChange={handleAddNewVendorChange}
            id="contact_1_name"
            value={addNewVendorDetails.contact_1_name}
            placeholder="Enter Contact Person 1 Name*"
          />
        </div>
        {addNewVendorErrors.contact_1_name && (
          <div className="text-danger mb-3">
            Please enter Contact Person 1 Name
          </div>
        )}
        <div className="input-box-2 mb-3">
          {addNewVendorDetails.contact_1_email &&
            addNewVendorDetails.contact_1_email != "" && (
              <label htmlFor="contact_1_email">Contact Person 1 Email *</label>
            )}
          <input
            type="email"
            id="contact_1_email"
            onBlur={handleChange2}
            onChange={handleAddNewVendorChange}
            value={addNewVendorDetails.contact_1_email}
            className="w-100"
            placeholder="Enter Contact Person 1 Email*"
          />
        </div>
        {addNewVendorErrors.contact_1_email && (
          <div className="text-danger mb-3">
            Please enter Contact Person 1 Email
          </div>
        )}
        <div className="input-box-2 mb-3">
          {addNewVendorDetails.contact_2_name &&
            addNewVendorDetails.contact_2_name != "" && (
              <label htmlFor="contact_2_name">Contact Person 2 Name </label>
            )}
          <input
            type="text"
            className="w-100"
            onChange={handleAddNewVendorChange}
            id="contact_2_name"
            value={addNewVendorDetails.contact_2_name}
            placeholder="Enter Contact Person 2 Name*"
          />
        </div>
        
        <div className="input-box-2 mb-3">
          {addNewVendorDetails.contact_2_email &&
            addNewVendorDetails.contact_2_email != "" && (
              <label htmlFor="contact_2_email">Contact Person 2 Email </label>
            )}
          <input
            type="email"
            id="contact_2_email"
            onBlur={handleChange2}
            onChange={handleAddNewVendorChange}
            value={addNewVendorDetails.contact_2_email}
            className="w-100"
            placeholder="Enter Contact Person 2 Email*"
          />
        </div>
        
        <div className="input-box-2 mb-3">
          {addNewVendorDetails.contact_phone &&
            addNewVendorDetails.contact_phone != "" && (
              <label htmlFor="contact_phone">Contact Person Phone *</label>
            )}
          <input
            type="number"
            className="w-100"
            onChange={handleAddNewVendorChange}
            id="contact_phone"
            value={addNewVendorDetails.contact_phone}
            placeholder="Enter Contact Person Phone*"
          />
        </div>
        {addNewVendorErrors.contact_phone && (
          <div className="text-danger mb-3">
            Please enter Contact Person Phone
          </div>
        )}
        <div className="input-box-2 mb-3">
          {addNewVendorDetails.communication_mode &&
            addNewVendorDetails.communication_mode != "" && (
              <label htmlFor="communication_mode" className="mb-1">
                We chat or other communication way
              </label>
            )}
          <div className="form-floating">
            <textarea
              className="form-control"
              onChange={handleAddNewVendorChange}
              value={addNewVendorDetails.communication_mode}
              placeholder="We chat or other communication way "
              id="communication_mode"
            ></textarea>
          </div>
        </div>
        <div className="mb-3">
          {selectedOptionCountry && (
            <label className="mb-1">Origin Country</label>
          )}
          <Select
            value={selectedOptionCountry}
            onChange={(option) => {
              setoptionsForPorts([]);
              setSelectedOptionPort(null);
              setselectedOptionCountry(option);
            }}
            options={optionsForCountries}
            isSearchable={true}
            placeholder="Origin Country"
            isClearable
            isLoading={!optionsForCountries?.length}
          />
        </div>
        <div className="input-box-2 mb-3">
          {addNewVendorDetails.Address &&
            addNewVendorDetails.Address != "" && (
              <label htmlFor="Address" className="mb-1">
                Address
              </label>
            )}
          <div className="form-floating">
            <textarea
              className="form-control"
              onChange={handleAddNewVendorChange}
              value={addNewVendorDetails.Address}
              placeholder="Address "
              id="Address"
            ></textarea>
          </div>
          {addNewVendorErrors.Address && (
          <div className="text-danger mb-3">Please enter address</div>
        )}
        </div>
        <div className="mb-3">
          {selectedOptionPort && (
            <label className="mb-1">Nearest Sea Port of Loading</label>
          )}
          <Select
            value={selectedOptionPort}
            onChange={(option) => setSelectedOptionPort(option)}
            options={optionsForPorts}
            isSearchable={true}
            placeholder="Nearest Sea Port of Loading"
            isClearable
            isLoading={!optionsForPorts?.length}
          />
        </div>
        <div className="input-box-2 mb-3">
          {addNewVendorDetails.nearest_air_port_name &&
            addNewVendorDetails.nearest_air_port_name != "" && (
              <label htmlFor="nearest_air_port_name">
                Nearest Air Port of Loading
              </label>
            )}
          <input
            type="text"
            className="w-100"
            id="nearest_air_port_name"
            onChange={handleAddNewVendorChange}
            value={addNewVendorDetails.nearest_air_port_name}
            placeholder="Nearest Air Port of Loading"
          />
        </div>
        <div className="input-box-2 mb-3">
          {addNewVendorDetails.nearest_land_port_name &&
            addNewVendorDetails.nearest_land_port_name != "" && (
              <label htmlFor="nearest_land_port_name">
                Nearest Inland Transport
              </label>
            )}
          <input
            type="text"
            onChange={handleAddNewVendorChange}
            id="nearest_land_port_name"
            value={addNewVendorDetails.nearest_land_port_name}
            className="w-100"
            placeholder="Nearest Inland Transport"
          />
        </div>
        {submitAddNewVendorError && (
          <div className="text-danger mb-3">{submitAddNewVendorError}</div>
        )}
        <div className="d-flex">
          <div className="ms-auto">
            <button
              className="grd-btn rounded-pill"
              onClick={() => {
                if (addNewVendorDetails?.vendor_id) {
                  onSubmitAddNewVendor("edit");
                } else {
                  onSubmitAddNewVendor("add");
                }
              }}
              disabled={submittingAddNewVendor}
            >
              {submittingAddNewVendor ? <Loader /> : "Submit"}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AddVendorModal;
