import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Navbar, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import logo from "./srcimages/logo.png";
import Processing from "../components/Processing";
import { DatePicker } from "antd";
import moment from "moment";

function Rejection() {
  const [yesNo, setYesNo] = useState("no");
  const [reason, setreason] = useState("");
  const [error, seterror] = useState(false);
  const [showVendorName, setshowVendorName] = useState(false);
  const handleCloseVendorName = () => setshowVendorName(false);
  const [loadingCompleted, setloadingComplted] = useState(false);
  const [submitting, setsubmitting] = useState(false);

  // =====
  const params = useParams();
  const { custom_task_id, freightify_id, approval_uniq_id } = params;
  const navigate = useNavigate();
  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const getRejectType = () => {
    if (yesNo == "yes") {
      return "Cost";
    } else if (yesNo == "no") {
      return "Other Reason";
    } else if (yesNo == "validityTime") {
      return "Validity Time";
    } else {
      return "Free Days";
    }
  };
  const postVenderRejectList = async () => {
    if (reason?.length == 0) {
      seterror(true);
      return;
    }
    seterror(false);
    try {
      setsubmitting(true);
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmqote/customer/reject`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          custom_task_id: custom_task_id,
          freightify_id: freightify_id,
          reject_type: getRejectType(),
          reject_reason: reason,
        },
      });
      if (response.status == 200) {
        if (!response.data.error) {
          setsubmitting(false);
          navigate("/saved", {
            replace: true,
            state: {
              message: "We have saved your response",
            },
          });
        } else {
          navigate("/saved", {
            replace: true,
            state: {
              message: response.data.message,
            },
          });
        }
      }
    } catch (error) {
      setsubmitting(false);
      console.log(error, "error");
    }
  };
  const statusApi = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmqote/customer/status`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          custom_task_id: custom_task_id,
          approval_uniq_id: approval_uniq_id,
        },
      });
      if (response.status == 200) {
        if (!response.data.error) {
          setloadingComplted(true);
        } else {
          setloadingComplted(true);
          navigate("/saved", {
            replace: true,
            state: {
              message: response.data.message,
            },
          });
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  useEffect(() => {
    statusApi();
  }, []);

  return (
    <>
      {loadingCompleted ? (
        <>
          <Navbar expand="lg">
            <Container>
              <Navbar.Brand href="#">
                <img
                  src={logo}
                  style={{ width: "140px", height: "52px" }}
                ></img>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbarScroll" />
            </Container>
          </Navbar>
          <Container>
            <Row>
              <Col md={12}>
                <div className="wrapper-box">
                  <h2 className="text-center fw-700 fs-30 mt-4">
                    Reason For Rejection
                  </h2>
                </div>
                <div className="content-wrapper">
                  <form>
                    <div className="mb-3 ">
                      <p>Please Choose your reason for rejection</p>
                      <div className="form-check pt-3 pb-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          onClick={() => {
                            setYesNo("yes");
                            seterror(false);
                            setreason("");
                          }}
                        />
                        <label
                          className="form-check-label"
                          for="flexRadioDefault1"
                        >
                          Cost
                        </label>
                      </div>
                      {yesNo == "yes" && (
                        <div className="mb-3 border-bottom pb-3">
                          <input
                            type="number"
                            className=" w-100"
                            placeholder="What's your market target"
                            value={reason}
                            style={{ border: "none", outline: "none" }}
                            onChange={(e) => {
                              setreason(e.target.value);
                              seterror(false);
                            }}
                          />
                        </div>
                      )}
                      {error && yesNo == "yes" && (
                        <div className="mb-4 text-danger pb-2">
                          Please enter What's your market target
                        </div>
                      )}
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          onClick={() => {
                            setYesNo("no");
                            seterror(false);
                            setreason("");
                          }}
                          checked={yesNo == "no"}
                        />
                        <label
                          className="form-check-label"
                          for="flexRadioDefault2"
                        >
                          Other Reason
                        </label>
                      </div>
                    </div>
                    {yesNo == "no" && (
                      <div className="mb-3 border-bottom pb-3">
                        <input
                          type="text"
                          className=" w-100"
                          placeholder="Enter your reason"
                          value={reason}
                          style={{ border: "none", outline: "none" }}
                          onChange={(e) => {
                            setreason(e.target.value);
                            seterror(false);
                          }}
                        />
                      </div>
                    )}
                    {error && yesNo == "no" && (
                      <div className="mt-2 text-danger pb-2">
                        Please enter your reason
                      </div>
                    )}
                    <div className="form-check  pb-2">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        onClick={() => {
                          setYesNo("validityTime");
                          seterror(false);
                          setreason("");
                        }}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault1"
                      >
                        Validity Time
                      </label>
                    </div>
                    {yesNo == "validityTime" && (
                      <div className="mb-3 pb-3">
                        {/* <input
                          type="date"
                          className=" w-100"
                          id="dispatch_date"
                          value={reason}
                          min={getCurrentDate()}
                          onChange={(e) => {
                            setreason(e.target.value);
                            seterror(false);
                          }}
                        /> */}
                        <DatePicker
                          className=" w-100 custom-datepicker"
                          rootClassName="custom-datepicker"
                          placeholder={reason || "Enter the desired validity time"}
                          id="dispatch_date"
                          disabledDate={(current) => {
                            // Can not select days before today and today
                            return current && current < moment().startOf("day");
                          }}
                          onChange={(e) => {
                            setreason(e?.format("YYYY-MM-DD"));
                            if (e) {
                              seterror(false);
                            }
                          }}
                        />
                      </div>
                    )}
                    {error && yesNo == "validityTime" && (
                      <div className="mt-2 text-danger pb-2">
                        Please select validity time
                      </div>
                    )}
                    <div className="form-check  pb-2">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        onClick={() => {
                          setYesNo("free_days");
                          seterror(false);
                          setreason("");
                        }}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault1"
                      >
                        Free Days
                      </label>
                    </div>
                    {yesNo == "free_days" && (
                      <div className="mb-3 border-bottom pb-3">
                        <input
                          type="number"
                          className=" w-100"
                          placeholder="Enter Free days"
                          value={reason}
                          onChange={(e) => {
                            setreason(e.target.value);
                            seterror(false);
                          }}
                        />
                      </div>
                    )}
                    {error && yesNo == "free_days" && (
                      <div className="mt-2 text-danger pb-2">
                        Please enter Free days
                      </div>
                    )}
                    <div className="d-flex py-4">
                      <div className="ms-auto">
                        <button
                          type="button"
                          className="py-2 px-4 grd-btn fs-20 rounded-pill fw-600 text-center w-auto"
                          onClick={() => {
                            if (reason?.length == 0 && yesNo == "no") {
                              seterror(true);

                              return;
                            }
                            postVenderRejectList();
                          }}
                        >
                          {submitting ? "Submitting..." : "Submit"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Col>
            </Row>
          </Container>
          <Modal show={showVendorName} onHide={handleCloseVendorName} centered>
            <Modal.Header closeButton style={{ border: "none" }}></Modal.Header>
            <Modal.Body className="p-4">
              <p className="fs-4 text-center m-0 p-0">
                Please reconfirm your submission
              </p>
              <div className="input-box mb-4 mt-2 p-0 border-bottom">
                <label>Custom Task Id</label>
                <input
                  type="text"
                  className="w-100"
                  placeholder="Enter Description"
                  style={{ border: "none", outline: "none" }}
                  id="vender_code"
                  value={`${custom_task_id}`}
                />
              </div>
              <div className="input-box mb-4 mt-2 p-0 border-bottom">
                <label>Freightify Id</label>
                <input
                  type="text"
                  className="w-100"
                  placeholder="Enter Description"
                  style={{ border: "none", outline: "none" }}
                  id="vender_code"
                  value={`${freightify_id}`}
                />
              </div>
              <div className="d-flex justify-content-end mt-2">
                <div>
                  <button
                    type="button"
                    disabled={submitting}
                    className="mt-2 py-1 px-4  grd-btn fs-20 rounded-pill fw-600 d-flex text-center"
                    onClick={() => {
                      postVenderRejectList();
                    }}
                  >
                    {submitting ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      ) : (
        <>
          <Processing />
        </>
      )}
    </>
  );
}

export default Rejection;
