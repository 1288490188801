import { useCallback, useEffect, useRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Tab,
  Tabs,
  Form,
  Modal,
  Button,
} from "react-bootstrap";
import NavBar from "../components/NavBar";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Select from "react-select";
import { conditionalExp } from "../utils/commonFn";

function Manage_Carrier() {
  const [show, setShow] = useState(false);
  const [carrierType, setcarrierType] = useState("ocean");
  const [modalData, setmodalData] = useState({
    career_name: "",
    career_scac: "",
    career_email: "",
    career_type: "ocean",
    transport_type: "",
    career_short_name: "",
    agent_id: "",
    agent_type: "",
  });
  const [allAgents, setallAgents] = useState([]);
  const agentTypes = [
    { label: "Agent at Origin", value: "origin" },
    {
      label: "Agent at Destination",
      value: "destination",
    },
    { label: "Custom Broker", value: "custom_broker" },
  ];
  const [type, setType] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, sethasMore] = useState(false);
  const observer = useRef();
  const tempRef = useRef();
  const [clkData, setclkData] = useState([]);
  const [dataFetched, setdataFetched] = useState(true);
  const [errorMessage, seterrorMessage] = useState("");
  const [deleting, setdeleting] = useState(false);
  const [selectedCarrer, setselectedCarrer] = useState({});
  const [showVendorNew, setShowVendorNew] = useState(false);
  const [addingCarrer, setaddingCarrer] = useState(false);
  const [addingCarrerError, setaddingCarrerError] = useState(null);
  const handleCloseVendorNew = () => setShowVendorNew(false);
  const handleShowVendorNew = () => setShowVendorNew(true);
  const navigate = useNavigate();
  const loaderFnSave = (state, value) => {
    return state ? <Loader /> : value;
  };
  const handleChange = (e) => {
    setmodalData({ ...modalData, [e.target.id]: e.target.value });
  };
  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) {
        return;
      }
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, hasMore]
  );
  const getCarrierDetails = async (from) => {
    setIsLoadingMore(true);
    seterrorMessage(null);
    try {
      const token = localStorage.getItem("mtm_user_token");
      let data = {
        page: from == "reload" ? "0" : currentPage?.toString(),
        career_type: carrierType,
      };

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtm/list_career`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        
        data: data,
      });

      console.log(response, "result");

      setIsLoadingMore(false);
      setdataFetched(true);
      sethasMore(false);
      if (!response.data.error) {
        const newList = response.data.carriersList.filter((newList) => {
          return !clkData.some(
            (existingList) => existingList.career_id === newList.career_id
          );
        });
        if (from == "reload") {
          setclkData(response.data.carriersList);
        } else {
          setclkData([...clkData, ...newList]);
        }
        sethasMore(newList.length > 0);
      } else {
        if (clkData.length == 0) {
          seterrorMessage(response.data.message);
        }
      }
    } catch (error) {
      setclkData([]);
      setdataFetched(true);
      seterrorMessage(error.message);
      setCurrentPage(0);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }

      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  };
  const addCarrerDetails = async (from) => {
    setaddingCarrerError(null);
    setaddingCarrer(true);
    try {
      const token = localStorage.getItem("mtm_user_token");
      let data = {
        career_name: modalData.career_name,
        career_scac: modalData.career_scac,
        career_type: modalData.career_type,
        career_email: modalData.career_email,
        transport_type: conditionalExp(modalData?.career_type == "truck" , modalData.transport_type , null),
        career_short_name: modalData.career_short_name,
        agent_id: modalData.agent_id,
        agent_type: modalData.agent_type,
      };
      if (from == "edit") {
        data["career_id"] = modalData?.career_id;
      }

      const response = await axios({
        url:
          from == "add"
            ? `${process.env.REACT_APP_BASE_URL}/mtm/add_career`
            : `${process.env.REACT_APP_BASE_URL}/mtm/update_career`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        
        data: data,
      });

      setaddingCarrer(false);
      if (!response.data.error) {
        setclkData([]);
        getCarrierDetails("reload");
        handleClose();
      } else {
        setaddingCarrerError(response?.data?.message);
      }
    } catch (error) {
      setaddingCarrer(false);
      setaddingCarrerError(error.message);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }

      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  };
  const getAgentDetails = async (value) => {
    try {
      setallAgents([]);
      setmodalData({
        ...modalData,
        label: null,
        agent_id: null,
        agent_type: null,
      });
      const token = localStorage.getItem("mtm_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/broker/all`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
          type: value,
        },
        
      });

      console.log(response, "result");

      if (!response.data.error) {
        let optionsForAgents = [];
        let brokers = response.data.brokers;
        for (const element of brokers) {
          let obj = {
            label: element?.company_name,
            value: element?.broker_id,
            agent_type: element?.type,
          };
          optionsForAgents.push(obj);
        }
        setallAgents(optionsForAgents);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }

      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  };
  // console.log(allAgents,"all agents");
  // console.log(modalData,"modal data");

  const deleteLableFn = async () => {
    try {
      setdeleting(true);
      let token = localStorage.getItem("mtm_user_token");
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtm/delete_career`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: {
          career_id: selectedCarrer?.career_id,
        },
      });

      if (response.status == 200) {
        setdeleting(false);
        if (response.data.error) {
          alert(response.data.message);
        } else {
          setclkData([]);
          getCarrierDetails("reload");
          handleCloseVendorNew();
        }
      }
    } catch (error) {
      setdeleting(false);
      console.log(error);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("mtm_user_token");
    if (!token) {
      navigate("/");
    }
    if (token) {
      const decodedToken = jwt_decode(token);
      if (decodedToken.exp < Date.now() / 1000) {
        // Token has expired
        navigate("/");
      }
    }
    getCarrierDetails();
  }, [carrierType, currentPage]);

  useEffect(() => {
    if (type) {
      getAgentDetails(type?.value);
    }
  }, [modalData?.career_type, type]);

  return (
    <>
      <NavBar />
      <Container>
        <Row>
          <Col className="col-12 py-3">
            <div className="wrapper-box">
              <div className="d-flex pb-4">
                <div className="py-3">
                  <button
                    type="button"
                    className="back-btn d-flex alig-items-center"
                    onClick={() => {
                      navigate("/manage-self-pricing");
                    }}
                  >
                    <svg className="icon me-2 fs-22">
                      <use href="#icon_leftarrow"></use>
                    </svg>
                    <strong className="fs-14">Back</strong>
                  </button>
                </div>
                <div className="d-flex ms-auto">
                  <button
                    type="button"
                    className="pricing-btn rounded-pill w-btn"
                    onClick={() => {
                      getAgentDetails();
                      setmodalData({
                        career_name: "",
                        career_scac: "",
                        career_email: "",
                        career_type: "ocean",
                        transport_type: "",
                        career_short_name: "",
                        agent_id: null,
                        agent_type: null,
                        label: null,
                      });
                      setType(null);
                      handleShow();
                    }}
                  >
                    Add Carrier
                  </button>
                </div>
              </div>
              <div className="d-flex pt-2">
                <div className="w-25">
                  <select
                    className="form-select"
                    aria-label="Ocean"
                    value={carrierType}
                    onChange={(e) => {
                      setCurrentPage(0);
                      setclkData([]);
                      setcarrierType(e.target.value);
                    }}
                  >
                    <option selected value="ocean">
                      Ocean
                    </option>
                    <option value="air">Air</option>
                    <option value="truck">Truck</option>
                  </select>
                </div>
              </div>
            </div>
            {dataFetched && clkData?.length > 0 && (
              <div className="w-100 selfpricing-tbl">
                <table className="table text-center my-4 border">
                  <thead>
                    <tr className="border" style={{ background: "#EBEEFF" }}>
                      <th>Carrier Name</th>
                      <th>Carrier Code</th>
                      {/* <th>Agent Name</th> */}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataFetched &&
                      clkData?.map((clk, clk_index) => (
                        <tr
                          key={clk?.career_name}
                          ref={
                            clkData?.length == clk_index + 1
                              ? lastBookElementRef
                              : tempRef
                          }
                        >
                          <td>{clk?.career_name}</td>
                          <td>{clk?.career_scac}</td>
                          {/* <td>{clk?.agent_name}</td> */}
                          <td>
                            <div className="d-flex align-items-center justify-content-center">
                              <button
                                type="button"
                                className="p-0 m-0 border-0 bg-body me-4 edit-btn"
                                onClick={() => {
                                  let label = allAgents.find((item) => {
                                    return item.value == clk.agent_id;
                                  });
                                  setmodalData({
                                    career_id: clk?.career_id,
                                    career_name: clk?.career_name,
                                    career_scac: clk?.career_scac,
                                    career_email: clk?.career_email,
                                    career_type: clk?.career_type,
                                    transport_type: clk?.transport_type,
                                    career_short_name: clk?.career_short_name,
                                    agent_id: clk?.agent_id,
                                    agent_type: clk?.agent_type,
                                    label: label?.label,
                                  });
                                  handleShow();
                                }}
                              >
                                <svg className="icon ms-2 fs-22">
                                  <use href="#icon_edit"></use>
                                </svg>
                              </button>
                              <button
                                type="button"
                                className="p-0 m-0 border-0 bg-body me-4 edit-btn"
                                onClick={() => {
                                  setselectedCarrer(clk);
                                  handleShowVendorNew();
                                }}
                              >
                                <svg className="icon ms-2 fs-22">
                                  <use href="#icon_delete"></use>
                                </svg>
                              </button>
                              <button
                                type="button"
                                className="p-0 m-0 border-0 bg-body edit-btn"
                                onClick={() => {
                                  navigate(
                                    `/carrier-profile/${clk?.career_id}/${clk?.career_name}`
                                  );
                                }}
                              >
                                <svg className="icon ms-2 fs-22">
                                  <use href="#icon_arrow"></use>
                                </svg>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}

            {(isLoadingMore || !dataFetched) && (
              <div className="w-100 text-center pt-3">
                <Loader />
              </div>
            )}
            {!isLoadingMore && dataFetched && clkData?.length == 0 && (
              <div className="w-100 text-center ">
                <h1>{errorMessage}</h1>
              </div>
            )}
          </Col>
        </Row>
      </Container>

      <Modal
        className="tracking-modal"
        show={show}
        centered
        onHide={() => {
          setmodalData({
            career_name: "",
            career_scac: "",
            career_email: "",
            career_type: "ocean",
            transport_type: "",
            career_short_name: "",
            agent_id: "",
            agent_type: "",
          });
          handleClose();
        }}
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title>
            {modalData?.career_id ? "Edit Carrier" : "Add Carrier"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="input-box mb-2">
              {modalData.career_type != "" && <label>Carrier Type</label>}
              <select
                required
                className="form-select select-modal"
                value={modalData.career_type}
                onChange={handleChange}
                id="career_type"
              >
                <option selected value="ocean">
                  Ocean
                </option>
                <option value="air">Air</option>
                <option value="truck">Truck</option>
              </select>
            </div>
            {modalData?.career_type == "truck" && (
              <div className="input-box mb-2">
                {modalData.transport_type != "" && (
                  <label>Transport Type</label>
                )}
                <select
                  required={modalData?.career_type == "truck"}
                  className="form-select select-modal"
                  value={modalData.transport_type}
                  onChange={handleChange}
                  id="transport_type"
                >
                  <option value="">Select Transport Type</option>
                  <option value="Domestic">Domestic</option>
                  <option value="International">International</option>
                </select>
              </div>
            )}

            <div className="input-box mb-2">
              {modalData.career_name != "" && (
                <label className="pt-3">Carrier Name</label>
              )}
              <input
                required
                type="text"
                className="w-100"
                id="career_name"
                value={modalData.career_name}
                onChange={handleChange}
                placeholder="Carrier Name"
              />
            </div>

            <div className="input-box mb-2">
              {modalData.career_scac != "" && (
                <label className="pt-3">Carrier Code</label>
              )}
              <input
                required
                type="text"
                className="w-100"
                id="career_scac"
                value={modalData.career_scac}
                onChange={handleChange}
                placeholder="Carrier Code"
              />
            </div>

                {
                  modalData?.career_type == 'truck' && (
                    <div className="input-box mb-2">
              {modalData.career_email != "" && (
                <label className="pt-3">Carrier Email</label>
              )}
              <input
                required
                type="email"
                className="w-100"
                id="career_email"
                value={modalData.career_email}
                onChange={handleChange}
                placeholder="Carrier Email"
              />
            </div>
                  )
                }
            {/* <div className="input-box mt-2">
                  {type && (
                    <label htmlFor="" className="mb-1">
                      Agent Type*
                    </label>
                  )}
                  <Select
                    required
                    value={type}
                    onChange={(option) => setType(option)}
                    options={agentTypes}
                    isSearchable={true}
                    placeholder="Select Agent Type"
                    isClearable
                    isLoading={!agentTypes?.length}
                  />
                </div>

                <div className='input-box mb-2'>
                  {modalData.label!=""&&<label className='pt-3'>Agent Name</label>}
                    <Select
                        required
                        options={allAgents}
                        isSearchable={true}
                        isClearable
                        placeholder="Select Agent Name"
                        value={{
                            label:modalData.label,
                            value: modalData.agent_id,
                            agent_type:modalData.agent_type,
                        }}
                        onChange={(option)=>{
                            setmodalData({
                                ...modalData,
                                agent_type:option.agent_type,
                                agent_id:option.value,
                                label:option.label
                            })
                        }}
                    />
                </div> */}
          </form>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <p className="text-danger">{addingCarrerError}</p>
          <button
            type="submit"
            disabled={conditionalExp(
              modalData.career_name === "" || modalData.career_scac === "",
              true,
              conditionalExp(
                modalData?.career_type == "truck" &&
                  (!modalData?.transport_type || !modalData?.career_email),
                true,
                false
              )
            )}
            className="next1-btn rounded-pill d-block"
            onClick={(e) => {
              e.preventDefault();
              let from = "add";
              if (modalData?.career_id) {
                from = "edit";
              }
              addCarrerDetails(from);
            }}
          >
            {addingCarrer ? <Loader /> : "Submit"}
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={showVendorNew} onHide={handleCloseVendorNew} centered>
        <Modal.Body className="p-4">
          <p className="fs-4 text-center">
            Are you sure you want to delete this Detail?
          </p>
          <div className="d-flex justify-content-center mt-2">
            <Button
              variant="secondary"
              className="me-3"
              onClick={handleCloseVendorNew}
            >
              Close
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                deleteLableFn();
              }}
            >
              {loaderFnSave(deleting, "Delete")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Manage_Carrier;
