import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { conditionalExp, getCountriesFn } from "../utils/commonFn";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "./Loader";
import Select from "react-select";
import ErrorModal from "./ErrorModal";
import { SuccessModal } from "./SuccessModal";
import axios from "axios";

function AgentsBankDetails() {
  const [isLoading, setIsLoading] = useState(false);
  const [showadd, setShowadd] = useState(false);
  const [optionsForCountries, setoptionsForCountries] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [showErrModal, setShowErrModal] = useState(false);
  const [error, setError] = useState({ title: "", err: "" });
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [modalData, setModalData] = useState({
    bank_name: null,
    account_name: null,
    account_number: null,
    country: "",
    swift_code: null,
    bank_address: null,
  });
  const [modalDataErrors, setModalDataErrors] = useState({
    bank_name: false,
    account_name: false,
    account_number: false,
    country: false,
    swift_code: false,
    bank_address: false,
  });
  const navigate = useNavigate();
  const { broker_id } = useParams();

  const handleChange = (e) => {
    setModalData({
      ...modalData,
      [e.target.id]: e.target.value,
    });
    setModalDataErrors({
      ...modalDataErrors,
      [e.target.id]: false,
    });
  };

 

  function smallCheckFnForm() {
    let bank_name = !modalData?.bank_name?.trim() ? true : false;
    let account_name = !modalData?.account_name?.trim() ? true : false;
    let account_number = !modalData?.account_number?.trim() ? true : false;
    let country = !modalData?.country?.value ? true : false;
    let swift_code = !modalData?.swift_code?.trim()
      ? true
      : false;
    let bank_address = !modalData?.bank_address?.trim()
      ? true
      : false;

    if (
      bank_name ||
      account_name ||
      account_number ||
      country ||
      swift_code ||
      bank_address 
    ) {
      setModalDataErrors({
        bank_name,
        account_name,
        account_number,
        country,
        swift_code,
        bank_address,
      });
      return true;
    } else {
      return false;
    }
  }

  async function handleAddBank(event) {
    event.preventDefault();

    if (smallCheckFnForm()) {
      return;
    }

    setIsLoading(true);
    try {
      const token = localStorage.getItem("mtm_user_token");
      let data = {
        ...modalData,
        broker_id: broker_id,
        country: modalData?.country?.value,
      };

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtm/agents/add_edit_bank`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        
        data: data,
      });

      if (!response.data.error) {
        setSuccessMsg(response.data.message);
        setShowSuccessModal(true);
        setBankList([]);
        setShowadd(false);
        setModalData({
          bank_name: null,
          account_name: null,
          account_number: null,
          country: "",
          swift_code: null,
          bank_address: null,
        });
        setTimeout(() => {
          setShowSuccessModal(false);
          setSuccessMsg();
        }, 5000);
      } else {
        setBankList([]);
        setError({ title: "Error", err: response?.data?.message });
        setShowErrModal(true);
      }
    } catch (error) {
      setBankList([]);
      setShowErrModal(true);
      setError({ title: "Error", err: error.message });
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }

      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      await getBankList();
      setIsLoading(false);
    }
  }

  const getBankList = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("mtm_user_token");
      let data = {
        broker_id: broker_id,
      };

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtm/agents/list_bank`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        
        data: data,
      });

      if (!response.data.error) {
        setBankList(response?.data?.banks);
      } else {
        setBankList([]);
      }
    } catch (error) {
      setBankList([]);
      setShowErrModal(true);
      setError({ title: "Error", err: error.message });
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }

      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getBankList();
    getCountriesFn(setoptionsForCountries, null, navigate);
  }, []);
  return (
    <>
      <div>
        <Row>
          <Col>
            <button
              type="button"
              className="pricing-btn rounded-pill"
              onClick={() => {
                setShowadd(true);
              }}
            >
              Add Bank Details
            </button>
          </Col>
        </Row>
        <hr />
        <div>
          {conditionalExp(
            !bankList.length && !isLoading,
            <div className="w-100 text-center ">
              <h1>No Banks Found.</h1>
            </div>,
            conditionalExp(
              isLoading,
              <div className="w-100 text-center ">
                <Loader />
              </div>,
              <div className="w-100 selfpricing-tbl">
                <table className="table text-center my-4 border">
                  <thead>
                    <tr className="border" style={{ background: "#EBEEFF" }}>
                      <th>Bank Name</th>
                      <th>Account Name</th>
                      <th>Account Number</th>
                      <th>SWIFT</th>
                      <th>Bank Address</th>
                      <th>Country</th>
                      <th>&nbsp; </th>
                    </tr>
                  </thead>
                  <tbody>
                    {bankList?.map((office) => (
                      <tr key={office?.bank_name + office?.bank_id}>
                        <td>{office?.bank_name}</td>
                        <td >
                          {office?.account_name}
                        </td>
                        <td>{office?.account_number}</td>
                        <td>{office?.swift_code}</td>
                        <td className="text-truncate" style={{ maxWidth: "200px",height:'max-content' }}>{office?.bank_address}</td>
                        <td>{office?.country}</td>
                        <td>
                          <div className="d-flex align-items-center justify-content-center">
                            <button
                              type="button"
                              className="p-0 m-0 border-0 bg-body me-4 edit-btn"
                              onClick={() => {
                                setModalData({
                                  bank_id: office?.bank_id,
                                  bank_name: office?.bank_name,
                                  account_name: office?.account_name,
                                  account_number: office?.account_number,
                                  country: {
                                    label: office?.country,
                                    value: office?.country,
                                  },
                                  swift_code:
                                    office?.swift_code,
                                  bank_address:
                                    office?.bank_address,
                                 
                                });
                                setShowadd(true);
                              }}
                            >
                              <svg className="icon ms-2 fs-22">
                                <use href="#icon_edit"></use>
                              </svg>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )
          )}
        </div>
      </div>
      <Modal
        show={showadd}
        centered
        onHide={() => {
          setIsLoading(false);
          setShowadd(false);
          setModalData({
            bank_name: null,
            account_name: null,
            account_number: null,
            country: "",
            swift_code: null,
            bank_address: null,
          });
        }}
        className="tracking-modal"
        scrollable={true}
      >
        <Modal.Header className="border-0 text-center fs-26 fw-bold pb-2" closeButton>{conditionalExp(
                modalData?.bank_id,
                "Edit Bank Details",
                "Add Bank Details"
              )}</Modal.Header>
        <Modal.Body className="px-4 overflow-auto">
          <form onSubmit={handleAddBank} className="p-4">
            <div className="input-box mb-3">
              {modalData?.bank_name && modalData?.bank_name != "" && (
                <label htmlFor="" className="fw-bold">
                  Bank Name*
                </label>
              )}
              <input
                value={modalData?.bank_name}
                onChange={handleChange}
                required
                type="text"
                className="w-100"
                id="bank_name"
                placeholder="Enter Bank Name*"
              />
              {modalDataErrors?.bank_name && (
                <p className="text-danger">Please Enter Bank Name</p>
              )}
            </div>
            <div className="input-box mb-3">
              {modalData?.account_name &&
                modalData?.account_name != "" && (
                  <label htmlFor="" className="fw-bold">
                    Account Name*
                  </label>
                )}
              <input
                value={modalData?.account_name}
                onChange={handleChange}
                required
                type="text"
                className="w-100"
                id="account_name"
                placeholder="Enter Account Name*"
              />
              {modalDataErrors?.account_name && (
                <p className="text-danger">Please Enter Account Name</p>
              )}
            </div>

            <div className="input-box mb-3">
              {modalData?.account_number &&
                modalData?.account_number != "" && (
                  <label htmlFor="" className="fw-bold">
                    Account Number*
                  </label>
                )}
              <input
                value={modalData?.account_number}
                onChange={handleChange}
                required
                type="text"
                className="w-100"
                id="account_number"
                placeholder="Enter Account Number*"
              />
              {modalDataErrors?.account_number && (
                <p className="text-danger">Please Enter Account Number</p>
              )}
            </div>

            <div className="input-box mb-3">
              {modalData?.swift_code &&
                modalData?.swift_code != "" && (
                  <label htmlFor="" className="fw-bold">
                    SWIFT*
                  </label>
                )}
              <input
                value={modalData?.swift_code}
                onChange={handleChange}
                required
                type="text"
                className="w-100"
                id="swift_code"
                placeholder="Enter SWIFT*"
              />
              {modalDataErrors?.swift_code && (
                <p className="text-danger">Please Enter SWIFT</p>
              )}
            </div>

            <div className="input-box mb-3">
              {modalData?.bank_address &&
                modalData?.bank_address != "" && (
                  <label htmlFor="" className="fw-bold">
                    Bank Address*
                  </label>
                )}
              <input
                value={modalData?.bank_address}
                onChange={handleChange}
                required
                type="email"
                className="w-100"
                id="bank_address"
                placeholder="Enter Bank Address*"
              />
              {modalDataErrors?.bank_address && (
                <p className="text-danger">
                  Please Enter Valid Bank Address
                </p>
              )}
            </div>

            <div className="input-box mb-3">
              {modalData.country && (
                <label htmlFor="" className="fw-bold mb-1">
                  Country*
                </label>
              )}
              <Select
                required
                value={modalData.country}
                onChange={(option) => {
                  setModalData({ ...modalData, country: option });
                  if (option) {
                    setModalDataErrors({ ...modalDataErrors, country: false });
                  } else {
                    setModalDataErrors({ ...modalDataErrors, country: true });
                  }
                }}
                options={optionsForCountries}
                isSearchable={true}
                placeholder="Select Country*"
                isClearable
                isLoading={!optionsForCountries?.length}
              />
              {modalDataErrors?.country && (
                <p className="text-danger">Please Select Country</p>
              )}
            </div>

            

           
            

            <div className="d-flex">
              <div className="ms-auto">
                <button
                  type="submit"
                  className="grd-btn rounded-pill"
                  onClick={(e) => {
                    handleAddBank(e);
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? <Loader /> : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <ErrorModal
        error={error}
        showErrModal={showErrModal}
        setShowErrModal={setShowErrModal}
      />
      <SuccessModal
        showSuccess={showSuccessModal}
        handleCloseSuccess={() => {
          setShowSuccessModal(false);
          setSuccessMsg();
        }}
        successMessagePopup={successMsg}
      />
    </>
  );
}

export default AgentsBankDetails;
