// import React from "react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { CKEditor } from "@ckeditor/ckeditor5-react";

// const editorConfiguration = {
//   toolbar: {
//     items: [
//       "heading",
//       "|",
//       "bold",
//       "italic",
//       "|",
//       "link",
//       "|",
//       "bulletedList",
//       "numberedList",
//       "|",
//       "insertTable",
//       "|",
//       "imageUpload",
//       "blockQuote",
//       "|",
//       "undo",
//       "redo",
//     ],
//     shouldNotGroupWhenFull: true,
//   },
//   // CKFinder configuration (if needed)
//   ckfinder: {
//     uploadUrl: `${process.env.REACT_APP_BASE_URL}/mtm/comments/upload_image`,
//   },
//   //   image: {
//   //     toolbar: ['imageStyle:inline', 'imageStyle:wrapText', 'imageStyle:breakText', '|',
//   //     'toggleImageCaption', 'imageTextAlternative'],
//   //     resizeUnit: '%',
//   //     styles: [
//   //         // This option is equal to a situation where no style is applied.
//   //         'full',
//   //         // This represents an image aligned to the left.
//   //         'alignLeft',
//   //         // This represents an image aligned to the right.
//   //         'alignRight'
//   //     ]

//   // },
// };

// class Ckeditor extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       editorData: props.commentData || "",
//       setEditorData: props.setCommentData || "",
//       editSave: props.hasOwnProperty("editSave") ? props.editSave : true,
//       quotestatus: props.hasOwnProperty("quotestatus") ? props?.quotestatus : 0,
//       from: props.hasOwnProperty("from") ? props?.from : "Default",
//     };
//   }
//   render() {
//     return (
//       <div className="App">
//         <CKEditor
//           editor={ClassicEditor}
//           config={editorConfiguration}
//           data={this.state.editorData}
//           disabled={
//             this.state.editSave === false || this.state.quotestatus === 2
//           }
//           onReady={(editor) => {
//             // You can store the "editor" and use it when needed.
//             // console.log("Editor is ready to use!", editor);
//             editor.editing.view.change((writer) => {
//               writer.setStyle(
//                 "height",
//                 "50vh",
//                 editor.editing.view.document.getRoot()
//               );
//             });
//           }}
//           onChange={(event, editor) => {
//             const data = editor.getData();
//             this.state.setEditorData(data);
//             console.log(data);
//           }}
//         />
//       </div>
//     );
//   }
// }

// export default Ckeditor;
import React from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

class Ckeditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorData: props.commentData || "",
      setEditorData: props.setCommentData || "",
      editSave: props.hasOwnProperty("editSave") ? props.editSave : true,
      quotestatus: props.hasOwnProperty("quotestatus") ? props?.quotestatus : 0,
      from: props.hasOwnProperty("from") ? props?.from : "Default",
    };
  }

  render() {
    // Conditionally adjust toolbar items based on the value of 'from'
    const editorConfiguration = {
      toolbar: {
        items:
          this.state.from === "pricing"
            ? []
            : [
                "heading",
                "|",
                "bold",
                "italic",
                "|",
                "link",
                "|",
                "bulletedList",
                "numberedList",
                "|",
                "insertTable",
                "|",
                "imageUpload",
                "blockQuote",
                "|",
                "undo",
                "redo",
              ],
        shouldNotGroupWhenFull: true,
      },
      ckfinder: {
        uploadUrl: `${process.env.REACT_APP_BASE_URL}/mtm/comments/upload_image`,
      },
    };

    return (
      <div className="App">
        <CKEditor
          editor={ClassicEditor}
          config={editorConfiguration}
          data={this.state.editorData}
          disabled={
            this.state.editSave === false || this.state.quotestatus === 2
          }
          onReady={(editor) => {
            editor.editing.view.change((writer) => {
              writer.setStyle(
                "height",
                "50vh",
                editor.editing.view.document.getRoot()
              );
            });
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            this.state.setEditorData(data);
            console.log(data);
          }}
        />
      </div>
    );
  }
}

export default Ckeditor;
