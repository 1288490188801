import axios from "axios";
export const getCountriesFn = async (
  setoptionsForCountries,
  setallCountries,
  navigate,
  customerId
) => {
  try {
    let token = localStorage.getItem("mtm_user_token");

    const response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/country/list`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        jwt_token: token,
      },
    });

    console.log(response, "result");
    if (response.data.status) {
      let optionsForCountries = [];
      let countries = response?.data?.country;
      for (let i of countries) {
        const element = i;
        let obj = {};
        obj["label"] = element?.country;
        obj["value"] = element?.country;
        optionsForCountries.push(obj);
      }
      setoptionsForCountries && setoptionsForCountries(optionsForCountries);

      setallCountries && setallCountries(response?.data?.country);
    }
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate && navigate("/login");
      return;
    }
    if (error.code === "ECONNABORTED") {
      console.log("Request timed out");
    } else {
      console.log("An error occurred:", error);
    }
  }
};

export const getPortDetails = async (
  countryCode,
  setoptionsForPorts,
  setallPortsForCountries,
  navigate
) => {
  try {
    let token = localStorage.getItem("mtm_user_token");

    const response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/country/port/search`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        jwt_token: token,
      },
      data: {
        country_sort_code: countryCode,
      },
    });

    console.log(response, "port details");
    if (response.data.status) {
      let optionsForPorts = [];
      let ports = response?.data?.port_details;
      for (let i of ports) {
        const element = i;
        let obj = {};
        obj["label"] = `${element?.port_name} | ${element?.global_port_code}`;
        obj["value"] = `${element?.port_name} | ${element?.global_port_code}`;
        optionsForPorts.push(obj);
      }
      setoptionsForPorts && setoptionsForPorts(optionsForPorts);
      setallPortsForCountries &&
        setallPortsForCountries(response?.data?.port_details);
    }
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/login");
      return;
    }
    if (error.code === "ECONNABORTED") {
      console.log("Request timed out");
    } else {
      console.log("An error occurred:", error);
    }
  }
};
export const getPOL_PODPorts = async (
  setOptionsForPortsOrigin,
  setOptionsForPortsDestination,
  navigate
) => {
  try {
    let token = localStorage.getItem("mtm_user_token");

    const response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/mtm/everest/getport`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        jwt_token: token,
      },
    });

    if (!response.data.error) {
      let portloadings = [];
      let portdischarge = [];
      let pol = response?.data?.portloadings;
      let pod = response?.data?.portdischarge;
      for (let i of pol) {
        const element = i;
        let obj = {};
        obj["label"] = `${element?.port_of_loading}`;
        obj["value"] = `${element?.port_of_loading}`;
        obj["country"] = `${element?.origin_country}`;
        portloadings.push(obj);
      }
      for (let i of pod) {
        const element = i;
        let obj = {};
        obj["label"] = `${element?.port_of_discharge}`;
        obj["value"] = `${element?.port_of_discharge}`;
        portdischarge.push(obj);
      }

      setOptionsForPortsOrigin && setOptionsForPortsOrigin(portloadings);
      setOptionsForPortsDestination &&
        setOptionsForPortsDestination(portdischarge);
    }
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/login");
      return;
    }
    if (error.code === "ECONNABORTED") {
      console.log("Request timed out");
    } else {
      console.log("An error occurred:", error);
    }
  }
};

export const getSKUListFootprint = async (setSkuListDetailsRaw, navigate) => {
  try {
    let token = localStorage.getItem("mtm_user_token");

    const response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/mtm/everest/item/details`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        jwt_token: token,
      },
    });

    if (!response.data.error) {
      setSkuListDetailsRaw(response.data.items);
    }
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/login");
      return;
    }
    if (error.code === "ECONNABORTED") {
      console.log("Request timed out");
    } else {
      console.log("An error occurred:", error);
    }
  }
};

export const getCountryCode = (allCountries, selectedOptionCountry) => {
  let countryCode = "";
  for (let i = 0; i < allCountries?.length; i++) {
    const element = allCountries[i];
    if (element?.country == selectedOptionCountry?.label) {
      countryCode = element?.country_sort_code;
      break;
    }
  }
  return countryCode;
};

export async function getCarrier(setCarrierList, navigate) {
  const token = localStorage.getItem("mtm_user_token");
  try {
    let carriers = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/career/dropdowns`,
      {
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
      }
    );
    setCarrierList &&
      setCarrierList(
        carriers?.data?.career_details?.map((option) => {
          return {
            value: option.career_scac,
            label: `${option?.career_name} (${option.career_scac})`,
          };
        })
      );
  } catch (err) {
    if (err.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate && navigate("/login");
      return;
    }
    console.log(err);
  }
}

export const getVendorList = async (
  customerId,
  setoptionsModified,
  setvendorListDetailsRaw,
  setvendorListDetails,
  navigate
) => {
  console.log(customerId, "getVendorList customer_id");
  try {
    const token = localStorage.getItem("mtm_user_token");
    let response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/vendor/all`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        jwt_token: token,
        customer_id: customerId,
      },
    });
    if (response.status == 200) {
      if (!response.data.error) {
        let data = response.data.vendors?.map((item) => {
          return {
            label: item.vendor_name,
            value: item.vendor_name,
          };
        });
        setoptionsModified &&
          setoptionsModified([
            {
              label: "Add Vendor",
              value: "Add Vendor",
            },
            ...data,
          ]);
        // console.log(data,"data")
        setvendorListDetails && setvendorListDetails(response.data.vendors);
        setvendorListDetailsRaw &&
          setvendorListDetailsRaw(response.data.vendors);
        return response.data?.vendors;
      }
    }
  } catch (error) {
    console.log(error);
    if (error.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/login");
      return;
    }
  }
};
export const getWarehouseList = async (
  customerId,
  setwarehouseListDetailsRaw,
  setwarehouseListDetails,
  navigate,
  setIsgettingwarehouselist
) => {
  try {
    setIsgettingwarehouselist && setIsgettingwarehouselist(true);
    const token = localStorage.getItem("mtm_user_token");
    let response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/mtmwarehouse/getall`,
      method: "POST",
      data: { customer_id: customerId },
      headers: {
        "Content-Type": "application/json",
        jwt_token: token,
      },
    });
    if (response.status == 200) {
      if (!response.data.error) {
        let options = response.data.warehouses?.map((item) => {
          return {
            label: item.warehouse_code + " | " + item.warehouse_name,
            value: item.warehouse_code,
            warehouse_id: item.warehouse_id,
            warehouse_name: item.warehouse_name,
          };
        });
        setwarehouseListDetails && setwarehouseListDetails(options);
        setwarehouseListDetailsRaw &&
          setwarehouseListDetailsRaw(response.data.warehouses);

        return options;
      }
    }
  } catch (error) {
    console.log(error);
    if (error.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/login");
      return;
    }
  } finally {
    setIsgettingwarehouselist && setIsgettingwarehouselist(false);
  }
};

export const getSKUList = async (
  customerId,
  setskuListDetails,
  setskuListDetailsRaw,
  navigate,
  setskuListOptions
) => {
  try {
    const token = localStorage.getItem("mtm_user_token");
    let response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/sku/all`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        jwt_token: token,
        customer_id: customerId,
      },
    });
    if (response.status == 200) {
      if (!response.data.error) {
        setskuListDetails && setskuListDetails(response.data.skuItems);
        let options = response.data.skuItems?.map((item) => {
          return {
            label: item?.item_code,
            value: item?.item_code,
          };
        });
        setskuListOptions &&
          setskuListOptions([
            ...options,
            {
              label: "Add SKU",
              value: "Add SKU",
            },
          ]);
        setskuListDetailsRaw && setskuListDetailsRaw(response.data.skuItems);
      }
    }
  } catch (error) {
    console.log(error);
    if (error.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/login");
      return;
    }
  }
};

export async function getQuotes(
  customer_id,
  page,
  setoptionsModifiedQuote,
  setQuoteDetailsFetched,
  navigate,
  {
    selectedProductType,
    selectedDate,
    selectedShipment,
    clickupData,
    setCurrentClickup,
    setdataFetchedPillsQuotes,
    getBookingList,
  }
) {
  setdataFetchedPillsQuotes && setdataFetchedPillsQuotes(false);
  const token = localStorage.getItem("mtm_user_token");
  let data = { customer_id: customer_id };

  if (page === "home") {
    let clickupId = clickupData?.find(
      (clickup) =>
        clickup.dropdown_type == "product" &&
        clickup.clickup_product_name == selectedProductType
    );
    setCurrentClickup && setCurrentClickup(clickupId);

    data = {
      ...data,
      from_date: selectedDate[0],
      to_date: selectedDate[1],
      booking_status: selectedShipment,
      product_type: clickupId?.clickup_product_id,
    };
  }
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/mtmbooking/customer/opportunity`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        jwt_token: token,
      },
      data: data,
    });

    if (!response.data.error) {
      const filteredQuotes = await response?.data?.quotes.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      let data = filteredQuotes?.map((item) => {
        return {
          label: `${item?.opportunity_name} | ${item?.custom_task_id}`,
          value: item?.quote_id,
        };
      });
      setoptionsModifiedQuote && setoptionsModifiedQuote(data);
      setQuoteDetailsFetched && setQuoteDetailsFetched(filteredQuotes);
      setdataFetchedPillsQuotes && setdataFetchedPillsQuotes(true);
      getBookingList && getBookingList(filteredQuotes);
    }
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/login");
      return;
    }
    console.log(error);
    setdataFetchedPillsQuotes && setdataFetchedPillsQuotes(true);
  }
}

export async function getCustomerDetails(
  setIsLoading,
  customerId,
  navigate,
  setCustomerData,
  updateCustomer
) {
  setIsLoading && setIsLoading(true);
  const token = localStorage.getItem("mtm_user_token");
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/single`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        jwt_token: token,
      },
      data: {
        customer_id: customerId,
      },
    });

    if (!response.data.error) {
      const userData = JSON.parse(localStorage.getItem("mtm_user_data"));
      if (
        !response?.data?.ops_users?.find((e) => e.user_id == userData?.id) &&
        userData?.user_type != 1
      ) {
        console.log("not authorized", response?.data?.ops_users);
        navigate("/unauthorized");
        return;
      }
      setCustomerData && setCustomerData(response.data?.custumer);
      updateCustomer && updateCustomer(response.data?.custumer);
      setIsLoading && setIsLoading(false);
    }
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/login");
      return;
    }
    console.log(error);
    setIsLoading && setIsLoading(false);
  }
}

export async function getTagCalculationList(
  selectedDateTagList,
  setTagCalculationList,
  setIsLoadingTagCalList,
  navigate,
  customerId
) {
  setIsLoadingTagCalList && setIsLoadingTagCalList(true);
  const token = localStorage.getItem("mtm_user_token");
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/mtmtagcalculation/getlist`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        jwt_token: token,
      },
      data: {
        from_date: selectedDateTagList[0] || null,
        to_date: selectedDateTagList[1] || null,
        customer_id: customerId,
      },
    });

    if (!response.data.error) {
      setTagCalculationList && setTagCalculationList(response?.data?.tagList);
    } else {
      setTagCalculationList && setTagCalculationList([]);
    }
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/login");
      return;
    }
    console.log(error);
  } finally {
    setIsLoadingTagCalList && setIsLoadingTagCalList(false);
  }
}

export async function getTagSKUList(
  setTagCalculationSKUList,
  setIsLoadingTagCalSKU,
  navigate,
  customerId
) {
  setIsLoadingTagCalSKU && setIsLoadingTagCalSKU(true);
  const token = localStorage.getItem("mtm_user_token");
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/mtmtagcalculation/getSKUlist`,
      method: "POST",
      data: {
        customer_id: customerId,
      },
      headers: {
        "Content-Type": "application/json",
        jwt_token: token,
      },
    });

    if (!response.data.error) {
      setTagCalculationSKUList &&
        setTagCalculationSKUList(response?.data?.tagList);
    } else {
      setTagCalculationSKUList && setTagCalculationSKUList([]);
    }
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/login");
      return;
    }
    console.log(error);
  } finally {
    setIsLoadingTagCalSKU && setIsLoadingTagCalSKU(false);
  }
}

export const getFormattedDate = (item) => {
  const currentDate = item["$d"];
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export async function syncWarehouseFromSap(
  setIsLoadingWarehoseSync,
  navigate,
  getWarehouseList,
  customerId,
  { setShowSuccess, setsuccessMessagePopup, setShowErrModal, setError }
) {
  setIsLoadingWarehoseSync && setIsLoadingWarehoseSync(true);
  const token = localStorage.getItem("mtm_user_token");
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/mtmwarehouse/syncfromsap`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        jwt_token: token,
        customer_id: customerId,
      },
    });

    if (!response.data.error) {
      getWarehouseList && (await getWarehouseList());
      setsuccessMessagePopup &&
        setsuccessMessagePopup("Warehouse Synced Successfully");
      setShowSuccess && setShowSuccess(true);
    } else {
      setError && setError({ title: "Error", err: response?.data?.message });
      setShowErrModal && setShowErrModal(true);
    }
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/login");
      return;
    }
    console.log(error);
  } finally {
    setIsLoadingWarehoseSync && setIsLoadingWarehoseSync(false);
  }
}

export function conditionalExp(condition, a, b) {
  if (condition) {
    return a;
  } else {
    return b;
  }
}

export const getDifferentCurrencies = (selectedQuotePriceDetails) => {
  let currencies = ["USD"];
  for (let i = 0; i < selectedQuotePriceDetails.length; i++) {
    const element = selectedQuotePriceDetails[i];
    if (!currencies?.includes(element.rate_currency)) {
      currencies.push(element?.rate_currency);
    }
  }
  return currencies;
};

export async function allAiseBarPrices(
  allDetails,
  setasideBarQuotePrices,
  container_type
) {
  let obj = {};

  for (let i = 0; i < allDetails?.length; i++) {
    const detailElement = allDetails[i];
    if (detailElement?.blockprices?.length > 0) {
      if (container_type == "20GP + 40GP") {
        console.log("container_type if condition");
        let blockPricesGround = detailElement?.blockprices?.filter(
          (item) =>
            item?.rate_type_code === "GROUND_TOTAL" &&
            item?.rate_currency == "USD"
        );
        // console.log(blockPricesGround,"blockPricesGround....")

        obj[blockPricesGround[0]?.freightify_id] = {
          amount_usd:
            parseFloat(blockPricesGround[0]?.amount_usd || 0) +
            parseFloat(blockPricesGround[1]?.amount_usd || 0),
          actual_amount_usd:
            parseFloat(blockPricesGround[0]?.actual_amount_usd || 0) +
            parseFloat(blockPricesGround[1]?.actual_amount_usd || 0),
        };
      } else {
        let blockPricesGround = detailElement?.blockprices?.find(
          (item) =>
            item?.rate_type_code === "GROUND_TOTAL" &&
            item?.rate_currency == "USD"
        );

        obj[blockPricesGround?.freightify_id] = {
          amount_usd: parseFloat(blockPricesGround?.amount_usd || 0),
          actual_amount_usd: parseFloat(
            blockPricesGround?.actual_amount_usd || 0
          ),
        };
      }
    } else {
      console.log(
        detailElement?.freightify_id,
        "detailElement?.freightify_id  in else..."
      );
      let sum = 0;
      let sum_original = 0;
      let quote_price_details = detailElement?.quote_price_details;
      let currencies = getDifferentCurrencies(quote_price_details);
      console.log(currencies, "currencies....in comoon function");
      for (let l = 0; l < currencies.length; l++) {
        const currencyElement = currencies[l];
        let curr = quote_price_details?.filter(
          (item) => item?.rate_currency === currencyElement
        );
        let current_price = 0;
        let current_price_original = 0;

        for (let m = 0; m < curr.length; m++) {
          const currElement = curr[m];
          current_price += parseFloat(currElement?.amount_usd || 0);
          current_price_original += parseFloat(
            currElement?.actual_amount_usd || 0
          );
        }
        if (currencyElement == "USD") {
          sum += current_price;
          sum_original += current_price_original;
        } else {
          let converted_price = await convertPrice(
            current_price,
            currencyElement,
            "USD"
          );
          let converted_price_original = await convertPrice(
            current_price_original,
            currencyElement,
            "USD"
          );
          sum += isNaN(converted_price) ? 0 : converted_price;
          sum_original += isNaN(converted_price_original)? 0 : converted_price_original;
        }
      }
      obj[detailElement?.freightify_id] = {
        amount_usd: sum,
        actual_amount_usd: sum_original,
      };
    }
  }
  setasideBarQuotePrices(obj);
}

export function debounce(func, delay) {
  let timeoutId;

  return function (...args) {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

export const currencyOptions = [
  {
    label: "USD-US Dollar",
    value: "USD",
  },
  {
    label: "EUR-Euro",
    value: "EUR",
  },
  {
    label: "GBP-British Pound",
    value: "GBP",
  },
  {
    label: "CAD-Canadian Dollar",
    value: "CAD",
  },
  {
    label: "AUD-Australian Dollar",
    value: "AUD",
  },
  {
    label: "JPY-Japanese Yen",
    value: "JPY",
  },
  {
    label: "INR-Indian Rupee",
    value: "INR",
  },
  {
    label: "NZD-New Zealand Dollar",
    value: "NZD",
  },
  {
    label: "CHF-Swiss Franc",
    value: "CHF",
  },
  {
    label: "ZAR-South African Rand",
    value: "ZAR",
  },
  {
    label: "RUB-Russian Ruble",
    value: "RUB",
  },
  {
    label: "CNY-Chinese Yuan Renminbi",
    value: "CNY",
  },
  {
    label: "MXN-Maxican Peso",
    value: "MXN",
  },
  {
    label: "BRL-Brazilian Real",
    value: "BRL",
  },
  {
    label: "GTQ-Guatemalan Quetzal",
    value: "GTQ",
  }
];

// export const convertPrice = async (price, currency_from, currency_to) => {
//   try {
//     const result = await convert(
//       parseFloat(price || 0),
//       currency_from,
//       currency_to
//     );
//     return result;
//   } catch (error) {
//     // Handle the error gracefully, provide default values, or try an alternative approach.
//     console.error("Error converting price:", error);
//     return null; // Or provide a default conversion value
//   }
// };

export const convertPrice = async (price, currency_from, currency_to) => {
  try {
    if (price <= 0) {
      return price;
    }
    const token = localStorage.getItem("mtm_user_token");

    const response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/mtm/currency/convert`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        jwt_token: token,
      },
      data: {
        from_currency: currency_from,
        to_currency: currency_to,
        amount: price,
      },
    });

    if (!response.data?.error) {
      return parseFloat(response.data?.amount);
    } else {
      return 0;
    }
  } catch (error) {
    // Handle the error gracefully, provide default values, or try an alternative approach.
    console.error("Error converting price:", error);
    return null; // Or provide a default conversion value
  }
};
