import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "../pages/Login";
import Filter from "../pages/Filter";
import LayoutOutlet from "./LayoutOutlet";
import Home2 from "../pages/Home2";
import Accept from "../pages/Accept";
import Rejection from "../pages/Rejection";
import Save2 from "../pages/Save2";
import Trade from "../pages/Trade";
import LayoutOutlet2 from "./LayoutOutlet2";
import Pricing from "../pages/Pricing";
import Manage_Carrier from "../pages/Manage_Carrier";
import Self_Pricing from "../pages/Self_Pricing";
import Agents from "../pages/Agents";
import Carrier_Profile from "../pages/Carrier_Profile";
import Agents_Profile from "../pages/Agents_Profile";

function Routing() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/filter" element={<Filter />}></Route>
          <Route
            path="/pricing/:quote_id/:task_id/:trade"
            element={<Pricing />}
          />
          <Route
            path="/pricing/accept/:custom_task_id/:freightify_id/:approval_uniq_id"
            element={<Accept />}
          ></Route>
          <Route
            path="/pricing/rejection/:custom_task_id/:freightify_id/:approval_uniq_id"
            element={<Rejection />}
          ></Route>
          <Route path="/saved" element={<Save2 />}></Route>

          <Route path="/" element={<LayoutOutlet />}>
            <Route
              path="/filter/:quote_id/:task_id/:trade"
              element={<Home2 />}
            />
          </Route>
          <Route path="/manage-carrier" element={<Manage_Carrier />}></Route>
          <Route path="/carrier-profile/:career_id/:career_name" element={<Carrier_Profile />}></Route>
          <Route path="/manage-self-pricing" element={<Self_Pricing />}></Route>
          <Route path="/manage-agents" element={<Agents />}></Route>
          <Route path="/agents-profile/:broker_id/:company_name" element={<Agents_Profile />}></Route>
          <Route path="/" element={<LayoutOutlet2 />}>
            <Route path="/manage_trade_margin" element={<Trade />}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default Routing;
