import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { conditionalExp, getCountriesFn } from "../utils/commonFn";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "./Loader";
import Select from "react-select";
import ErrorModal from "./ErrorModal";
import { SuccessModal } from "./SuccessModal";
import axios from "axios";
import  data  from 'currency-codes-ts';
function CreditStandardPrice() {
  const [isLoading, setIsLoading] = useState(false);
  const [showadd, setShowadd] = useState(false);
  const [optionsForCurrency, setoptionsForCurrency] = useState([]);
  const [priceList, setPriceList] = useState([]);
  const [showErrModal, setShowErrModal] = useState(false);
  const [error, setError] = useState({ title: "", err: "" });
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [modalData, setModalData] = useState({
    price_name: null,
    amount: null,
    currency: "",
    credit_days: null,
  });
  const [modalDataErrors, setModalDataErrors] = useState({
    price_name: false,
    amount: false,
    currency: false,
    credit_days: false,
    
  });
  const navigate = useNavigate();
  const { career_id } = useParams();

  const handleChange = (e) => {
    setModalData({
      ...modalData,
      [e.target.id]: e.target.value,
    });
    setModalDataErrors({
      ...modalDataErrors,
      [e.target.id]: false,
    });
  };


  function smallCheckFnForm() {
    let price_name = !modalData?.price_name?.trim() ? true : false;
    let amount = !modalData?.amount?.trim() ? true : false;
    let currency = !modalData?.currency?.value ? true : false;
    let credit_days = !modalData?.credit_days?.trim() ? true : false;

    if (
      price_name ||
      amount ||
      currency ||
      credit_days 
    ) {
      setModalDataErrors({
        price_name,
        amount,
        currency,
        credit_days,
      });
      return true;
    } else {
      return false;
    }
  }

  async function handleAddPrice(event) {
    event.preventDefault();

    if (smallCheckFnForm()) {
      return;
    }

    setIsLoading(true);
    try {
      const token = localStorage.getItem("mtm_user_token");
      let data = {
        ...modalData,
        career_id: career_id,
        currency: modalData?.currency?.value,
      };

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtm/carrier/add_edit_price`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        
        data: data,
      });

      if (!response.data.error) {
        setSuccessMsg(response.data.message);
        setShowSuccessModal(true);
        setPriceList([]);
        setShowadd(false);
        setModalData({
          price_name: null,
          amount: null,
          currency: "",
          credit_days: null,
        });
        setTimeout(() => {
          setShowSuccessModal(false);
          setSuccessMsg();
        }, 5000);
      } else {
        setPriceList([]);
        setError({ title: "Error", err: response?.data?.message });
        setShowErrModal(true);
      }
    } catch (error) {
      setPriceList([]);
      setShowErrModal(true);
      setError({ title: "Error", err: error.message });
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }

      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      await getPriceList();
      setIsLoading(false);
    }
  }

  const getPriceList = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("mtm_user_token");
      let data = {
        career_id: career_id,
      };

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtm/carrier/list_price`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        
        data: data,
      });

      if (!response.data.error) {
        setPriceList(response?.data?.prices);
      } else {
        setPriceList([]);
      
      }
    } catch (error) {
      setPriceList([]);
      setShowErrModal(true);
      setError({ title: "Error", err: error.message });
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }

      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
// console.log(data);
    setoptionsForCurrency(data.data?.map(cr=> ({
      label: cr?.currency + " | " + cr?.code,
      value : cr?.code
    })))

    getPriceList();
  }, []);
  return (
    <>
      <div>
        <Row>
          <Col>
            <button
              type="button"
              className="pricing-btn rounded-pill"
              onClick={() => {
                setShowadd(true);
              }}
            >
              Add Standard Prices
            </button>
          </Col>
        </Row>
        <hr />
        <div>
          {conditionalExp(
            !priceList.length && !isLoading,
            <div className="w-100 text-center ">
              <h1>No Standard Prices Found.</h1>
            </div>,
            conditionalExp(
              isLoading,
              <div className="w-100 text-center ">
                <Loader />
              </div>,
              <div className="w-100 selfpricing-tbl">
                <table className="table text-center my-4 border">
                  <thead>
                    <tr className="border" style={{ background: "#EBEEFF" }}>
                      <th>Price Name</th>
                      <th>Amount</th>
                      <th>Currency</th>
                      <th>Credit Days</th>
                      <th>&nbsp; </th>
                    </tr>
                  </thead>
                  <tbody>
                    {priceList?.map((office) => (
                      <tr key={office?.price_name + office?.credit_price_id}>
                        <td>{office?.price_name}</td>
                        <td>{office?.amount}</td>
                        <td>{optionsForCurrency?.find(c=>c.value == office?.currency)?.label}</td>
                        <td>{office?.credit_days}</td>
                        <td>
                          <div className="d-flex align-items-center justify-content-center">
                            <button
                              type="button"
                              className="p-0 m-0 border-0 bg-body me-4 edit-btn"
                              onClick={() => {
                                setModalData({
                                  credit_price_id: office?.credit_price_id,
                                  price_name: office?.price_name,
                                  amount: office?.amount,
                                  currency: {
                                    label: office?.currency,
                                    value: office?.currency,
                                  },
                                  credit_days: office?.credit_days,
                                });
                                setShowadd(true);
                              }}
                            >
                              <svg className="icon ms-2 fs-22">
                                <use href="#icon_edit"></use>
                              </svg>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )
          )}
        </div>
      </div>
      <Modal
        show={showadd}
        centered
        onHide={() => {
          setIsLoading(false);
          setShowadd(false);
          setModalData({
            price_name: null,
            amount: null,
            currency: "",
            credit_days: null,
          });
        }}
        className="tracking-modal"
        scrollable={true}
      >
        <Modal.Header className="border-0 text-center fs-26 fw-bold pb-2" closeButton> {conditionalExp(
                modalData?.credit_price_id,
                "Edit Standard Price",
                "Add Standard Price"
              )}</Modal.Header>
        <Modal.Body className="px-4 overflow-auto">
          <form onSubmit={handleAddPrice} className="p-4">
            
            <div className="input-box mb-3">
              {modalData?.price_name && modalData?.price_name != "" && (
                <label htmlFor="" className="fw-bold">
                  Price Name*
                </label>
              )}
              <input
                value={modalData?.price_name}
                onChange={handleChange}
                required
                type="text"
                className="w-100"
                id="price_name"
                placeholder="Enter Price Name*"
              />
              {modalDataErrors?.price_name && (
                <p className="text-danger">Please Enter Price Name</p>
              )}
            </div>
            <div className="input-box mb-3">
              {modalData?.amount &&
                modalData?.amount != "" && (
                  <label htmlFor="" className="fw-bold">
                    Amount*
                  </label>
                )}
              <input
                value={modalData?.amount}
                onChange={handleChange}
                required
                type="number"
                className="w-100"
                id="amount"
                placeholder="Enter Amount*"
              />
              {modalDataErrors?.amount && (
                <p className="text-danger">Please Enter Amount</p>
              )}
            </div>
            <div className="input-box mb-3">
              {modalData.currency && (
                <label htmlFor="" className="fw-bold mb-1">
                  Currency*
                </label>
              )}
              <Select
                required
                value={modalData.currency}
                onChange={(option) => {
                  setModalData({ ...modalData, currency: option });
                  if (option) {
                    setModalDataErrors({ ...modalDataErrors, currency: false });
                  } else {
                    setModalDataErrors({ ...modalDataErrors, currency: true });
                  }
                }}
                options={optionsForCurrency}
                isSearchable={true}
                placeholder="Select Currency*"
                isClearable
                isLoading={!optionsForCurrency?.length}
              />
              {modalDataErrors?.currency && (
                <p className="text-danger">Please Select Currency</p>
              )}
            </div>

            <div className="input-box mb-3">
              {modalData?.credit_days &&
                modalData?.credit_days != "" && (
                  <label htmlFor="" className="fw-bold">
                    Credit Days*
                  </label>
                )}
              <input
                value={modalData?.credit_days}
                onChange={handleChange}
                required
                type="number"
                className="w-100"
                id="credit_days"
                placeholder="Enter Credit Days*"
              />
              {modalDataErrors?.credit_days && (
                <p className="text-danger">Please Enter Credit Days</p>
              )}
            </div>


            <div className="d-flex">
              <div className="ms-auto">
                <button
                  type="submit"
                  className="grd-btn rounded-pill"
                  onClick={(e) => {
                    handleAddPrice(e);
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? <Loader /> : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <ErrorModal
        error={error}
        showErrModal={showErrModal}
        setShowErrModal={setShowErrModal}
      />
      <SuccessModal
        showSuccess={showSuccessModal}
        handleCloseSuccess={() => {
          setShowSuccessModal(false);
          setSuccessMsg();
        }}
        successMessagePopup={successMsg}
      />
    </>
  );
}

export default CreditStandardPrice;
