import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
function Trade() {
  const navigate = useNavigate();
  const [tradesLoaded, settradesLoaded] = useState(false);
  const [allTrades, setallTrades] = useState([]);
  const [allTradesOriginal, setallTradesOriginal] = useState([]);
  const [submitting, setsubmitting] = useState(false);
 
  const [submitMessage, setsubmitMessage] = useState("");
  const [editSave, seteditSave] = useState(false);

  const [modalInputBoxDisabled, setmodalInputBoxDisabled] = useState(true);
  const handleModalInputBoxChange = (e, label) => {
    setallTrades((prev) =>
      prev.map((item) => {
        if (item.clickup_product_name == label) {
          return { ...item, trade_percent: e.target.value, error: "" };
        } else {
          return item;
        }
      })
    );
  };
  const handleModalInputBoxChangeMin = (e, label) => {
    setallTrades((prev) =>
      prev.map((item) => {
        if (item.clickup_product_name == label) {
          return {
            ...item,
            min_trade_percent: e.target.value,
            errorMinimum: "",
          };
        } else {
          return item;
        }
      })
    );
  };

  


  const getAllMargins = async () => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtm/admin/margin`,
        method: "GET",
        headers: {
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
      });
      if (!response.data.error) {
        let newTrades = response.data.trades?.map((item) => {
          return { ...item, type: "number", error: "", errorMinimum: "" };
        });
        setallTrades(newTrades);
        setallTradesOriginal(newTrades);
        settradesLoaded(true);
      }
      console.log(response, "response");
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      console.log(error, "error");
    }
  };
  const runErrorFn=()=>{
    let error=false;
    setallTrades((prev) =>
      prev.map((item) => {
        if (item.trade_percent == "" && item.min_trade_percent == "") {
          error = true;
          return {
            ...item,
            error: `Please enter average of ${item?.clickup_product_name}`,
            errorMinimum: `Please enter minimum of ${item?.clickup_product_name}`,
          };
        } else if (item.trade_percent == "") {
          error = true;
          return {
            ...item,
            error: `Please enter average of ${item?.clickup_product_name}`,
          };
        } else if (item.min_trade_percent == "") {
          error = true;
          return {
            ...item,
            errorMinimum: `Please enter minimum of ${item?.clickup_product_name}`,
          };
        } else {
          return item;
        }
      })
    );
    return error;
  }
  const runMinValueCheckFn=()=>{
    setallTrades((prev) =>
    prev.map((item) => {
      if (
        parseFloat(item.min_trade_percent) > parseFloat(item.trade_percent)
      ) {
        return {
          ...item,
          errorMinimum: `Please enter minimum value should be less than or equal to average`,
        };
      } else {
        return item;
      }
    })
  );
  }
  const runMintradeAndTradeFn=()=>{
    let error=false;
    for (const element of allTrades) {
      if (
        !element.min_trade_percent ||
        element.min_trade_percent == "" ||
        !element.trade_percent ||
        element.trade_percent == ""
      ) {
        error = true;
      }

      if (
        parseFloat(element.min_trade_percent) >
        parseFloat(element.trade_percent)
      ) {
        error = true;
      }
    }
    return error;
  }
  const postAllMargins = async () => {
    runMinValueCheckFn();
    if (runErrorFn()||runMintradeAndTradeFn()) {
      return;
    }
    seteditSave(false);
    try {
      setsubmitting(true);
      let dataToSend = [];
      dataToSend = allTrades?.map((item) => {
        return {
          clickup_product_id: item.clickup_product_id,
          trade_percent: item.trade_percent,
          min_trade_percent: item.min_trade_percent,
        };
      });
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtm/admin/margin/update`,
        method: "POST",
        headers: {
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
        data: {
          margins: dataToSend,
        },
      });
      if (!response.data.error) {
        setsubmitting(false);
        setmodalInputBoxDisabled(true);
        setsubmitMessage(response.data.message);
        setTimeout(() => {
          setsubmitMessage("");
        }, 2000);

        getAllMargins();
      }
      console.log(response, "response");
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      setsubmitting(false);
      console.log(error, "error");
    }
  };
  useEffect(() => {

    getAllMargins();
  }, []);
  console.log(allTrades, "all trades");

  return (
    <>
 
      {!tradesLoaded ? (
        <Container className="w-100 h-100" style={{marginLeft:"-100px"}}>
          <Row className=" w-100 h-100 d-flex justify-content-center align-items-center">
            <Col
              md={12}
              className=""
              style={{
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                // width: "80vw",
                height: "100vh",
              }}
            >
              <Loader />
            </Col>
          </Row>
        </Container>
      ) : (
        <Container className="mt-4" style={{marginLeft:"-100px"}}>
          <Row>
            <Col md={12}>
              <div className="row mt-5">
                <div className="col-4" onClick={() => navigate(-1)}>
                  <div
                    style={{
                      padding: "7px",
                      border: "1px solid gray",
                      textAlign: "center",
                      borderRadius: "25px 4px 4px 25px",
                      cursor: "pointer",
                      width: "8vw",
                      background: "#EBEEFF",
                      fontWeight: "500",
                    }}
                  >
                    {"<"} Back
                  </div>
                </div>
                <div className="col-4">
                  <h2 className="text-center fw-700 fs-30">
                    Manage Trade Margin
                  </h2>
                </div>
                <div className="col-4"></div>
              </div>
              <div className="content-wrapper">
                <div className="row mb-3 ">
                  <div className="col-12 d-flex align-items-center justify-content-end ">
                    <div className="">
                      {editSave ? (
                        <button
                          type="button"
                          className="logout-btn1 me-3 px-4"
                          onClick={() => {
                            setallTrades(allTradesOriginal);
                            setmodalInputBoxDisabled(true);
                            seteditSave(false);
                          }}
                        >
                          Cancel
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="logout-btn1 px-4"
                          onClick={() => {
                            seteditSave(true);
                            setmodalInputBoxDisabled(false);
                          }}
                        >
                          Edit
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="modify-tbl my-3">
                  <div className="row pb-3 fw-bold fs-5">
                    <div className="col-4">Continent</div>
                    <div className="col-4">Minimum</div>
                    <div className="col-4">Average</div>
                  </div>
                  {allTrades?.map((trade, trade_index) => (
                    <div className="row pb-1 " key={trade.clickup_product_name}>
                      <div className="col-4 ">
                        <div className="fw-600 ">
                          {trade.clickup_product_name} *
                        </div>
                      </div>
                      <div className="col-4 ">
                        <div className="">
                          <input
                            className=""
                            type="number"
                            value={trade.min_trade_percent}
                            disabled={modalInputBoxDisabled}
                            onChange={(e) =>
                              handleModalInputBoxChangeMin(
                                e,
                                trade.clickup_product_name
                              )
                            }
                          />
                          {trade?.errorMinimum != "" && (
                            <div className="text-danger">
                              {trade.errorMinimum}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-4 ">
                        <div className="">
                          <input
                            className=""
                            type="number"
                            value={trade.trade_percent}
                            disabled={modalInputBoxDisabled}
                            onChange={(e) =>
                              handleModalInputBoxChange(
                                e,
                                trade.clickup_product_name
                              )
                            }
                          />
                          {trade?.error != "" && (
                            <div className="text-danger">{trade.error}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {submitMessage.length !== "" && (
                  <div className="d-flex justify-content-end">
                    <div className="ml-2 fw-600">{submitMessage}</div>
                  </div>
                )}

                <div className="d-flex justify-content-end">
                  <div className="ml-2">
                    <button
                      type="button"
                      className="mt-2 py-2 px-4  grd-btn fs-20 rounded-pill fw-600 d-flex text-center"
                      onClick={() => {
                        postAllMargins();
                      }}
                    >
                      {submitting ? <Loader /> : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default Trade;
