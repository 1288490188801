import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Tab,
  Tabs,
  Form,
  Modal,
  Button,
} from "react-bootstrap";
import AddWarehouseModal from "../components/AddWarehouseModal";
import NavBar from "../components/NavBar";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import axios, { all } from "axios";
import Loader from "../components/Loader";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import {
  conditionalExp,
  debounce,
  getVendorList,
  getWarehouseList,
  currencyOptions,
  convertPrice
} from "../utils/commonFn";
import ErrorModal from "../components/ErrorModal";
import moment from "moment";
import AddVendorModal from "../components/AddVendorModal";
import Ckeditor from "../components/Ckeditor";
function Self_Pricing() {
  const [show, setShow] = useState(false);
  const [commentData, setCommentData] = useState("");
  const [commentList, setCommentList] = useState([]);
  const [changingCurrency, setChangingCurrency] = useState(false)
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [addingComment, setAddingComment] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, sethasMore] = useState(false);
  const observer = useRef();
  const tempRef = useRef();
  const [clkData, setclkData] = useState([]);
  const [dataFetched, setdataFetched] = useState(true);
  const [errorMessage, seterrorMessage] = useState("");
  const [selectedLabel, setselectedLabel] = useState({});
  const [selectedOptionPort, setSelectedOptionPort] = useState(null);
  const [selectedOptionPortDestination, setSelectedOptionPortDestination] =
    useState(null);
  const [options, setoptions] = useState([]);
  const [optionsPort, setoptionsPort] = useState([]);
  const [optionsPortDestination, setoptionsPortDestination] = useState([]);
  const [allCountries, setallCountries] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionDestination, setSelectedOptionDestination] =
    useState(null);
  const [deleting, setdeleting] = useState(false);
  const [filterDetails, setfilterDetails] = useState({
    page: 0,
    product_type: "ocean",
    service_type: "fcl",
    validity: "valid",
  });
  const [editClicked, seteditClicked] = useState(false);
  const [isCompleted, setisCompleted] = useState(false);
  const [dateDays, setdateDays] = useState("");
  const [addSelfPricingClicked, setaddSelfPricingClicked] = useState("");
  const [previouslySelectedServiceType, setpreviouslySelectedServiceType] =
    useState("");
  const [showCustomer, setshowCustomer] = useState(false);
  const [selectedDocId, setselectedDocId] = useState("");
  const [sendingPlusDetails, setsendingPlusDetails] = useState(false);
  const handleCloseCustomer = () => setshowCustomer(false);
  const handleShowCustomer = () => {
    setshowCustomer(true);
  };
  const [airportCodes, setAirportCodes] = useState([]);
  const [submittingDetails, setsubmittingDetails] = useState(false);
  const [sendingPlusDetailsError, setsendingPlusDetailsError] = useState(null);
  const [showCustomer2, setshowCustomer2] = useState(false);
  const handleCloseCustomer2 = () => setshowCustomer2(false);
  const [showVendorNew, setShowVendorNew] = useState(false);
  const handleCloseVendorNew = () => {
    setShowVendorNew(false);
    setpriceIdToDelete(null);
    setselectedDocId("");
  };
  const handleShowVendorNew = () => setShowVendorNew(true);
  const [rateTypeCodes, setrateTypeCodes] = useState([]);
  const [selectedQuotePriceDetails, setselectedQuotePriceDetails] = useState(
    []
  );
  const [selectedQuotePriceDetailsUSD, setselectedQuotePriceDetailsUSD] = useState(
    []
  );
  const [showSuccess, setshowSuccess] = useState(false);
  const [errRows, setErrRows] = useState([]);
  const [showErrModal, setShowErrModal] = useState(false);
  const [error, setError] = useState({ title: "", err: "" });
  const [uploadOption, setUploadOption] = useState("");
  const [previewUrl, setpreviewUrl] = useState();
  const [isFileUploading, setisFileUploading] = useState(false);
  const [rawFile, setrawFile] = useState();
  const [priceId, setpriceId] = useState(null);
  const [priceIdToDelete, setpriceIdToDelete] = useState(null);
  const [managePricingDetails, setmanagePricingDetails] = useState({
    quote_type: "named_accounts",
    product_id: "",
    clickup_product_name: "",
    modal_name: 13,
    min_weight_clicked: false,
    max_weight_clicked: false,
    cbm_clicked: false,
    min_weight_value: "",
    max_weight_value: "",
    cbm_value: "",
    container_type: [],
    max_allow_containers: "",
    originstart: "",
    originend: "",
    deststart: "",
    destend: "",
    origin_free_days: "",
    destination_free_days: "",
    contract_number: "",
    transit_time: "",
  });
  const [portDetails, setportDetails] = useState([]);
  const agentTypes = [
    { label: "Agent at Origin", value: "origin" },
    {
      label: "Agent at Destination",
      value: "destination",
    },
    { label: "Custom Broker", value: "custom_broker" },
  ];
  // const [errors, seterrors] = useState({
  //   customer:null,
  //   allowedContainers:null,
  //   allowedDays:null,
  //   validityDate:null,
  //   choose_service:null,
  //   choose_modal:null,
  //   choose_country_origin:null,
  //   choose_country_destination:null,
  //   choose_origin_port
  // });
  const [carrierList, setcarrierList] = useState([]);
  const [warehouseList, setwarehouseList] = useState([]);
  const [vendorList, setvendorList] = useState([]);
  const [agentList, setagentList] = useState([]);
  const [commodityList, setcommodityList] = useState([]);
  const [optionsForCustomers, setoptionsForCustomers] = useState([]);
  const [optionsModifiedCustomers, setoptionsModifiedCustomers] = useState([]);
  const [selectedOptionCustomer, setselectedOptionCustomer] = useState([]);
  const [clickupData, setClickupData] = useState([]);
  const [modalTypes, setmodalTypes] = useState([]);
const [groundTotalBlocks, setGroundTotalBlocks] = useState([])
  const [maxAllowedDays, setMaxAllowedDays] = useState("");
  const [maxValidityDate, setMaxValidityDate] = useState("");
  const [maxValidityEndDate, setMaxValidityEndDate] = useState("");
  const [selectedCarrerList, setselectedCarrerList] = useState("");
  const [selectedCarrerListDestination, setselectedCarrerListDestination] =
    useState("");
  const [selectedWarehouse, setselectedWarehouse] = useState();
  const [selectedVendor, setselectedVendor] = useState();
  const [showvendorAdd, setShowvendorAdd] = useState(false);
  const [showwarehouseAdd, setShowwarehouseAdd] = useState(false);
  const [addNewWarehouseDetails, setaddNewWarehouseDetails] = useState({
    customer_id: selectedOptionCustomer?.value,
    company_name: "",
    warehouse_code: "",
    warehouse_name: "",
    city: "",
    state: "",
    country: "",
    zip_cdoe: "",
  });
  const [addNewVendorDetails, setaddNewVendorDetails] = useState({
    customer_id: selectedOptionCustomer?.value,
    company_name: "",
    origin_country: "",
    contact_name: "",
    contact_email: "",
    contact_phone: "",
    communication_mode: "",
    Address: "",
    nearest_sea_port_code: "",
    nearest_sea_port_name: "",
    nearest_air_port_code: "",
    nearest_air_port_name: "",
    nearest_land_port_code: "",
    nearest_land_port_name: "",
  });
  const handleClosevendorAdd = () => setShowvendorAdd(false);
  const handleShowvendorAdd = () => setShowvendorAdd(true);
  const handleClosewarehouseAdd = () => setShowwarehouseAdd(false);
  const handleShowwarehouseAdd = () => setShowwarehouseAdd(true);
  const [selectedAgentList, setselectedAgentList] = useState("");
  const [selectedCommodityList, setselectedCommodityList] = useState([]);
  const handleClose = (type) => {
    type != "add" && getPricingDetails("reload");
    setCurrIndex(0);
    setmanagePricingDetails({
      quote_type: "named_accounts",
      product_id: "",
      clickup_product_name: "",
      modal_name: 13,
      min_weight_clicked: false,
      max_weight_clicked: false,
      cbm_clicked: false,
      min_weight_value: "",
      max_weight_value: "",
      cbm_value: "",
      container_type: [],
      max_allow_containers: "",
      originstart: "",
      originend: "",
      deststart: "",
      destend: "",
      origin_free_days: "",
      destination_free_days: "",
      contract_number: "",
      transit_time: "",
    });
    setselectedOptionCustomer(null);
    setselectedWarehouse(null);
    setselectedVendor(null);
    setSelectedOption(null);
    setSelectedOptionDestination(null);
    setSelectedOptionPort(null);
    setSelectedOptionPortDestination(null);
    setMaxValidityDate("");
    setMaxValidityEndDate("");
    setMaxAllowedDays("");
    setShow(false);
  };
  const MAX_FILE_SIZE = 6 * 1024 * 1024; // 6MB in bytes
  const [fileUploadLimitRxError, setfileUploadLimitRxError] = useState(false);
  function getFileType(type) {
    if (type == "pdf") {
      return (
        <svg className="icon me-2 fs-22">
          <use href="#icon_pdf"></use>
        </svg>
      );
    } else if (type == "xls" || type == "xlsx") {
      return (
        <svg className="icon me-2 fs-22">
          <use href="#icon_exel"></use>
        </svg>
      );
    } else if (type == "doc" || type == "docx") {
      return (
        <svg className="icon me-2 fs-22">
          <use href="#icon_word"></use>
        </svg>
      );
    } else {
      return (
        <svg className="icon me-2 fs-22">
          <use href="#icon_doc"></use>
        </svg>
      );
    }
  }
  const handleShowUpload = () => {
    setpreviewUrl(null);
    setrawFile(null);
    setShowUpload(true);
  };

  const handleCloseUpload = () => {
    setShowUpload(false);
    setpreviewUrl(null);
    setrawFile(null);
  };
  const handleShow = () => setShow(true);
  const handleChangeFileFun = (e) => {
    for (let file of e.target.files) {
      if (file && file.size > MAX_FILE_SIZE) {
        setfileUploadLimitRxError(true);
        return;
      }
    }
    setfileUploadLimitRxError(false);
    setrawFile(Object.values(e.target.files));
  };
  const checkRxType = (file) => {
    let rawFilee = file ? file : rawFile;
    let objectURL = URL.createObjectURL(rawFilee);
    if (!rawFilee) {
      return (
        <img
          src={objectURL}
          style={{
            width: "40px",
            height: "30px",
            margin: "5px",
            marginRight: "10px",
          }}
          alt="doc"
        />
      );
    }
    let type = rawFilee?.name?.split(".");
    return getTypeDoc(objectURL, type);
  };
  const getTypeDoc = (path, typ) => {
    if (!path) {
      return "";
    }
    let type = typ ? typ : path?.split(".");
    if (type[type.length - 1] == "pdf") {
      return returnImgComp(window.location.origin + "/images/pdf.png");
    } else if (
      type[type.length - 1] == "xls" ||
      type[type.length - 1] == "xlsx"
    ) {
      return returnImgComp(window.location.origin + "/images/exel.png");
    } else if (
      type[type.length - 1] == "doc" ||
      type[type.length - 1] == "docx"
    ) {
      return returnImgComp(window.location.origin + "/images/doc.png");
    }

    return returnImgComp(path);

    function returnImgComp(src) {
      return (
        <img
          src={src}
          style={{
            width: "30px",
            height: "30px",
            margin: "5px",
            marginRight: "10px",
          }}
          alt="doc"
        />
      );
    }
  };

  async function handleFileUpload(event) {
    event.preventDefault();
    if (!rawFile?.length) {
      setError({ title: "Error", err: "Please select a file to upload" });
      setShowErrModal(true);
      return;
    }
    setisFileUploading(true);
    try {
      const token = localStorage.getItem("mtm_user_token");
      const formData = new FormData();

      rawFile?.forEach((file) => {
        formData.append("document", file);
      });

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtm/selfprice/${filterDetails.product_type}/bulk_upload`,
        method: "POST",
        headers: {
          jwt_token: token,
        },

        data: formData,
      });

      if (!response?.data?.error) {
        setshowSuccess(true);
        handleCloseUpload();
        setErrRows(response?.data?.errorItems || []);
        setTimeout(() => {
          !response?.data?.errorItems?.length && setshowSuccess(false);
        }, 5000);
      } else {
        setError({ title: "Error", err: response?.data?.message });
        setShowErrModal(true);
      }
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      await getPricingDetails("reload");
      setCurrIndex(0);
      setisFileUploading(false);
    }
  }

  //   stepper
  const [currIndex, setCurrIndex] = useState(0);

  const indicators = document.getElementsByClassName("screen-indicator");
  const progressElm = document.getElementsByClassName("progress")[0];
  const [showPortModal, setshowPortModal] = useState(false);
  const [portModalDisplay, setportModalDisplay] = useState({
    name: "",
    data: [],
  });
  const disableControls = () => {
    const prevBtn = currIndex <= 0;
    const nextBtn = currIndex >= indicators.length - 1;
    return { prevBtn, nextBtn };
  };

  const previous = () => {
    // Delay should not be applied when removing class
    setCurrIndex(0);
    // indicators[currIndex].style.transitionDelay = "0.6s";
    // progressElm.style.width = `${(currIndex / (indicators.length - 1)) * 100}%`;
  };

  const next = () => {
    // if(!priceId){
    setselectedQuotePriceDetails([]);
    setselectedQuotePriceDetailsUSD([]);
    // }
    submitButtonFn();
    // Delay should be applied when adding class

    // indicators[currIndex].style.transitionDelay = "0s";
    // progressElm.style.width = `${(currIndex / (indicators.length - 1)) * 100}%`;
  };

  // const { prevBtn, nextBtn } = disableControls();
  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) {
        return;
      }
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, hasMore]
  );
  function capitalizeFirstLetter(str) {
    if (!str) {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  const getFrieghtRatesOriginal = (rate_type_code, cont,from) => {
    let array = from == "USD" ? selectedQuotePriceDetailsUSD : selectedQuotePriceDetails
    let allRrieghtDetails = array?.filter((quote) => {
      if (cont == "other") {
        return quote?.rate_type_code?.includes(rate_type_code);
      } else {
        return (
          quote?.rate_type_code?.includes(rate_type_code) &&
          quote?.container_type == cont
        );
      }
    });
    let sum = 0;

    for (const element of allRrieghtDetails) {
      if (element?.actual_amount_usd) {
        sum += parseFloat(element?.actual_amount_usd);
      }
    }
    return sum;
  };
  const getAirRates = (rate_type_code, type,from) => {
    let array = from == "USD" ? selectedQuotePriceDetailsUSD : selectedQuotePriceDetails 
    let allRrieghtDetails = array?.filter((quote) => {
      return quote?.rate_type_code?.includes(rate_type_code);
    });
    let sum = 0;

    for (const element of allRrieghtDetails) {
      if (element[type]) {
        sum += parseFloat(element[type]);
      }
    }
    return sum;
  };

  const getFinalRatesAir = () => {
    return (
      getAirRates("FREIGHT", "actual_amount_usd") +
      getAirRates("ORIGIN", "actual_amount_usd") +
      getAirRates("DESTINATION", "actual_amount_usd") +
      getAirRates("ADDON", "actual_amount_usd")
    );
  };
  const grossTotalOriginal = (cont) => {
    let sum = 0;
    let array = [];
    if (cont != "other") {
      array = selectedQuotePriceDetails?.filter((item) => {
        return item?.container_type == cont;
      });
    } else {
      array = selectedQuotePriceDetails;
    }
    for (const element of array) {
      sum += element?.actual_amount_usd
        ? parseFloat(element?.actual_amount_usd)
        : 0;
    }
    return parseFloat(sum).toFixed(2);
  };
  const loaderFnSave = (state, value) => {
    return state ? <Loader /> : value;
  };
  const handleShowCustomer2 = () => {
    setshowCustomer2(true);
  };
  const deleteLableFn = async () => {
    try {
      setdeleting(true);
      let token = localStorage.getItem("mtm_user_token");
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtm/selfprice/delete_breackup`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: {
          id: selectedDocId,
        },
      });

      if (response.status == 200) {
        setdeleting(false);
        if (response.data.error) {
          alert(response.data.message);
        } else {
          setselectedQuotePriceDetails([]);
          setselectedQuotePriceDetailsUSD([]);
          getPriceDetails(priceId);
          handleCloseVendorNew();
        }
      }
    } catch (error) {
      setdeleting(false);
      console.log(error);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
    }
  };
  const deletePriceFn = async () => {
    try {
      setdeleting(true);
      let token = localStorage.getItem("mtm_user_token");
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtm/selfprice/delete_price`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: {
          price_id: priceIdToDelete,
        },
      });

      if (response.status == 200) {
        setdeleting(false);
        if (response.data.error) {
          setError({ title: "Error", err: response?.data?.message });
          setShowErrModal(true);
        } else {
          await getPricingDetails("reload");
          setCurrIndex(0);
          handleCloseVendorNew();
        }
      }
    } catch (error) {
      setdeleting(false);
      console.log(error);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
    }
  };
  console.log(previouslySelectedServiceType, "previously selected");
  const getBoilerPlate = async (price_id) => {
    let name = "";
    if (managePricingDetails?.clickup_product_name?.includes("OCEAN")) {
      name = "ocean";
    } else if (managePricingDetails?.clickup_product_name?.includes("TRUCK")) {
      name = "truck";
    } else if (managePricingDetails?.clickup_product_name?.includes("Air")) {
      name = "air";
    } else {
      name = "";
    }
    let data = {
      product_type: name,
      price_id: price_id,
      container_type: managePricingDetails?.container_type?.join(","),
    };
    if (previouslySelectedServiceType != managePricingDetails?.product_id) {
      data["previous_product_type"] = previouslySelectedServiceType;
    }
    const response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/mtm/selfprice/boilerplate/pricebreackup`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        jwt_token: localStorage.getItem("mtm_user_token"),
      },
      data: data,
    });
    if (!response.data.error) {
      console.log(response, "response...");
      let quote_prices = response.data.priceBreakups;

      let rate_type_codes = [];

      for (const element of quote_prices) {
        if (!rate_type_codes.includes(element?.rate_type_code)) {
          rate_type_codes.push(element?.rate_type_code);
        }
      }
      setrateTypeCodes(rate_type_codes);
      // let priceBreakups=response?.data?.priceBreakups?.map((item,index)=>{
      //   return {...item,id:index}
      // });

      let usdPriceBreckups = []

      for (const breckup of quote_prices) {
        let edited = {...breckup };

        if(edited?.rate_currency != "USD"){
          edited['actual_amount_usd'] = await convertPrice(parseFloat(edited?.actual_amount_usd || 0),edited?.rate_currency,"USD")
          edited['amount_usd'] = await convertPrice(parseFloat(edited?.amount_usd || 0),edited?.rate_currency,"USD")
          edited['rate_usd'] = await convertPrice(parseFloat(edited?.rate_usd || 0),edited?.rate_currency,"USD")
          edited['actual_rate_usd'] = await convertPrice(parseFloat(edited?.actual_rate_usd || 0),edited?.rate_currency,"USD")
        }
        usdPriceBreckups.push(edited)
      }

      setselectedQuotePriceDetails(quote_prices);
      setselectedQuotePriceDetailsUSD(usdPriceBreckups);
    } else {
      console.log("ERROR");
    }
  };
  const getPricingDetails = async (from) => {
    setIsLoadingMore(true);
    seterrorMessage(null);
    try {
      const token = localStorage.getItem("mtm_user_token");
      let data = {
        page: from == "reload" ? "0" : currentPage,
        product_type: filterDetails?.product_type,
        service_type: filterDetails?.service_type,
        validity: filterDetails?.validity,
        origin_port: filterDetails?.origin_port,
        destination_port: filterDetails?.destination_port,
      };
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtm/list_selfprice`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },

        data: data,
      });

      console.log(response, "result");

      setIsLoadingMore(false);
      setdataFetched(true);
      sethasMore(false);
      if (!response.data.error) {
        const newList = response.data.priceList.filter((newList) => {
          return !clkData.some(
            (existingList) => existingList.price_id === newList.price_id
          );
        });
        if (from == "reload") {
          setclkData(response.data.priceList);
        } else {
          setclkData([...clkData, ...newList]);
        }
        sethasMore(newList.length > 0);
      } else {
        if (clkData.length == 0) {
          seterrorMessage(response.data.message);
        }
      }
    } catch (error) {
      setclkData([]);
      setdataFetched(true);
      seterrorMessage(error.message);
      setCurrentPage(0);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }

      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  };

  const handleChange = (option) => {
    setSelectedOptionPort(null);
    setSelectedOption(option);
  };
  const handleChangeDestination = (option) => {
    setSelectedOptionPortDestination(null);
    setSelectedOptionDestination(option);
  };
  const finalRates = (rate_type_code) => {
    let name = rate_type_code?.toUpperCase();
    if (name?.includes("FREIGHT")) {
      return getAirRates(rate_type_code, "actual_amount_usd","USD")?.toFixed(2);
    } else if (name?.includes("ORIGIN")) {
      return getAirRates(rate_type_code, "actual_amount_usd","USD")?.toFixed(2);
    } else if (name?.includes("DESTINATION")) {
      return getAirRates(rate_type_code, "actual_amount_usd","USD")?.toFixed(2);
    } else if (name?.includes("ADDON")) {
      return getAirRates(rate_type_code, "actual_amount_usd","USD")?.toFixed(2);
    }
  };
  const finalRatesMarkUp = (rate_type_code) => {
    let name = rate_type_code?.toUpperCase();
    if (name?.includes("FREIGHT")) {
      return getAirRates(rate_type_code, "amount_usd")?.toFixed(2);
    } else if (name?.includes("ORIGIN")) {
      return getAirRates(rate_type_code, "amount_usd")?.toFixed(2);
    } else if (name?.includes("DESTINATION")) {
      return getAirRates(rate_type_code, "amount_usd")?.toFixed(2);
    } else if (name?.includes("ADDON")) {
      return getAirRates(rate_type_code, "amount_usd")?.toFixed(2);
    }
  };
  console.log(dateDays, "dateDays");
  useEffect(() => {
    if (dateDays == "calender") {
      return;
    }

    if (maxAllowedDays) {
      setdateDays("days");
      if (maxAllowedDays.length === 0) {
        setMaxValidityDate(new Date().toISOString().split("T")[0]);
        return;
      }
      // Calculate the maximum validity date based on the selected number of days
      const currentDate = new Date();
      const maxValidityDate = new Date(currentDate);
      maxValidityDate.setDate(
        currentDate.getDate() + parseInt(maxAllowedDays, 10)
      );

      // Set the time part of maxValidityDate to midnight (00:00:00) to avoid date display issues
      maxValidityDate.setHours(0, 0, 0, 0);

      // Format the date to the 'YYYY-MM-DD' format required by the input type='date'
      const formattedDate = maxValidityDate.toISOString().split("T")[0];

      setMaxValidityDate(formattedDate);
    }
  }, [maxAllowedDays]);

  // const handleMaxValidityDateChange = (e) => {
  //   const selectedDate = new Date(e.target.value);
  //   const currentDate = new Date();
  //   setdateDays("calender");
  //   // Calculate the difference in days, ensuring it's rounded to the nearest whole day
  //   // const diffInDays = Math.round(
  //   //   (selectedDate - currentDate) / (24 * 60 * 60 * 1000)
  //   // );

  //   setMaxValidityDate(e.target.value);
  //   // setMaxAllowedDays(diffInDays.toString());
  // };

  async function getDropdown() {
    try {
      const token = localStorage.getItem("mtm_user_token");
      let clickup_data = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/quote/dropdowns`,
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );
      return clickup_data?.data?.dropdown_details;
    } catch (err) {
      if (err?.response?.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      console.log(err);
    }
  }
  const getModalTypes = async () => {
    try {
      const token = localStorage.getItem("mtm_user_token");
      let data = {
        product_id: managePricingDetails.product_id,
      };

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/quote/dropdowns/service_type`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },

        data: data,
      });
      console.log(response, "result");
      if (!response.data.error) {
        setmodalTypes(response.data.dropdown_details);
      } else {
        alert(response?.data?.message);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }

      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  };
  console.log(selectedOptionPort, "selectedOptionPort");
  const getCountriesFn = async () => {
    try {
      let token = localStorage.getItem("mtm_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/country/list`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
      });

      console.log(response, "result");
      if (response.data.status) {
        let optionsForCountries = [];
        let countries = response?.data?.country;
        for (const element of countries) {
          let obj = {
            label: element?.country + " | " + element?.country_sort_code,
            value: element?.country,
          };
          optionsForCountries.push(obj);
        }
        setoptions(optionsForCountries);

        setallCountries(response?.data?.country);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  };
  // console.log(selectedOptionPort?.label?.split(" | ")[1],"origin");
  // console.log(selectedOptionPortDestination?.label?.split(" | ")[1],"destination");
  const getInitialPort = (clk, type) => {
    let port = clk?.ports?.filter((item) => item?.port_type == type);
    // console.log(port,"clk clk")
    return `${port[0]?.port_name}`;
  };
  const submitButtonFn = async () => {
    try {
      setsubmittingDetails(true);
      let origin_free_days_total = "";
      let destination_free_days_total = "";
      if (managePricingDetails?.clickup_product_name?.includes("TRUCK")) {
        origin_free_days_total =
          managePricingDetails?.origin_free_days + " hours";
        destination_free_days_total =
          managePricingDetails?.destination_free_days + " hours";
      } else if (
        managePricingDetails?.clickup_product_name?.includes("OCEAN")
      ) {
        origin_free_days_total =
          managePricingDetails?.origin_free_days + " days";
        destination_free_days_total =
          managePricingDetails?.destination_free_days + " days";
      } else {
        origin_free_days_total = "";
        destination_free_days_total = "";
      }
      let port_of_loadingArray = selectedOptionPort;
      port_of_loadingArray = port_of_loadingArray?.map((item) => {
        let name = item?.label;
        let nameSplit = name?.split(" | ");
        let obj = {
          global_port_code: nameSplit[1],
          port_name: nameSplit[0],
        };
        return obj;
      });
      let port_of_DischargeArray = selectedOptionPortDestination;
      port_of_DischargeArray = port_of_DischargeArray?.map((item) => {
        let name = item?.label;
        let nameSplit = name?.split(" | ");
        let obj = {
          global_port_code: nameSplit[1],
          port_name: nameSplit[0],
        };
        return obj;
      });

      let data = {
        quote_type: managePricingDetails.quote_type,
        validity_start_date: maxValidityDate,
        validity_end_date: maxValidityEndDate,
        customer_id: conditionalExp(
          managePricingDetails.quote_type == "named_accounts",
          selectedOptionCustomer?.value,
          null
        ),
        warehouse_code: conditionalExp(
          managePricingDetails.quote_type == "named_accounts" &&
            (managePricingDetails?.clickup_product_name?.includes("TRUCK") ||
              selectedOptionCustomer?.demand_forcast_type),
          selectedWarehouse?.value,
          null
        ),
        vendor_name: conditionalExp(
          managePricingDetails.quote_type == "named_accounts" &&
            selectedOptionCustomer?.demand_forcast_type,
          selectedVendor?.value,
          null
        ),
        product_id: managePricingDetails?.product_id,
        modal_id: managePricingDetails?.modal_name,
        origin_country: selectedOption?.value,
        pickup_address: managePricingDetails?.pickup_address,
        port_of_loading: managePricingDetails?.clickup_product_name?.includes(
          "TRUCK"
        )
          ? ""
          : port_of_loadingArray,
        destination_country: selectedOptionDestination?.value,
        delivery_address: managePricingDetails?.delivery_address,
        port_of_discharge: managePricingDetails?.clickup_product_name?.includes(
          "TRUCK"
        )
          ? ""
          : port_of_DischargeArray,
        career_code: selectedCarrerList?.value,
        destination_career_code: selectedCarrerListDestination?.value,
        broker_id: selectedAgentList?.value,
        parent_hs_code: selectedCommodityList?.map((i) => i.value)?.join(","),
        // commodity_id: selectedCommodityList,
        max_allow_containers: conditionalExp(
          managePricingDetails.quote_type == "spot",
          null,
          managePricingDetails.max_allow_containers
        ),
        contract_number: conditionalExp(
          managePricingDetails.quote_type == "spot",
          null,
          managePricingDetails.contract_number
        ),
        cbm: managePricingDetails?.cbm_value,
        transit_time: managePricingDetails?.transit_time,
        min_weight: managePricingDetails?.min_weight_value,
        max_weight: managePricingDetails?.max_weight_value,
        max_allow_days: maxAllowedDays,
        remark: "",
        container_type: managePricingDetails?.container_type?.join(","),
        // origin_zipcode_range:
        //   managePricingDetails?.clickup_product_name?.includes("TRUCK")
        //     ? managePricingDetails?.originstart +
        //       "_" +
        //       managePricingDetails?.originend
        //     : "",
        // destination_zipcode_range:
        //   managePricingDetails?.clickup_product_name?.includes("TRUCK")
        //     ? managePricingDetails?.deststart +
        //       "_" +
        //       managePricingDetails?.destend
        //     : "",
        origin_zipcode_range: managePricingDetails?.origin_zipcode_range,
        destination_zipcode_range:
          managePricingDetails?.destination_zipcode_range,
        origin_free_time: origin_free_days_total,
        destination_free_time: destination_free_days_total,
      };
      if (priceId) {
        data["price_id"] = priceId;
      }

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtm/selfprice/edit_quote`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
        data: data,
      });
      if (!response.data.error) {
        setpriceId(response.data.price_id);
        // if(!priceId){
        await getBoilerPlate(response.data.price_id);
        // }
        setCurrIndex(1);
      }
      setsubmittingDetails(false);
    } catch (error) {
      setsubmittingDetails(false);
      console.log(error);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  };
  const getPortDetails = async (countryCode, from,port) => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/country/port/search`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
        data: {
          country_sort_code: countryCode,
          port:port
        },
      });

     return handlePortDetailsResponse(response.data, from);
    } catch (error) {
      handlePortDetailsError(error);
    }
  };
  const getAirPort = async () => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/country/airport_get`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
      });

      if (!response.data?.error) {
        setAirportCodes(response.data?.port_details || []);
      }
    } catch (error) {
      handlePortDetailsError(error);
    }
  };
  const handleChangePort = (option) => {
    setSelectedOptionPort(option);
  };
  const handleChangePortDestination = (option) => {
    setSelectedOptionPortDestination(option);
  };
  const findPortByCode = (ports, portCode) => {
    return ports.find((element) => element.global_port_code === portCode);
  };
  const handlePortDetailsResponse = (data, from) => {
    if (managePricingDetails?.clickup_product_name?.includes("Air")) {
      let countryAirports;
      if (from == "origin") {
        countryAirports = airportCodes?.filter(
          (ap) =>
            ap?.country?.toLowerCase() ==
              selectedOption?.value?.toLowerCase() && ap?.iata
        );
        // Map the airport data to the format required by react-select options
        const mappedAirports = countryAirports.map((airport) => ({
          value: `${airport.name} | ${airport.iata}`,
          label: `${airport.name} | ${airport.iata}`,
        }));
        setoptionsPort(mappedAirports);
      } else {
        countryAirports = airportCodes?.filter(
          (ap) =>
            ap?.country?.toLowerCase() ==
              selectedOptionDestination?.value?.toLowerCase() && ap?.iata
        );
        // Map the airport data to the format required by react-select options
        const mappedAirports = countryAirports.map((airport) => ({
          value: `${airport.name} | ${airport.iata}`,
          label: `${airport.name} | ${airport.iata}`,
        }));
        setoptionsPortDestination(mappedAirports);
      }
      return;
    }
    if (data.status) {
      const optionsForPorts = data.port_details.map((element) => ({
        label: `${element.port_name} | ${element.global_port_code}`,
        value: `${element.port_name} | ${element.global_port_code}`,
      }));
      if (from == "origin") {
        setoptionsPort(optionsForPorts);
      } else {
        setoptionsPortDestination(optionsForPorts);
      }
      return data.port_details?.map((element) => ({
        label: `${element.port_name} | ${element.global_port_code}`,
        value: element?.global_port_code,
      }));
    }
  };
  const handlePortDetailsError = (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/");
    } else if (error.code === "ECONNABORTED") {
      console.log("Request timed out");
    } else {
      console.log("An error occurred:", error);
    }
  };
  const getCountryCode = (option) => {
    let countryCode = option?.label.split('|')[1].trim();
    /*for (let i = 0; i < allCountries?.length; i++) {
      const element = allCountries[i];
      if (element?.country == option?.label) {
        countryCode = element?.country_sort_code;
        break;
      }
    }*/
    return countryCode;
  };
  const grossTotalTruck = (container) => {
    let sum = 0;
    let array = [];

    array = selectedQuotePriceDetails;

    for (const element of array) {
      if (container && element?.container_type !== container) continue;
      sum += element?.actual_amount_usd
        ? parseFloat(element?.actual_amount_usd)
        : 0;
    }
    return parseFloat(sum).toFixed(2);
  };
  
  console.log(selectedQuotePriceDetails, "selected quote price details");
  console.log(selectedOptionCustomer, "selectedOptionCustomer");

  const getPriceDetails = async (
    price_id,
    from,
    carrier,
    destinationCarrier
  ) => {
    let token = localStorage.getItem("mtm_user_token");
    try {
      let data = {
        price_id: price_id,
      };

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtm/selfprice/details_quote`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: data,
      });
      if (!response.data.error) {
        if (response?.data?.details?.state == 1) {
          setpreviouslySelectedServiceType(managePricingDetails?.product_id);
          setisCompleted(true);
        }
        let rate_type_codes = [];
        for (const element of response?.data?.details?.selfprice) {
          if (!rate_type_codes.includes(element?.rate_type_code)) {
            rate_type_codes.push(element?.rate_type_code);
          }
        }
        setrateTypeCodes(rate_type_codes);
        setselectedQuotePriceDetails(response?.data?.details?.selfprice);

        let usdPriceBreckups = []

      for (const breckup of response?.data?.details?.selfprice) {
        let edited = {...breckup };

        if(edited?.rate_currency != "USD"){
          edited['actual_amount_usd'] = await convertPrice(parseFloat(edited?.actual_amount_usd || 0),edited?.rate_currency,"USD")
          edited['amount_usd'] = await convertPrice(parseFloat(edited?.amount_usd || 0),edited?.rate_currency,"USD")
          edited['rate_usd'] = await convertPrice(parseFloat(edited?.rate_usd || 0),edited?.rate_currency,"USD")
          edited['actual_rate_usd'] = await convertPrice(parseFloat(edited?.actual_rate_usd || 0),edited?.rate_currency,"USD")
        }
        usdPriceBreckups.push(edited)
      }

      setselectedQuotePriceDetailsUSD(usdPriceBreckups);

        if (from == "edit") {
          setSelectedOption(null);
          setSelectedOptionDestination(null);
          let details = response?.data?.details;
          let origin_zipcode_range = details?.origin_zipcode_range;
          let destination_zipcode_range = details?.destination_zipcode_range;
          let clickup_product_name = clickupData
            ?.filter((item) => item.dropdown_type == "product")
            ?.find(
              (item) => item?.product_id == details?.product_id
            )?.clickup_product_name;
          let originstart = "";
          let originend = "";
          let deststart = "";
          let destend = "";
          if (clickup_product_name?.includes("TRUCK")) {
            originstart = origin_zipcode_range?.split("_")[0];
            originend = origin_zipcode_range?.split("_")[1];
            deststart = destination_zipcode_range?.split("_")[0];
            destend = destination_zipcode_range?.split("_")[1];
          }
          seteditClicked(true);
          setmanagePricingDetails({
            ...managePricingDetails,
            quote_type: details?.quote_type,
            customer_id: details?.customer_id,
            product_id: details?.product_id,
            modal_id: details?.modal_id,
            modal_name: details?.modal_id,
            max_allow_containers: details?.max_allow_containers,
            cbm_value: details?.cbm,
            min_weight_value: details?.min_weight,
            max_weight_value: details?.max_weight,
            cbm_clicked: true,
            min_weight_clicked: true,
            max_weight_clicked: true,
            originstart,
            originend,
            deststart,
            destend,
            container_type: details?.container_type?.split(",") || [],
            clickup_product_name: clickup_product_name,
            port_of_loading: details?.port_of_loading,
            port_of_discharge: details?.port_of_discharge,
            origin_country: details?.origin_country,
            pickup_address: details?.pickup_address,
            origin_zipcode_range: details?.origin_zipcode_range,
            destination_country: details?.destination_country,
            delivery_address: details?.delivery_address,
            destination_zipcode_range: details?.destination_zipcode_range,
            origin_free_days: details?.origin_free_time?.split(" ")[0],
            destination_free_days:
              details?.destination_free_time?.split(" ")[0],
            ports: details?.ports,
            contract_number: details?.contract_number,
            transit_time: details?.transit_time,
            diffrentTruckLine:
              (details?.product_id == 2 || details?.product_id == 6) &&
              details?.origin_country != details.destination_country,
          });
          let optionFound = optionsForCustomers?.find(
            (item) => item?.value == details?.customer_id
          );
          setselectedOptionCustomer({
            label: optionFound?.label,
            value: optionFound?.value,
            everest_type: optionFound?.everest_type,
            demand_forcast_type: optionFound?.demand_forcast_type,
          });

          let foundOriginCountry = options?.find(
            (country) => country?.value == details?.origin_country
          );
          setSelectedOption(foundOriginCountry);
          let foundDestinationCountry = options?.find(
            (country) => country?.value == details?.destination_country
          );
          // console.log(foundOriginCountry,foundDestinationCountry,'origin and destination country');
          setSelectedOptionDestination(foundDestinationCountry);
          setMaxAllowedDays(details?.max_allow_days);
          setMaxValidityDate(details?.validity_start_date);
          setMaxValidityEndDate(details?.validity_end_date);

          let foundCareer = carrierList?.find(
            (item) => item?.value == details?.career_code
          );
          let foundCareerDest = carrierList?.find(
            (item) => item?.value == details?.destination_career_code
          );
          let foundAgent = agentList?.find(
            (item) => item?.value == details?.broker_id
          );
          let foundCommodity = commodityList?.filter((item) =>
            details?.parent_hs_code?.includes(item?.value)
          );
          setselectedCarrerList({
            label: foundCareer?.label || carrier?.label,
            value: foundCareer?.value || carrier?.value,
          });
          setselectedCarrerListDestination({
            label: foundCareerDest?.label || destinationCarrier?.label,
            value: foundCareerDest?.value || destinationCarrier?.value,
          });
          setselectedAgentList({
            label: foundAgent?.label,
            value: foundAgent?.value,
          });
          setselectedCommodityList(foundCommodity);

          let warehouseListInside = [];
          let vendorListInside = [];
          if (optionFound?.demand_forcast_type) {
            warehouseListInside = await getWarehouseList(
              optionFound?.value,
              null,
              setwarehouseList,
              navigate,
              null
            );
            vendorListInside = await getVendorList(
              optionFound?.value,
              null,
              null,
              setvendorList,
              navigate
            );
          }

          if (details?.warehouse_code) {
            if (warehouseListInside.length == 0) {
              warehouseListInside = warehouseList;
            }

            console.log("warehouse", details?.warehouse_code);
            let optionFoundWarehouse = warehouseListInside?.find(
              (item) => item?.value == details?.warehouse_code
            );
            setselectedWarehouse({
              label: optionFoundWarehouse?.label || details?.warehouse_code,
              value: optionFoundWarehouse?.value || details?.warehouse_code,
            });
          }

          if (details?.vendor_name) {
            if (vendorListInside.length == 0) {
              vendorListInside = vendorList;
            }
            let optionFoundVendor = vendorListInside?.find(
              (vendor) => vendor?.vendor_name == details?.vendor_name
            );

            setselectedVendor({
              label: conditionalExp(
                optionFoundVendor?.vendor_name,
                conditionalExp(
                  optionFoundVendor?.Address,
                  optionFoundVendor?.vendor_name +
                    " | " +
                    optionFoundVendor?.Address,
                  optionFoundVendor?.vendor_name
                ),
                details?.vendor_name
              ),
              value: optionFoundVendor?.vendor_name || details?.vendor_name,
            });
          }

          // setselectedCommodityList(details?.commodity_id);
          setTimeout(() => {
            handleShow();
          }, 500);
        }

        getComments(price_id);
      } else {
        console.log(response?.data?.message);
      }
    } catch (error) {
      console.log(error, "error of get price details");
      if (error?.response?.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  };
  const getComments = async (price_id) => {
    let token = localStorage.getItem("mtm_user_token");
    try {
      let data = {
        price_id: price_id,
      };

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtm/selfprice/get_comments`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: data,
      });
      if (!response.data.error) {
        setCommentList(response.data?.comments);
      }
    } catch (error) {
      console.log(error, "error of add comment");
      if (error?.response?.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  };
  const handleAddComment = async (e) => {
    e.preventDefault();
    let token = localStorage.getItem("mtm_user_token");
    setAddingComment(true);
    try {
      let data = {
        price_id: priceId,
        comment: commentData,
        container_type: showCommentModal?.container_type,
        price_rate_type_id: showCommentModal?.price_rate_type_id,
      };

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtm/selfprice/add_comment`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: data,
      });
      if (!response.data.error) {
        setShowCommentModal(false);
        setCommentData("");
        getPriceDetails(priceId);
      } else {
        console.log(response?.data?.message);
      }
    } catch (error) {
      console.log(error, "error of add comment");
      if (error?.response?.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setAddingComment(false);
    }
  };
  const handleQuoteClone = async (price_id) => {
    let token = localStorage.getItem("mtm_user_token");
    setIsLoadingMore(true);
    try {
      let data = {
        price_id: price_id,
      };

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtm/selfprice/clone_quote`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: data,
      });
      if (!response.data.error) {
        await getPricingDetails("reload");
        setCurrIndex(0);
      } else {
        console.log(response?.data?.message);
      }
    } catch (error) {
      console.log(error, "error of get price details");
      if (error?.response?.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsLoadingMore(false);
    }
  };
  const postPlusIcon = async (from) => {
    // console.log(from,"from");
    setsendingPlusDetails(true);
    setsendingPlusDetailsError(null);
    try {
      let token = localStorage.getItem("mtm_user_token");
      let data = {
        description: selectedLabel?.description,
        rate_type_code: selectedLabel?.rate_type_code,
        rate_usd: selectedLabel?.rate_usd,
        rate_currency: selectedLabel?.rate_currency,
        rate_type_id: selectedLabel?.rate_type_id || null,
        container_type: selectedLabel?.container_type,
        amount_usd: selectedLabel?.amount_usd,
        actual_amount_usd: selectedLabel?.actual_amount_usd,
        actual_rate_usd: selectedLabel?.actual_rate_usd,
        rate_description: selectedLabel?.rate_description,
        pallet: selectedLabel?.pallet,
        qty: selectedLabel?.qty,
        price_id: priceId,
      };

      if (from == "edit") {
        data.id = selectedLabel?.id;
      }

      const response = await axios({
        url:
          from == "add"
            ? `${process.env.REACT_APP_BASE_URL}/mtm/selfprice/add_breackup`
            : `${process.env.REACT_APP_BASE_URL}/mtm/selfprice/edit_breackup`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: data,
      });
      setsendingPlusDetails(false);
      if (!response.data.error) {
        setselectedQuotePriceDetails([]);
        setselectedQuotePriceDetailsUSD([]);
        getPriceDetails(priceId);
        handleCloseCustomer();
      } else {
        setsendingPlusDetailsError(response.data.message);
      }
    } catch (error) {
      setsendingPlusDetails(false);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  };
  const getCustomerList = async () => {
    try {
      let token = localStorage.getItem("mtm_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/list`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
      });

      if (!response.data.error) {
        let customers = response?.data?.customers?.filter(
          (item) => item.customer_type == "regular"
        );
        let optionsForCustomers = [];
        for (const element of customers) {
          let obj = {
            label: element?.organization_name,
            value: element?.customer_id,
            everest_type: element?.everest_type,
            demand_forcast_type: element?.demand_forcast_type,
            // id: element?.customer_id,
            // customer_type: element?.customer_type,
          };
          optionsForCustomers.push(obj);
        }
        setoptionsForCustomers(optionsForCustomers);
        setoptionsModifiedCustomers(optionsForCustomers);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  };
  const getCarrierDetails = async (clickup_product_name) => {
    let name = "";
    if (clickup_product_name?.includes("OCEAN")) {
      name = "ocean";
    } else if (clickup_product_name?.includes("TRUCK")) {
      name = "truck";
    } else if (clickup_product_name?.includes("Air")) {
      name = "air";
    } else {
      name = "";
    }

    try {
      const token = localStorage.getItem("mtm_user_token");
      let data = {
        career_type: name,
        disablePagination: true,
      };

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtm/list_career`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },

        data: data,
      });
      if (!response.data.error) {
        console.log(response, "response of carrier list");
        const carriersList = response.data.carriersList.map((element) => ({
          label: element.career_name,
          value: element.career_scac,
          // career_code:element.career_scac
        }));
        setcarrierList(carriersList);
      } else {
        setcarrierList([]);
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }

      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  };
  const getCommodities = async () => {
    try {
      const token = localStorage.getItem("mtm_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtm/commodity/list_commodity_category`,
        method: "get",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
      });
      if (!response.data.error) {
        const commodities = response.data.commodities.map((element) => ({
          label: element.hs_code + " | " + element.commodity_name,
          value: element.hs_code,
        }));
        setcommodityList(commodities);
      } else {
        setcommodityList([]);
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }

      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  };
  async function getBrokers() {
    setIsLoadingMore(true);
    try {
      const token = localStorage.getItem("mtm_user_token");
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/broker/all`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
      });
      if (response.status == 200 && !response.data.error) {
        const brokers = response.data.brokers.map((element) => ({
          label: element.company_name,
          value: element.broker_id,
        }));
        setagentList(brokers);
      } else {
        setagentList([]);
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    } finally {
      setIsLoadingMore(false);
    }
  }
  const getTruckRates = (rate_type_code, cont, key,from) => {
    let array = from == "USD" ? selectedQuotePriceDetailsUSD : selectedQuotePriceDetails
    let allRrieghtDetails = array?.filter((quote) => {
      if (cont == "other") {
        return quote?.rate_type_code?.includes(rate_type_code);
      } else {
        return (
          quote?.rate_type_code?.includes(rate_type_code) &&
          quote?.container_type == cont
        );
      }
    });


    let sum = 0;

    for (const element of allRrieghtDetails) {
      if (element[key]) {
        sum += parseFloat(element[key]);
      }
    }
    return sum;
  };
  const checkAllDetailsFilled = () => {
    let allFilled = true;
    if (managePricingDetails?.quote_type == "named_accounts") {
      if (
        !selectedOptionCustomer ||
        managePricingDetails?.contract_number == "" ||
        maxValidityDate == "" ||
        maxValidityEndDate == ""
      ) {
        allFilled = false;
      }
    } else if (managePricingDetails?.quote_type == "spot") {
      if (maxValidityDate == "" || maxValidityEndDate == "") {
        allFilled = false;
      }
    } else if (managePricingDetails?.quote_type == "contract") {
      if (
        managePricingDetails?.contract_number == "" ||
        maxValidityDate == "" ||
        maxValidityEndDate == ""
      ) {
        allFilled = false;
      }
    }
    if (
      managePricingDetails?.product_id == "" ||
      managePricingDetails?.modal_name == "" ||
      !selectedOption ||
      !selectedOptionDestination
    ) {
      allFilled = false;
    }
    if (!managePricingDetails?.clickup_product_name?.includes("TRUCK")) {
      if (!selectedOptionPort || !selectedOptionPortDestination) {
        allFilled = conditionalExp(
          managePricingDetails?.quote_type == "named_accounts" &&
            selectedOptionCustomer?.value &&
            selectedOptionCustomer?.demand_forcast_type,
          allFilled,
          false
        );
      }
    }
    // if(managePricingDetails?.clickup_product_name?.includes("TRUCK") && managePricingDetails?.quote_type == "named_accounts" && !selectedWarehouse?.value){
    //   allFilled = false;
    // }
    // if (managePricingDetails?.clickup_product_name?.includes("TRUCK")) {
    //   if (
    //     managePricingDetails?.originstart == "" ||
    //     managePricingDetails?.originend == "" ||
    //     managePricingDetails?.deststart == "" ||
    //     managePricingDetails?.destend == ""
    //   ) {
    //     allFilled = false;
    //   }
    // }

    if (
      managePricingDetails?.modal_name == 3 ||
      managePricingDetails?.modal_name == 4 ||
      managePricingDetails?.modal_name == 6 ||
      managePricingDetails?.modal_name == 12 ||
      managePricingDetails?.modal_name == 15 ||
      managePricingDetails?.modal_name == 16 ||
      managePricingDetails?.modal_name == 17 ||
      managePricingDetails?.modal_name == 18
    ) {
      if (
        !managePricingDetails?.pickup_address ||
        !managePricingDetails?.origin_zipcode_range
      ) {
        allFilled = conditionalExp(
          managePricingDetails?.quote_type == "named_accounts" &&
            selectedOptionCustomer?.value &&
            selectedOptionCustomer?.demand_forcast_type,
          allFilled,
          false
        );
      }
    } else if (
      managePricingDetails?.modal_name == 2 ||
      managePricingDetails?.modal_name == 4 ||
      managePricingDetails?.modal_name == 7 ||
      managePricingDetails?.modal_name == 12 ||
      managePricingDetails?.modal_name == 14 ||
      managePricingDetails?.modal_name == 16 ||
      managePricingDetails?.modal_name == 17
    ) {
      if (
        !managePricingDetails?.delivery_address ||
        !managePricingDetails?.destination_zipcode_range
      ) {
        allFilled = conditionalExp(
          managePricingDetails?.quote_type == "named_accounts" &&
            selectedOptionCustomer?.value &&
            selectedOptionCustomer?.demand_forcast_type,
          allFilled,
          false
        );
      }
    }

    if (!selectedCarrerList) {
      allFilled = false;
    }
    if (!selectedAgentList) {
      allFilled = false;
    }
    if (!selectedCommodityList.length) {
      allFilled = false;
    }
    if (
      managePricingDetails?.clickup_product_name == "TRUCK LTL" ||
      managePricingDetails?.clickup_product_name == "OCEAN LCL"
    ) {
      if (
        (managePricingDetails?.min_weight_clicked &&
          managePricingDetails?.min_weight_value == "") ||
        (managePricingDetails?.max_weight_clicked &&
          managePricingDetails?.max_weight_value == "") ||
        (managePricingDetails?.cbm_clicked &&
          managePricingDetails?.cbm_value == "")
      ) {
        allFilled = false;
      }
    }
    // if((managePricingDetails?.clickup_product_name?.includes("OCEAN")||managePricingDetails?.clickup_product_name?.includes("TRUCK"))){
    //   if(managePricingDetails?.origin_free_days==""){
    //     allFilled=false;
    //   }
    // }

    if (!managePricingDetails?.transit_time) {
      allFilled = false;
    }

    if (
      managePricingDetails?.quote_type == "named_accounts" &&
      selectedOptionCustomer?.value &&
      selectedOptionCustomer?.demand_forcast_type
    ) {
      if (!selectedVendor?.value || !selectedWarehouse?.value) {
        allFilled = false;
      }
    }

    return !allFilled;
  };


  const getBlockCurrency = (rate_type_code, cont) => {
    const getforCondition = (element) => {
      if (cont && cont != "other") {
        return (
          element?.rate_type_code === rate_type_code &&
          element?.container_type === cont
        );
      } else {
        return element?.rate_type_code === rate_type_code;
      }
    };
    for (let i = 0; i < selectedQuotePriceDetails.length; i++) {
      const element = selectedQuotePriceDetails[i];
      if (getforCondition(element)) {
        return element?.rate_currency;
      }
    }
    return "";
  };
 async function getGroundTotalCurrencyBlock(containers) {
  console.log('getgroundtotal called')
   if(changingCurrency == false) setChangingCurrency(true);
    let container_type = ["other"];
    if(containers?.length){
      container_type = containers;
    }
    
    let currencyBlocks = []
    
    for (const cont of container_type) {
        let currencies = ["USD"];
        for (let i = 0; i < selectedQuotePriceDetails.length; i++) {
          const element = selectedQuotePriceDetails[i];
          if (cont && cont != "other") {
            if (element?.container_type === cont) {
              if(currencies.includes(element?.rate_currency)){
                continue;
              }
              currencies.push(element?.rate_currency);
            }
          } else {
            if(currencies.includes(element?.rate_currency)){
                continue;
              }
            currencies.push(element?.rate_currency);
          }
        }
    
        for (const i in currencies) {
          let total = 0;
    
          for (const rate_type_code of rateTypeCodes) {
            let blockCurrency = getBlockCurrency(rate_type_code, cont);
            let blockAmount = getFrieghtRatesOriginal(
              rate_type_code,
              cont
            )
    
            if(currencies[i] == blockCurrency){
              total += blockAmount;
            }else{
              let converted =  await convertPrice(blockAmount,blockCurrency,currencies[i])
    
              total += converted
            }
    
            
          }
    
          currencies[i] = {
            currency: currencies[i],
            amount: total?.toFixed(2),
            container_type: cont,
          }
        }
        currencyBlocks.push(...currencies)
      }
  

    setGroundTotalBlocks(currencyBlocks);
    console.log(currencyBlocks,'groundBlocks')
    setChangingCurrency(false)
    return currencyBlocks
  }
  async function updateBlockCurrency (rate_type_code, cont,to_currency){
 setChangingCurrency(true);
  let breackupsFiltered =  selectedQuotePriceDetails?.filter((quote) => {
                                            if (cont == "other") {
                                              return quote?.rate_type_code?.includes(
                                                rate_type_code
                                              );
                                            } else {
                                              return (
                                                quote?.rate_type_code?.includes(
                                                  rate_type_code
                                                ) &&
                                                quote?.container_type == cont
                                              );
                                            }
                                          })

          let breackupsToBeUpdated = [];

        for (const breckup of breackupsFiltered) {
           breackupsToBeUpdated.push({
            id: breckup?.id,
            actual_amount_usd :await  convertPrice(breckup?.actual_amount_usd,breckup?.rate_currency,to_currency),
            amount_usd : await convertPrice(breckup?.amount_usd,breckup?.rate_currency,to_currency),
            rate_usd : await convertPrice(breckup?.rate_usd,breckup?.rate_currency,to_currency),
            actual_rate_usd : await convertPrice(breckup?.actual_rate_usd,breckup?.rate_currency,to_currency),
          })
        }              

        
          try {
            let token = localStorage.getItem("mtm_user_token");
            let response = await axios({
              url: `${process.env.REACT_APP_BASE_URL}/mtm/selfprice/breackup_currency_change`,
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                jwt_token: token,
              },
              data: {
                price_id: priceId,
                rate_currency: to_currency,
                breakups: breackupsToBeUpdated,
              },
            });
      
            if (response.status == 200) {
              if (response.data.error) {
                alert(response.data.message);
              } else {
                // setselectedQuotePriceDetails([]);
               await getPriceDetails(priceId);
              }
            }
          } catch (error) {
            console.log(error);
            if (error.response.status == 401) {
              localStorage.removeItem("mtm_user_token");
              localStorage.removeItem("mtm_user_data");
              navigate("/");
              return;
            }
          }

          // setChangingCurrency(false)
        
       


  } 

  useEffect(() => {
    if(
     managePricingDetails?.container_type 
    ){
      getGroundTotalCurrencyBlock(managePricingDetails?.container_type)
    }
  }, [
    rateTypeCodes,
    selectedQuotePriceDetails,
  ])
  
  useEffect(() => {
    async function getDropdownData() {
      const tempClickupData = await getDropdown();
      setClickupData(tempClickupData);
    }
    getDropdownData();
    getCountriesFn();
    getCustomerList();
    getCommodities();
    getBrokers();
    getAirPort();
  }, []);

  useEffect(() => {
    if (!selectedOption) {
      setSelectedOptionPort(null);
    }
    if (selectedOption && allCountries?.length > 0) {
      let countryCode = getCountryCode(selectedOption);
      getPortDetails(countryCode, "origin");
    }
  }, [selectedOption, allCountries?.length]);
  useEffect(() => {
    if (!selectedOptionDestination) {
      setSelectedOptionPortDestination(null);
    }
    if (selectedOptionDestination && allCountries?.length > 0) {
      let countryCode = getCountryCode(selectedOptionDestination);
      getPortDetails(countryCode, "destination");
    }
  }, [selectedOptionDestination, allCountries?.length]);

  // console.log(selectedOption,"selectedOgetCarrierDetailsption");
  // console.log(selectedOptionDestination,"selectedOptionDestination");

  useEffect(() => {
    if (managePricingDetails?.product_id == "") {
      return;
    }
    getModalTypes();
    let clickup_product_name = clickupData
      ?.filter((item) => item.dropdown_type == "product")
      ?.find(
        (item) => item?.product_id == managePricingDetails?.product_id
      )?.clickup_product_name;
    getCarrierDetails(clickup_product_name);
    setmanagePricingDetails({
      ...managePricingDetails,
      clickup_product_name: clickup_product_name,
    });
    setSelectedOption(null);
    setSelectedOptionDestination(null);
    setSelectedOptionPort(null);
    setSelectedOptionPortDestination(null);
  }, [managePricingDetails?.product_id]);

  console.log(managePricingDetails, "managePricingDetails");
  useEffect(() => {
    const token = localStorage.getItem("mtm_user_token");
    if (!token) {
      navigate("/");
    }
    if (token) {
      const decodedToken = jwt_decode(token);
      if (decodedToken.exp < Date.now() / 1000) {
        // Token has expired
        navigate("/");
      }
    }
    getPricingDetails();
  }, [
    currentPage,
    filterDetails?.product_type,
    filterDetails?.service_type,
    filterDetails?.validity,
    filterDetails?.origin_port,
    filterDetails?.destination_port,
  ]);

  useEffect(() => {
    if (
      managePricingDetails?.origin_country &&
      managePricingDetails?.destination_country
    ) {
      let foundOriginCountry = options?.find(
        (country) => country?.value == managePricingDetails?.origin_country
      );
      setSelectedOption(foundOriginCountry);
      let foundDestinationCountry = options?.find(
        (country) => country?.value == managePricingDetails?.destination_country
      );
      setSelectedOptionDestination(foundDestinationCountry);

      // console.log(
      //   "country selected",
      //   foundOriginCountry,
      //   foundDestinationCountry
      // );
    }
  }, [
    managePricingDetails?.origin_country,
    managePricingDetails?.destination_country,
  ]);

  useEffect(() => {
    if (
      optionsPort?.length > 0 &&
      optionsPortDestination?.length > 0 &&
      editClicked &&
      managePricingDetails?.ports
    ) {
      // let port=optionsPort?.find((item)=>item?.label?.includes(managePricingDetails?.port_of_loading));
      // let destination=optionsPortDestination?.find((item)=>item?.label?.includes(managePricingDetails?.port_of_discharge));
      let port_of_loadingArray = managePricingDetails?.ports
        ?.filter((item) => item?.port_type == "port_of_loading")
        ?.map((item) => {
          let obj = {
            label: `${item?.port_name} | ${item?.global_port_code}`,
            value: `${item?.port_name} | ${item?.global_port_code}`,
          };
          return obj;
        });
      let port_of_destinationArray = managePricingDetails?.ports
        ?.filter((item) => item?.port_type == "port_of_discharge")
        ?.map((item) => {
          let obj = {
            label: `${item?.port_name} | ${item?.global_port_code}`,
            value: `${item?.port_name} | ${item?.global_port_code}`,
          };
          return obj;
        });
      setSelectedOptionPort(port_of_loadingArray);
      setSelectedOptionPortDestination(port_of_destinationArray);
    }
  }, [
    optionsPort?.length,
    optionsPortDestination?.length,
    managePricingDetails?.ports,
  ]);

  useEffect(() => {
    if (!rawFile || rawFile.length == 0) {
      setpreviewUrl(null);
      return;
    }
    const objectURL = URL.createObjectURL(
      rawFile.length ? rawFile[0] : rawFile
    );
    setpreviewUrl(objectURL);
  }, [rawFile]);

  useEffect(() => {
    if (
      managePricingDetails?.clickup_product_name?.includes("TRUCK") &&
      managePricingDetails?.quote_type == "named_accounts" &&
      selectedOptionCustomer?.value
    ) {
      getWarehouseList(
        selectedOptionCustomer?.value,
        null,
        setwarehouseList,
        navigate,
        null
      );
    } else if (
      managePricingDetails?.quote_type == "named_accounts" &&
      selectedOptionCustomer?.value &&
      selectedOptionCustomer?.demand_forcast_type
    ) {
      getWarehouseList(
        selectedOptionCustomer?.value,
        null,
        setwarehouseList,
        navigate,
        null
      );
      getVendorList(
        selectedOptionCustomer?.value,
        null,
        null,
        setvendorList,
        navigate
      );
    }
  }, [selectedOptionCustomer?.value]);

  return (
    <>
      <NavBar />
      <Container>
        <Row>
          <Col className="col-12 py-3">
            <div className="wrapper-box">
              <div className="w-100 tabs-row self-price">
                <div className="d-flex pb-4">
                  <div className="py-3">
                    <button
                      type="button"
                      className="back-btn d-flex alig-items-center"
                      onClick={() => {
                        navigate("/filter");
                      }}
                    >
                      <svg className="icon me-2 fs-22">
                        <use href="#icon_leftarrow"></use>
                      </svg>
                      <strong className="fs-14">Back</strong>
                    </button>
                  </div>
                  <div className="d-flex ms-auto">
                    <button
                      type="button"
                      className="pricing-btn rounded-pill w-btn me-4"
                      onClick={() => navigate("/manage-agents")}
                    >
                      Manage Agent
                    </button>
                    <button
                      type="button"
                      className="pricing-btn rounded-pill w-btn me-4"
                      onClick={() => navigate("/manage-carrier")}
                    >
                      Manage Carrier
                    </button>
                    <button
                      type="button"
                      className="pricing-btn rounded-pill w-btn"
                      onClick={() => {
                        setaddSelfPricingClicked("add");
                        setCurrIndex(0);
                        setmanagePricingDetails({
                          quote_type: "named_accounts",
                          product_id: "",
                          clickup_product_name: "",
                          modal_name: 13,
                          min_weight_clicked: false,
                          max_weight_clicked: false,
                          cbm_clicked: false,
                          min_weight_value: "",
                          max_weight_value: "",
                          cbm_value: "",
                          container_type: [],
                          max_allow_containers: "",
                          originstart: "",
                          originend: "",
                          deststart: "",
                          destend: "",
                          origin_free_days: "",
                          destination_free_days: "",
                          contract_number: "",
                          transit_time: "",
                        });
                        setisCompleted(false);
                        setselectedOptionCustomer(null);
                        setselectedWarehouse(null);
                        setselectedVendor(null);
                        setSelectedOption(null);
                        setSelectedOptionDestination(null);
                        setSelectedOptionPort(null);
                        setSelectedOptionPortDestination(null);
                        setCurrIndex(0);
                        handleClose("add");
                        setpriceId(null);
                        setCommentList([]);
                        setMaxValidityDate();
                        setMaxValidityEndDate();
                        setMaxAllowedDays("");
                        setselectedCarrerList(null);
                        setselectedAgentList(null);
                        setselectedCommodityList([]);
                        setpreviouslySelectedServiceType("");
                        seteditClicked(false);
                        handleShow();
                      }}
                    >
                      + Add Self Pricing
                    </button>
                  </div>
                </div>

                <ul
                  className="nav nav-pills mb-3 gap-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li
                    className="nav-item"
                    role="presentation"
                    onClick={() => {
                      if (filterDetails.product_type != "ocean") {
                        setclkData([]);
                      }
                      setfilterDetails({
                        ...filterDetails,
                        product_type: "ocean",
                        service_type: "fcl",
                      });
                      setCurrentPage(0);
                    }}
                  >
                    <button
                      className="nav-link active"
                      id="pills-ocean-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-ocean"
                      type="button"
                      role="tab"
                      aria-controls="pills-ocean"
                      aria-selected="true"
                    >
                      <svg className="icon me-2 fs-22">
                        <use href="#icon_ocean"></use>
                      </svg>
                      Ocean
                    </button>
                  </li>
                  <li
                    className="nav-item"
                    role="presentation"
                    onClick={() => {
                      if (filterDetails.product_type != "air") {
                        setclkData([]);
                      }
                      setfilterDetails({
                        ...filterDetails,
                        product_type: "air",
                        service_type: "",
                      });
                      setCurrentPage(0);
                    }}
                  >
                    <button
                      className="nav-link"
                      id="pills-air-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-air"
                      type="button"
                      role="tab"
                      aria-controls="pills-air"
                      aria-selected="true"
                    >
                      <svg className="icon me-2 fs-22">
                        <use href="#icon_air"></use>
                      </svg>
                      Air
                    </button>
                  </li>
                  <li
                    className="nav-item"
                    role="presentation"
                    onClick={() => {
                      if (filterDetails.product_type != "truck") {
                        setclkData([]);
                      }
                      setfilterDetails({
                        ...filterDetails,
                        product_type: "truck",
                        service_type: "ftl",
                      });
                      setCurrentPage(0);
                    }}
                  >
                    <button
                      className="nav-link"
                      id="pills-truck-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-truck"
                      type="button"
                      role="tab"
                      aria-controls="pills-truck"
                      aria-selected="true"
                    >
                      <svg className="icon me-2 fs-22">
                        <use href="#icon_truck"></use>
                      </svg>
                      Truck
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <div className="d-flex pt-2">
                {filterDetails.product_type != "air" && (
                  <div className="w-25 me-4">
                    {filterDetails.product_type == "ocean" && (
                      <select
                        className="form-select"
                        value={filterDetails.service_type}
                        onChange={(e) => {
                          setclkData([]);
                          setfilterDetails({
                            ...filterDetails,
                            service_type: e.target.value,
                          });
                          setCurrentPage(0);
                        }}
                      >
                        <option value="fcl">FCL</option>
                        <option value="lcl">LCL</option>
                      </select>
                    )}
                    {filterDetails.product_type == "truck" && (
                      <select
                        className="form-select"
                        value={filterDetails.service_type}
                        onChange={(e) => {
                          setclkData([]);
                          setfilterDetails({
                            ...filterDetails,
                            service_type: e.target.value,
                          });
                          setCurrentPage(0);
                        }}
                      >
                        <option value="ftl">FTL</option>
                        <option value="ltl">LTL</option>
                      </select>
                    )}
                  </div>
                )}

                <div className="w-25 me-4">
                  <select
                    className="form-select"
                    onChange={(e) => {
                      setclkData([]);
                      setfilterDetails({
                        ...filterDetails,
                        validity: e.target.value,
                      });
                      setCurrentPage(0);
                    }}
                  >
                    <option value="valid">Valid</option>
                    <option value="invalid">Invalid</option>
                  </select>
                </div>
                {filterDetails?.product_type != 'truck' && <>
                
                <div className="w-25 me-4">
                  <AsyncSelect
                    cacheOptions
                    placeholder='Origin Port'
                    // options={optionsPort}
                    loadOptions={debounce(async(inputValue, callback) => {
                      if (inputValue) {
                        let options = []
                        
                        if(filterDetails?.product_type == 'air'){
                         let countryAirports = airportCodes?.filter(
                            (ap) =>
                              ap?.iata?.toLowerCase()?.includes(inputValue?.toLowerCase()) || (ap?.name?.toLowerCase()?.includes(inputValue?.toLowerCase()) && ap?.iata)
                          ); 

                          options =  countryAirports.map((airport) => ({
                            label: `${airport.name} | ${airport.iata}`,
                            value: airport.iata,
                          }));
                        }else{
                         options = await getPortDetails(null, "origin",inputValue);
                        }
                          callback(options)
                          
                      } else {
                        callback([]);
                      }
                    },500)}
                    isClearable
                    isSearchable
                    onChange={(e) => {
                      setclkData([]);
                      setfilterDetails({
                        ...filterDetails,
                        origin_port: e?.value,
                      });
                      setCurrentPage(0);
                    }}
                  />
                </div>
                <div className="w-25 me-4">
                <AsyncSelect
                    cacheOptions
                    placeholder='Destination Port'
                    // options={optionsPort}
                    loadOptions={debounce(async(inputValue, callback) => {
                      if (inputValue) {
                        let options = []
                        
                        if(filterDetails?.product_type == 'air'){
                         let countryAirports = airportCodes?.filter(
                            (ap) =>
                              ap?.iata?.toLowerCase()?.includes(inputValue?.toLowerCase()) || (ap?.name?.toLowerCase()?.includes(inputValue?.toLowerCase()) && ap?.iata)
                          ); 

                          options =  countryAirports.map((airport) => ({
                            label: `${airport.name} | ${airport.iata}`,
                            value: airport.iata,
                          }));
                        }else{
                        options =  await getPortDetails(null, "destination",inputValue);
                        }
                          callback(options)
                          
                      } else {
                        callback([]);
                      }
                    },500)}
                    isClearable
                    isSearchable
                    onChange={(e) => {
                      setclkData([]);
                      setfilterDetails({
                        ...filterDetails,
                        destination_port: e?.value,
                      });
                      setCurrentPage(0);
                    }}
                  />
                </div>
                </>}
                <div className="w-25">
                  <button
                    type="button"
                    onClick={handleShowUpload}
                    className="pricing-btn rounded-pill w-btn me-4"
                  >
                    Bulk Upload
                  </button>
                </div>
              </div>
              {dataFetched && clkData?.length > 0 && (
                <div className="w-100 selfpricing-tbl">
                  <table className="table text-center my-4 border">
                    <thead>
                      <tr className="border" style={{ background: "#EBEEFF" }}>
                        <th>Created At</th>
                        <th>Created By</th>
                        <th>Quote Type</th>
                        <th>Service Type</th>
                        <th>Modal Type</th>
                        {filterDetails.product_type == "truck" ? (
                          <>
                            <th>Origin Country</th>
                            <th>Destination Country</th>
                          </>
                        ) : (
                          <>
                            <th>Origin Port</th>
                            <th>Destination Port</th>
                          </>
                        )}
                        <th>Carrier Name</th>
                        <th>Validity Start Date</th>
                        <th>Validity End Date</th>
                        <th>
                          {conditionalExp(
                            filterDetails.product_type == "truck" ||
                              filterDetails.product_type == "air" ||
                              filterDetails.service_type == "lcl",
                            "CBM",
                            "Container Type"
                          )}
                        </th>
                        <th>Agent</th>
                        <th>Total Cost</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataFetched &&
                        clkData?.map((clk, clk_index) => (
                          <tr
                            ref={
                              clkData?.length == clk_index + 1
                                ? lastBookElementRef
                                : tempRef
                            }
                          >
                            <td>
                              {moment(clk?.createdAt).format("DD-MM-YYYY")}
                            </td>
                            <td>{clk?.created_by}</td>
                            <td>
                              <div>
                                {clk?.quote_type
                                  ?.replace("_", " ")
                                  ?.replace(/\w+/g, function (w) {
                                    return (
                                      w[0].toUpperCase() +
                                      w.slice(1).toLowerCase()
                                    );
                                  })}
                              </div>
                              <div>{clk?.state == 0 && "(Uncomplete)"}</div>
                            </td>
                            <td>{clk?.service_type}</td>
                            <td>{clk?.modal_name}</td>
                            {filterDetails.product_type == "truck" ? (
                              <>
                                <td>{clk?.origin_country}</td>
                                <td>{clk?.destination_country}</td>
                              </>
                            ) : (
                              <>
                                <td className="">
                                  <div className="d-flex justify-content-center align-items-center">
                                    <div>
                                      {clk?.ports?.filter(
                                        (item) =>
                                          item?.port_type == "port_of_loading"
                                      )?.length > 0 && (
                                        <>
                                          {getInitialPort(
                                            clk,
                                            "port_of_loading"
                                          )}
                                        </>
                                      )}
                                    </div>
                                    <div className="">
                                      {clk?.ports?.filter(
                                        (item) =>
                                          item?.port_type == "port_of_loading"
                                      )?.length > 1 && (
                                        <button
                                          type="button"
                                          className="p-0 m-0 border-0 bg-body edit-btn "
                                          onClick={() => {
                                            setshowPortModal(true);
                                            setportModalDisplay({
                                              name: "Origin Port",
                                              data: clk?.ports,
                                              type: "port_of_loading",
                                            });
                                          }}
                                          style={{ background: "none" }}
                                        >
                                          <svg className="icon ms-2 fs-22 mt-2">
                                            <use href="#icon_vision_withoutslash"></use>
                                          </svg>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex justify-content-center align-items-center">
                                    <div>
                                      {clk?.ports?.filter(
                                        (item) =>
                                          item?.port_type == "port_of_discharge"
                                      )?.length > 0 && (
                                        <>
                                          {getInitialPort(
                                            clk,
                                            "port_of_discharge"
                                          )}
                                        </>
                                      )}
                                    </div>
                                    <div>
                                      {clk?.ports?.filter(
                                        (item) =>
                                          item?.port_type == "port_of_discharge"
                                      )?.length > 1 && (
                                        <button
                                          type="button"
                                          className="p-0 m-0 border-0 bg-body edit-btn"
                                          onClick={() => {
                                            setshowPortModal(true);
                                            setportModalDisplay({
                                              name: "Destination Port",
                                              data: clk?.ports,
                                              type: "port_of_discharge",
                                            });
                                          }}
                                          style={{ background: "none" }}
                                        >
                                          <svg className="icon ms-2 fs-22 mt-2">
                                            <use href="#icon_vision_withoutslash"></use>
                                          </svg>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </td>
                              </>
                            )}
                            <td>{clk?.career_name}</td>
                            <td>
                              {conditionalExp(
                                clk?.validity_start_date,
                                moment(clk?.validity_start_date).format(
                                  "DD-MM-YYYY"
                                )
                              )}
                            </td>
                            <td>
                              {conditionalExp(
                                clk?.validity_end_date,
                                moment(clk?.validity_end_date).format(
                                  "DD-MM-YYYY"
                                )
                              )}
                            </td>
                            <td>
                              {conditionalExp(
                                filterDetails.product_type == "truck" ||
                                  filterDetails.product_type == "air" ||
                                  filterDetails.service_type == "lcl",
                                parseFloat(clk?.cbm || 0).toFixed(2),
                                clk?.container_type
                              )}
                            </td>
                            <td>{clk?.agent_name}</td>
                            <td>
                            <div className="">
                                      {
                                        <button
                                          type="button"
                                          className="p-0 m-0 border-0 bg-body edit-btn "
                                          onClick={() => {
                                            setshowPortModal(true);
                                            setportModalDisplay({
                                              name: "Total Cost",
                                              data: clk?.total_cost_by_container,
                                              type: "total_cost",
                                            });
                                          }}
                                          style={{ background: "none" }}
                                        >
                                          <svg className="icon ms-2 fs-22 mt-2">
                                            <use href="#icon_vision_withoutslash"></use>
                                          </svg>
                                        </button>
                                      }
                                    </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center justify-content-center">
                                <button
                                  type="button"
                                  className="p-0 m-0 border-0 bg-body me-4 edit-btn"
                                  onClick={() => {
                                    setmanagePricingDetails({
                                      ...managePricingDetails,
                                      modal_name: clk?.modal_id,
                                    });
                                    setpriceId(clk?.price_id);
                                    getPriceDetails(
                                      clk?.price_id,
                                      "edit",
                                      {
                                        label: clk?.career_name,
                                        value: clk?.career_code,
                                      },
                                      {
                                        label: clk?.destination_career_name,
                                        value: clk?.destination_career_code,
                                      }
                                    );
                                    setisCompleted(false);
                                    setCurrIndex(0);
                                  }}
                                >
                                  <svg className="icon ms-2 fs-22">
                                    <use href="#icon_edit"></use>
                                  </svg>
                                </button>
                                <button
                                  onClick={() =>
                                    handleQuoteClone(clk?.price_id)
                                  }
                                  type="button"
                                  className="p-0 m-0 border-0 bg-body edit-btn"
                                >
                                  <svg className="icon ms-2 fs-22">
                                    <use href="#icon_copy"></use>
                                  </svg>
                                </button>
                                {conditionalExp(
                                  JSON.parse(
                                    localStorage.getItem("mtm_user_data")
                                  )?.user_type == 1,
                                  <button
                                    onClick={() => {
                                      setpriceIdToDelete(clk?.price_id);
                                      handleShowVendorNew();
                                    }}
                                    type="button"
                                    className="p-0 m-0 border-0 bg-body ms-4 text-danger edit-btn"
                                  >
                                    <svg className="icon ms-2 fs-22">
                                      <use href="#icon_delete"></use>
                                    </svg>
                                  </button>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
              {(isLoadingMore || !dataFetched) && (
                <div className="w-100 text-center pt-3">
                  <Loader />
                </div>
              )}
              {!isLoadingMore && dataFetched && clkData?.length == 0 && (
                <div className="w-100 text-center ">
                  <h1>{errorMessage}</h1>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      {/* Modal */}
      <Modal
        show={show}
        fullscreen={true}
        onHide={() => {
          handleClose();
        }}
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="text-center">
              {editClicked ? "Editing Quote Details" : "Adding Quote Details"}
            </h5>
            <div className="br-gray"></div>
            <div className="stepper-wrapper">
              <div className="progress"></div>
              <div className="screen-indicator completed">1</div>
              <div
                className={`screen-indicator ${currIndex == 1 && "completed"}`}
              >
                2
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="form-modal px-5">
          {currIndex == 0 && (
            <>
              <h6>Quote Type</h6>
              <div className="d-flex py-2">
                <div className="form-check me-4">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="spot"
                    checked={managePricingDetails?.quote_type == "spot"}
                    onClick={() => {
                      setmanagePricingDetails({
                        ...managePricingDetails,
                        quote_type: "spot",
                        max_allow_containers: "",
                      });
                      setselectedOptionCustomer(null);
                      setselectedWarehouse(null);
                      setselectedVendor(null);
                    }}
                  />
                  <label className="form-check-label" for="spot">
                    Spot
                  </label>
                </div>
                <div className="form-check me-4">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="contract"
                    checked={managePricingDetails?.quote_type == "contract"}
                    onClick={() => {
                      setmanagePricingDetails({
                        ...managePricingDetails,
                        quote_type: "contract",
                        max_allow_containers: "",
                      });
                      setselectedOptionCustomer(null);
                      setselectedWarehouse(null);
                      setselectedVendor(null);
                    }}
                  />
                  <label className="form-check-label" for="contract">
                    Contract
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="named_accounts"
                    checked={
                      managePricingDetails?.quote_type == "named_accounts"
                    }
                    onClick={() =>
                      setmanagePricingDetails({
                        ...managePricingDetails,
                        quote_type: "named_accounts",
                      })
                    }
                  />
                  <label className="form-check-label" for="named_accounts">
                    Named Accounts
                  </label>
                </div>
              </div>

              {conditionalExp(
                managePricingDetails?.quote_type == "named_accounts",
                <div className="row row-cols-2 g-4 pb-2">
                  <div className="col">
                    <label htmlFor="Choose-Customer" className="form-label">
                      Choose Customer
                    </label>
                    <Select
                      value={selectedOptionCustomer}
                      onChange={(option) => {
                        setselectedOptionCustomer(option);
                      }}
                      options={optionsModifiedCustomers}
                      isSearchable={true}
                      placeholder="Search"
                      isClearable
                    />
                  </div>

                  <div className="col">
                    <label
                      htmlFor="exampleFormControlInput2"
                      className="form-label"
                    >
                      Maximum Allowed Containers
                    </label>
                    <input
                      type="number"
                      value={managePricingDetails?.max_allow_containers}
                      className="w-100 form-control"
                      placeholder="Enter Allowed Containers"
                      onChange={(e) => {
                        setmanagePricingDetails({
                          ...managePricingDetails,
                          max_allow_containers: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col">
                    <label
                      htmlFor="exampleFormControlInput2"
                      className="form-label"
                    >
                      Validity Start Date
                    </label>
                    <input
                      type="date"
                      id="maxValidityDate"
                      className="w-100 form-control"
                      placeholder="Enter Allowed Days"
                      value={maxValidityDate}
                      min={new Date().toISOString().split("T")[0]}
                      max={
                        maxValidityEndDate
                          ? new Date(maxValidityEndDate)
                              .toISOString()
                              .split("T")[0]
                          : null
                      }
                      onChange={(e) => setMaxValidityDate(e.target.value)}
                    />
                  </div>
                  <div className="col">
                    <label
                      htmlFor="exampleFormControlInput2"
                      className="form-label"
                    >
                      Validity End Date
                    </label>
                    <input
                      type="date"
                      id="maxValidityEndDate"
                      className="w-100 form-control"
                      placeholder="Enter Allowed Days"
                      value={maxValidityEndDate}
                      min={
                        maxValidityDate
                          ? new Date(maxValidityDate)
                              .toISOString()
                              .split("T")[0]
                          : new Date().toISOString().split("T")[0]
                      }
                      onChange={(e) => setMaxValidityEndDate(e.target.value)}
                    />
                  </div>
                  <div className="col">
                    <label
                      htmlFor="exampleFormControlInput2"
                      className="form-label"
                    >
                      Contract Number
                    </label>
                    <input
                      type="text"
                      value={managePricingDetails?.contract_number}
                      className="w-100 form-control"
                      placeholder="Enter Contract Number"
                      onChange={(e) => {
                        setmanagePricingDetails({
                          ...managePricingDetails,
                          contract_number: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              )}
              {conditionalExp(
                managePricingDetails?.quote_type == "contract",
                <div className="row row-cols-2 g-4 pb-2">
                  <div className="col">
                    <label
                      htmlFor="exampleFormControlInput2"
                      className="form-label"
                    >
                      Contract Number
                    </label>
                    <input
                      type="text"
                      value={managePricingDetails?.contract_number}
                      className="w-100 form-control"
                      placeholder="Enter Contract Number"
                      onChange={(e) => {
                        setmanagePricingDetails({
                          ...managePricingDetails,
                          contract_number: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col">
                    <label
                      htmlFor="exampleFormControlInput2"
                      className="form-label"
                    >
                      Maximum Allowed Containers
                    </label>
                    <input
                      type="number"
                      value={managePricingDetails?.max_allow_containers}
                      className="w-100 form-control"
                      placeholder="Enter Allowed Containers"
                      onChange={(e) => {
                        setmanagePricingDetails({
                          ...managePricingDetails,
                          max_allow_containers: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col">
                    <label
                      htmlFor="exampleFormControlInput2"
                      className="form-label"
                    >
                      Validity Start Date
                    </label>
                    <input
                      type="date"
                      id="maxValidityDate"
                      className="w-100 form-control"
                      placeholder="Enter Allowed Days"
                      value={maxValidityDate}
                      min={new Date().toISOString().split("T")[0]}
                      max={
                        maxValidityEndDate
                          ? new Date(maxValidityEndDate)
                              .toISOString()
                              .split("T")[0]
                          : null
                      }
                      onChange={(e) => setMaxValidityDate(e.target.value)}
                    />
                  </div>
                  <div className="col">
                    <label
                      htmlFor="exampleFormControlInput2"
                      className="form-label"
                    >
                      Validity End Date
                    </label>
                    <input
                      type="date"
                      id="maxValidityEndDate"
                      className="w-100 form-control"
                      placeholder="Enter Allowed Days"
                      value={maxValidityEndDate}
                      min={
                        maxValidityDate
                          ? new Date(maxValidityDate)
                              .toISOString()
                              .split("T")[0]
                          : new Date().toISOString().split("T")[0]
                      }
                      onChange={(e) => setMaxValidityEndDate(e.target.value)}
                    />
                  </div>
                </div>
              )}
              {conditionalExp(
                managePricingDetails?.quote_type == "spot",
                <div className="row row-cols-2 g-4 pb-2">
                  <div className="col">
                    <label
                      htmlFor="exampleFormControlInput2"
                      className="form-label"
                    >
                      Validity Start Date
                    </label>
                    <input
                      type="date"
                      id="maxValidityDate"
                      className="w-100 form-control"
                      placeholder="Enter Allowed Days"
                      value={maxValidityDate}
                      min={new Date().toISOString().split("T")[0]}
                      // max={new Date(maxValidityEndDate).toISOString().split("T")[0]}
                      onChange={(e) => setMaxValidityDate(e.target.value)}
                    />
                  </div>
                  <div className="col">
                    <label
                      htmlFor="exampleFormControlInput2"
                      className="form-label"
                    >
                      Validity End Date
                    </label>
                    <input
                      type="date"
                      id="maxValidityEndDate"
                      className="w-100 form-control"
                      placeholder="Enter Allowed Days"
                      value={maxValidityEndDate}
                      min={
                        maxValidityDate
                          ? new Date(maxValidityDate)
                              .toISOString()
                              .split("T")[0]
                          : new Date().toISOString().split("T")[0]
                      }
                      onChange={(e) => setMaxValidityEndDate(e.target.value)}
                    />
                  </div>
                </div>
              )}

              <h6>Service And Modal Type</h6>
              <div className="row row-cols-2 g-4 pb-2">
                <div className="col">
                  <label htmlFor="Choose-Service" className="form-label">
                    Choose Service
                  </label>
                  <select
                    className="form-select"
                    aria-label="Choose Service"
                    value={managePricingDetails?.product_id}
                    disabled={isCompleted}
                    onChange={(e) => {
                      setpreviouslySelectedServiceType(
                        managePricingDetails?.product_id
                      );
                      setmanagePricingDetails({
                        ...managePricingDetails,
                        product_id: e.target.value,
                        modal_name: "",
                        originstart: "",
                        originend: "",
                        deststart: "",
                        destend: "",
                        container_type: [],
                        min_weight_clicked: false,
                        min_weight_value: "",
                        max_weight_clicked: false,
                        max_weight_value: "",
                        cbm_clicked: false,
                        cbm_value: "",
                        origin_free_days: "",
                        destination_free_days: "",
                        transit_time: "",
                      });
                      setSelectedOption(null);
                      setSelectedOptionPort(null);
                      setSelectedOptionDestination(null);
                      setSelectedOptionPortDestination(null);
                      setselectedCarrerList(null);
                      setselectedAgentList(null);
                      setselectedCommodityList([]);
                    }}
                  >
                    <>
                      <option value={""}>Choose service</option>
                      {clickupData
                        ?.filter(
                          (item) =>
                            item.dropdown_type == "product" &&
                            item.product_id != "3" &&
                            item.product_id != "4" &&
                            item.product_id != "158"
                        )
                        ?.map((item) => (
                          <option value={item?.product_id}>
                            {item?.clickup_product_name}
                          </option>
                        ))}
                    </>
                  </select>
                </div>
                <div className="col">
                  <label htmlFor="Choose-Modal" className="form-label">
                    Choose Modal
                  </label>
                  <select
                    className="form-select"
                    aria-label="Choose Modal"
                    value={managePricingDetails?.modal_name}
                    onChange={(e) =>
                      setmanagePricingDetails({
                        ...managePricingDetails,
                        modal_name: e.target.value,
                      })
                    }
                  >
                    <option value={""}>Choose Modal</option>
                    {modalTypes?.map((item) => (
                      <option value={item?.modal_id}>{item?.modal_name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <h6>Origin Details</h6>
              {conditionalExp(
                managePricingDetails?.quote_type == "named_accounts" &&
                  selectedOptionCustomer?.demand_forcast_type,
                <div className="row row-cols-2 g-4 pb-2">
                  <div className="col">
                    <label htmlFor="Choose-Country" className="form-label">
                      Choose Country
                    </label>
                    <Select
                      value={selectedOption}
                      onChange={handleChange}
                      options={options}
                      isSearchable={true}
                      placeholder="Origin Country"
                      isClearable
                    />
                  </div>
                  <div className="col">
                    <label htmlFor="Choose-Vendor" className="form-label">
                      Choose Vendor
                    </label>
                    <Select
                      value={selectedVendor}
                      onChange={(option) => {
                        if (option?.value == "Add Vendor") {
                          handleShowvendorAdd();
                          return;
                        }
                        setselectedVendor(option);
                      }}
                      options={[
                        { label: "Add Vendor", value: "Add Vendor" },
                        ...vendorList?.map((item) => ({
                          label: conditionalExp(
                            item?.Address,
                            item?.vendor_name + " | " + item?.Address,
                            item?.vendor_name
                          ),
                          value: item?.vendor_name,
                        })),
                      ]}
                      isSearchable={true}
                      placeholder="Choose Vendor"
                      isClearable
                    />
                  </div>
                </div>,
                <>
                  <div className="row row-cols-2 g-4 pb-2">
                    <div className="col">
                      <label htmlFor="Choose-Country" className="form-label">
                        Choose Country
                      </label>
                      <Select
                        value={selectedOption}
                        onChange={handleChange}
                        options={options}
                        isSearchable={true}
                        placeholder="Origin Country"
                        isClearable
                      />
                    </div>
                    <div className="col">
                      {!managePricingDetails?.clickup_product_name?.includes(
                        "TRUCK"
                      ) && (
                        <>
                          <label htmlFor="Choose-Port" className="form-label">
                            {managePricingDetails?.clickup_product_name?.includes(
                              "Air"
                            )
                              ? "Choose Airport"
                              : "Choose Port"}
                          </label>
                          <Select
                            value={selectedOptionPort}
                            onChange={handleChangePort}
                            options={optionsPort}
                            isSearchable={true}
                            placeholder="Port of Loading*"
                            isClearable
                            isMulti
                          />
                        </>
                      )}
                    </div>
                  </div>
                  {/* {managePricingDetails?.clickup_product_name?.includes(
                "TRUCK"
              ) && (
                <div className="row row-cols-2 g-4 pb-2">
                  <div className="col">
                    <label htmlFor="Choose-Service" className="form-label">
                      Zip code start
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="please enter zipcode start"
                      value={managePricingDetails?.originstart}
                      onChange={(e) => {
                        setmanagePricingDetails({
                          ...managePricingDetails,
                          originstart: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col">
                    <label htmlFor="Choose-Modal" className="form-label">
                      Zip code end
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="please enter zipcode end"
                      value={managePricingDetails?.originend}
                      onChange={(e) => {
                        setmanagePricingDetails({
                          ...managePricingDetails,
                          originend: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              )} */}
                  {(managePricingDetails?.modal_name == 3 ||
                    managePricingDetails?.modal_name == 4 ||
                    managePricingDetails?.modal_name == 6 ||
                    managePricingDetails?.modal_name == 12 ||
                    managePricingDetails?.modal_name == 15 ||
                    managePricingDetails?.modal_name == 16 ||
                    managePricingDetails?.modal_name == 17 ||
                    managePricingDetails?.modal_name == 18) && (
                    <div className="row row-cols-2 g-4 pb-2">
                      <div className="col">
                        <label
                          htmlFor="exampleFormControlInput2"
                          className="form-label"
                        >
                          Pickup Address
                        </label>
                        <input
                          type="text"
                          value={managePricingDetails?.pickup_address}
                          onChange={(e) => {
                            setmanagePricingDetails({
                              ...managePricingDetails,
                              pickup_address: e.target.value,
                            });
                          }}
                          className="w-100 form-control"
                          placeholder="Enter Pickup Address"
                        />
                      </div>
                      <div className="col">
                        <label
                          htmlFor="exampleFormControlInput2"
                          className="form-label"
                        >
                          Zip Code
                        </label>
                        <input
                          type="text"
                          value={managePricingDetails?.origin_zipcode_range}
                          onChange={(e) => {
                            setmanagePricingDetails({
                              ...managePricingDetails,
                              origin_zipcode_range: e.target.value,
                            });
                          }}
                          className="w-100 form-control"
                          placeholder="Enter Pickup Zip Code"
                        />
                      </div>
                    </div>
                  )}
                  {(managePricingDetails?.clickup_product_name?.includes(
                    "TRUCK"
                  ) ||
                    managePricingDetails?.clickup_product_name?.includes(
                      "OCEAN"
                    )) && (
                    <div className="row row-cols-2 g-4 pb-2">
                      <div className="col">
                        <label
                          htmlFor="exampleFormControlInput2"
                          className="form-label"
                        >
                          Free Time (Ocean = days, Truck = hours)
                        </label>
                        <input
                          type="number"
                          min={0}
                          value={managePricingDetails?.origin_free_days}
                          onChange={(e) => {
                            setmanagePricingDetails({
                              ...managePricingDetails,
                              origin_free_days: e.target.value,
                            });
                          }}
                          className="w-100 form-control"
                          placeholder="Enter Free Time"
                        />
                      </div>{" "}
                    </div>
                  )}
                </>
              )}
              <h6 className="mt-2">Destination Details</h6>
              {conditionalExp(
                managePricingDetails?.quote_type == "named_accounts" &&
                  selectedOptionCustomer?.demand_forcast_type,
                <div className="row row-cols-2 g-4 pb-2">
                  <div className="col">
                    <label htmlFor="Choose-Country" className="form-label">
                      Choose Country
                    </label>
                    <Select
                      value={selectedOptionDestination}
                      onChange={handleChangeDestination}
                      options={options}
                      isSearchable={true}
                      placeholder="Destination Country"
                      isClearable
                    />
                  </div>
                  <div className="col">
                    <label htmlFor="Choose-Warehouse" className="form-label">
                      Choose Warehouse
                    </label>
                    <Select
                      value={selectedWarehouse}
                      onChange={(option) => {
                        if (option?.value == "Add Warehouse") {
                          handleShowwarehouseAdd();
                          return;
                        }
                        setselectedWarehouse(option);
                      }}
                      options={[
                        { label: "Add Warehouse", value: "Add Warehouse" },
                        ...warehouseList,
                      ]}
                      isSearchable={true}
                      placeholder="Choose Warehouse"
                      isClearable
                    />
                  </div>
                </div>,
                <>
                  <div className="row row-cols-2 g-4 pb-2">
                    <div className="col">
                      <label htmlFor="Choose-Country" className="form-label">
                        Choose Country
                      </label>
                      <Select
                        value={selectedOptionDestination}
                        onChange={handleChangeDestination}
                        options={options}
                        isSearchable={true}
                        placeholder="Destination Country"
                        isClearable
                      />
                    </div>
                    <div className="col">
                      {!managePricingDetails?.clickup_product_name?.includes(
                        "TRUCK"
                      ) && (
                        <>
                          <label htmlFor="Choose-Port" className="form-label">
                            {managePricingDetails?.clickup_product_name?.includes(
                              "Air"
                            )
                              ? "Choose Airport"
                              : "Choose Port"}
                          </label>
                          <Select
                            value={selectedOptionPortDestination}
                            onChange={handleChangePortDestination}
                            options={optionsPortDestination}
                            isSearchable={true}
                            placeholder="Port of Discharge*"
                            isClearable
                            isMulti
                          />
                        </>
                      )}
                    </div>
                  </div>
                  {/* {managePricingDetails?.clickup_product_name?.includes(
                "TRUCK"
              ) && (
                <div className="row row-cols-2 g-4 pb-2">
                  <div className="col">
                    <label htmlFor="Choose-Service" className="form-label">
                      Zip code start
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="please enter zipcode start"
                      value={managePricingDetails?.deststart}
                      onChange={(e) => {
                        setmanagePricingDetails({
                          ...managePricingDetails,
                          deststart: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col">
                    <label htmlFor="Choose-Modal" className="form-label">
                      Zip code end
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="please enter zipcode end"
                      value={managePricingDetails?.destend}
                      onChange={(e) => {
                        setmanagePricingDetails({
                          ...managePricingDetails,
                          destend: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              )} */}
                  {(managePricingDetails?.modal_name == 2 ||
                    managePricingDetails?.modal_name == 4 ||
                    managePricingDetails?.modal_name == 7 ||
                    managePricingDetails?.modal_name == 12 ||
                    managePricingDetails?.modal_name == 14 ||
                    managePricingDetails?.modal_name == 16 ||
                    managePricingDetails?.modal_name == 17) && (
                    <div className="row row-cols-2 g-4 pb-2">
                      <div className="col">
                        <label
                          htmlFor="exampleFormControlInput2"
                          className="form-label"
                        >
                          Delivery Address
                        </label>
                        <input
                          type="text"
                          value={managePricingDetails?.delivery_address}
                          onChange={(e) => {
                            setmanagePricingDetails({
                              ...managePricingDetails,
                              delivery_address: e.target.value,
                            });
                          }}
                          className="w-100 form-control"
                          placeholder="Enter Delivery Address"
                        />
                      </div>
                      <div className="col">
                        <label
                          htmlFor="exampleFormControlInput2"
                          className="form-label"
                        >
                          Zip Code
                        </label>
                        <input
                          type="text"
                          value={
                            managePricingDetails?.destination_zipcode_range
                          }
                          onChange={(e) => {
                            setmanagePricingDetails({
                              ...managePricingDetails,
                              destination_zipcode_range: e.target.value,
                            });
                          }}
                          className="w-100 form-control"
                          placeholder="Enter Delivery Zip Code"
                        />
                      </div>
                    </div>
                  )}
                  {(managePricingDetails?.clickup_product_name?.includes(
                    "TRUCK"
                  ) ||
                    managePricingDetails?.clickup_product_name?.includes(
                      "OCEAN"
                    )) && (
                    <div className="row row-cols-2 g-4 pb-2">
                      <div className="col">
                        <label
                          htmlFor="exampleFormControlInput2"
                          className="form-label"
                        >
                          Free Time (Ocean = days, Truck = hours)
                        </label>
                        <input
                          min={0}
                          type="number"
                          value={managePricingDetails?.destination_free_days}
                          onChange={(e) => {
                            setmanagePricingDetails({
                              ...managePricingDetails,
                              destination_free_days: e.target.value,
                            });
                          }}
                          className="w-100 form-control"
                          placeholder="Enter Free Time"
                        />
                      </div>
                      {conditionalExp(
                        managePricingDetails?.product_id == 2 ||
                          (managePricingDetails?.product_id == 6 &&
                            selectedOption?.value &&
                            selectedOptionDestination?.value &&
                            selectedOption?.value !==
                              selectedOptionDestination?.value),
                        <div className="form-check align-self-end ">
                          <input
                            type="checkbox"
                            checked={managePricingDetails?.diffrentTruckLine}
                            onChange={(e) => {
                              setmanagePricingDetails({
                                ...managePricingDetails,
                                diffrentTruckLine: e.target.checked,
                              });
                            }}
                            id="diffrentTruckLine"
                            className="form-check-input "
                          />
                          <label
                            htmlFor="diffrentTruckLine"
                            className="form-check-label"
                          >
                            Different Truck Line
                          </label>
                        </div>
                      )}
                      {/* {conditionalExp(
                  (managePricingDetails?.product_id == 2 ||
                  managePricingDetails?.product_id == 6) && managePricingDetails?.quote_type == "named_accounts" && selectedOptionCustomer?.value,
                  <div className="col">
                    <label htmlFor="Choose-Warehouse" className="form-label">
                      Choose Warehouse
                    </label>
                    <Select
                      value={selectedWarehouse}
                      onChange={(option) => {
                        if(option?.value == 'Add Warehouse'){
                          handleShowwarehouseAdd()
                          return
                        }
                        setselectedWarehouse(option);
                      }}
                      options={[{label:'Add Warehouse',value:'Add Warehouse'},...warehouseList]}
                      isSearchable={true}
                      placeholder="Search"
                      isClearable
                      isLoading={!warehouseList?.length}
                    />
                  </div>
                )} */}
                    </div>
                  )}
                </>
              )}
              <h6>Containers / Goods Details</h6>
              <div className="row row-cols-2 g-4 pb-2">
                <div className="col">
                  <label htmlFor="Choose-Carrier" className="form-label">
                    {conditionalExp(
                      managePricingDetails?.diffrentTruckLine,
                      "Choose Origin Carrier",
                      "Choose Carrier"
                    )}
                  </label>
                  <Select
                    value={selectedCarrerList}
                    onChange={(option) => {
                      setselectedCarrerList(option);
                    }}
                    options={carrierList}
                    isSearchable={true}
                    placeholder="Search"
                    isClearable
                    isLoading={!carrierList?.length}
                  />
                </div>
                {conditionalExp(
                  managePricingDetails?.diffrentTruckLine,
                  <div className="col">
                    <label htmlFor="Choose-Carrier" className="form-label">
                      Choose Destination Carrier
                    </label>
                    <Select
                      value={selectedCarrerListDestination}
                      onChange={(option) => {
                        setselectedCarrerListDestination(option);
                      }}
                      options={carrierList}
                      isSearchable={true}
                      placeholder="Search"
                      isClearable
                      isLoading={!carrierList?.length}
                    />
                  </div>
                )}
                <div className="col">
                  <label htmlFor="Choose-Carrier" className="form-label">
                    Choose Commodity
                  </label>
                  <Select
                    value={selectedCommodityList}
                    onChange={setselectedCommodityList}
                    options={commodityList}
                    isSearchable={true}
                    placeholder="Search"
                    isMulti
                  />
                </div>
              </div>
              <div className="row row-cols-2 g-4 pb-2">
                <div className="col">
                  <label htmlFor="Choose-Carrier" className="form-label">
                    Choose Agent
                  </label>
                  <Select
                    value={selectedAgentList}
                    onChange={(option) => {
                      setselectedAgentList(option);
                    }}
                    options={agentList}
                    isSearchable={true}
                    placeholder="Search"
                    isClearable
                  />
                </div>
                <div className="col">
                  <label
                    htmlFor="exampleFormControlInput2"
                    className="form-label"
                  >
                    Transit Time
                  </label>
                  <input
                    type="number"
                    value={managePricingDetails?.transit_time}
                    className="w-100 form-control"
                    placeholder="Enter Transit Time"
                    onChange={(e) => {
                      setmanagePricingDetails({
                        ...managePricingDetails,
                        transit_time: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              {conditionalExp(
                managePricingDetails?.product_id == 1 ||
                  managePricingDetails?.product_id == 2,
                <div className="row row-cols-5 g-4 py-2">
                  {clickupData
                    ?.filter((item) => {
                      if (managePricingDetails?.product_id == 1) {
                        return (
                          item.dropdown_type == "container_type" &&
                          item?.clickup_product_name != "LTL" &&
                          item?.clickup_product_name != "Aéreo" &&
                          item?.clickup_product_name != "LCL"
                        );
                      } else {
                        if (
                          selectedOption?.value &&
                          selectedOptionDestination?.value &&
                          selectedOption?.value ==
                            selectedOptionDestination?.value
                        ) {
                          return (
                            item.dropdown_type == "truck_container_type_both" ||
                            item.dropdown_type ==
                              "truck_container_type_domestic"
                          );
                        } else if (
                          selectedOption?.value &&
                          selectedOptionDestination?.value &&
                          selectedOption?.value !=
                            selectedOptionDestination?.value
                        ) {
                          return (
                            item.dropdown_type == "truck_container_type_both" ||
                            item.dropdown_type ==
                              "truck_container_type_international"
                          );
                        } else {
                          return (
                            item.dropdown_type == "truck_container_type_both"
                          );
                        }
                      }
                    })
                    ?.map((item, index) => (
                      <div className="col d-flex align-items-center">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id={item?.container_type}
                          checked={managePricingDetails?.container_type?.includes(
                            item?.container_type
                          )}
                          onClick={() => {
                            if (
                              managePricingDetails?.container_type?.includes(
                                item?.container_type
                              )
                            ) {
                              let contArr =
                                managePricingDetails?.container_type?.filter(
                                  (cont) => cont != item?.container_type
                                );
                              setmanagePricingDetails({
                                ...managePricingDetails,
                                container_type: contArr,
                              });
                            } else {
                              setmanagePricingDetails({
                                ...managePricingDetails,
                                container_type: [
                                  ...managePricingDetails?.container_type,
                                  item?.container_type,
                                ],
                              });
                            }
                          }}
                        />
                        <label
                          className="form-check-label"
                          for="53-Refrigerated Box"
                        >
                          {item?.clickup_product_name}
                        </label>
                      </div>
                    ))}
                </div>
              )}
              {managePricingDetails?.clickup_product_name != "OCEAN FCL" &&
                managePricingDetails?.clickup_product_name != "TRUCK FTL" && (
                  <>
                    <div className="col d-flex align-items-center my-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id=""
                        checked={managePricingDetails.min_weight_clicked}
                        onClick={() => {
                          setmanagePricingDetails({
                            ...managePricingDetails,
                            min_weight_clicked:
                              !managePricingDetails?.min_weight_clicked,
                          });
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="min_weight"
                        style={{ paddingRight: "10px" }}
                      >
                        Minimum Weight
                      </label>
                      {managePricingDetails?.min_weight_clicked && (
                        <input
                          value={managePricingDetails?.min_weight_value}
                          style={{
                            border: "none",
                            outline: "none",
                            borderBottom: "2px solid black",
                            padding: "10px 0",
                          }}
                          type="number"
                          id="min_weight"
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Check if the entered value is not negative
                            if (inputValue >= 0) {
                              setmanagePricingDetails({
                                ...managePricingDetails,
                                min_weight_value: inputValue,
                              });
                            }
                          }}
                        />
                      )}
                    </div>
                    <div className="col d-flex align-items-center my-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id=""
                        checked={managePricingDetails.max_weight_clicked}
                        onClick={() => {
                          setmanagePricingDetails({
                            ...managePricingDetails,
                            max_weight_clicked:
                              !managePricingDetails?.max_weight_clicked,
                          });
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="max_weight"
                        style={{ paddingRight: "10px" }}
                      >
                        Maximum Weight
                      </label>
                      {managePricingDetails?.max_weight_clicked && (
                        <input
                          id="max_weight"
                          value={managePricingDetails?.max_weight_value}
                          style={{
                            border: "none",
                            outline: "none",
                            borderBottom: "2px solid black",
                            padding: "10px 0",
                          }}
                          type="number"
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (inputValue >= 0) {
                              setmanagePricingDetails({
                                ...managePricingDetails,
                                max_weight_value: e.target.value,
                              });
                            }
                          }}
                        />
                      )}
                    </div>
                    <div className="col d-flex align-items-center my-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="CBM"
                        checked={managePricingDetails?.cbm_clicked}
                        onClick={() => {
                          setmanagePricingDetails({
                            ...managePricingDetails,
                            cbm_clicked: !managePricingDetails?.cbm_clicked,
                          });
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="cbm"
                        style={{ paddingRight: "10px" }}
                      >
                        CBM
                      </label>
                      {managePricingDetails?.cbm_clicked && (
                        <input
                          id="cbm_value"
                          value={managePricingDetails?.cbm_value}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (inputValue >= 0) {
                              setmanagePricingDetails({
                                ...managePricingDetails,
                                cbm_value: e.target.value,
                              });
                            }
                          }}
                          style={{
                            border: "none",
                            outline: "none",
                            borderBottom: "2px solid black",
                            padding: "10px 0",
                          }}
                          type="number"
                        />
                      )}
                    </div>
                  </>
                )}
            </>
          )}
          {currIndex == 1 && (
             conditionalExp(changingCurrency,<div className="d-flex align-items-center justify-content-center"><Loader/></div>,
            <div>
              {/* In case of air */}
              {managePricingDetails?.clickup_product_name?.includes("Air") && (
                <Row>
                  <Col md={8}>
                    <div>
                      <table style={{ borderCollapse: "collapse" }}>
                        <tbody>
                          <tr>
                            <td colSpan={6} className="">
                              <p>&nbsp;</p>
                            </td>
                          </tr>
                          {rateTypeCodes?.map(
                            (rate_type_code, rate_type_code_index) => (
                              <>
                                <tr key={rate_type_code}>
                                  <td
                                    style={{
                                      background: "#002981",
                                      color: "#ffff",
                                      borderBottom: "4px solid #e9b217",
                                      padding: "5px 0",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      alignContent: "center",
                                    }}
                                  >
                                    <div></div>
                                    <div>
                                      {rate_type_code?.toUpperCase()} RATES
                                    </div>
                                    <div className="d-flex gap-2">
                                                    <div>
                                              <Form.Select
                                              
                                                value={getBlockCurrency(
                                                  rate_type_code,
                                                  "other"
                                                )}
                                                aria-label="Default select example"
                                                style={{ width: "150px" }}
                                                onChange={e=>{
                                                  updateBlockCurrency(rate_type_code,"other",e.target.value)
                                                }}
                                              >
                                                {currencyOptions.map((item) => (
                                                  <option value={item?.value}>
                                                    {item?.label}
                                                  </option>
                                                ))}
                                              </Form.Select>
                                            </div>
                                      <div
                                        style={{
                                          cursor: "pointer",
                                          paddingRight: "5px",
                                        }}
                                        onClick={() => {
                                          setselectedLabel({
                                            name: `ADD ${rate_type_code} RATES`,
                                            description: "",
                                            rate_type_code: rate_type_code,
                                            rate_usd: "",
                                            amount_usd: "",
                                            rate_currency: getBlockCurrency(
                                              rate_type_code,
                                              "other"
                                            ),
                                            actual_amount_usd: "",
                                            actual_rate_usd: "",
                                            rate_description: "",
                                          });
                                          handleShowCustomer();
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-plus-circle-fill"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                        </svg>
                                      </div>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  {rate_type_code_index == 0 && (
                                    <div className="w-100">
                                      <div
                                        style={{
                                          display: "inline-block",
                                          width: "29%",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        CONCEPT
                                      </div>

                                      <div
                                        className="table-div"
                                        style={{
                                          display: "inline-block",
                                          fontWeight: "bold",
                                          width: "13%",
                                        }}
                                      >
                                        CURRENCY
                                      </div>

                                      <div
                                        className="table-div"
                                        style={{
                                          display: "inline-block",
                                          fontWeight: "bold",
                                          width: "13%",
                                        }}
                                      >
                                        RATE PER KG
                                      </div>

                                      <div
                                        className="table-div"
                                        style={{
                                          display: "inline-block",
                                          fontWeight: "bold",
                                          width: "13%",
                                        }}
                                      >
                                        AMOUNT
                                      </div>
                                    </div>
                                  )}

                                  {/* Freight */}
                                  {rate_type_code?.toUpperCase() ==
                                    "FREIGHT" && (
                                    <>
                                      {selectedQuotePriceDetails
                                        ?.filter((quote) => {
                                          return quote?.rate_type_code?.includes(
                                            rate_type_code
                                          );
                                        })
                                        ?.map((quote, quote_index) => (
                                          <div
                                            className="w-100"
                                            key={quote?.description}
                                          >
                                            <div
                                              style={{
                                                display: "inline-block",
                                                width: "29%",
                                              }}
                                            >
                                              {quote?.description}
                                            </div>
                                            <div
                                              className="table-div"
                                              style={{
                                                display: "inline-block",
                                                width: "13%",
                                              }}
                                            >
                                              <input
                                                type="text"
                                                value={
                                                  quote?.rate_currency
                                                    ? quote?.rate_currency
                                                    : ""
                                                }
                                                style={{
                                                  border: "none",
                                                  outline: "none",
                                                  background: "none",
                                                }}
                                              ></input>
                                            </div>
                                            <div
                                              className="table-div"
                                              style={{
                                                display: "inline-block",
                                                width: "13%",
                                              }}
                                            >
                                              {quote?.actual_rate_usd
                                                ? quote?.actual_rate_usd
                                                : ""}
                                            </div>
                                            <div
                                              className="table-div"
                                              style={{
                                                display: "inline-block",
                                                width: "13%",
                                              }}
                                            >
                                              {quote?.actual_amount_usd
                                                ? quote?.actual_amount_usd
                                                : ""}
                                            </div>

                                            <div
                                              style={{
                                                display: "inline-block",
                                                marginLeft: "-10px",
                                                height: "18px",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                setselectedLabel({
                                                  name: `EDIT ${rate_type_code} RATES`,
                                                  description:
                                                    quote?.description,
                                                  rate_type_code:
                                                    rate_type_code,
                                                  rate_usd: quote?.rate_usd,
                                                  amount_usd: quote?.amount_usd,
                                                  rate_currency: quote?.rate_currency,
                                                  container_type:
                                                    quote?.container_type,
                                                  id: quote?.id,
                                                  actual_amount_usd:
                                                    quote?.actual_amount_usd,
                                                  actual_rate_usd:
                                                    quote?.actual_rate_usd,
                                                  rate_description:
                                                    quote?.rate_description,
                                                });
                                                handleShowCustomer();
                                              }}
                                            >
                                              <svg
                                                className="icon"
                                                style={{
                                                  height: "18px",
                                                }}
                                              >
                                                <use href="#icon_edit"></use>
                                              </svg>
                                            </div>
                                            <div
                                              style={{
                                                display: "inline-block",
                                                height: "18px",
                                                cursor: "pointer",
                                                marginLeft: "20px",
                                              }}
                                              onClick={() => {
                                                setselectedDocId(quote?.id);
                                                handleShowVendorNew();
                                              }}
                                            >
                                              <svg
                                                className="icon"
                                                style={{
                                                  height: "18px",
                                                }}
                                              >
                                                <use href="#icon_delete"></use>
                                              </svg>
                                            </div>
                                          </div>
                                        ))}
                                    </>
                                  )}
                                  {/* origin */}
                                  {rate_type_code?.toUpperCase() ==
                                    "ORIGIN" && (
                                    <>
                                      {selectedQuotePriceDetails
                                        ?.filter((quote) => {
                                          return quote?.rate_type_code?.includes(
                                            rate_type_code
                                          );
                                        })
                                        ?.map((quote, quote_index) => (
                                          <div
                                            className="w-100"
                                            key={quote?.description}
                                          >
                                            <div
                                              style={{
                                                display: "inline-block",
                                                width: "20%",
                                              }}
                                            >
                                              {quote?.description}
                                            </div>
                                            <div
                                              className="table-div"
                                              style={{
                                                display: "inline-block",
                                                width: "10.8%",
                                              }}
                                            >
                                              <input
                                                type="text"
                                                value={
                                                  quote?.rate_currency
                                                    ? quote?.rate_currency
                                                    : ""
                                                }
                                                style={{
                                                  border: "none",
                                                  outline: "none",
                                                  background: "none",
                                                }}
                                              ></input>
                                            </div>
                                            <div
                                              className="table-div"
                                              style={{
                                                display: "inline-block",
                                                width: "10.8%",
                                              }}
                                            >
                                              {quote?.actual_rate_usd
                                                ? quote?.actual_rate_usd
                                                : ""}
                                            </div>

                                            <div
                                              className="table-div"
                                              style={{
                                                display: "inline-block",
                                                width: "20%",
                                              }}
                                            >
                                              {quote?.rate_description}
                                            </div>
                                            <div
                                              className="table-div"
                                              style={{
                                                display: "inline-block",
                                                width: "10.8%",
                                              }}
                                            >
                                              {quote?.actual_amount_usd
                                                ? quote?.actual_amount_usd
                                                : ""}
                                            </div>

                                            <div
                                              style={{
                                                display: "inline-block",
                                                marginLeft: "-10px",
                                                height: "18px",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                setselectedLabel({
                                                  name: `EDIT ${rate_type_code} RATES`,
                                                  description:
                                                    quote?.description,
                                                  rate_type_code:
                                                    rate_type_code,
                                                  rate_usd: quote?.rate_usd,
                                                  amount_usd: quote?.amount_usd,
                                                  rate_currency: quote?.rate_currency,
                                                  container_type:
                                                    quote?.container_type,
                                                  id: quote?.id,
                                                  actual_amount_usd:
                                                    quote?.actual_amount_usd,
                                                  actual_rate_usd:
                                                    quote?.actual_rate_usd,
                                                  rate_description:
                                                    quote?.rate_description,
                                                });
                                                handleShowCustomer();
                                              }}
                                            >
                                              <svg
                                                className="icon"
                                                style={{
                                                  height: "18px",
                                                }}
                                              >
                                                <use href="#icon_edit"></use>
                                              </svg>
                                            </div>
                                            <div
                                              style={{
                                                display: "inline-block",
                                                height: "18px",
                                                cursor: "pointer",
                                                marginLeft: "20px",
                                              }}
                                              onClick={() => {
                                                setselectedDocId(quote?.id);
                                                handleShowVendorNew();
                                              }}
                                            >
                                              <svg
                                                className="icon"
                                                style={{
                                                  height: "18px",
                                                }}
                                              >
                                                <use href="#icon_delete"></use>
                                              </svg>
                                            </div>
                                          </div>
                                        ))}
                                    </>
                                  )}
                                  {/* destination */}
                                  {rate_type_code?.toUpperCase() ==
                                    "DESTINATION" && (
                                    <>
                                      {selectedQuotePriceDetails
                                        ?.filter((quote) => {
                                          return quote?.rate_type_code?.includes(
                                            rate_type_code
                                          );
                                        })
                                        ?.map((quote, quote_index) => (
                                          <div
                                            className="w-100"
                                            key={quote?.description}
                                          >
                                            <div
                                              style={{
                                                display: "inline-block",
                                                width: "20%",
                                              }}
                                            >
                                              {quote?.description}
                                            </div>
                                            <div
                                              className="table-div"
                                              style={{
                                                display: "inline-block",
                                                width: "10.8%",
                                              }}
                                            >
                                              <input
                                                type="text"
                                                value={
                                                  quote?.rate_currency
                                                    ? quote?.rate_currency
                                                    : ""
                                                }
                                                style={{
                                                  border: "none",
                                                  outline: "none",
                                                  background: "none",
                                                }}
                                              ></input>
                                            </div>
                                            <div
                                              className="table-div"
                                              style={{
                                                display: "inline-block",
                                                width: "10.8%",
                                              }}
                                            >
                                              {quote?.actual_amount_usd
                                                ? quote?.actual_amount_usd
                                                : ""}
                                            </div>

                                            <div
                                              style={{
                                                display: "inline-block",
                                                marginLeft: "-10px",
                                                height: "18px",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                setselectedLabel({
                                                  name: `EDIT ${rate_type_code} RATES`,
                                                  description:
                                                    quote?.description,
                                                  rate_type_code:
                                                    rate_type_code,
                                                  rate_usd: quote?.rate_usd,
                                                  amount_usd: quote?.amount_usd,
                                                  rate_currency: quote?.rate_currency,
                                                  container_type:
                                                    quote?.container_type,
                                                  id: quote?.id,
                                                  actual_amount_usd:
                                                    quote?.actual_amount_usd,
                                                  actual_rate_usd:
                                                    quote?.actual_rate_usd,
                                                  rate_description:
                                                    quote?.rate_description,
                                                });
                                                handleShowCustomer();
                                              }}
                                            >
                                              <svg
                                                className="icon"
                                                style={{
                                                  height: "18px",
                                                }}
                                              >
                                                <use href="#icon_edit"></use>
                                              </svg>
                                            </div>
                                            <div
                                              style={{
                                                display: "inline-block",
                                                height: "18px",
                                                cursor: "pointer",
                                                marginLeft: "20px",
                                              }}
                                              onClick={() => {
                                                setselectedDocId(quote?.id);
                                                handleShowVendorNew();
                                              }}
                                            >
                                              <svg
                                                className="icon"
                                                style={{
                                                  height: "18px",
                                                }}
                                              >
                                                <use href="#icon_delete"></use>
                                              </svg>
                                            </div>
                                          </div>
                                        ))}
                                    </>
                                  )}
                                  {rate_type_code?.toUpperCase() == "ADDON" && (
                                    <>
                                      {selectedQuotePriceDetails
                                        ?.filter((quote) => {
                                          return quote?.rate_type_code?.includes(
                                            rate_type_code
                                          );
                                        })
                                        ?.map((quote, quote_index) => (
                                          <div
                                            className="w-100"
                                            key={quote?.description}
                                          >
                                            <div
                                              style={{
                                                display: "inline-block",
                                                width: "20%",
                                              }}
                                            >
                                              {quote?.description}
                                            </div>
                                            <div
                                              className="table-div"
                                              style={{
                                                display: "inline-block",
                                                width: "10.8%",
                                              }}
                                            >
                                              <input
                                                type="text"
                                                value={
                                                  quote?.rate_currency
                                                    ? quote?.rate_currency
                                                    : ""
                                                }
                                                style={{
                                                  border: "none",
                                                  outline: "none",
                                                  background: "none",
                                                }}
                                              ></input>
                                            </div>
                                            <div
                                              className="table-div"
                                              style={{
                                                display: "inline-block",
                                                width: "10.8%",
                                              }}
                                            >
                                              {quote?.actual_amount_usd
                                                ? quote?.actual_amount_usd
                                                : ""}
                                            </div>

                                            <div
                                              style={{
                                                display: "inline-block",
                                                marginLeft: "-10px",
                                                height: "18px",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                setselectedLabel({
                                                  name: `EDIT ${rate_type_code} RATES`,
                                                  description:
                                                    quote?.description,
                                                  rate_type_code:
                                                    rate_type_code,
                                                  rate_usd: quote?.rate_usd,
                                                  amount_usd: quote?.amount_usd,
                                                  rate_currency: quote?.rate_currency,
                                                  container_type:
                                                    quote?.container_type,
                                                  id: quote?.id,
                                                  actual_amount_usd:
                                                    quote?.actual_amount_usd,
                                                  actual_rate_usd:
                                                    quote?.actual_rate_usd,
                                                  rate_description:
                                                    quote?.rate_description,
                                                });
                                                handleShowCustomer();
                                              }}
                                            >
                                              <svg
                                                className="icon"
                                                style={{
                                                  height: "18px",
                                                }}
                                              >
                                                <use href="#icon_edit"></use>
                                              </svg>
                                            </div>
                                            <div
                                              style={{
                                                display: "inline-block",
                                                height: "18px",
                                                cursor: "pointer",
                                                marginLeft: "20px",
                                              }}
                                              onClick={() => {
                                                setselectedDocId(quote?.id);
                                                handleShowVendorNew();
                                              }}
                                            >
                                              <svg
                                                className="icon"
                                                style={{
                                                  height: "18px",
                                                }}
                                              >
                                                <use href="#icon_delete"></use>
                                              </svg>
                                            </div>
                                          </div>
                                        ))}
                                    </>
                                  )}

                                  {/* Frieght */}

                                  {rate_type_code?.toUpperCase() ==
                                    "FREIGHT" && (
                                    <div className="w-100 bg-gray">
                                      <div
                                        style={{
                                          display: "inline-block",
                                          width: "29%",
                                        }}
                                      >
                                        <p className="p-0 m-0">
                                          Total{" "}
                                          {capitalizeFirstLetter(
                                            rate_type_code
                                          )}{" "}
                                          Rates
                                        </p>
                                      </div>
                                      <div
                                        className="table-div"
                                        style={{
                                          display: "inline-block",
                                          width: "13%",
                                        }}
                                      >
                                        <input
                                          type="text"
                                          value={getBlockCurrency(
                                            rate_type_code,
                                            "other"
                                          )}
                                          style={{
                                            border: "none",
                                            outline: "none",
                                            background: "none",
                                          }}
                                        ></input>
                                      </div>
                                      <div
                                        className="table-div"
                                        style={{
                                          display: "inline-block",
                                          width: "13%",
                                        }}
                                      >
                                        <input
                                          type="text"
                                          value={getAirRates(
                                            rate_type_code,
                                            "actual_rate_usd"
                                          )?.toFixed(2)}
                                          style={{
                                            border: "none",
                                            outline: "none",
                                            background: "none",
                                          }}
                                        ></input>
                                      </div>

                                      <div
                                        className="table-div"
                                        style={{
                                          width: "13%",
                                          display: "inline-block",
                                        }}
                                      >
                                        <input
                                          type="text"
                                          value={getAirRates(
                                            rate_type_code,
                                            "actual_amount_usd"
                                          )?.toFixed(2)}
                                          style={{
                                            border: "none",
                                            outline: "none",
                                            background: "none",
                                          }}
                                        ></input>
                                      </div>
                                    </div>
                                  )}
                                  {/* Origin */}
                                  {rate_type_code?.toUpperCase() ==
                                    "ORIGIN" && (
                                    <div className="w-100 bg-gray">
                                      <div
                                        style={{
                                          display: "inline-block",
                                          width: "20%",
                                        }}
                                      >
                                        <p className="p-0 m-0">
                                          Total{" "}
                                          {capitalizeFirstLetter(
                                            rate_type_code
                                          )}{" "}
                                          Rates
                                        </p>
                                      </div>
                                      <div
                                        className="table-div"
                                        style={{
                                          display: "inline-block",
                                          width: "10.8%",
                                        }}
                                      >
                                        <input
                                          type="text"
                                          value={getBlockCurrency(
                                            rate_type_code,
                                            "other"
                                          )}
                                          style={{
                                            border: "none",
                                            outline: "none",
                                            background: "none",
                                          }}
                                        ></input>
                                      </div>
                                      <div
                                        className="table-div"
                                        style={{
                                          display: "inline-block",
                                          width: "10.8%",
                                        }}
                                      >
                                        <input
                                          type="text"
                                          value={getAirRates(
                                            rate_type_code,
                                            "actual_rate_usd"
                                          )?.toFixed(2)}
                                          style={{
                                            border: "none",
                                            outline: "none",
                                            background: "none",
                                          }}
                                        ></input>
                                      </div>

                                      <div
                                        className="table-div"
                                        style={{
                                          width: "20%",
                                          display: "inline-block",
                                        }}
                                      >
                                        <input
                                          type="text"
                                          value={""}
                                          style={{
                                            border: "none",
                                            outline: "none",
                                            background: "none",
                                          }}
                                        ></input>
                                      </div>
                                      <div
                                        className="table-div"
                                        style={{
                                          width: "10.8%",
                                          display: "inline-block",
                                        }}
                                      >
                                        <input
                                          type="text"
                                          value={getAirRates(
                                            rate_type_code,
                                            "actual_amount_usd"
                                          )?.toFixed(2)}
                                          style={{
                                            border: "none",
                                            outline: "none",
                                            background: "none",
                                          }}
                                        ></input>
                                      </div>
                                    </div>
                                  )}
                                  {/* Destination */}
                                  {rate_type_code?.toUpperCase() ==
                                    "DESTINATION" && (
                                    <div className="w-100 bg-gray">
                                      <div
                                        style={{
                                          display: "inline-block",
                                          width: "20%",
                                        }}
                                      >
                                        <p className="p-0 m-0">
                                          Total{" "}
                                          {capitalizeFirstLetter(
                                            rate_type_code
                                          )}{" "}
                                          Rates
                                        </p>
                                      </div>
                                      <div
                                        className="table-div"
                                        style={{
                                          display: "inline-block",
                                          width: "10.8%",
                                        }}
                                      >
                                        <input
                                          type="text"
                                          value={getBlockCurrency(
                                            rate_type_code,
                                            "other"
                                          )}
                                          style={{
                                            border: "none",
                                            outline: "none",
                                            background: "none",
                                          }}
                                        ></input>
                                      </div>
                                      <div
                                        className="table-div"
                                        style={{
                                          display: "inline-block",
                                          width: "10.8%",
                                        }}
                                      >
                                        <input
                                          type="text"
                                          value={getAirRates(
                                            rate_type_code,
                                            "actual_amount_usd"
                                          )?.toFixed(2)}
                                          style={{
                                            border: "none",
                                            outline: "none",
                                            background: "none",
                                          }}
                                        ></input>
                                      </div>
                                    </div>
                                  )}
                                  {/* Destination */}
                                  {rate_type_code?.toUpperCase() == "ADDON" && (
                                    <div className="w-100 bg-gray">
                                      <div
                                        style={{
                                          display: "inline-block",
                                          width: "20%",
                                        }}
                                      >
                                        <p className="p-0 m-0">
                                          Total{" "}
                                          {capitalizeFirstLetter(
                                            rate_type_code
                                          )}{" "}
                                          Rates
                                        </p>
                                      </div>
                                      <div
                                        className="table-div"
                                        style={{
                                          display: "inline-block",
                                          width: "10.8%",
                                        }}
                                      >
                                        <input
                                          type="text"
                                          value={getBlockCurrency(
                                            rate_type_code,
                                            "other"
                                          )}
                                          style={{
                                            border: "none",
                                            outline: "none",
                                            background: "none",
                                          }}
                                        ></input>
                                      </div>
                                      <div
                                        className="table-div"
                                        style={{
                                          display: "inline-block",
                                          width: "10.8%",
                                        }}
                                      >
                                        <input
                                          type="text"
                                          value={getAirRates(
                                            rate_type_code,
                                            "actual_amount_usd"
                                          )?.toFixed(2)}
                                          style={{
                                            border: "none",
                                            outline: "none",
                                            background: "none",
                                          }}
                                        ></input>
                                      </div>
                                    </div>
                                  )}
                                </tr>
                              </>
                            )
                          )}

                          {/* total now */}
                          <tr className="w-100">
                            <td
                              style={{
                                textAlign: "center",
                                background: "#002981",
                                color: "#ffff",
                                borderTop: "4px solid #e9b217",
                                padding: "5px 0",
                              }}
                            >
                              TOTAL
                            </td>
                          </tr>
                          <tr className="w-100">
                            <td>
                              <div className="w-100">
                                <div
                                  className="bg-gray"
                                  style={{
                                    display: "inline-block",
                                    width: "31%",
                                  }}
                                >
                                  {rateTypeCodes?.map(
                                    (rate_type_code, rate_type_code_index) => (
                                      <div key={rate_type_code}>
                                        <p className="p-0 m-0">
                                          {capitalizeFirstLetter(
                                            rate_type_code
                                          )}{" "}
                                          Rates
                                        </p>
                                      </div>
                                    )
                                  )}
                                </div>

                                <div
                                  className="table-div"
                                  style={{
                                    display: "inline-block",
                                    width: "10.8%",
                                  }}
                                >
                                  {rateTypeCodes?.map(
                                    (rate_type_code, rate_type_code_index) => (
                                      <div key={rate_type_code}>
                                        <input
                                          type="text"
                                          value={finalRates(rate_type_code)}
                                          style={{
                                            border: "none",
                                            outline: "none",
                                            background: "none",
                                          }}
                                        ></input>
                                      </div>
                                    )
                                  )}
                                </div>

                                <div
                                  className="table-div"
                                  style={{
                                    display: "inline-block",
                                    width: "13%",
                                  }}
                                >
                                  {rateTypeCodes?.map(
                                    (rate_type_code, rate_type_code_index) => (
                                      <div key={rate_type_code}>
                                        <input
                                          type="text"
                                          value={'USD'}
                                          style={{
                                            border: "none",
                                            outline: "none",
                                            background: "none",
                                          }}
                                        ></input>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                          {/* <tr className="w-100">
                            <td
                              style={{
                                background: "#002981",
                                color: "#ffff",
                                borderBottom: "4px solid #e9b217",
                                padding: "5px 0",
                              }}
                            >
                              <div className="w-100">
                                <div
                                  style={{
                                    display: "inline-block",
                                    width: "31%",
                                  }}
                                >
                                  <p>GROSS TOTAL</p>
                                </div>

                                <div
                                  className="table-div"
                                  style={{
                                    display: "inline-block",
                                    width: "10.8%",
                                  }}
                                >
                                  <p>{getFinalRatesAir()?.toFixed(2)}</p>
                                </div>

                                <div
                                  className="table-div"
                                  style={{
                                    width: "13%",
                                    display: "inline-block",
                                  }}
                                >
                                  <p>USD</p>
                                </div>
                              </div>
                            </td>
                          </tr> */}
                          <tr className="w-100 d-flex flex-column">
                                  {
                                    groundTotalBlocks?.filter(total=>total?.container_type == "other")?.map((total)=>(
                                      <td
                                      key={total?.currency}
                                      style={{
                                        background: "#002981",
                                        color: "#ffff",
                                        borderBottom: "4px solid #e9b217",
                                        padding: "5px 0",
                                      }}
                                    >
                                      <div className="w-100">
                                        <div
                                          style={{
                                            display: "inline-block",
                                            width: "31%",
                                          }}
                                        >
                                          <p>GROSS TOTAL {total?.currency}</p>
                                        </div>
        
                                        <div
                                          className="table-div"
                                          style={{
                                            display: "inline-block",
                                            width: "10.8%",
                                          }}
                                        >
                                          {/* <p>{getFinalRatesAir()?.toFixed(2)}</p> */}
                                          <p>{total?.amount}</p>
                                        </div>
        
                                        <div
                                          className="table-div"
                                          style={{
                                            width: "13%",
                                            display: "inline-block",
                                          }}
                                        >
                                          <p>{total?.currency}</p>
                                        </div>
                                      </div>
                                    </td>
                                    ))
                                  }
                                </tr>
                        </tbody>
                      </table>
                    </div>
                  </Col>

                  <Col md={4} className="mt-4">
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        marginTop: "2px",
                      }}
                    >
                      <button
                        type="button"
                        className="px-4"
                        style={{
                          background: "#0e2074",
                          border: "1px solid #0e2074",
                          color: "#ffffff",
                          borderRadius: "4px",
                          width: "max-content",
                        }}
                        onClick={() => {
                          setselectedLabel({
                            name: `ADDON`,
                            description: "",
                            rate_type_code: "ADDON",
                            rate_usd: "",
                            amount_usd: "",
                            rate_currency: getBlockCurrency("ADDON","other") || "USD",
                            container_type: "",
                            actual_amount_usd: "",
                            actual_rate_usd: "",
                            rate_description: "",
                          });
                          if (
                            managePricingDetails?.clickup_product_name?.includes(
                              "TRUCK"
                            )
                          ) {
                            handleShowCustomer2();
                          } else {
                            handleShowCustomer();
                          }
                        }}
                      >
                        Addon
                      </button>
                      <button
                        type="button"
                        className="px-4"
                        style={{
                          background: "#0e2074",
                          border: "1px solid #0e2074",
                          color: "#ffffff",
                          borderRadius: "4px",
                          width: "max-content",
                        }}
                        onClick={() => {
                          setCommentData(commentList[0]?.comment_content);
                          setShowCommentModal({
                            container_type: null,
                            price_rate_type_id: commentList[0]?.id || null,
                          });
                        }}
                      >
                        Comments
                      </button>
                    </div>
                  </Col>
                </Row>
              )}

              {/* In case of truck */}
              {managePricingDetails?.clickup_product_name?.includes("TRUCK") &&
                conditionalExp(
                  managePricingDetails?.clickup_product_name?.includes(
                    "TRUCK LTL"
                  ),
                  <Container>
                    <Row>
                      {["other"]?.map((cont, cont_index) => (
                        <Col md={6}>
                          <div className="mt-4">
                            <table
                              style={{
                                borderCollapse: "collapse",
                              }}
                            >
                              <tbody>
                                {rateTypeCodes?.map(
                                  (rate_type_code, rate_type_code_index) => (
                                    <>
                                      <tr key={rate_type_code}>
                                        <td
                                          style={{
                                            background: "#002981",
                                            color: "#ffff",
                                            borderBottom: "4px solid #e9b217",
                                            padding: "5px 0",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            alignContent: "center",
                                          }}
                                        >
                                          <div></div>
                                          <div>
                                            {rate_type_code?.toUpperCase()}{" "}
                                            RATES
                                          </div>
                                          <div className="d-flex gap-2">
                                                    <div>
                                              <Form.Select
                                              
                                                value={getBlockCurrency(
                                                  rate_type_code,
                                                  cont
                                                )}
                                                aria-label="Default select example"
                                                style={{ width: "150px" }}
                                                onChange={e=>{
                                                  updateBlockCurrency(rate_type_code,cont,e.target.value)
                                                }}
                                              >
                                                {currencyOptions.map((item) => (
                                                  <option value={item?.value}>
                                                    {item?.label}
                                                  </option>
                                                ))}
                                              </Form.Select>
                                            </div>
                                            <div
                                              style={{
                                                cursor: "pointer",
                                                paddingRight: "5px",
                                              }}
                                              onClick={() => {
                                                setselectedLabel({
                                                  name: `ADD ${rate_type_code} RATES`,
                                                  description: "",
                                                  rate_type_code:
                                                    rate_type_code,
                                                  rate_usd: "",
                                                  amount_usd: "",
                                                  rate_currency: getBlockCurrency(
                                                    rate_type_code,
                                                    cont
                                                  ),
                                                  actual_amount_usd: "",
                                                  actual_rate_usd: "",
                                                  rate_description: "",
                                                  pallet: "",
                                                  qty: "",
                                                });
                                                handleShowCustomer();
                                              }}
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-plus-circle-fill"
                                                viewBox="0 0 16 16"
                                              >
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                              </svg>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        {rate_type_code_index == 0 && (
                                          <div className="w-100">
                                            <div
                                              className="table-div"
                                              style={{
                                                display: "inline-block",
                                                fontWeight: "bold",
                                                width: "30%",
                                              }}
                                            >
                                              Concept
                                            </div>

                                            <div
                                              className="table-div"
                                              style={{
                                                display: "inline-block",
                                                fontWeight: "bold",
                                                width: "20%",
                                              }}
                                            >
                                              Currency
                                            </div>
                                            <div
                                              className="table-div"
                                              style={{
                                                display: "inline-block",
                                                fontWeight: "bold",
                                                width: "15%",
                                              }}
                                            >
                                              Original
                                            </div>
                                          </div>
                                        )}

                                        {selectedQuotePriceDetails
                                          ?.filter((quote) => {
                                            if (cont == "other") {
                                              return quote?.rate_type_code?.includes(
                                                rate_type_code
                                              );
                                            } else {
                                              return (
                                                quote?.rate_type_code?.includes(
                                                  rate_type_code
                                                ) &&
                                                quote?.container_type == cont
                                              );
                                            }
                                          })
                                          ?.map((quote, quote_index) => (
                                            <div
                                              className="w-100"
                                              key={quote?.description}
                                            >
                                              <div
                                                style={{
                                                  display: "inline-block",
                                                  width: "30%",
                                                }}
                                              >
                                                {quote?.description}
                                              </div>

                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  width: "20%",
                                                }}
                                              >
                                                {quote?.rate_currency}
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  width: "15%",
                                                }}
                                              >
                                                {quote?.actual_amount_usd
                                                  ? quote?.actual_amount_usd
                                                  : ""}
                                              </div>

                                              <div
                                                style={{
                                                  display: "inline-block",
                                                  marginLeft: "-10px",
                                                  height: "18px",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  setselectedLabel({
                                                    name: `EDIT ${rate_type_code} RATES`,
                                                    description:
                                                      quote?.description,
                                                    rate_type_code:
                                                      rate_type_code,
                                                    rate_usd: quote?.rate_usd,
                                                    amount_usd:
                                                      quote?.amount_usd,
                                                    rate_currency: quote?.rate_currency,
                                                    container_type:
                                                      quote?.container_type,
                                                    id: quote?.id,
                                                    actual_amount_usd:
                                                      quote?.actual_amount_usd,
                                                    actual_rate_usd:
                                                      quote?.actual_rate_usd,
                                                    rate_description:
                                                      quote?.rate_description,
                                                    pallet: quote?.pallet,
                                                    qty: quote?.qty,
                                                  });
                                                  handleShowCustomer();
                                                }}
                                              >
                                                <svg
                                                  className="icon"
                                                  style={{
                                                    height: "18px",
                                                  }}
                                                >
                                                  <use href="#icon_edit"></use>
                                                </svg>
                                              </div>

                                              <div
                                                style={{
                                                  display: "inline-block",
                                                  height: "18px",
                                                  cursor: "pointer",
                                                  marginLeft: "20px",
                                                }}
                                                onClick={() => {
                                                  setselectedDocId(quote?.id);
                                                  handleShowVendorNew();
                                                }}
                                              >
                                                <svg
                                                  className="icon"
                                                  style={{
                                                    height: "18px",
                                                  }}
                                                >
                                                  <use href="#icon_delete"></use>
                                                </svg>
                                              </div>
                                            </div>
                                          ))}

                                        <div className="w-100 bg-gray">
                                          <div
                                            style={{
                                              display: "inline-block",
                                              width: "29.5%",
                                            }}
                                          >
                                            <p className="p-0 m-0">
                                              {capitalizeFirstLetter(
                                                rate_type_code
                                              )}{" "}
                                              Rates
                                            </p>
                                          </div>

                                          <div
                                            className="table-div"
                                            style={{
                                              display: "inline-block",
                                              width: "20%",
                                            }}
                                          >
                                            <input
                                              type="text"
                                              value={getBlockCurrency(
                                                rate_type_code,
                                                cont
                                              )}
                                              style={{
                                                border: "none",
                                                outline: "none",
                                                background: "none",
                                              }}
                                            ></input>
                                          </div>
                                          <div
                                            className="table-div"
                                            style={{
                                              display: "inline-block",
                                              width: "15%",
                                            }}
                                          >
                                            <input
                                              type="text"
                                              value={getTruckRates(
                                                rate_type_code,
                                                cont,
                                                "actual_amount_usd"
                                              )?.toFixed(2)}
                                              style={{
                                                border: "none",
                                                outline: "none",
                                                background: "none",
                                              }}
                                            ></input>
                                          </div>
                                        </div>
                                      </tr>
                                    </>
                                  )
                                )}

                                {/* total */}
                                <tr className="w-100">
                                  <td
                                    style={{
                                      textAlign: "center",
                                      background: "#002981",
                                      color: "#ffff",
                                      borderTop: "4px solid #e9b217",
                                      padding: "5px 0",
                                    }}
                                  >
                                    TOTAL
                                  </td>
                                </tr>
                                <tr className="w-100">
                                  <td>
                                    <div className="w-100">
                                      <div
                                        className="bg-gray"
                                        style={{
                                          display: "inline-block",
                                          width: "29.5%",
                                        }}
                                      >
                                        {rateTypeCodes?.map(
                                          (
                                            rate_type_code,
                                            rate_type_code_index
                                          ) => (
                                            <div key={rate_type_code}>
                                              <p className="p-0 m-0">
                                                {capitalizeFirstLetter(
                                                  rate_type_code
                                                )}{" "}
                                                Rates
                                              </p>
                                            </div>
                                          )
                                        )}
                                      </div>
                                      <div
                                        className="table-div"
                                        style={{
                                          display: "inline-block",
                                          width: "20%",
                                        }}
                                      >
                                        {rateTypeCodes?.map(
                                          (
                                            rate_type_code,
                                            rate_type_code_index
                                          ) => (
                                            <div key={rate_type_code}>
                                              <input
                                                type="text"
                                                value={'USD'}
                                                style={{
                                                  border: "none",
                                                  outline: "none",
                                                  background: "none",
                                                }}
                                              ></input>
                                            </div>
                                          )
                                        )}
                                      </div>

                                      <div
                                        className="table-div"
                                        style={{
                                          display: "inline-block",
                                          width: "15%",
                                        }}
                                      >
                                        {rateTypeCodes?.map(
                                          (
                                            rate_type_code,
                                            rate_type_code_index
                                          ) => (
                                            <div key={rate_type_code}>
                                              <input
                                                type="text"
                                                value={getTruckRates(
                                                  rate_type_code,
                                                  cont,
                                                  "actual_amount_usd",
                                                  "USD"
                                                )?.toFixed(2)}
                                                style={{
                                                  border: "none",
                                                  outline: "none",
                                                  background: "none",
                                                }}
                                              ></input>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr className="w-100 d-flex flex-column">
                                  {
                                    groundTotalBlocks?.filter(total=>total?.container_type == cont)?.map((total)=>(
                                      <td
                                      key={total?.currency}
                                    style={{
                                      background: "#002981",
                                      color: "#ffff",
                                      borderBottom: "4px solid #e9b217",
                                      padding: "5px 0",
                                    }}
                                  >
                                    <div className="w-100">
                                      <div
                                        style={{
                                          display: "inline-block",
                                          width: "30%",
                                        }}
                                      >
                                        <p>GROSS TOTAL</p>
                                      </div>

                                      <div
                                        className="table-div"
                                        style={{
                                          display: "inline-block",
                                          width: "20%",
                                        }}
                                      >
                                        <p>{total?.currency}</p>
                                      </div>
                                      <div
                                        className="table-div"
                                        style={{
                                          display: "inline-block",
                                          width: "15%",
                                        }}
                                      >
                                        {/* <p>{grossTotalTruck()}</p> */}
                                        {total?.amount}
                                      </div>
                                    </div>
                                  </td>
                                    ))
                                  }
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </Col>
                      ))}
                      <Col md={6} className="mt-4">
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            marginTop: "2px",
                          }}
                        >
                          <button
                            type="button"
                            className="px-4"
                            style={{
                              background: "#0e2074",
                              border: "1px solid #0e2074",
                              color: "#ffffff",
                              borderRadius: "4px",
                              width: "max-content",
                            }}
                            onClick={() => {
                              setselectedLabel({
                                name: `ADDON`,
                                description: "",
                                rate_type_code: "ADDON",
                                rate_usd: "",
                                amount_usd: "",
                                rate_currency: getBlockCurrency("ADDON","other") || "USD",
                                container_type: "",
                                actual_amount_usd: "",
                                actual_rate_usd: "",
                                rate_description: "",
                              });

                              handleShowCustomer();
                            }}
                          >
                            Addon
                          </button>
                          <button
                            type="button"
                            className="px-4"
                            style={{
                              background: "#0e2074",
                              border: "1px solid #0e2074",
                              color: "#ffffff",
                              borderRadius: "4px",
                              width: "max-content",
                            }}
                            onClick={() => {
                              setCommentData(commentList[0]?.comment_content);
                              setShowCommentModal({
                                container_type: null,
                                price_rate_type_id: commentList[0]?.id || null,
                              });
                            }}
                          >
                            Comments
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Container>,
                  <Container>
                    <div className="w-100 tabs-row">
                      <ul
                        className="nav nav-pills mb-3 gap-3"
                        id="pills-tab"
                        role="tablist"
                      >
                        {managePricingDetails?.container_type?.map(
                          (container, index) => (
                            <li className="nav-item" role="presentation">
                              <button
                                className={`nav-link ${conditionalExp(
                                  index == 0,
                                  "active",
                                  ""
                                )}`}
                                id={`pills-${container
                                  ?.replaceAll(" ", "")
                                  ?.replaceAll(/[^a-zA-Z]+/g, "")}-tab`}
                                data-bs-toggle="pill"
                                data-bs-target={`#pills-${container
                                  ?.replaceAll(" ", "")
                                  ?.replaceAll(/[^a-zA-Z]+/g, "")}`}
                                type="button"
                                role="tab"
                                aria-controls={`pills-${container
                                  ?.replaceAll(" ", "")
                                  ?.replaceAll(/[^a-zA-Z]+/g, "")}`}
                                aria-selected="false"
                              >
                                {container}
                              </button>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                    <div className="tab-content" id="pills-tabContent">
                      {managePricingDetails?.container_type?.map(
                        (container, index) => (
                          <div
                            className={`tab-pane fade ${conditionalExp(
                              index == 0,
                              "show active",
                              ""
                            )}`}
                            id={`pills-${container
                              ?.replaceAll(" ", "")
                              ?.replaceAll(/[^a-zA-Z]+/g, "")}`}
                            role="tabpanel"
                            aria-labelledby={`pills-${container
                              ?.replaceAll(" ", "")
                              ?.replaceAll(/[^a-zA-Z]+/g, "")}-tab`}
                            tabIndex="1"
                          >
                            <Row>
                              {[container || "other"]?.map(
                                (cont, cont_index) => (
                                  <Col md={6}>
                                    <div className="mt-4">
                                      <table
                                        style={{
                                          borderCollapse: "collapse",
                                        }}
                                      >
                                        <tbody>
                                          {rateTypeCodes?.map(
                                            (
                                              rate_type_code,
                                              rate_type_code_index
                                            ) => (
                                              <>
                                                <tr key={rate_type_code}>
                                                  <td
                                                    style={{
                                                      background: "#002981",
                                                      color: "#ffff",
                                                      borderBottom:
                                                        "4px solid #e9b217",
                                                      padding: "5px 0",
                                                      display: "flex",
                                                      justifyContent:
                                                        "space-between",
                                                      alignItems: "center",
                                                      alignContent: "center",
                                                    }}
                                                  >
                                                    <div></div>
                                                    <div>
                                                      {rate_type_code?.toUpperCase()}{" "}
                                                      RATES
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                    <div>
                                              <Form.Select
                                              
                                                value={getBlockCurrency(
                                                  rate_type_code,
                                                  cont
                                                )}
                                                aria-label="Default select example"
                                                style={{ width: "150px" }}
                                                onChange={e=>{
                                                  updateBlockCurrency(rate_type_code,cont,e.target.value)
                                                }}
                                              >
                                                {currencyOptions.map((item) => (
                                                  <option value={item?.value}>
                                                    {item?.label}
                                                  </option>
                                                ))}
                                              </Form.Select>
                                            </div>
                                                      <div
                                                        style={{
                                                          cursor: "pointer",
                                                          paddingRight: "5px",
                                                        }}
                                                        onClick={() => {
                                                          setselectedLabel({
                                                            name: `ADD ${rate_type_code} RATES`,
                                                            description: "",
                                                            rate_type_code:
                                                              rate_type_code,
                                                            rate_usd: "",
                                                            amount_usd: "",
                                                            rate_currency:
                                                            getBlockCurrency(
                                                              rate_type_code,
                                                              cont
                                                            ),
                                                            container_type:
                                                              container,
                                                            actual_amount_usd:
                                                              "",
                                                            actual_rate_usd: "",
                                                            rate_description:
                                                              "",
                                                            pallet: "",
                                                            qty: "",
                                                          });
                                                          handleShowCustomer();
                                                        }}
                                                      >
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          width="16"
                                                          height="16"
                                                          fill="currentColor"
                                                          class="bi bi-plus-circle-fill"
                                                          viewBox="0 0 16 16"
                                                        >
                                                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                                        </svg>
                                                      </div>
                                                    </div>
                                                  </td>
                                                </tr>

                                                <tr>
                                                  {rate_type_code_index ==
                                                    0 && (
                                                    <div className="w-100">
                                                      <div
                                                        className="table-div"
                                                        style={{
                                                          display:
                                                            "inline-block",
                                                          fontWeight: "bold",
                                                          width: "30%",
                                                        }}
                                                      >
                                                        Concept
                                                      </div>

                                                      <div
                                                        className="table-div"
                                                        style={{
                                                          display:
                                                            "inline-block",
                                                          fontWeight: "bold",
                                                          width: "20%",
                                                        }}
                                                      >
                                                        Currency
                                                      </div>
                                                      <div
                                                        className="table-div"
                                                        style={{
                                                          display:
                                                            "inline-block",
                                                          fontWeight: "bold",
                                                          width: "15%",
                                                        }}
                                                      >
                                                        Original
                                                      </div>
                                                    </div>
                                                  )}

                                                  {selectedQuotePriceDetails
                                                    ?.filter((quote) => {
                                                      if (cont == "other") {
                                                        return quote?.rate_type_code?.includes(
                                                          rate_type_code
                                                        );
                                                      } else {
                                                        return (
                                                          quote?.rate_type_code?.includes(
                                                            rate_type_code
                                                          ) &&
                                                          quote?.container_type ==
                                                            cont
                                                        );
                                                      }
                                                    })
                                                    ?.map(
                                                      (quote, quote_index) => (
                                                        <div
                                                          className="w-100"
                                                          key={
                                                            quote?.description
                                                          }
                                                        >
                                                          <div
                                                            style={{
                                                              display:
                                                                "inline-block",
                                                              width: "30%",
                                                            }}
                                                          >
                                                            {quote?.description}
                                                          </div>

                                                          <div
                                                            className="table-div"
                                                            style={{
                                                              display:
                                                                "inline-block",
                                                              width: "20%",
                                                            }}
                                                          >
                                                            {quote?.rate_currency}
                                                          </div>
                                                          <div
                                                            className="table-div"
                                                            style={{
                                                              display:
                                                                "inline-block",
                                                              width: "15%",
                                                            }}
                                                          >
                                                            {quote?.actual_amount_usd
                                                              ? quote?.actual_amount_usd
                                                              : ""}
                                                          </div>

                                                          <div
                                                            style={{
                                                              display:
                                                                "inline-block",
                                                              marginLeft:
                                                                "-10px",
                                                              height: "18px",
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                              setselectedLabel({
                                                                name: `EDIT ${rate_type_code} RATES`,
                                                                description:
                                                                  quote?.description,
                                                                rate_type_code:
                                                                  rate_type_code,
                                                                rate_usd:
                                                                  quote?.rate_usd,
                                                                amount_usd:
                                                                  quote?.amount_usd,
                                                                rate_currency:
                                                                  quote?.rate_currency,
                                                                container_type:
                                                                  container,
                                                                id: quote?.id,
                                                                actual_amount_usd:
                                                                  quote?.actual_amount_usd,
                                                                actual_rate_usd:
                                                                  quote?.actual_rate_usd,
                                                                rate_description:
                                                                  quote?.rate_description,
                                                                pallet:
                                                                  quote?.pallet,
                                                                qty: quote?.qty,
                                                              });
                                                              handleShowCustomer();
                                                            }}
                                                          >
                                                            <svg
                                                              className="icon"
                                                              style={{
                                                                height: "18px",
                                                              }}
                                                            >
                                                              <use href="#icon_edit"></use>
                                                            </svg>
                                                          </div>

                                                          <div
                                                            style={{
                                                              display:
                                                                "inline-block",
                                                              height: "18px",
                                                              cursor: "pointer",
                                                              marginLeft:
                                                                "20px",
                                                            }}
                                                            onClick={() => {
                                                              setselectedDocId(
                                                                quote?.id
                                                              );
                                                              handleShowVendorNew();
                                                            }}
                                                          >
                                                            <svg
                                                              className="icon"
                                                              style={{
                                                                height: "18px",
                                                              }}
                                                            >
                                                              <use href="#icon_delete"></use>
                                                            </svg>
                                                          </div>
                                                        </div>
                                                      )
                                                    )}

                                                  <div className="w-100 bg-gray">
                                                    <div
                                                      style={{
                                                        display: "inline-block",
                                                        width: "29.5%",
                                                      }}
                                                    >
                                                      <p className="p-0 m-0">
                                                        {capitalizeFirstLetter(
                                                          rate_type_code
                                                        )}{" "}
                                                        Rates
                                                      </p>
                                                    </div>

                                                    <div
                                                      className="table-div"
                                                      style={{
                                                        display: "inline-block",
                                                        width: "20%",
                                                      }}
                                                    >
                                                      <input
                                                        type="text"
                                                        value={getBlockCurrency(
                                                          rate_type_code,
                                                          cont
                                                        )}
                                                        style={{
                                                          border: "none",
                                                          outline: "none",
                                                          background: "none",
                                                        }}
                                                      ></input>
                                                    </div>
                                                    <div
                                                      className="table-div"
                                                      style={{
                                                        display: "inline-block",
                                                        width: "15%",
                                                      }}
                                                    >
                                                      <input
                                                        type="text"
                                                        value={getTruckRates(
                                                          rate_type_code,
                                                          cont,
                                                          "actual_amount_usd"
                                                        )?.toFixed(2)}
                                                        style={{
                                                          border: "none",
                                                          outline: "none",
                                                          background: "none",
                                                        }}
                                                      ></input>
                                                    </div>
                                                  </div>
                                                </tr>
                                              </>
                                            )
                                          )}

                                          <tr className="w-100">
                                            <td
                                              style={{
                                                textAlign: "center",
                                                background: "#002981",
                                                color: "#ffff",
                                                borderTop: "4px solid #e9b217",
                                                padding: "5px 0",
                                              }}
                                            >
                                              TOTAL
                                            </td>
                                          </tr>
                                          <tr className="w-100">
                                            <td>
                                              <div className="w-100">
                                                <div
                                                  className="bg-gray"
                                                  style={{
                                                    display: "inline-block",
                                                    width: "29.5%",
                                                  }}
                                                >
                                                  {rateTypeCodes?.map(
                                                    (
                                                      rate_type_code,
                                                      rate_type_code_index
                                                    ) => (
                                                      <div key={rate_type_code}>
                                                        <p className="p-0 m-0">
                                                          {capitalizeFirstLetter(
                                                            rate_type_code
                                                          )}{" "}
                                                          Rates
                                                        </p>
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                                <div
                                                  className="table-div"
                                                  style={{
                                                    display: "inline-block",
                                                    width: "20%",
                                                  }}
                                                >
                                                  {rateTypeCodes?.map(
                                                    (
                                                      rate_type_code,
                                                      rate_type_code_index
                                                    ) => (
                                                      <div key={rate_type_code}>
                                                        <input
                                                          type="text"
                                                          value={'USD'}
                                                          style={{
                                                            border: "none",
                                                            outline: "none",
                                                            background: "none",
                                                          }}
                                                        ></input>
                                                      </div>
                                                    )
                                                  )}
                                                </div>

                                                <div
                                                  className="table-div"
                                                  style={{
                                                    display: "inline-block",
                                                    width: "15%",
                                                  }}
                                                >
                                                  {rateTypeCodes?.map(
                                                    (
                                                      rate_type_code,
                                                      rate_type_code_index
                                                    ) => (
                                                      <div key={rate_type_code}>
                                                        <input
                                                          type="text"
                                                          value={getTruckRates(
                                                            rate_type_code,
                                                            cont,
                                                            "actual_amount_usd",
                                                            "USD"
                                                          )?.toFixed(2)}
                                                          style={{
                                                            border: "none",
                                                            outline: "none",
                                                            background: "none",
                                                          }}
                                                        ></input>
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                          <tr className="w-100 d-flex flex-column">
                                  {
                                    groundTotalBlocks?.filter(total=>total?.container_type == cont)?.map((total)=>(
                                      <td
                                      key={total?.currency}
                                    style={{
                                      background: "#002981",
                                      color: "#ffff",
                                      borderBottom: "4px solid #e9b217",
                                      padding: "5px 0",
                                    }}
                                  >
                                    <div className="w-100">
                                      <div
                                        style={{
                                          display: "inline-block",
                                          width: "30%",
                                        }}
                                      >
                                        <p>GROSS TOTAL</p>
                                      </div>

                                      <div
                                        className="table-div"
                                        style={{
                                          display: "inline-block",
                                          width: "20%",
                                        }}
                                      >
                                        <p>{total?.currency}</p>
                                      </div>
                                      <div
                                        className="table-div"
                                        style={{
                                          display: "inline-block",
                                          width: "15%",
                                        }}
                                      >
                                        {/* <p>{grossTotalTruck()}</p> */}
                                        {total?.amount}
                                      </div>
                                    </div>
                                  </td>
                                    ))
                                  }
                                </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </Col>
                                )
                              )}
                              <Col md={6} className="mt-4">
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    marginTop: "2px",
                                  }}
                                >
                                  <button
                                    type="button"
                                    className="px-4"
                                    style={{
                                      background: "#0e2074",
                                      border: "1px solid #0e2074",
                                      color: "#ffffff",
                                      borderRadius: "4px",
                                      width: "max-content",
                                    }}
                                    onClick={() => {
                                      setselectedLabel({
                                        name: `ADDON`,
                                        description: "",
                                        rate_type_code: "ADDON",
                                        rate_usd: "",
                                        amount_usd: "",
                                        rate_currency: getBlockCurrency("ADDON",container) ||"USD",
                                        container_type: container,
                                        actual_amount_usd: "",
                                        actual_rate_usd: "",
                                        rate_description: "",
                                      });

                                      handleShowCustomer();
                                    }}
                                  >
                                    Addon
                                  </button>
                                  <button
                                    type="button"
                                    className="px-4"
                                    style={{
                                      background: "#0e2074",
                                      border: "1px solid #0e2074",
                                      color: "#ffffff",
                                      borderRadius: "4px",
                                      width: "max-content",
                                    }}
                                    onClick={() => {
                                      setCommentData(
                                        commentList?.find(
                                          (comment) =>
                                            comment?.container_type == container
                                        )?.comment_content
                                      );
                                      setShowCommentModal({
                                        container_type: container,
                                        price_rate_type_id:
                                          commentList?.find(
                                            (comment) =>
                                              comment?.container_type ==
                                              container
                                          )?.id || null,
                                      });
                                    }}
                                  >
                                    Comments
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        )
                      )}
                    </div>
                  </Container>
                )}
              {/* ocean type */}
              {managePricingDetails?.clickup_product_name?.includes("OCEAN") &&
                conditionalExp(
                  managePricingDetails?.clickup_product_name?.includes(
                    "OCEAN LCL"
                  ),
                  <Container>
                   <Row>
                      {["other"]?.map((cont, cont_index) => (
                        <Col md={7}>
                          <div>
                            {cont != "other" && (
                              <h4 className="text-center pt-3 pb-2">
                                CONTAINER TYPE {cont}
                              </h4>
                            )}
                            <table
                              className="w-100"
                              style={{ borderCollapse: "collapse" }}
                            >
                              <tbody>
                                {cont == "other" && (
                                  <tr>
                                    <td colSpan={6} className="">
                                      <p>&nbsp;</p>
                                    </td>
                                  </tr>
                                )}
                                {rateTypeCodes?.map(
                                  (rate_type_code, rate_type_code_index) => (
                                    <>
                                      <tr key={rate_type_code}>
                                        <td
                                          style={{
                                            background: "#002981",
                                            color: "#ffff",
                                            borderBottom: "4px solid #e9b217",
                                            padding: "5px 0",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            alignContent: "center",
                                          }}
                                        >
                                          <div></div>
                                          <div>
                                            {rate_type_code?.toUpperCase()}{" "}
                                            RATES
                                          </div>

                                          <div className="d-flex gap-2">
                                          <div>
                                              <Form.Select
                                              
                                                value={getBlockCurrency(
                                                  rate_type_code,
                                                  cont
                                                )}
                                                aria-label="Default select example"
                                                style={{ width: "150px" }}
                                                onChange={e=>{
                                                  updateBlockCurrency(rate_type_code,cont,e.target.value)
                                                }}
                                              >
                                                {currencyOptions.map((item) => (
                                                  <option value={item?.value}>
                                                    {item?.label}
                                                  </option>
                                                ))}
                                              </Form.Select>
                                            </div>
                                            <div
                                              style={{
                                                cursor: "pointer",
                                                paddingRight: "5px",
                                              }}
                                              onClick={() => {
                                                setselectedLabel({
                                                  name: `ADD ${rate_type_code} RATES`,
                                                  description: "",
                                                  rate_type_code:
                                                    rate_type_code,
                                                  rate_usd: "",
                                                  rate_type_id: "",
                                                  amount_usd: "",
                                                  rate_currency: getBlockCurrency(
                                                    rate_type_code,
                                                    cont
                                                  ),
                                                  actual_amount_usd: "",
                                                  actual_rate_usd: "",
                                                  rate_description: "",
                                                  qty: "1.00",
                                                  container_type: null,
                                                  actual_rate_usd: "0.00",
                                                  rate_description: null,
                                                  pallet: null,
                                                });
                                                handleShowCustomer();
                                              }}
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-plus-circle-fill"
                                                viewBox="0 0 16 16"
                                              >
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                              </svg>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        {rate_type_code_index == 0 && (
                                          <div className="w-100">
                                            <div
                                              style={{
                                                display: "inline-block",
                                                width: "40%",
                                              }}
                                            >
                                              <p className="p-0 m-0"></p>
                                            </div>

                                            <div
                                              className="table-div"
                                              style={{
                                                display: "inline-block",
                                                fontWeight: "bold",
                                                width: "15%",
                                              }}
                                            >
                                              Original Price
                                            </div>
                                          </div>
                                        )}

                                        {selectedQuotePriceDetails
                                          ?.filter((quote) => {
                                            if (cont == "other") {
                                              return quote?.rate_type_code?.includes(
                                                rate_type_code
                                              );
                                            } else {
                                              return (
                                                quote?.rate_type_code?.includes(
                                                  rate_type_code
                                                ) &&
                                                quote?.container_type == cont
                                              );
                                            }
                                          })
                                          ?.map((quote, quote_index) => (
                                            <div
                                              className="w-100 "
                                              key={quote?.description}
                                            >
                                              <div
                                                style={{
                                                  display: "inline-block",
                                                  width: "40%",
                                                }}
                                              >
                                                {quote?.description}
                                              </div>

                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  width: "15%",
                                                }}
                                              >
                                                {quote?.actual_amount_usd
                                                  ? quote?.actual_amount_usd
                                                  : ""}
                                              </div>

                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  width: "10%",
                                                }}
                                              >
                                                <input
                                                  type="text"
                                                  value={
                                                    quote?.rate_currency
                                                      ? quote?.rate_currency
                                                      : ""
                                                  }
                                                  style={{
                                                    border: "none",
                                                    outline: "none",
                                                    background: "none",
                                                    maxWidth: "max-content",
                                                  }}
                                                ></input>
                                              </div>
                                              <div
                                                style={{
                                                  display: "inline-block",
                                                  height: "18px",
                                                  marginRight: "20px",
                                                  minWidth: "120px",
                                                }}
                                              >
                                                {quote?.rate_type_id == 1 && (
                                                  <span>Per Shipment</span>
                                                )}
                                                {quote?.rate_type_id == 2 && (
                                                  <span>Per Container</span>
                                                )}
                                                &nbsp;
                                              </div>
                                              <div
                                                style={{
                                                  display: "inline-block",
                                                  marginLeft: "-10px",
                                                  height: "18px",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  setselectedLabel({
                                                    name: `EDIT ${rate_type_code} RATES`,
                                                    description:
                                                      quote?.description,
                                                    rate_type_code:
                                                      rate_type_code,
                                                    rate_usd: quote?.rate_usd,
                                                    rate_type_id:
                                                      quote?.rate_type_id,
                                                    amount_usd:
                                                      quote?.amount_usd,
                                                    rate_currency: quote?.rate_currency,
                                                    container_type:
                                                      quote?.container_type,
                                                    id: quote?.id,
                                                    actual_amount_usd:
                                                      quote?.actual_amount_usd,
                                                    actual_rate_usd:
                                                      quote?.actual_rate_usd,
                                                    rate_description:
                                                      quote?.rate_description,
                                                  });
                                                  handleShowCustomer();
                                                }}
                                              >
                                                <svg
                                                  className="icon"
                                                  style={{
                                                    height: "18px",
                                                  }}
                                                >
                                                  <use href="#icon_edit"></use>
                                                </svg>
                                              </div>
                                              <div
                                                style={{
                                                  display: "inline-block",
                                                  height: "18px",
                                                  cursor: "pointer",
                                                  marginLeft: "20px",
                                                }}
                                                onClick={() => {
                                                  setselectedDocId(quote?.id);
                                                  handleShowVendorNew();
                                                }}
                                              >
                                                <svg
                                                  className="icon"
                                                  style={{
                                                    height: "18px",
                                                  }}
                                                >
                                                  <use href="#icon_delete"></use>
                                                </svg>
                                              </div>
                                            </div>
                                          ))}

                                        <div className="w-100 bg-gray">
                                          <div
                                            style={{
                                              display: "inline-block",
                                              width: "40%",
                                            }}
                                          >
                                            <p className="p-0 m-0">
                                              Total{" "}
                                              {capitalizeFirstLetter(
                                                rate_type_code
                                              )}{" "}
                                              Rates
                                            </p>
                                          </div>

                                          <div
                                            className="table-div"
                                            style={{
                                              width: "15%",
                                              display: "inline-block",
                                            }}
                                          >
                                            <input
                                              type="text"
                                              value={getFrieghtRatesOriginal(
                                                rate_type_code,
                                                cont
                                              )?.toFixed(2)}
                                              style={{
                                                border: "none",
                                                outline: "none",
                                                background: "none",
                                              }}
                                            ></input>
                                          </div>
                                          <div
                                            className="table-div"
                                            style={{
                                              display: "inline-block",
                                              width: "15%",
                                            }}
                                          >
                                            <input
                                              type="text"
                                              value={getBlockCurrency(
                                                rate_type_code,
                                                cont
                                              )}
                                              style={{
                                                border: "none",
                                                outline: "none",
                                                background: "none",
                                              }}
                                            ></input>
                                          </div>
                                        </div>
                                      </tr>
                                    </>
                                  )
                                )}

                                {/* total */}
                                <tr className="w-100">
                                  <td
                                    style={{
                                      textAlign: "center",
                                      background: "#002981",
                                      color: "#ffff",
                                      borderTop: "4px solid #e9b217",
                                      padding: "5px 0",
                                    }}
                                  >
                                    TOTAL
                                  </td>
                                </tr>
                                <tr className="w-100">
                                  <td>
                                    <div className="w-100">
                                      <div
                                        className="bg-gray"
                                        style={{
                                          display: "inline-block",
                                          width: "40%",
                                        }}
                                      >
                                        {rateTypeCodes?.map(
                                          (
                                            rate_type_code,
                                            rate_type_code_index
                                          ) => (
                                            <div key={rate_type_code}>
                                              <p className="p-0 m-0">
                                                {capitalizeFirstLetter(
                                                  rate_type_code
                                                )}{" "}
                                                Rates
                                              </p>
                                            </div>
                                          )
                                        )}
                                      </div>
                                      <div
                                        className="table-div"
                                        style={{
                                          width: "15%",
                                          display: "inline-block",
                                        }}
                                      >
                                        {rateTypeCodes?.map(
                                          (
                                            rate_type_code,
                                            rate_type_code_index
                                          ) => (
                                            <div key={rate_type_code}>
                                              <input
                                                type="text"
                                                value={getFrieghtRatesOriginal(
                                                  rate_type_code,
                                                  cont,
                                                  "USD"
                                                )?.toFixed(2)}
                                                style={{
                                                  border: "none",
                                                  outline: "none",
                                                  background: "none",
                                                }}
                                              ></input>
                                            </div>
                                          )
                                        )}
                                      </div>

                                      <div
                                        className="table-div"
                                        style={{
                                          display: "inline-block",
                                          width: "15%",
                                        }}
                                      >
                                        {rateTypeCodes?.map(
                                          (
                                            rate_type_code,
                                            rate_type_code_index
                                          ) => (
                                            <div key={rate_type_code}>
                                              <input
                                                type="text"
                                                value={"USD"}
                                                style={{
                                                  border: "none",
                                                  outline: "none",
                                                  background: "none",
                                                }}
                                              ></input>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr className="w-100 d-flex flex-column">
                                  {
                                    groundTotalBlocks?.filter(total=>total?.container_type == cont)?.map((total)=>(
                                      <td
                                      key={total?.currency}
                                    style={{
                                      background: "#002981",
                                      color: "#ffff",
                                      borderBottom: "4px solid #e9b217",
                                      padding: "5px 0",
                                    }}
                                  >
                                    <div className="w-100">
                                      <div
                                        style={{
                                          display: "inline-block",
                                          width: "40%",
                                        }}
                                      >
                                        <p>GROSS TOTAL {total?.currency}</p>
                                      </div>

                                      <div
                                        className="table-div"
                                        style={{
                                          width: "15%",
                                          display: "inline-block",
                                        }}
                                      >
                                        {/* <p>{grossTotalOriginal(cont)}</p> */}
                                        <p>{total?.amount}</p>
                                      </div>
                                      <div
                                        className="table-div"
                                        style={{
                                          width: "15%",
                                          display: "inline-block",
                                        }}
                                      >
                                        <p>{total?.currency}</p>
                                      </div>
                                    </div>
                                  </td>
                                    ))
                                  }
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </Col>
                      ))}
                      <Col md={4} className="mt-4">
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            marginTop: "2px",
                          }}
                        >
                          <button
                            type="button"
                            className="px-4"
                            style={{
                              background: "#0e2074",
                              border: "1px solid #0e2074",
                              color: "#ffffff",
                              borderRadius: "4px",
                              width: "max-content",
                            }}
                            onClick={() => {
                              setselectedLabel({
                                name: `ADDON`,
                                description: "",
                                rate_type_code: "ADDON",
                                rate_usd: "",
                                rate_type_id: "",
                                amount_usd: "",
                                rate_currency: getBlockCurrency("ADDON","other") ||"USD",
                                container_type: "",
                                actual_amount_usd: "",
                                actual_rate_usd: "",
                                rate_description: "",
                              });
                              if (
                                managePricingDetails?.clickup_product_name?.includes(
                                  "TRUCK"
                                )
                              ) {
                                handleShowCustomer2();
                              } else {
                                handleShowCustomer();
                              }
                            }}
                          >
                            Addon
                          </button>
                          <button
                            type="button"
                            className="px-4"
                            style={{
                              background: "#0e2074",
                              border: "1px solid #0e2074",
                              color: "#ffffff",
                              borderRadius: "4px",
                              width: "max-content",
                            }}
                            onClick={() => {
                              setCommentData(commentList[0]?.comment_content);
                              setShowCommentModal({
                                container_type: null,
                                price_rate_type_id: commentList[0]?.id || null,
                              });
                            }}
                          >
                            Comments
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Container>,
                  <Container>
                    <div className="w-100 tabs-row">
                      <ul
                        className="nav nav-pills mb-3 gap-3"
                        id="pills-tab"
                        role="tablist"
                      >
                        {managePricingDetails?.container_type?.map(
                          (container, index) => (
                            <li className="nav-item" role="presentation">
                              <button
                                className={`nav-link ${conditionalExp(
                                  index == 0,
                                  "active",
                                  ""
                                )}`}
                                id={`pills-${container?.replaceAll(
                                  " + ",
                                  ""
                                )}-tab`}
                                data-bs-toggle="pill"
                                data-bs-target={`#pills-${container?.replaceAll(
                                  " + ",
                                  ""
                                )}`}
                                type="button"
                                role="tab"
                                aria-controls={`pills-${container?.replaceAll(
                                  " + ",
                                  ""
                                )}`}
                                aria-selected="false"
                              >
                                {container}
                              </button>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                    <div className="tab-content" id="pills-tabContent">
                      {managePricingDetails?.container_type?.map(
                        (container, index) => (
                          <div
                            className={`tab-pane fade ${conditionalExp(
                              index == 0,
                              "show active",
                              ""
                            )}`}
                            id={`pills-${container?.replaceAll(" + ", "")}`}
                            role="tabpanel"
                            aria-labelledby={`pills-${container?.replaceAll(
                              " + ",
                              ""
                            )}-tab`}
                            tabIndex="1"
                          >
                            <Row>
                              {[container || "other"]?.map(
                                (cont, cont_index) => (
                                  <Col md={7}>
                                    <div>
                                      <table
                                        className="w-100"
                                        style={{ borderCollapse: "collapse" }}
                                      >
                                        <tbody>
                                          {cont == "other" && (
                                            <tr>
                                              <td colSpan={6} className="">
                                                <p>&nbsp;</p>
                                              </td>
                                            </tr>
                                          )}
                                          {rateTypeCodes?.map(
                                            (
                                              rate_type_code,
                                              rate_type_code_index
                                            ) => (
                                              <>
                                                <tr key={rate_type_code}>
                                                  <td
                                                    style={{
                                                      background: "#002981",
                                                      color: "#ffff",
                                                      borderBottom:
                                                        "4px solid #e9b217",
                                                      padding: "5px 0",
                                                      display: "flex",
                                                      justifyContent:
                                                        "space-between",
                                                      alignItems: "center",
                                                      alignContent: "center",
                                                    }}
                                                  >
                                                    <div></div>
                                                    <div>
                                                      {rate_type_code?.toUpperCase()}{" "}
                                                      RATES
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                    <div>
                                              <Form.Select
                                              
                                                value={getBlockCurrency(
                                                  rate_type_code,
                                                  cont
                                                )}
                                                aria-label="Default select example"
                                                style={{ width: "150px" }}
                                                onChange={e=>{
                                                  updateBlockCurrency(rate_type_code,cont,e.target.value)
                                                }}
                                              >
                                                {currencyOptions.map((item) => (
                                                  <option value={item?.value}>
                                                    {item?.label}
                                                  </option>
                                                ))}
                                              </Form.Select>
                                            </div>
                                                      <div
                                                        style={{
                                                          cursor: "pointer",
                                                          paddingRight: "5px",
                                                        }}
                                                        onClick={() => {
                                                          setselectedLabel({
                                                            name: `ADD ${rate_type_code} RATES`,
                                                            description: "",
                                                            rate_type_code:
                                                              rate_type_code,
                                                            rate_usd: "",
                                                            rate_type_id: "",
                                                            amount_usd: "",
                                                            rate_currency:
                                                            getBlockCurrency(
                                                              rate_type_code,
                                                              cont
                                                            ),
                                                            actual_amount_usd:
                                                              "",
                                                            actual_rate_usd: "",
                                                            rate_description:
                                                              "",
                                                            qty: "1.00",
                                                            container_type:
                                                              container,
                                                            actual_rate_usd:
                                                              "0.00",
                                                            rate_description:
                                                              null,
                                                            pallet: null,
                                                          });
                                                          handleShowCustomer();
                                                        }}
                                                      >
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          width="16"
                                                          height="16"
                                                          fill="currentColor"
                                                          class="bi bi-plus-circle-fill"
                                                          viewBox="0 0 16 16"
                                                        >
                                                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                                        </svg>
                                                      </div>
                                                    </div>
                                                  </td>
                                                </tr>

                                                <tr>
                                                  {rate_type_code_index ==
                                                    0 && (
                                                    <div className="w-100">
                                                      <div
                                                        style={{
                                                          display:
                                                            "inline-block",
                                                          width: "40%",
                                                        }}
                                                      >
                                                        <p className="p-0 m-0"></p>
                                                      </div>

                                                      <div
                                                        className="table-div"
                                                        style={{
                                                          display:
                                                            "inline-block",
                                                          fontWeight: "bold",
                                                          width: "15%",
                                                        }}
                                                      >
                                                        Original Price
                                                      </div>
                                                    </div>
                                                  )}

                                                  {selectedQuotePriceDetails
                                                    ?.filter((quote) => {
                                                      if (cont == "other") {
                                                        return quote?.rate_type_code?.includes(
                                                          rate_type_code
                                                        );
                                                      } else {
                                                        return (
                                                          quote?.rate_type_code?.includes(
                                                            rate_type_code
                                                          ) &&
                                                          quote?.container_type ==
                                                            cont
                                                        );
                                                      }
                                                    })
                                                    ?.map(
                                                      (quote, quote_index) => (
                                                        <div
                                                          className="w-100 "
                                                          key={
                                                            quote?.description
                                                          }
                                                        >
                                                          <div
                                                            style={{
                                                              display:
                                                                "inline-block",
                                                              width: "40%",
                                                            }}
                                                          >
                                                            {quote?.description}
                                                          </div>

                                                          <div
                                                            className="table-div"
                                                            style={{
                                                              display:
                                                                "inline-block",
                                                              width: "15%",
                                                            }}
                                                          >
                                                            {quote?.actual_amount_usd
                                                              ? quote?.actual_amount_usd
                                                              : ""}
                                                          </div>

                                                          <div
                                                            className="table-div"
                                                            style={{
                                                              display:
                                                                "inline-block",
                                                              width: "10%",
                                                            }}
                                                          >
                                                            <input
                                                              type="text"
                                                              value={
                                                                quote?.rate_currency
                                                                  ? quote?.rate_currency
                                                                  : ""
                                                              }
                                                              style={{
                                                                border: "none",
                                                                outline: "none",
                                                                background:
                                                                  "none",
                                                                maxWidth:
                                                                  "max-content",
                                                              }}
                                                            ></input>
                                                          </div>
                                                          <div
                                                            style={{
                                                              display:
                                                                "inline-block",
                                                              height: "18px",
                                                              marginRight:
                                                                "20px",
                                                              minWidth: "120px",
                                                            }}
                                                          >
                                                            {quote?.rate_type_id ==
                                                              1 && (
                                                              <span>
                                                                Per Shipment
                                                              </span>
                                                            )}
                                                            {quote?.rate_type_id ==
                                                              2 && (
                                                              <span>
                                                                Per Container
                                                              </span>
                                                            )}
                                                            &nbsp;
                                                          </div>
                                                          <div
                                                            style={{
                                                              display:
                                                                "inline-block",
                                                              marginLeft:
                                                                "-10px",
                                                              height: "18px",
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                              setselectedLabel({
                                                                name: `EDIT ${rate_type_code} RATES`,
                                                                description:
                                                                  quote?.description,
                                                                rate_type_code:
                                                                  rate_type_code,
                                                                rate_usd:
                                                                  quote?.rate_usd,
                                                                rate_type_id:
                                                                  quote?.rate_type_id,
                                                                amount_usd:
                                                                  quote?.amount_usd,
                                                                rate_currency:
                                                                  quote?.rate_currency,
                                                                container_type:
                                                                  container,
                                                                id: quote?.id,
                                                                actual_amount_usd:
                                                                  quote?.actual_amount_usd,
                                                                actual_rate_usd:
                                                                  quote?.actual_rate_usd,
                                                                rate_description:
                                                                  quote?.rate_description,
                                                              });
                                                              handleShowCustomer();
                                                            }}
                                                          >
                                                            <svg
                                                              className="icon"
                                                              style={{
                                                                height: "18px",
                                                              }}
                                                            >
                                                              <use href="#icon_edit"></use>
                                                            </svg>
                                                          </div>
                                                          <div
                                                            style={{
                                                              display:
                                                                "inline-block",
                                                              height: "18px",
                                                              cursor: "pointer",
                                                              marginLeft:
                                                                "20px",
                                                            }}
                                                            onClick={() => {
                                                              setselectedDocId(
                                                                quote?.id
                                                              );
                                                              handleShowVendorNew();
                                                            }}
                                                          >
                                                            <svg
                                                              className="icon"
                                                              style={{
                                                                height: "18px",
                                                              }}
                                                            >
                                                              <use href="#icon_delete"></use>
                                                            </svg>
                                                          </div>
                                                        </div>
                                                      )
                                                    )}

                                                  <div className="w-100 bg-gray">
                                                    <div
                                                      style={{
                                                        display: "inline-block",
                                                        width: "40%",
                                                      }}
                                                    >
                                                      <p className="p-0 m-0">
                                                        Total{" "}
                                                        {capitalizeFirstLetter(
                                                          rate_type_code
                                                        )}{" "}
                                                        Rates
                                                      </p>
                                                    </div>

                                                    <div
                                                      className="table-div"
                                                      style={{
                                                        width: "15%",
                                                        display: "inline-block",
                                                      }}
                                                    >
                                                      <input
                                                        type="text"
                                                        value={getFrieghtRatesOriginal(
                                                          rate_type_code,
                                                          cont
                                                        )?.toFixed(2)}
                                                        style={{
                                                          border: "none",
                                                          outline: "none",
                                                          background: "none",
                                                        }}
                                                      ></input>
                                                    </div>
                                                    <div
                                                      className="table-div"
                                                      style={{
                                                        display: "inline-block",
                                                        width: "15%",
                                                      }}
                                                    >
                                                      <input
                                                        type="text"
                                                        value={getBlockCurrency(
                                                          rate_type_code,
                                                          cont
                                                        )}
                                                        style={{
                                                          border: "none",
                                                          outline: "none",
                                                          background: "none",
                                                        }}
                                                      ></input>
                                                    </div>
                                                  </div>
                                                </tr>
                                              </>
                                            )
                                          )}

                                          {/* total */}
                                          <tr className="w-100">
                                            <td
                                              style={{
                                                textAlign: "center",
                                                background: "#002981",
                                                color: "#ffff",
                                                borderTop: "4px solid #e9b217",
                                                padding: "5px 0",
                                              }}
                                            >
                                              TOTAL
                                            </td>
                                          </tr>
                                          <tr className="w-100">
                                            <td>
                                              <div className="w-100">
                                                <div
                                                  className="bg-gray"
                                                  style={{
                                                    display: "inline-block",
                                                    width: "40%",
                                                  }}
                                                >
                                                  {rateTypeCodes?.map(
                                                    (
                                                      rate_type_code,
                                                      rate_type_code_index
                                                    ) => (
                                                      <div key={rate_type_code}>
                                                        <p className="p-0 m-0">
                                                          {capitalizeFirstLetter(
                                                            rate_type_code
                                                          )}{" "}
                                                          Rates
                                                        </p>
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                                <div
                                                  className="table-div"
                                                  style={{
                                                    width: "15%",
                                                    display: "inline-block",
                                                  }}
                                                >
                                                  {rateTypeCodes?.map(
                                                    (
                                                      rate_type_code,
                                                      rate_type_code_index
                                                    ) => (
                                                      <div key={rate_type_code}>
                                                        <input
                                                          type="text"
                                                          value={getFrieghtRatesOriginal(
                                                            rate_type_code,
                                                            cont,
                                                            'USD'
                                                          )?.toFixed(2)}
                                                          style={{
                                                            border: "none",
                                                            outline: "none",
                                                            background: "none",
                                                          }}
                                                        ></input>
                                                      </div>
                                                    )
                                                  )}
                                                </div>

                                                <div
                                                  className="table-div"
                                                  style={{
                                                    display: "inline-block",
                                                    width: "15%",
                                                  }}
                                                >
                                                  {rateTypeCodes?.map(
                                                    (
                                                      rate_type_code,
                                                      rate_type_code_index
                                                    ) => (
                                                      <div key={rate_type_code}>
                                                        <input
                                                          type="text"
                                                          value={'USD'}
                                                          style={{
                                                            border: "none",
                                                            outline: "none",
                                                            background: "none",
                                                          }}
                                                        ></input>
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                          <tr className="w-100 d-flex flex-column">
                                  {
                                    groundTotalBlocks?.filter(total=>total?.container_type == cont)?.map((total)=>(
                                      <td
                                      key={total?.currency}
                                    style={{
                                      background: "#002981",
                                      color: "#ffff",
                                      borderBottom: "4px solid #e9b217",
                                      padding: "5px 0",
                                    }}
                                  >
                                    <div className="w-100">
                                      <div
                                        style={{
                                          display: "inline-block",
                                          width: "40%",
                                        }}
                                      >
                                        <p>GROSS TOTAL {total?.currency}</p>
                                      </div>

                                      <div
                                        className="table-div"
                                        style={{
                                          width: "15%",
                                          display: "inline-block",
                                        }}
                                      >
                                        {/* <p>{grossTotalOriginal(cont)}</p> */}
                                        <p>{total?.amount}</p>
                                      </div>
                                      <div
                                        className="table-div"
                                        style={{
                                          width: "15%",
                                          display: "inline-block",
                                        }}
                                      >
                                        <p>{total?.currency}</p>
                                      </div>
                                    </div>
                                  </td>
                                    ))
                                  }
                                </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </Col>
                                )
                              )}
                              <Col md={4} className="mt-4">
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    marginTop: "2px",
                                  }}
                                >
                                  <button
                                    type="button"
                                    className="px-4"
                                    style={{
                                      background: "#0e2074",
                                      border: "1px solid #0e2074",
                                      color: "#ffffff",
                                      borderRadius: "4px",
                                      width: "max-content",
                                    }}
                                    onClick={() => {
                                      setselectedLabel({
                                        name: `ADDON`,
                                        description: "",
                                        rate_type_code: "ADDON",
                                        rate_usd: "",
                                        rate_type_id: "",
                                        amount_usd: "",
                                        rate_currency: getBlockCurrency("ADDON",container) ||"USD",
                                        container_type: container,
                                        actual_amount_usd: "",
                                        actual_rate_usd: "",
                                        rate_description: "",
                                      });
                                      if (
                                        managePricingDetails?.clickup_product_name?.includes(
                                          "TRUCK"
                                        )
                                      ) {
                                        handleShowCustomer2();
                                      } else {
                                        handleShowCustomer();
                                      }
                                    }}
                                  >
                                    Addon
                                  </button>
                                  <button
                                    type="button"
                                    className="px-4"
                                    style={{
                                      background: "#0e2074",
                                      border: "1px solid #0e2074",
                                      color: "#ffffff",
                                      borderRadius: "4px",
                                      width: "max-content",
                                    }}
                                    onClick={() => {
                                      setCommentData(
                                        commentList?.find(
                                          (comment) =>
                                            comment?.container_type == container
                                        )?.comment_content
                                      );
                                      setShowCommentModal({
                                        container_type: container,
                                        price_rate_type_id:
                                          commentList?.find(
                                            (comment) =>
                                              comment?.container_type ==
                                              container
                                          )?.id || null,
                                      });
                                    }}
                                  >
                                    Comments
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        )
                      )}
                    </div>
                  </Container>
                )}
            </div>)
          )}
        </Modal.Body>
        <Modal.Footer>
          {currIndex == 0 ? (
            <>
              <button
                type="button"
                className="next1-btn rounded-2"
                disabled={submittingDetails || checkAllDetailsFilled()}
                onClick={next}
              >
                {submittingDetails ? <Loader /> : "NEXT"}
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                className="pricing-btn rounded-2"
                onClick={previous}
              >
                BACK
              </button>
              <button
                type="button"
                className="next1-btn rounded-2"
                onClick={() => {
                  setshowSuccess(true);
                  setTimeout(() => {
                    setshowSuccess(false);
                  }, 1000);
                  setTimeout(() => {
                    handleClose();
                  }, 1000);
                }}
              >
                SUBMIT
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        show={showCustomer}
        centered
        onHide={handleCloseCustomer}
        className="tracking-modal"
      >
        <Modal.Header closeButton style={{ border: "none" }}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <h2 className="fw-600 fs-26 text-center">{selectedLabel?.name}</h2>
          <form className="p-4">
            <div className="input-box mb-4">
              {selectedLabel.description && selectedLabel.description != "" && (
                <label htmlFor="description">Description</label>
              )}
              <input
                required
                type="text"
                className="w-100 border-bottom pb-3"
                placeholder="Enter Description"
                id="description"
                value={selectedLabel?.description}
                onChange={(e) => {
                  setselectedLabel({
                    ...selectedLabel,
                    description: e.target.value,
                  });
                }}
              />
            </div>
            {managePricingDetails?.clickup_product_name?.includes("Air") && (
              <>
                {(selectedLabel?.name?.includes("ORIGIN") ||
                  selectedLabel?.name?.includes("FREIGHT")) && (
                  <>
                    <div className="input-box mb-4">
                      {selectedLabel.actual_rate_usd &&
                        selectedLabel.actual_rate_usd != "" && (
                          <label htmlFor="actual_rate_usd">
                            {!selectedLabel?.name?.includes("FREIGHT") ? (
                              <span>AMOUNT</span>
                            ) : (
                              <span>RATE PER KG</span>
                            )}
                          </label>
                        )}
                      <input
                        required
                        type="number"
                        className="w-100 border-bottom pb-3"
                        placeholder={
                          !selectedLabel?.name?.includes("FREIGHT")
                            ? "Enter Amount"
                            : "Enter Rate PER KG"
                        }
                        id="actual_rate_usd"
                        value={selectedLabel?.actual_rate_usd}
                        onChange={(e) => {
                          setselectedLabel({
                            ...selectedLabel,
                            actual_rate_usd: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </>
                )}

                {selectedLabel?.name?.includes("ORIGIN") && (
                  <div className="input-box mb-4">
                    {selectedLabel.rate_description &&
                      selectedLabel.rate_description != "" && (
                        <label htmlFor="rate_description">
                          RATE DESCRIPTION
                        </label>
                      )}
                    <input
                      required
                      type="text"
                      className="w-100 border-bottom pb-3"
                      placeholder="Enter Rate Description"
                      id="rate_description"
                      value={selectedLabel?.rate_description}
                      onChange={(e) => {
                        setselectedLabel({
                          ...selectedLabel,
                          rate_description: e.target.value,
                        });
                      }}
                    />
                  </div>
                )}
              </>
            )}
            <div className="input-box mb-4">
              {selectedLabel.actual_amount_usd &&
                selectedLabel.actual_amount_usd != "" && (
                  <label htmlFor="actual_amount_usd">
                    {managePricingDetails?.clickup_product_name?.includes("Air")
                      ? "Amount"
                      : "Original Price"}
                  </label>
                )}

              <input
                required
                type="number"
                className="w-100 border-bottom pb-3"
                placeholder={
                  managePricingDetails?.clickup_product_name?.includes("Air")
                    ? "Enter Amount"
                    : "Enter Original Price"
                }
                id="actual_amount_usd"
                value={selectedLabel?.actual_amount_usd}
                onChange={(e) => {
                  setselectedLabel({
                    ...selectedLabel,
                    actual_amount_usd: e.target.value,
                  });
                }}
              />
            </div>
            {managePricingDetails?.clickup_product_name?.toLowerCase() ==
              "ocean fcl" && (
              <div className="input-box mb-4">
                {selectedLabel.rate_type_id &&
                  selectedLabel.rate_type_id != "" && (
                    <label htmlFor="rate_type_id">Rate Type</label>
                  )}

                <select
                  className="w-100 form-select"
                  id="rate_type_id"
                  required
                  value={selectedLabel?.rate_type_id}
                  onChange={(e) => {
                    setselectedLabel({
                      ...selectedLabel,
                      rate_type_id: e.target.value,
                    });
                  }}
                >
                  <option value="">select rate type</option>
                  <option value="1">Per Shipment</option>
                  <option value="2">Per Container</option>
                </select>
              </div>
            )}

            <div className="d-flex justify-content-end">
              {sendingPlusDetailsError && (
                <div className="text-danger">{sendingPlusDetailsError}</div>
              )}
              <div>
                <button
                  type="submit"
                  className="mt-4 py-2 px-4 grd-btn fs-20 rounded-pill fw-600 d-flex text-center"
                  disabled={
                    sendingPlusDetails
                    // || !selectedLabel?.description || !selectedLabel?.actual_amount_usd || !selectedLabel?.rate_type_id
                  }
                  onClick={(e) => {
                    e.preventDefault();

                    if ("id" in selectedLabel) {
                      postPlusIcon("edit");
                    } else {
                      postPlusIcon("add");
                    }
                  }}
                >
                  {loaderFnSave(sendingPlusDetails, "Save")}
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal
        show={showPortModal}
        centered
        onHide={() => {
          setshowPortModal(false);
        }}
        className="tracking-modal"
      >
        <Modal.Header closeButton style={{ border: "none" }}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <h2 className="fw-600 fs-26 text-center py-1">
            {portModalDisplay?.name}
          </h2>
          {conditionalExp(portModalDisplay?.type == 'total_cost',
            Object.keys(portModalDisplay?.data)?.map((item) => (
              <div className="row w-100 text-center py-1">
            <div className="fw-600 fs-20 col">{item}</div>
            <div className="fw-600 fs-20 col">{portModalDisplay?.data[item]}</div>
          </div>
            ))
          )}
          {
            Array.isArray(portModalDisplay?.data) && <>
            <div className="row w-100 text-center">
           <div className="fw-600 fs-20 col">Port Name</div>
           <div className="fw-600 fs-20 col">Port Code</div>
         </div>
         {portModalDisplay?.data
           ?.filter((item) => item?.port_type == portModalDisplay?.type)
           ?.map((item) => (
             <div className="row w-100 text-center py-1">
               <div className="col">{item?.port_name}</div>
               <div className="col">{item?.global_port_code}</div>
             </div>
           ))}
           </>
            
            
          }
        </Modal.Body>
      </Modal>
      <Modal show={showVendorNew} onHide={handleCloseVendorNew} centered>
        <Modal.Body className="p-4">
          <p className="fs-4 text-center">
            Are you sure you want to delete this{" "}
            {conditionalExp(priceIdToDelete, "Self Price", "Breackup")} ?
          </p>
          <div className="d-flex justify-content-center mt-2">
            <Button
              variant="secondary"
              className="me-3"
              onClick={handleCloseVendorNew}
            >
              Close
            </Button>
            <Button
              variant="danger"
              onClick={(e) => {
                if (priceIdToDelete) {
                  deletePriceFn();
                } else if (selectedDocId) {
                  deleteLableFn();
                }
              }}
            >
              {loaderFnSave(deleting, "Delete")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showSuccess}
        centered
        scrollable
        onHide={() => {
          setshowSuccess(false);
        }}
        className="tracking-modal"
      >
        <Modal.Body className="p-4 text-center">
          <div class="d-flex justify-content-center  text-success pb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="55"
              height="55"
              fill="currentColor"
              className="bi bi-check-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
            </svg>
          </div>
          <h3>Success</h3>
          <p>Details Submitted Successfully</p>
          {conditionalExp(
            errRows?.length,
            <p className="text-danger">
              <p>Skipped Rows</p>
              {errRows?.map((item) => (
                <>
                  <span>
                    <b>Row {item?.row}</b>: {item?.msg}
                  </span>
                  <br />
                </>
              ))}
            </p>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        show={showUpload}
        centered
        onHide={handleCloseUpload}
        className="tracking-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <h4 className="text-center pb-3 fs-22">
            Bulk Upload {filterDetails.product_type?.toUpperCase()} Quotes
          </h4>
          <p className="text-center pb-3">
            <b>Note:</b> Only excel files are accepted. <br />
            <a
              className="text-center mb-3 "
              href={`${filterDetails.product_type?.toUpperCase()}_Self_Price_Sample.xlsx`}
              download
            >
              Download Sample File
            </a>
          </p>

          <div className="upload-box p-3">
            <label className="drag-box input-file">
              <img
                className="img-fluid rounded-start"
                width={80}
                height={55}
                src={window.location.origin + "/images/uploader.png"}
                alt="..."
              />
              <input
                type="file"
                accept=".xls,.xlsx"
                id="fileUpload2"
                onChange={handleChangeFileFun}
              />
              <span>
                Drag and drop your image or <a href="">browse file</a> on your
                computer
              </span>
            </label>
          </div>
          <div className="d-block rounded-2 p-1 my-3">
            {previewUrl ? (
              rawFile?.map((file, index) => (
                <div
                  key={file?.name + "rawFiles"}
                  className="file-import d-flex ai-center my-2"
                  id="remove_${inputid}"
                >
                  {checkRxType(file)}
                  <span style={{ marginRight: "auto" }}>{file?.name}</span>

                  <button
                    onClick={() => {
                      setrawFile((pre) =>
                        pre.filter((item, iIndex) => index != iIndex)
                      );
                    }}
                  >
                    <svg
                      className="icon"
                      style={{ width: "20px", height: "20px" }}
                    >
                      <use href="#icon_fileclose"></use>
                    </svg>
                  </button>
                </div>
              ))
            ) : (
              <div></div>
            )}
          </div>
          {fileUploadLimitRxError && (
            <div className="mb-2 w-100 text-danger text-center">
              Please upload file less than 6MB
            </div>
          )}
          <div className="mt-1">
            <button
              disabled={isFileUploading}
              onClick={handleFileUpload}
              className="button button-blue upload-btn w-100 py-2 fs-20"
            >
              {conditionalExp(isFileUploading, <Loader />, "Upload")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <ErrorModal
        error={error}
        showErrModal={showErrModal}
        setShowErrModal={setShowErrModal}
      />
      <AddWarehouseModal
        showwarehouseAdd={showwarehouseAdd}
        addNewWarehouseDetails={addNewWarehouseDetails}
        handleClosewarehouseAdd={handleClosewarehouseAdd}
        setaddNewWarehouseDetails={setaddNewWarehouseDetails}
        setwarehouseListDetailsRaw={setwarehouseList}
        setselectedWarehouse={setselectedWarehouse}
        customerId={selectedOptionCustomer?.value}
        getWarehouseList={() =>
          getWarehouseList(
            selectedOptionCustomer?.value,
            null,
            setwarehouseList,
            navigate,
            null
          )
        }
        everest_type={selectedOptionCustomer?.everest_type}
      />
      <AddVendorModal
        showvendorAdd={showvendorAdd}
        handleClosevendorAdd={handleClosevendorAdd}
        addNewVendorDetails={addNewVendorDetails}
        setaddNewVendorDetails={setaddNewVendorDetails}
        optionsForCountries={options}
        optionsForPorts={optionsPort}
        selectedOptionCountry={selectedOption}
        setselectedOptionCountry={setSelectedOption}
        selectedOptionPort={selectedOptionPort}
        setSelectedOption={setselectedVendor}
        setSelectedOptionPort={setSelectedOptionPort}
        setoptionsForPorts={setoptionsPort}
        customerId={selectedOptionCustomer?.value}
        getVendorList={() =>
          getVendorList(
            selectedOptionCustomer?.value,
            null,
            null,
            setvendorList,
            navigate
          )
        }
        setvendorListDetailsRaw={setvendorList}
      />
      <Modal
        size="xl"
        backdrop="static"
        show={showCommentModal}
        centered
        scrollable
        onHide={() => {
          setShowCommentModal(false);
          setCommentData("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Comments</Modal.Title>
        </Modal.Header>
        <Modal.Body className=" text-center ">
          <Ckeditor commentData={commentData} setCommentData={setCommentData} />
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <button
              className="ms-auto next1-btn rounded-2"
              disabled={addingComment}
              onClick={(e) => {
                handleAddComment(e);
              }}
            >
              {conditionalExp(addingComment, <Loader />, "Submit")}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Self_Pricing;
